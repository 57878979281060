import { Box } from "@chakra-ui/react";
import React, { useRef } from "react";

const FileUploadButton = ({
  onChange,
  children,
  multiple = false,
  name,
}: {
  onChange: (e: File[]) => void;
  children: React.ReactNode;
  multiple?: boolean;
  name: string;
}) => {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <label>
      <input
        type="file"
        hidden
        name={name}
        ref={ref}
        onChange={(e) => {
          if (e.target.files == null) return;

          onChange(Array.from(e.target.files));
          e.target.value = "";
        }}
        multiple={multiple}
      />
      <Box as="button" type="button" onClick={() => ref.current?.click()}>
        {children}
      </Box>
    </label>
  );
};

export default FileUploadButton;
