import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import YubinBango from "yubinbango.js";
import * as yup from "yup";
import { SharedPrefectures } from "../../../shared/lib/types";

const schema = yup.object({
  business_type: yup.string().trim().required().label("事業区分"),
  name: yup.string().trim().required().label("事業者名"),
  name_kana: yup.string().trim().hiragana().label("事業者名かな"),
  postal_code: yup
    .string()
    .trim()
    .required()
    .matches(/^\d{7}$/, {
      message: "ハイフン無しの7桁の数字を入力してください",
      excludeEmptyString: true,
    })
    .label("郵便番号"),
  prefecture_id: yup.string().trim().required().label("都道府県"),
  address1: yup.string().trim().required().label("市区町村"),
  address2: yup.string().trim().required().label("番地"),
  address3: yup.string().trim().label("建物名"),
  phone_number: yup.string().trim().required().phoneNumber().label("電話番号"),
  email: yup.string().trim().email().required().label("メールアドレス"),
  industry_id: yup.string().trim().required().label("主業種"),
  number_of_employees: yup
    .string()
    .trim()
    .required()
    .matches(/^(0|[1-9][0-9]*)$/, "半角数字で入力してください")
    .label("従業員数"),
  establishment_year: yup
    .number()
    .transform((value, originalValue) =>
      originalValue === "" ? undefined : value,
    )
    .required()
    .min(500)
    .max(new Date().getFullYear())
    .label("設立年"),
  establishment_month: yup
    .number()
    .transform((value, originalValue) =>
      originalValue === "" ? undefined : value,
    )
    .required()
    .min(1)
    .max(12)
    .label("設立月"),
  capital_ten_thousand_yen: yup
    .string()
    .trim()
    .matches(/^(0|[1-9][0-9]*)$/, "半角数字で入力してください")
    .required()
    .label("資本金"),
  president_name: yup.string().trim().required().label("代表者氏名"),
  website_url: yup.string().trim().url().label("Webサイト"),
  facebook_url: yup.string().trim().url().label("Facebook"),
  instagram_url: yup.string().trim().url().label("Instagram"),
  note_url: yup.string().trim().url().label("note"),
  pinterest_url: yup.string().trim().url().label("Pinterest"),
  threads_url: yup.string().trim().url().label("Threads"),
  tiktok_url: yup.string().trim().url().label("TikTok"),
  x_url: yup.string().trim().url().label("X（旧Twitter）"),
  youtube_url: yup.string().trim().url().label("YouTube"),
  agreement: yup.bool().is([true], "「利用規約」に同意してください"),
});

export type BusinessEntityFormData = yup.InferType<typeof schema>;

type BusinessEntityFormDefaultValues = Omit<
  yup.InferType<typeof schema>,
  "establishment_year" | "establishment_month"
> & {
  establishment_year: number | undefined;
  establishment_month: number | undefined;
};

export const useBusinessEntityForm = ({
  defaultValues,
  prefectures,
}: {
  defaultValues: BusinessEntityFormDefaultValues;
  prefectures: SharedPrefectures;
}) => {
  const form = useForm<BusinessEntityFormData>({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const fillAddressByPostalCode = async (postalCode: string) => {
    if (postalCode.length !== 7) return;

    try {
      const address = await YubinBango.getAddress(postalCode);
      const prefectureId = prefectures.find(
        (prefecture) => prefecture.name === address.prefecture,
      )!.id;

      form.setValue("prefecture_id", prefectureId.toString());
      form.setValue("address1", `${address.locality}${address.street}`);
      if (address.extended) form.setValue("address2", address.extended);

      form.clearErrors("postal_code");
    } catch (e) {
      if (
        e instanceof Error &&
        e.message.includes(`Can't find matched data.`)
      ) {
        form.setError("postal_code", { message: "存在しない郵便番号です" });
      } else {
        throw e;
      }
    }
  };

  return {
    fillAddressByPostalCode,
    ...form,
  };
};
