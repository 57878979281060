import {
  Box,
  ButtonProps,
  List,
  ListItem,
  ListItemProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import React from "react";
import MoreHorizIcon from "../icons/MoreHorizIcon";

const MoreMenu = ({
  buttonProps,
  listItemProps,
  listItemText,
}: {
  buttonProps: ButtonProps & { "data-testid"?: string };
  listItemProps: ListItemProps & { href?: string };
  listItemText: string;
}) => {
  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Box as="button" color="#348875" boxSize={10} {...buttonProps}>
          <MoreHorizIcon />
        </Box>
      </PopoverTrigger>
      <PopoverContent w={64}>
        <PopoverBody border="1px solid #8DCCBE" borderRadius={4} p={2}>
          <List>
            <ListItem
              cursor="pointer"
              w="full"
              display="block"
              px={3}
              py={1.5}
              _hover={{
                bgColor: "#EDF5F3",
              }}
              {...listItemProps}
            >
              <Box pt={1}>{listItemText}</Box>
            </ListItem>
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default MoreMenu;
