import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const ChevronRightIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" boxSize={5} {...props}>
    <path
      id="Vector"
      d="M8.08748 5L6.91248 6.175L10.7291 10L6.91248 13.825L8.08748 15L13.0875 10L8.08748 5Z"
      fill="currentColor"
    />
  </Icon>
);

export default ChevronRightIcon;
