import React from "react";
import { studyGroupPath, studyGroupsPath } from "../../../routes";
import { Flash, SharedTag } from "../../shared/lib/types";
import Footer from "../shared/components/atoms/Footer";
import Header from "../shared/components/atoms/Header";
import Application from "../shared/components/layouts/Application";
import { SharedApprovedCurrentUser } from "../shared/lib/types";
import useRequest from "../shared/lib/useRequest";
import StudyGroupForm, { RequestType } from "./components/StudyGroupForm";

const StudyGroupsNew = ({
  tags,
  flash,
  currentUser,
}: {
  tags: SharedTag[];
  flash: Flash;
  currentUser: SharedApprovedCurrentUser;
}) => {
  const request = useRequest();
  const onSubmit = async (formData: RequestType) => {
    const res = await request(studyGroupsPath(), "POST", formData);
    if (res.ok) {
      const json = await res.json();
      location.href = studyGroupPath(json.code);
    }
  };

  return (
    <Application flash={flash} currentUser={currentUser}>
      <Header currentUser={currentUser} />
      <StudyGroupForm
        defaultValues={{
          title: "",
          description: "",
          viewable_datetime: {
            started_at: "",
            ended_at: "",
          },
          tag_ids: [],
          thumbnail_url: "",
          instructor_info: {
            name: "",
            introduction: "",
            user_url: "",
            image_url: "",
          },
          html_meta_description: "",
          published: false,
          comment_enabled: false,
        }}
        tags={tags}
        title="勉強会作成"
        submitButtonLabel="勉強会を作成"
        onSubmit={onSubmit}
      />
      <Footer />
    </Application>
  );
};

export default StudyGroupsNew;
