import { Flex, FlexProps, Text } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import ErrorIcon from "../icons/ErrorIcon";

const WarningMessage = ({
  children,
  ...props
}: {
  children: ReactNode;
} & FlexProps) => {
  return (
    <Flex
      p={3}
      gap={2}
      color="#9B2C2C"
      bgColor="#FFF5F5"
      borderRadius="2px"
      {...props}
    >
      <ErrorIcon />
      <Text fontSize="sm">{children}</Text>
    </Flex>
  );
};

export default WarningMessage;
