import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const SendIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" boxSize={5} {...props}>
    <path
      d="M2.5 16.6668V3.3335L18.3333 10.0002L2.5 16.6668ZM4.16667 14.1668L14.0417 10.0002L4.16667 5.8335V8.75016L9.16667 10.0002L4.16667 11.2502V14.1668Z"
      fill="currentColor"
    />
  </Icon>
);

export default SendIcon;
