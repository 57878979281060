import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const LockIcon = (props: IconProps) => (
  <Icon viewBox="0 0 16 17" boxSize={5} {...props}>
    <path
      id="Vector"
      d="M11.9998 6.16667H11.3332V4.83333C11.3332 2.99333 9.83984 1.5 7.99984 1.5C6.15984 1.5 4.6665 2.99333 4.6665 4.83333V6.16667H3.99984C3.2665 6.16667 2.6665 6.76667 2.6665 7.5V14.1667C2.6665 14.9 3.2665 15.5 3.99984 15.5H11.9998C12.7332 15.5 13.3332 14.9 13.3332 14.1667V7.5C13.3332 6.76667 12.7332 6.16667 11.9998 6.16667ZM5.99984 4.83333C5.99984 3.72667 6.89317 2.83333 7.99984 2.83333C9.1065 2.83333 9.99984 3.72667 9.99984 4.83333V6.16667H5.99984V4.83333ZM11.9998 14.1667H3.99984V7.5H11.9998V14.1667ZM7.99984 12.1667C8.73317 12.1667 9.33317 11.5667 9.33317 10.8333C9.33317 10.1 8.73317 9.5 7.99984 9.5C7.2665 9.5 6.6665 10.1 6.6665 10.8333C6.6665 11.5667 7.2665 12.1667 7.99984 12.1667Z"
      fill="currentColor"
    />
  </Icon>
);

export default LockIcon;
