import {
  Box,
  Flex,
  Heading,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  businessMatchingOwnedBusinessEntityActiveBusinessEntityInfoApplicationPath,
  businessMatchingOwnedBusinessEntityBusinessEntityInfoApplicationEmailVerificationTokenPath,
  validateBusinessMatchingOwnedBusinessEntityPath,
} from "../../../../routes";
import Dropzone from "../../shared/components/atoms/Dropzone";
import DropzonePreview from "../../shared/components/atoms/DropzonePreview";
import { FormLabel, Input } from "../../shared/components/atoms/form";
import Select from "../../shared/components/atoms/form/Select";
import WarningMessage from "../../shared/components/atoms/WarningMessage";
import ModeEditIcon from "../../shared/components/icons/ModeEditIcon";
import {
  BusinessMatchingOwnedBusinessEntity,
  SharedApprovedCurrentUser,
  SharedIndustries,
  SharedPrefectures,
} from "../../shared/lib/types";
import useAutokana from "../../shared/lib/useAutokana";
import useFileState from "../../shared/lib/useFileState";
import useRequest from "../../shared/lib/useRequest";
import { Flash } from "../../../shared/lib/types";
import { uploadFiles } from "../../../shared/lib/uploadFile";
import OwnedBusinessEntityLayout from "../shared/components/OwnedBusinessEntityLayout";
import InfoItem from "../shared/components/InfoItem";
import InfoItemLeft from "../shared/components/InfoItemLeft";
import InfoItemRight from "../shared/components/InfoItemRight";
import getEstablishmentYears from "./lib/getEstablishmentYears";
import {
  BusinessEntityFormData,
  useBusinessEntityForm,
} from "./lib/useBusinessEntityForm";
import { Button } from "../../shared/components/atoms";
import { handleValidateEmailResponse } from "./lib/handleValidateEmailResponse";
import MarkEmailUnreadIcon from "../../shared/components/icons/MarkEmailUnreadIcon";
import SendIcon from "../../shared/components/icons/SendIcon";
import useFlash from "../../shared/lib/useFlash";

const OwnedBusinessEntitiesEdit = ({
  flash,
  currentUser,
  prefectures,
  industries,
  ownedBusinessEntity,
}: {
  flash: Flash;
  currentUser: SharedApprovedCurrentUser;
  prefectures: SharedPrefectures;
  industries: SharedIndustries;
  ownedBusinessEntity: BusinessMatchingOwnedBusinessEntity;
}) => {
  const { is_approved, applicationing, is_email_verified, ...defaultValues } =
    ownedBusinessEntity;

  const {
    fillAddressByPostalCode,
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { isDirty, isSubmitting },
    reset,
  } = useBusinessEntityForm({
    defaultValues: defaultValues,
    prefectures: prefectures,
  });
  const nameAutoKana = useAutokana("#name", "#name_kana");
  const logoImageFile = useFileState({
    required: true,
    firstPreview: ownedBusinessEntity.logo_image_url,
  });
  const request = useRequest();
  const [editing, setEditing] = useState<boolean>(false);
  const showFlash = useFlash();
  const resendingVerificationMailFormMethods = useForm();

  const createRequestData = async (data: BusinessEntityFormData) => {
    const { establishment_year, establishment_month, ...restData } = data;

    const requestData = {
      business_entity_info_application: {
        ...restData,
        establishment_year_month: `${establishment_year}/${establishment_month}`,
        logo_image: ownedBusinessEntity.logo_image_signed_id,
      },
    };

    if (logoImageFile.file) {
      const results = await uploadFiles({ files: [logoImageFile.file] });
      requestData.business_entity_info_application.logo_image =
        results[0].blob.signed_id;
    }

    return requestData;
  };

  const validateEmail = async (data: BusinessEntityFormData) => {
    const res = await request(
      validateBusinessMatchingOwnedBusinessEntityPath(ownedBusinessEntity.code),
      "POST",
      { business_entity_info_application: { email: data.email } },
    );
    return await handleValidateEmailResponse({
      response: res,
      methods: { setError, clearErrors },
    });
  };

  const submitInfoApplication = async (data: BusinessEntityFormData) => {
    if (!logoImageFile.validate()) return;
    if (!(await validateEmail(data))) {
      return;
    }

    const requestData = await createRequestData(data);
    const res = await request(
      businessMatchingOwnedBusinessEntityActiveBusinessEntityInfoApplicationPath(
        ownedBusinessEntity.code,
      ),
      "POST",
      requestData,
    );

    if (res.ok) {
      location.reload();
    }
  };

  const updateInfoApplication = async (data: BusinessEntityFormData) => {
    if (!logoImageFile.validate()) return;
    if (!(await validateEmail(data))) {
      return;
    }

    const requestData = await createRequestData(data);
    const res = await request(
      businessMatchingOwnedBusinessEntityActiveBusinessEntityInfoApplicationPath(
        ownedBusinessEntity.code,
      ),
      "PUT",
      requestData,
    );

    if (res.ok) {
      location.reload();
    }
  };

  const resendVerificationMail = async () => {
    const res = await request(
      businessMatchingOwnedBusinessEntityBusinessEntityInfoApplicationEmailVerificationTokenPath(
        ownedBusinessEntity.code,
      ),
      "PUT",
    );

    if (res.ok) {
      showFlash({
        success: <>認証メールを送信しました</>,
      });
    } else {
      showFlash({
        error: (
          <>
            認証メールの送信に失敗しました
            <br />
            ページをリロードしてください
          </>
        ),
      });
    }
  };

  const midiumFormMaxW = "320px";
  const shortFormMaxW = "240px";
  const snsUrlFormMaxW = { base: "160px", md: "276px" };
  const isFormDisabled = applicationing && !editing;

  return (
    <OwnedBusinessEntityLayout
      flash={flash}
      currentUser={currentUser}
      currentBusinessEntity={ownedBusinessEntity}
    >
      <Box
        as="form"
        onSubmit={handleSubmit(
          async (data) => {
            if (editing) {
              await updateInfoApplication(data);
            } else {
              await submitInfoApplication(data);
            }
          },
          () => logoImageFile.validate(),
        )}
      >
        <Box background="#FFF" p={8} borderRadius="2px">
          <Heading as="h1" size="lg">
            事業者確認情報
          </Heading>
          {is_email_verified === false ? (
            <>
              <Flex
                mt={4}
                p={3}
                gap={3}
                color="#9B2C2C"
                bgColor="#FFF5F5"
                borderRadius="2px"
                align="center"
                direction={{ base: "column", md: "row" }}
              >
                <HStack gap={3}>
                  <MarkEmailUnreadIcon boxSize={8} color="text.caution.dark" />
                  <Text fontSize="sm">
                    申請いただいたメールアドレスの認証が未完了のため、現在サービスご利用審査が一時停止中です。メールアドレスの認証を完了することでご利用審査が開始されます。
                  </Text>
                </HStack>
                <Button
                  pl={3}
                  pr={4}
                  py={2.5}
                  flexShrink={0}
                  w={{ base: "full", md: "fit-content" }}
                  onClick={resendingVerificationMailFormMethods.handleSubmit(
                    resendVerificationMail,
                  )}
                  isDisabled={
                    resendingVerificationMailFormMethods.formState.isSubmitting
                  }
                >
                  <HStack gap={1}>
                    <SendIcon boxSize="18px" />
                    <Box as="span" pt={0.5} fontSize="sm">
                      認証メール再送信
                    </Box>
                  </HStack>
                </Button>
              </Flex>
            </>
          ) : applicationing ? (
            <WarningMessage mt={4}>
              現在、審査中のため一時的に編集が不可になっています。
              <br />
              内容を変更したい場合はページ下部の「申請内容を変更して再申請」より、再変更を行ってください。
            </WarningMessage>
          ) : (
            <WarningMessage mt={4}>
              事業者確認情報を更新した場合、弊社で内容を審査します。
              <br />
              審査終了後、更新いただいた内容に反映されます。
            </WarningMessage>
          )}
          <Stack mt={8} gap={6}>
            <InfoItem>
              <InfoItemLeft>
                <FormLabel htmlFor="name" required>
                  事業者名
                </FormLabel>
              </InfoItemLeft>
              <InfoItemRight>
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      id="name"
                      {...field}
                      required
                      error={error?.message}
                      onInput={() => {
                        setValue("name_kana", nameAutoKana?.getFurigana());
                      }}
                      isDisabled={isFormDisabled}
                    />
                  )}
                />
              </InfoItemRight>
            </InfoItem>
            <InfoItem>
              <InfoItemLeft>
                <FormLabel htmlFor="name_kana">事業者名かな</FormLabel>
              </InfoItemLeft>
              <InfoItemRight>
                <Controller
                  name="name_kana"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      id="name_kana"
                      {...field}
                      error={error?.message}
                      isDisabled={isFormDisabled}
                    />
                  )}
                />
              </InfoItemRight>
            </InfoItem>
            <InfoItem>
              <InfoItemLeft>
                <FormLabel htmlFor="postal_code" required>
                  郵便番号
                </FormLabel>
              </InfoItemLeft>
              <InfoItemRight>
                <Box maxW={shortFormMaxW}>
                  <Controller
                    name="postal_code"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Input
                        {...field}
                        required
                        error={error?.message}
                        onChange={async (e) => {
                          field.onChange(e);
                          await fillAddressByPostalCode(e.target.value);
                        }}
                        isDisabled={isFormDisabled}
                      />
                    )}
                  />
                </Box>
              </InfoItemRight>
            </InfoItem>
            <InfoItem>
              <InfoItemLeft>
                <FormLabel htmlFor="prefecture_id" required>
                  都道府県
                </FormLabel>
              </InfoItemLeft>
              <InfoItemRight>
                <Box maxW={shortFormMaxW}>
                  <Controller
                    name="prefecture_id"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Select
                        required
                        error={error?.message ?? ""}
                        {...field}
                        isDisabled={isFormDisabled}
                      >
                        <option value="" key="empty">
                          選択してください
                        </option>
                        {prefectures.map((prefecture) => (
                          <option value={prefecture.id} key={prefecture.id}>
                            {prefecture.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  />
                </Box>
              </InfoItemRight>
            </InfoItem>
            <InfoItem>
              <InfoItemLeft>
                <FormLabel htmlFor="address1" required>
                  市区町村
                </FormLabel>
              </InfoItemLeft>
              <InfoItemRight>
                <Controller
                  name="address1"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      required
                      error={error?.message}
                      isDisabled={isFormDisabled}
                    />
                  )}
                />
              </InfoItemRight>
            </InfoItem>
            <InfoItem>
              <InfoItemLeft>
                <FormLabel htmlFor="address2" required>
                  番地
                </FormLabel>
              </InfoItemLeft>
              <InfoItemRight>
                <Controller
                  name="address2"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      required
                      error={error?.message}
                      isDisabled={isFormDisabled}
                    />
                  )}
                />
              </InfoItemRight>
            </InfoItem>
            <InfoItem>
              <InfoItemLeft>
                <FormLabel htmlFor="address3">建物名</FormLabel>
              </InfoItemLeft>
              <InfoItemRight>
                <Controller
                  name="address3"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      error={error?.message}
                      isDisabled={isFormDisabled}
                    />
                  )}
                />
              </InfoItemRight>
            </InfoItem>
            <InfoItem>
              <InfoItemLeft>
                <FormLabel htmlFor="phone_number" required>
                  電話番号
                </FormLabel>
              </InfoItemLeft>
              <InfoItemRight>
                <Box maxW={midiumFormMaxW}>
                  <Controller
                    name="phone_number"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Input
                        {...field}
                        required
                        error={error?.message}
                        isDisabled={isFormDisabled}
                      />
                    )}
                  />
                </Box>
              </InfoItemRight>
            </InfoItem>
            <InfoItem>
              <InfoItemLeft>
                <FormLabel htmlFor="email" required>
                  メールアドレス
                </FormLabel>
              </InfoItemLeft>
              <InfoItemRight>
                <Box maxW={midiumFormMaxW}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Input
                        {...field}
                        required
                        error={error?.message}
                        isDisabled={isFormDisabled}
                      />
                    )}
                  />
                </Box>
              </InfoItemRight>
            </InfoItem>
            <InfoItem>
              <InfoItemLeft>
                <FormLabel htmlFor="industry_id" required>
                  主業種
                </FormLabel>
              </InfoItemLeft>
              <InfoItemRight>
                <Box maxW={shortFormMaxW}>
                  <Controller
                    name="industry_id"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Select
                        required
                        error={error?.message ?? ""}
                        {...field}
                        isDisabled={isFormDisabled}
                      >
                        <option value="" key="empty">
                          選択してください
                        </option>
                        {industries.map((industry) => (
                          <option value={industry.id} key={industry.id}>
                            {industry.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  />
                </Box>
              </InfoItemRight>
            </InfoItem>
            <InfoItem>
              <InfoItemLeft>
                <FormLabel htmlFor="number_of_employees" required>
                  従業員数
                </FormLabel>
              </InfoItemLeft>
              <InfoItemRight>
                <Box maxW={shortFormMaxW}>
                  <Controller
                    name="number_of_employees"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Input
                        {...field}
                        required
                        error={error?.message}
                        isDisabled={isFormDisabled}
                      />
                    )}
                  />
                </Box>
              </InfoItemRight>
            </InfoItem>
            <InfoItem>
              <InfoItemLeft>
                <FormLabel required>設立年月</FormLabel>
              </InfoItemLeft>
              <InfoItemRight>
                <HStack gap={4}>
                  <HStack gap={2} flex={1}>
                    <Controller
                      name="establishment_year"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Select
                          required
                          error={error?.message ?? ""}
                          {...field}
                          aria-label="設立年"
                          isDisabled={isFormDisabled}
                        >
                          <option value="" key="empty"></option>
                          {getEstablishmentYears().map((year) => (
                            <option value={year} key={year}>
                              {year}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                    <Text as="span">年</Text>
                  </HStack>
                  <HStack gap={2} flex={1}>
                    <Controller
                      name="establishment_month"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Select
                          required
                          error={error?.message ?? ""}
                          {...field}
                          aria-label="設立月"
                          isDisabled={isFormDisabled}
                        >
                          <option value="" key="empty"></option>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                            (month) => (
                              <option value={month} key={month}>
                                {month}
                              </option>
                            ),
                          )}
                        </Select>
                      )}
                    />
                    <Text as="span">月</Text>
                  </HStack>
                </HStack>
              </InfoItemRight>
            </InfoItem>
            <InfoItem>
              <InfoItemLeft>
                <FormLabel htmlFor="capital_tem_thousand_yen" required>
                  資本金
                </FormLabel>
              </InfoItemLeft>
              <InfoItemRight>
                <Box maxW={shortFormMaxW}>
                  <HStack gap={2}>
                    <Controller
                      name="capital_ten_thousand_yen"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          required
                          error={error?.message}
                          id="capital_ten_thousand_yen"
                          isDisabled={isFormDisabled}
                        />
                      )}
                    />
                    <Text as="span" whiteSpace="nowrap">
                      万円
                    </Text>
                  </HStack>
                </Box>
              </InfoItemRight>
            </InfoItem>
            <InfoItem>
              <InfoItemLeft>
                <FormLabel required>事業者ロゴ</FormLabel>
              </InfoItemLeft>
              <InfoItemRight>
                <Box>
                  {logoImageFile.preview == null ? (
                    <Dropzone
                      onDrop={(files) => {
                        logoImageFile.setFile(files[0]);
                      }}
                      name="logo_image"
                      message={
                        "PNGもしくはJPGファイルで、5MB未満のサイズでアップロードしてください。"
                      }
                      error={logoImageFile.error}
                      accept={{
                        "image/png": [],
                        "image/jpeg": [],
                      }}
                    />
                  ) : (
                    <DropzonePreview
                      fileState={logoImageFile}
                      editable={!applicationing}
                    />
                  )}
                </Box>
              </InfoItemRight>
            </InfoItem>
            <InfoItem>
              <InfoItemLeft>
                <FormLabel htmlFor="president_name" required>
                  代表者指名
                </FormLabel>
              </InfoItemLeft>
              <InfoItemRight>
                <Controller
                  name="president_name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      required
                      error={error?.message}
                      isDisabled={isFormDisabled}
                    />
                  )}
                />
              </InfoItemRight>
            </InfoItem>
            <InfoItem>
              <InfoItemLeft>
                <FormLabel htmlFor="website_url">Webサイト</FormLabel>
              </InfoItemLeft>
              <InfoItemRight>
                <Controller
                  name="website_url"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      error={error?.message}
                      isDisabled={isFormDisabled}
                    />
                  )}
                />
              </InfoItemRight>
            </InfoItem>
            <InfoItem>
              <InfoItemLeft>
                <FormLabel>SNS</FormLabel>
              </InfoItemLeft>
              <InfoItemRight>
                <Stack gap={6}>
                  <Flex justifyContent="space-between" align="center">
                    <FormLabel>Facebook</FormLabel>
                    <Box maxW={snsUrlFormMaxW}>
                      <Controller
                        name="facebook_url"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Input
                            {...field}
                            error={error?.message}
                            isDisabled={isFormDisabled}
                          />
                        )}
                      />
                    </Box>
                  </Flex>
                  <Flex justifyContent="space-between" align="center">
                    <FormLabel>Instagram</FormLabel>
                    <Box maxW={snsUrlFormMaxW}>
                      <Controller
                        name="instagram_url"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Input
                            {...field}
                            error={error?.message}
                            isDisabled={isFormDisabled}
                          />
                        )}
                      />
                    </Box>
                  </Flex>
                  <Flex justifyContent="space-between" align="center">
                    <FormLabel>note</FormLabel>
                    <Box maxW={snsUrlFormMaxW}>
                      <Controller
                        name="note_url"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Input
                            {...field}
                            error={error?.message}
                            isDisabled={isFormDisabled}
                          />
                        )}
                      />
                    </Box>
                  </Flex>
                  <Flex justifyContent="space-between" align="center">
                    <FormLabel>Pinterest</FormLabel>
                    <Box maxW={snsUrlFormMaxW}>
                      <Controller
                        name="pinterest_url"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Input
                            {...field}
                            error={error?.message}
                            isDisabled={isFormDisabled}
                          />
                        )}
                      />
                    </Box>
                  </Flex>
                  <Flex justifyContent="space-between" align="center">
                    <FormLabel>Threads</FormLabel>
                    <Box maxW={snsUrlFormMaxW}>
                      <Controller
                        name="threads_url"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Input
                            {...field}
                            error={error?.message}
                            isDisabled={isFormDisabled}
                          />
                        )}
                      />
                    </Box>
                  </Flex>
                  <Flex justifyContent="space-between" align="center">
                    <FormLabel>TikTok</FormLabel>
                    <Box maxW={snsUrlFormMaxW}>
                      <Controller
                        name="tiktok_url"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Input
                            {...field}
                            error={error?.message}
                            isDisabled={isFormDisabled}
                          />
                        )}
                      />
                    </Box>
                  </Flex>
                  <Flex justifyContent="space-between" align="center">
                    <FormLabel>X（旧Twitter）</FormLabel>
                    <Box maxW={snsUrlFormMaxW}>
                      <Controller
                        name="x_url"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Input
                            {...field}
                            error={error?.message}
                            isDisabled={isFormDisabled}
                          />
                        )}
                      />
                    </Box>
                  </Flex>
                  <Flex justifyContent="space-between" align="center">
                    <FormLabel>Youtube</FormLabel>
                    <Box maxW={snsUrlFormMaxW}>
                      <Controller
                        name="youtube_url"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Input
                            {...field}
                            error={error?.message}
                            isDisabled={isFormDisabled}
                          />
                        )}
                      />
                    </Box>
                  </Flex>
                </Stack>
              </InfoItemRight>
            </InfoItem>
          </Stack>
        </Box>
        <Stack align="center" mt={10} gap={6}>
          {editing ? (
            <>
              <Button
                type="submit"
                isDisabled={
                  (!isDirty && !logoImageFile.isDirty) || isSubmitting
                }
              >
                <Box as="span" pt={0.5}>
                  申請内容を変更して再申請
                </Box>
              </Button>
              <Link
                as="button"
                color="textLink"
                textDecoration="underline"
                _hover={{ textDecoration: "none" }}
                onClick={() => {
                  setEditing(false);
                  logoImageFile.reset();
                  reset();
                  window.scrollTo(0, 0);
                }}
              >
                申請内容の変更をキャンセル
              </Link>
            </>
          ) : applicationing ? (
            <Button
              variant="outline"
              onClick={() => {
                setEditing(true);
                window.scrollTo(0, 0);
              }}
            >
              <HStack gap="2.5px">
                <ModeEditIcon boxSize="18px" />
                <Box as="span" pt={0.5}>
                  申請内容を変更して再申請
                </Box>
              </HStack>
            </Button>
          ) : (
            <Button
              type="submit"
              isDisabled={
                ((applicationing || is_approved) &&
                  !isDirty &&
                  !logoImageFile.isDirty) ||
                isSubmitting
              }
            >
              <Box as="span" pt={0.5}>
                {is_approved ? (
                  <>上記内容への変更を申請</>
                ) : (
                  <>上記内容で受発注機能の利用を申請</>
                )}
              </Box>
            </Button>
          )}
          {applicationing && !editing && (
            <CancelInfoApplicationButton
              isApproved={is_approved}
              businessEntityCode={ownedBusinessEntity.code}
            />
          )}
        </Stack>
      </Box>
    </OwnedBusinessEntityLayout>
  );
};

const CancelInfoApplicationButton = ({
  isApproved,
  businessEntityCode,
}: {
  isApproved: boolean;
  businessEntityCode: string;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const request = useRequest();

  const cancelInfoApplication = async (businessEntityCode: string) => {
    const res = await request(
      businessMatchingOwnedBusinessEntityActiveBusinessEntityInfoApplicationPath(
        businessEntityCode,
      ),
      "DELETE",
    );

    if (res.ok) {
      location.reload();
    }
  };

  const cancelButtonLabel = isApproved
    ? "事業者情報変更の申請をキャンセル"
    : "受発注機能の利用申請をキャンセル";

  return (
    <>
      <Link
        as="button"
        type="button"
        color="textLink"
        textDecoration="underline"
        _hover={{ textDecoration: "none" }}
        onClick={onOpen}
      >
        {cancelButtonLabel}
      </Link>
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent mx={2}>
          <ModalHeader fontWeight={"bold"}>{cancelButtonLabel}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>申請をキャンセルしますがよろしいですか？</Text>
            {isApproved && (
              <WarningMessage mt={3}>
                <>変更箇所が破棄され、変更申請前の状態に戻ります。</>
              </WarningMessage>
            )}
          </ModalBody>
          <ModalFooter gap={3}>
            <Button color="gray" onClick={onClose}>
              <Box as="span" pt={0.5}>
                戻る
              </Box>
            </Button>
            <Button
              color="red"
              onClick={handleSubmit(async () => {
                await cancelInfoApplication(businessEntityCode);
              })}
              isDisabled={isSubmitting}
            >
              <Box as="span" pt={0.5}>
                {cancelButtonLabel}
              </Box>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OwnedBusinessEntitiesEdit;
