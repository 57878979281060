import { Avatar, Box, HStack, Stack, Text } from "@chakra-ui/react";
import React from "react";
import dayjs from "dayjs";
import {TalkRooms} from "../../../shared/lib/types";

const TalkMessageListItem = ({
  message,
  last_sent_at,
  onClick,
  partner,
  isActive,
}: {
  message: string;
  last_sent_at: string;
  onClick: () => void;
  isActive: boolean;
  partner: TalkRooms[number]["partner"]
}) => {
  return (
    <HStack
      gap={3}
      p={3}
      borderRadius={8}
      bgColor={isActive ? "brand.50" : "white"}
      _hover={{
        bgColor: "brand.50",
      }}
      transitionDuration={"0.2s"}
      transitionTimingFunction={"ease-in-out"}
      onClick={onClick}
    >
      <Box w={{ base: 12, md: "4.5rem" }} flexShrink={0}>
        <Avatar
          size="full"
          src={partner.image_url}
        />
      </Box>
      <Stack gap={1}>
        <Text fontSize={{ base: "sm", sm: "md" }}>
          {partner.name}
        </Text>
        <Text
          fontSize={{ base: "xs", sm: "0.8125rem" }}
          noOfLines={2}
          color={"textGray"}
        >
          {message}
        </Text>
        <Text fontSize="xs" noOfLines={2} color={"textGray"}>
          {dayjs(last_sent_at).format("LLL")}
        </Text>
      </Stack>
    </HStack>
  );
};

export default TalkMessageListItem;
