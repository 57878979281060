import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const BarChartIcon = (props: IconProps) => (
  <Icon viewBox="0 0 22 22" boxSize="22" {...props}>
    <path
      d="M14.6667 18.8332V12.4165H18.3334V18.8332H14.6667ZM9.16675 18.8332V4.1665H12.8334V18.8332H9.16675ZM3.66675 18.8332V8.74984H7.33341V18.8332H3.66675Z"
      fill="currentColor"
    />
  </Icon>
);

export default BarChartIcon;
