import { Badge, Box, FormLabelProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import LockIcon from "../../icons/LockIcon";

const FormLabel = ({
  children,
  required = false,
  htmlFor = "",
  withLockIcon = false,
  ...props
}: {
  children: ReactNode;
  required?: boolean;
  htmlFor?: string;
  withLockIcon?: boolean;
} & FormLabelProps) => {
  return (
    <Box
      as="label"
      alignItems="center"
      gap={1}
      fontSize={{ base: "sm", sm: "md" }}
      fontWeight="600"
      htmlFor={htmlFor}
      display="inline-block"
      {...props}
    >
      {children}
      {required && (
        <Badge
          background="caution"
          color="#FFF"
          fontSize="0.625rem"
          mb={0.5}
          ml={0.5}
        >
          必須
        </Badge>
      )}
      {withLockIcon && (
        <Box display="inline-block" verticalAlign="1px">
          <LockIcon boxSize={4} />
        </Box>
      )}
    </Box>
  );
};

export default FormLabel;
