import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Link,
  SlideFade,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { postCommentPath, postCommentUsefulPath } from "../../../../routes";
import { HelperImageFile } from "../../../shared/lib/types";
import CustomLinkLinkify from "../../shared/components/atoms/CustomLinkLinkify";
import useFlash from "../../shared/lib/useFlash";
import useRequest from "../../shared/lib/useRequest";
import {
  PostsShow,
  PostComment,
  SharedCurrentUser,
} from "../../shared/lib/types";
import PostResourceAvatar from "../../shared/components/atoms/PostResourceAvatar";
import PostResourceDisplayName from "../../shared/components/atoms/PostResourceDisplayName";
import ExpandMoreIcon from "../../shared/components/icons/ExpandMoreIcon";
import FilesWithoutImage from "../../shared/components/atoms/FilesWithoutImage";
import ImagesWithSlider from "../../shared/components/atoms/ImagesWithSlider";
import {
  CommentFormFields,
  EditModal,
  useCommentForm,
} from "./CommentReplyForm";
import MoreMenu from "../../shared/components/atoms/MoreMenu";
import Replies from "./Replies";
import UsefulButton from "./UsefulButton";
import UserProfileLink from "./UserProfileLink";

const CommentEditModal = ({
  comment,
  onUpdated,
  disclosure,
  currentUser,
}: {
  comment: PostComment;
  onUpdated: (comment: PostComment) => void;
  disclosure: ReturnType<typeof useDisclosure>;
  currentUser: SharedCurrentUser;
}) => {
  const request = useRequest();
  const showFlash = useFlash();

  const commentFormState = useCommentForm({
    onSubmit: async (data) => {
      const res = await request(postCommentPath(comment.code), "PUT", data);
      if (res.ok) {
        onUpdated(await res.json());
      } else if (res.status === 404) {
        showFlash({
          error: (
            <>
              コメントが見つかりませんでした。
              <br />
              ページを再読み込みしてください。
            </>
          ),
        });
      }
    },
    defaultValues: { content: comment.content },
    defaultImageFiles: comment.image_files,
    defaultFilesWithoutImage: comment.files_without_image,
  });
  return (
    <EditModal
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      formState={commentFormState}
      title="コメント編集"
    >
      <CommentFormFields
        formState={commentFormState}
        currentUser={currentUser}
        anonymous={comment.author.anonymous}
      />
    </EditModal>
  );
};

const Comment = ({
  comment,
  currentUser,
  onUpdated,
  post,
}: {
  comment: PostComment;
  currentUser: SharedCurrentUser;
  onUpdated: (comment: PostComment) => void;
  post: PostsShow;
}) => {
  const [showReplies, setShowReplies] = useState(false);
  const [repliesCount, setRepliesCount] = useState(comment.replies_count);
  const [isReplyFocusInput, setIsReplyFocusInput] = useState(false);
  const disclosure = useDisclosure();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current == null) return;

    const search = new URLSearchParams(location.search);
    if (
      comment.code == search.get("comment_code") &&
      search.has("reply_code")
    ) {
      setShowReplies(true);
    } else if (comment.code == search.get("comment_code")) {
      ref.current.scrollIntoView({ block: "start" });
      // ピッタリはみづらいので微調整
      window.scrollTo(0, window.scrollY - 64);
    }
    // 初回のみ実行したいので
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SlideFade in offsetY="50px">
      <Flex gap={3} ref={ref}>
        <Stack>
          <UserProfileLink author={comment.author} currentUser={currentUser}>
            <PostResourceAvatar
              author={comment.author}
              w={{ base: 8, sm: 12 }}
              h={{ base: 8, sm: 12 }}
              loading="lazy"
              ignoreFallback
            />
          </UserProfileLink>
          {showReplies && (
            <Center h="full" w={{ base: 7, sm: 12 }}>
              <Divider
                orientation="vertical"
                borderWidth="1px"
                borderColor="brand.100"
                opacity="1"
              />
            </Center>
          )}
        </Stack>
        <Stack gap={1} w="full">
          <Flex justify="space-between" align="center">
            <UserProfileLink
              author={comment.author}
              currentUser={currentUser}
              fontSize="sm"
            >
              <PostResourceDisplayName author={comment.author} />
            </UserProfileLink>
            {!post.is_resolved && comment.is_own && (
              <MoreMenu
                buttonProps={{
                  "data-testid": "post-comment-detail-button",
                }}
                listItemProps={{
                  as: "button",
                  textAlign: "left",
                  onClick: disclosure.onOpen,
                }}
                listItemText="コメントを編集"
              />
            )}
          </Flex>
          <Box
            px={4}
            py={3}
            bgColor={comment.is_own ? "brand.100" : "#F5F5F5"}
            fontSize="sm"
            borderRadius="8px"
            wordBreak="break-word"
            whiteSpace="pre-wrap"
            w="full"
          >
            <Stack
              columnGap={3}
              rowGap={0}
              direction={{ base: "column", md: "row" }}
            >
              <Box fontSize="xs" color="#848484">
                投稿日：{dayjs(comment.created_at).format("L LT")}
              </Box>
              {comment.updated_at !== "" &&
                comment.created_at !== comment.updated_at && (
                  <Box fontSize="xs" color="#848484">
                    最終更新日：{dayjs(comment.updated_at).format("L LT")}
                  </Box>
                )}
            </Stack>
            <Box mt={2}>
              <CustomLinkLinkify>{comment.content}</CustomLinkLinkify>
            </Box>
            {comment.files_without_image.length !== 0 && (
              <Box mt={2}>
                <FilesWithoutImage files={comment.files_without_image} />
              </Box>
            )}
          </Box>
          {comment.image_files.length !== 0 && (
            <Box mt={2}>
              <ImagesWithSlider
                urls={comment.image_files.map(
                  (file: HelperImageFile) => file.url,
                )}
              />
            </Box>
          )}
          <Flex justifyContent="space-between">
            <UsefulButton
              postChild={comment}
              path={postCommentUsefulPath(comment.code)}
              isDisabled={comment.is_own}
            />
            <HStack gap={3}>
              {repliesCount !== 0 && (
                <Link
                  as="button"
                  fontSize="xs"
                  fontWeight="700"
                  onClick={async () => {
                    setShowReplies(!showReplies);
                    setIsReplyFocusInput(false);
                  }}
                >
                  <ExpandMoreIcon />
                  返信{repliesCount}件
                </Link>
              )}
              {!post.is_resolved && (
                <Link
                  as="button"
                  fontSize="xs"
                  onClick={async () => {
                    setShowReplies(true);
                    setIsReplyFocusInput(true);
                  }}
                >
                  返信する
                </Link>
              )}
            </HStack>
          </Flex>
        </Stack>
      </Flex>
      {showReplies && currentUser?.is_all_public_feature_accessible && (
        <Replies
          comment={comment}
          currentUser={currentUser}
          onFetched={(result) => setRepliesCount(result.replies_count)}
          onClose={() => setShowReplies(false)}
          isFocusInput={isReplyFocusInput}
          post={post}
        />
      )}
      <CommentEditModal
        comment={comment}
        onUpdated={onUpdated}
        disclosure={disclosure}
        currentUser={currentUser}
      />
    </SlideFade>
  );
};

const CommentWrapper = ({
  comment: propComment,
  currentUser,
  post,
}: {
  comment: PostComment;
  currentUser: SharedCurrentUser;
  post: PostsShow;
}) => {
  const [comment, setComment] = useState(propComment);
  return (
    <Comment
      key={comment.updated_at}
      comment={comment}
      currentUser={currentUser}
      onUpdated={setComment}
      post={post}
    />
  );
};

export default CommentWrapper;
