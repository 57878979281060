import { Box, Container, Flex, Heading } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { userPath } from "../../../../routes";
import { Flash } from "../../../shared/lib/types";
import Background from "../../shared/components/atoms/Background";
import Footer from "../../shared/components/atoms/Footer";
import GoBackLink from "../../shared/components/atoms/GoBackLink";
import Header from "../../shared/components/atoms/Header";
import SideMenu from "../../shared/components/atoms/SideMenu";
import Application from "../../shared/components/layouts/Application";
import { SharedCurrentUser } from "../../shared/lib/types";

const ProfilePageLayout = ({
  children,
  current_user,
  flash = {},
}: {
  children: ReactNode;
  current_user: NonNullable<SharedCurrentUser>;
  flash: Flash;
}) => {
  return (
    <Application flash={flash} currentUser={current_user}>
      <Header currentUser={current_user} />
      <Background color="#EDF5F3">
        <Container maxW="container.xl" mt={{ base: 6, md: 12 }}>
          <Heading as="p" size="lg" display={{ base: "block", md: "none" }}>
            プロフィール編集
          </Heading>
          <Flex
            columnGap={10}
            rowGap={4}
            direction={{ base: "column", md: "row" }}
            mt={{ base: 4, md: 0 }}
          >
            <Box flex="1">
              <SideMenu items={current_user.profile_page_sidebar_items} />
            </Box>
            <Box flex="4">
              <GoBackLink href={userPath(current_user.code)}>
                プロフィールに戻る
              </GoBackLink>
              <Box mt={2}>{children}</Box>
            </Box>
          </Flex>
        </Container>
      </Background>
      <Footer />
    </Application>
  );
};

export default ProfilePageLayout;
