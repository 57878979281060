import dayjs from "dayjs";
import React from "react";
import { studyGroupPath } from "../../../routes";
import Footer from "../shared/components/atoms/Footer";
import Header from "../shared/components/atoms/Header";
import Application from "../shared/components/layouts/Application";
import useRequest from "../shared/lib/useRequest";
import StudyGroupForm, { RequestType } from "./components/StudyGroupForm";
import useFlash from "../shared/lib/useFlash";
import {
  SharedApprovedCurrentUser,
  StudyGroupForEdit,
} from "../shared/lib/types";
import { Flash, SharedTag } from "../../shared/lib/types";

const StudyGroupsEdit = ({
  studyGroup,
  tags,
  flash,
  currentUser,
}: {
  studyGroup: StudyGroupForEdit;
  tags: SharedTag[];
  flash: Flash;
  currentUser: SharedApprovedCurrentUser;
}) => {
  const request = useRequest();
  const showFlash = useFlash();
  const onSubmit = async (formData: RequestType) => {
    const res = await request(studyGroupPath(studyGroup.code), "PUT", formData);
    if (res.ok) {
      location.href = studyGroupPath(studyGroup.code);
    } else if (res.status === 404) {
      showFlash({
        error: (
          <>
            勉強会が見つかりませんでした。
            <br />
            ページをリロードしてください。
          </>
        ),
      });
    }
  };

  return (
    <Application flash={flash} currentUser={currentUser}>
      <Header currentUser={currentUser} />
      <StudyGroupForm
        defaultValues={{
          ...studyGroup,
          viewable_datetime: {
            started_at: studyGroup.viewable_datetime.started_at
              ? dayjs(studyGroup.viewable_datetime.started_at).format(
                  "YYYY-MM-DDTHH:mm",
                )
              : "",
            ended_at: studyGroup.viewable_datetime.ended_at
              ? dayjs(studyGroup.viewable_datetime.ended_at).format(
                  "YYYY-MM-DDTHH:mm",
                )
              : "",
          },
          tag_ids: studyGroup.tags.map((tag) => tag.id.toString()),
        }}
        tags={tags}
        title="勉強会更新"
        submitButtonLabel="勉強会を更新"
        onSubmit={onSubmit}
      />
      <Footer />
    </Application>
  );
};

export default StudyGroupsEdit;
