import { Box, Heading, Stack } from "@chakra-ui/react";
import "cropperjs/dist/cropper.css";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  editProfileGuestUserPath,
  profileGuestUserPath,
} from "../../../../routes";
import { Button } from "../../shared/components/atoms";
import {
  ImageFileDropzone,
  useImageFileDropzoneState,
} from "../../shared/components/atoms/ImageFileDropzone";
import { FormLabel, Input } from "../../shared/components/atoms/form";
import useRequest from "../../shared/lib/useRequest";
import {
  ProfileItem,
  ProfileItemLeft,
  ProfileItemRight,
} from "../components/ProfileItem";
import ProfilePageLayout from "../components/ProfilePageLayout";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Flash } from "../../../shared/lib/types";
import { SharedApprovedCurrentUser } from "../../shared/lib/types";
import SiteSeal from "../../shared/components/atoms/SiteSeal";
import { uploadFile } from "../../../shared/lib/uploadFile";

const ProfileGuestUsersEdit = ({
  flash,
  currentUser,
}: {
  flash: Flash;
  currentUser: SharedApprovedCurrentUser;
}) => {
  const profileImageState = useImageFileDropzoneState({
    firstPreview: currentUser.profile_image_url,
    required: true,
  });

  const schema = yup.object().shape({
    name: yup.string().trim().required().label("ゲストユーザー名"),
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
    control,
  } = useForm({
    defaultValues: { name: currentUser.full_name },
    resolver: yupResolver(schema),
  });

  const request = useRequest();

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    const formData = new FormData();

    formData.append("guest_user[name]", data.name);

    if (profileImageState.file) {
      const { blob } = await uploadFile({ file: profileImageState.file! });
      formData.append("guest_user[profile_image]", blob.signed_id);
    }

    const res = await request(profileGuestUserPath(), "PUT", formData);

    if (res.ok) {
      location.href = editProfileGuestUserPath();
    }
  };

  return (
    <ProfilePageLayout current_user={currentUser} flash={flash}>
      <Stack as="form" onSubmit={handleSubmit(onSubmit)}>
        <Box
          background="#FFF"
          px={{ base: 5, md: 8 }}
          py={{ base: 6, md: 10 }}
          borderRadius="2px"
        >
          <Heading as="h1" size="lg">
            ゲストユーザー情報
          </Heading>
          <Stack mt={{ base: 6, md: 10 }} gap={6}>
            <ProfileItem>
              <ProfileItemLeft>
                <FormLabel required htmlFor="name">
                  ゲストユーザー名
                </FormLabel>
              </ProfileItemLeft>
              <ProfileItemRight>
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Input {...field} id="name" error={error?.message} />
                  )}
                />
              </ProfileItemRight>
            </ProfileItem>
            <ProfileItem>
              <ProfileItemLeft>
                <FormLabel required>プロフィール写真</FormLabel>
              </ProfileItemLeft>
              <ProfileItemRight>
                <Box>
                  <ImageFileDropzone
                    name="profile_image"
                    croppable
                    imageFileDropzoneState={profileImageState}
                    previewBoxProps={{ maxW: { base: "full", sm: "50%" } }}
                    rounded
                    label="プロフィール写真"
                  />
                </Box>
              </ProfileItemRight>
            </ProfileItem>
          </Stack>
        </Box>
        <Box mt={{ base: 8, md: 10 }} textAlign="center">
          <Button
            maxW="fit-content"
            type="submit"
            isDisabled={
              (!isDirty && profileImageState.file === undefined) || isSubmitting
            }
          >
            変更を保存
          </Button>
        </Box>
      </Stack>
      <Box mt={6}>
        <SiteSeal />
      </Box>
    </ProfilePageLayout>
  );
};

export default ProfileGuestUsersEdit;
