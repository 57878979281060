import { DirectUpload } from "@rails/activestorage";

// https://railsguides.jp/active_storage_overview.html#ライブラリやフレームワークとの統合
class DirectUploader {
  #upload: DirectUpload;
  #setProgress?: (n: number) => void;

  constructor(
    file: File,
    url: string,
    setProgress?: (progress: number) => void,
  ) {
    this.#upload = new DirectUpload(file, url, this);
    this.#setProgress = setProgress;
    // 大きいファイルだとアップロード処理の開始自体に時間がかかり、何も動作していないように見えてしまうのを防ぐため
    if (this.#setProgress) {
      this.#setProgress(0.1);
    }
  }

  async upload(): Promise<{ blob: { signed_id: string }; error: Error }> {
    return new Promise((resolve) => {
      this.#upload.create((error, blob) => {
        resolve({ blob, error });
      });
    });
  }

  directUploadWillStoreFileWithXHR(request: XMLHttpRequest) {
    request.upload.addEventListener("progress", (event) =>
      this.directUploadDidProgress(event),
    );
  }

  directUploadDidProgress(event: ProgressEvent) {
    const percentComplete = (event.loaded / event.total) * 100;
    if (this.#setProgress) {
      this.#setProgress(percentComplete);
    }
  }
}

export const uploadFile = async ({
  file,
  setProgress,
}: {
  file: File;
  setProgress?: (progress: number) => void;
}) => {
  const onBeforeUnload = (e: Event) => {
    e.preventDefault();
  };
  window.addEventListener("beforeunload", onBeforeUnload);
  const result = await new DirectUploader(
    file,
    "/rails/active_storage/direct_uploads",
    setProgress,
  ).upload();
  window.removeEventListener("beforeunload", onBeforeUnload);

  return result;
};

export const uploadFiles = async ({ files }: { files: File[] }) => {
  const promises = files.map((file) => uploadFile({ file }));

  return await Promise.all(promises)
};
