import { Flex, FlexProps } from "@chakra-ui/react";
import React from "react";
import { AnnouncementsAnnouncementCategory } from "../../lib/types";

const AnnouncementCategory = ({
  category,
  ...props
}: {
  category: AnnouncementsAnnouncementCategory;
} & FlexProps) => {
  return (
    <Flex
      fontSize="xs"
      fontWeight="bold"
      minW={24}
      backgroundColor={category.background_color}
      px={2}
      py={0.5}
      borderRadius={2}
      justify="center"
      color="white"
      {...props}
    >
      {category.name}
    </Flex>
  );
};

export default AnnouncementCategory;
