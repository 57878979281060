import { Link } from "@chakra-ui/react";
import Linkify from "linkify-react";
import React, { ReactNode } from "react";

const CustomLinkLinkify = ({ children }: { children: ReactNode }) => {
  return (
    <Linkify
      as="p"
      options={{
        render: ({ attributes, content }) => {
          // 半角以外の文字と,はリンクとして許可しない
          const index = content.search(/[^ -~｡-ﾟ]|[,]/);
          const contentIsLinkOnly = index < 0;
          if (contentIsLinkOnly) {
            return (
              <Link {...attributes} target="_blank" rel="noreferrer">
                {content}
              </Link>
            );
          } else {
            return (
              <>
                <Link {...attributes} target="_blank" rel="noreferrer">
                  {content.slice(0, index)}
                </Link>
                {content.slice(index)}
              </>
            );
          }
        },
      }}
    >
      {children}
    </Linkify>
  );
};

export default CustomLinkLinkify;
