import React from "react";
import { Box, Flex } from "@chakra-ui/react";

const CurrentSearchCondition = ({
  watchTags,
  watchOnlyBookmarked,
  tags,
}: {
  watchTags: string[];
  watchOnlyBookmarked: string;
  tags: Array<{ id: number; name: string }>;
}) => {
  const conditions = watchTags.map(
    (watchTagId) => tags.find((tag) => tag.id.toString() === watchTagId)?.name,
  );

  if (watchOnlyBookmarked === "1") {
    conditions.push("ブックマークした投稿のみ表示");
  }

  return (
    <>
      {conditions.length > 0 && (
        <Box fontSize="xs" color="gray" mt={3}>
          <Flex>
            <Box minWidth="fit-content">選択中の条件：</Box>
            <Box wordBreak="keep-all" overflowWrap="anywhere">
              {conditions.map((condition, index) => (
                <React.Fragment key={condition}>
                  {condition}
                  {index !== conditions.length - 1 && ", "}
                </React.Fragment>
              ))}
            </Box>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default CurrentSearchCondition;
