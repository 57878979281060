import { ReactNode, useCallback } from "react";
import { ToastPosition, useToast } from "@chakra-ui/react";

const useFlash = () => {
  const toast = useToast();

  const showFlash = useCallback(
    (flash: { [s: string]: ReactNode }, position: ToastPosition = "top") => {
      const colors = {
        success: "teal",
        error: "red",
        info: "blue",
        warning: "orange",
      };

      for (const flash_key of Object.keys(flash)) {
        if (flash[flash_key]) {
          // Railsから渡していて、基本的に初期レンダリングに実行されるためか
          // setTimeoutをしないとニュルっと出てこない
          setTimeout(() => {
            // deviseがこれらを設定する
            const status = ({
              alert: "error",
              notice: "info",
            }[flash_key] ?? flash_key) as
              | "info"
              | "error"
              | "success"
              | "warning";
            toast({
              title: flash[flash_key],
              status,
              position,
              colorScheme: colors[flash_key as keyof typeof colors],
            });
          }, 300);
        }
      }
    },
    [toast],
  );

  return showFlash;
};

export default useFlash;
