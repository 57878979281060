import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const ArrowDropDownIcon = (props: IconProps) => (
  <Icon viewBox="0 0 16 17" w="16px" h="17px" {...props}>
    <path
      d="M4.66602 6.8335L7.99935 10.1668L11.3327 6.8335H4.66602Z"
      fill="currentColor"
    />
  </Icon>
);

export default ArrowDropDownIcon;
