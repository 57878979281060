import { Avatar, Box, HStack, Spinner, Stack, Text } from "@chakra-ui/react";
import { createConsumer } from "@rails/actioncable";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import { businessMatchingTalkRoomMessagesPath } from "../../../../../routes";
import {
  BusinessMatchingTalkMessagesIndex,
  BusinessMatchingSharedTalkMessage,
  TalkRooms,
  SharedOwnedBusinessEntity,
} from "../../../shared/lib/types";
import useRequest from "../../../shared/lib/useRequest";
import TalkMessageForm from "./TalkMessageForm";
import useControlScrollPosition from "../lib/useControlScrollPosition";
import TalkMessageBubble from "./TalkMessageBubble";

const TalkMessages = ({
  talkRoom,
  currentBusinessEntity,
}: {
  talkRoom: TalkRooms[number];
  currentBusinessEntity: SharedOwnedBusinessEntity;
}) => {
  const [addedTalkMessages, setAddedTalkMessages] = useState<
    BusinessMatchingSharedTalkMessage[]
  >([]);

  const request = useRequest();
  const queryClient = useQueryClient();

  const fetchTalkMessages =
    async (): Promise<BusinessMatchingTalkMessagesIndex> => {
      const params: { last_talk_message_code?: string } = {};

      const lastTalkMessage = data?.pages.at(-1)?.talk_messages?.at(-1);
      if (lastTalkMessage != null) {
        params.last_talk_message_code = lastTalkMessage.code;
      }

      const res = await request(
        businessMatchingTalkRoomMessagesPath(talkRoom.code),
        "GET",
        params,
      );
      return res.json();
    };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } =
    useInfiniteQuery({
      queryKey: ["talk_rooms", talkRoom.code],
      queryFn: fetchTalkMessages,
      initialPageParam: 1,
      getNextPageParam: (lastPage) => lastPage.page_metadata.next,
    });

  const onChangeInView = async (inView: boolean) => {
    if (scrollContainerRef.current === null) return;
    if (!inView || isFetchingNextPage) return;

    setPreviousScrollHeight(scrollContainerRef.current.scrollHeight);
    await fetchNextPage();
  };

  const { scrollContainerRef, setPreviousScrollHeight } =
    useControlScrollPosition({
      data,
      addedTalkMessages,
      currentBusinessEntity: currentBusinessEntity,
    });

  useEffect(() => {
    return () => {
      queryClient.removeQueries({ queryKey: ["talk_rooms", talkRoom.code] });
    };
  }, [queryClient, talkRoom.code]);

  useEffect(() => {
    const consumer = createConsumer();
    consumer.subscriptions.create(
      { channel: "TalkRoomChannel", talk_room_code: talkRoom.code },
      {
        received: (data) => {
          setAddedTalkMessages((prev) => [...prev, data]);
        },
      },
    );
    return () => {
      consumer.disconnect();
    };
  }, [talkRoom.code]);

  return (
    <>
      <Stack
        gap={4}
        pt={{ base: 0, sm: 8 }}
        pb={6}
        px={4}
        overflowY="scroll"
        flexGrow={1}
        minH={0}
        ref={scrollContainerRef}
      >
        {isPending || data == null ? (
          <Box textAlign="center">
            <Spinner />
          </Box>
        ) : (
          <>
            <InView
              as="div"
              onChange={(inView) => onChangeInView(inView)}
              skip={!hasNextPage}
            >
              {isFetchingNextPage && (
                <Box textAlign="center">
                  <Spinner />
                </Box>
              )}
            </InView>
            {addedTalkMessages.length === 0 &&
            data.pages[0].talk_messages.length === 0 ? (
              <Box textAlign="center">メッセージがありません</Box>
            ) : (
              <>
                {data.pages
                  .map((page) => page.talk_messages)
                  .flat()
                  .reverse()
                  .map((talkMessage, i, talkMessages) => (
                    <TalkMessageBubble
                      key={talkMessage.code}
                      is_own={
                        currentBusinessEntity.code === talkMessage.sender.code
                      }
                      is_consecutive={
                        talkMessages.at(i - 1)?.sender.code ===
                        talkMessage.sender.code
                      }
                      message={talkMessage}
                      partner={talkRoom.partner}
                    />
                  ))}
                {addedTalkMessages.map((talkMessage, i, talkMessages) => (
                  <TalkMessageBubble
                    key={talkMessage.code}
                    is_own={
                      currentBusinessEntity.code === talkMessage.sender.code
                    }
                    is_consecutive={
                      talkMessages.at(i - 1)?.sender.code ===
                      talkMessage.sender.code
                    }
                    message={talkMessage}
                    partner={talkRoom.partner}
                  />
                ))}
              </>
            )}
          </>
        )}
      </Stack>
      <Stack
        borderTopColor="border.cardOutline"
        borderTopWidth="1px"
        px={{ base: 4, sm: 4 }}
        pt={4}
        gap={2}
      >
        <HStack gap={1} display={{ base: "flex", sm: "none" }}>
          <Avatar boxSize="1.125rem" />
          <Text fontSize="xs" fontWeight="bold">
            {currentBusinessEntity.name}
          </Text>
        </HStack>
        <TalkMessageForm talkRoomCode={talkRoom.code} />
      </Stack>
    </>
  );
};

export default TalkMessages;
