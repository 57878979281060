import React from "react";
import { SharedCurrentUser } from "../../shared/lib/types";

const VideoForCurrentUser = ({
  src,
  poster,
  currentUser,
}: {
  src: string;
  poster: string;
  currentUser: SharedCurrentUser;
}) => {
  const props = currentUser?.is_all_public_feature_accessible
    ? {
        controls: true,
        controlsList: "nodownload",
      }
    : {};
  return (
    <video
      onContextMenu={(e) => e.preventDefault()}
      poster={poster}
      width="100%"
      preload="none"
      {...props}
    >
      <source src={src} />
    </video>
  );
};

export default VideoForCurrentUser;
