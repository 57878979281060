import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const ThumbUpFilledIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" boxSize={6} {...props}>
    <path
      d="M15 17.5002H6.66669V6.66683L12.5 0.833496L13.5417 1.87516C13.6389 1.97238 13.7188 2.10433 13.7813 2.271C13.8438 2.43766 13.875 2.59738 13.875 2.75016V3.04183L12.9584 6.66683H17.5C17.9445 6.66683 18.3334 6.8335 18.6667 7.16683C19 7.50016 19.1667 7.88905 19.1667 8.33349V10.0002C19.1667 10.0974 19.1563 10.2016 19.1354 10.3127C19.1146 10.4238 19.0834 10.5279 19.0417 10.6252L16.5417 16.5002C16.4167 16.7779 16.2084 17.014 15.9167 17.2085C15.625 17.4029 15.3195 17.5002 15 17.5002ZM5.00002 6.66683V17.5002H1.66669V6.66683H5.00002Z"
      fill="currentColor"
    />
  </Icon>
);

export default ThumbUpFilledIcon;
