import dayjs from "dayjs";

export const calcAgeFromString = (dateString: string) => {
  const date = new Date(dateString);
  return calcAge(date.getFullYear(), date.getMonth(), date.getDay());
};

export const calcAge = (
  year: number | string,
  month: number | string,
  day: number | string,
) => {
  if (!validateDate(year, month, day)) return 0;

  const today = Number(
    new Date().toLocaleDateString("sv-SE").split("-").join(""),
  );
  const birthday = Number(
    new Date(Number(year), Number(month) - 1, Number(day))
      .toLocaleDateString("sv-SE")
      .split("-")
      .join(""),
  );

  return Math.floor((today - birthday) / 10000.0);
};

export const validateDate = (
  year: number | string,
  month: number | string,
  day: number | string,
) => {
  const date = `${year.toString()}${month.toString().padStart(2, "0")}${day.toString().padStart(2, "0")}`;

  return dayjs(date, "YYYYMMDD").format("YYYYMMDD") === date;
};

export const secondsToHoursMinutes = (seconds: number) => {
  const duration = dayjs.duration({ seconds });
  const hours = Math.floor(duration.asHours());
  const minutes = Math.ceil(duration.asMinutes() - hours * 60);

  let result = `${minutes}分`;
  if (hours > 0) {
    result = `${hours}時間${result}`;
  }

  return result;
};
