import { Stack, Text } from "@chakra-ui/react";
import React from "react";

export const BracketsList = ({ contents = [] }: { contents: string[] }) => {
  return (
    <Stack pos={"relative"} mt={2} gap={0}>
      {contents.map((content, index) => (
        <Text
          key={index}
          _before={{
            content: `"(${index + 1})"`,
            display: "block",
            position: "absolute",
            left: "0",
          }}
          ml={8}
        >
          {content}
        </Text>
      ))}
    </Stack>
  );
};

export default BracketsList;
