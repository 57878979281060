import { useEffect, useRef, useState } from "react";
import { InfiniteData } from "@tanstack/react-query";
import {
  BusinessMatchingTalkMessagesIndex,
  BusinessMatchingSharedTalkMessage,
  BusinessMatchingTalkRoomsBusinessEntity,
} from "../../../shared/lib/types";

const useControlScrollPosition = ({
  data,
  addedTalkMessages,
  currentBusinessEntity: ownedBusinessEntity,
}: {
  data: InfiniteData<BusinessMatchingTalkMessagesIndex, unknown> | undefined;
  addedTalkMessages: BusinessMatchingSharedTalkMessage[];
  currentBusinessEntity: BusinessMatchingTalkRoomsBusinessEntity;
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isFirstScrollFinished, setIsFirstScrollFinished] = useState(false);
  const [previousScrollHeight, setPreviousScrollHeight] = useState(0);

  useEffect(() => {
    if (scrollContainerRef.current == null) return;
    if (addedTalkMessages.at(-1)?.sender.code !== ownedBusinessEntity.code)
      return;

    scrollContainerRef.current.scrollTop =
      scrollContainerRef.current.scrollHeight;
  }, [addedTalkMessages, ownedBusinessEntity]);

  useEffect(() => {
    if (scrollContainerRef.current === null || data == null) return;

    scrollContainerRef.current.scrollTop =
      scrollContainerRef.current.scrollHeight - previousScrollHeight;
  }, [data, previousScrollHeight]);

  useEffect(() => {
    if (data != null && scrollContainerRef.current && !isFirstScrollFinished) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
      setIsFirstScrollFinished(true);
    }
  }, [data, isFirstScrollFinished]);

  return {
    scrollContainerRef,
    setPreviousScrollHeight,
  };
};

export default useControlScrollPosition;
