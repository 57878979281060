import { Flex, FlexProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

const Background = ({
  children,
  color = "brand.100",
  ...props
}: { children: ReactNode; color?: string } & FlexProps) => {
  return (
    <Flex
      bgColor={color}
      minH="100vh"
      direction="column"
      pb={{ base: 16, lg: 20 }}
      {...props}
    >
      {children}
    </Flex>
  );
};

export default Background;
