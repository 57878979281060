import { Box } from "@chakra-ui/react";
import React from "react";

const StudyGroupCaution = () => {
  return (
    <Box
      fontSize={{ base: "xs", md: "sm" }}
      py={5}
      px={{ base: 5, md: 6 }}
      bgColor="#D9EEE9"
      color="gray"
    >
      本サービスは、ユーザーご自身の責任に基づくご利用によって成り立っております。弊社は、掲載する勉強会情報に関して、できる限り正確に掲載するように努めておりますが、正確性や速報性など、ユーザーに対していかなる保証をするものではありませんのでご注意ください。
    </Box>
  );
};

export default StudyGroupCaution;
