import { Container, Flex, Heading, HStack, Stack } from "@chakra-ui/react";
import React from "react";
import { rootPath } from "../../../routes";
import { Flash, Pagy } from "../../shared/lib/types";
import Background from "../shared/components/atoms/Background";
import GoBackLink from "../shared/components/atoms/GoBackLink";
import Header from "../shared/components/atoms/Header";
import Pagination from "../shared/components/atoms/Pagination";
import ShadowCard from "../shared/components/atoms/ShadowCard";
import AwarenessIcon from "../shared/components/icons/AwarenessIcon";
import Application from "../shared/components/layouts/Application";
import { SharedAnnouncements, SharedCurrentUser } from "../shared/lib/types";
import AnnouncementListItem from "../shared/components/atoms/AnnouncementListItem";

const AnnouncementsIndex = ({
  announcements,
  pagy,
  flash,
  currentUser,
}: {
  announcements: SharedAnnouncements;
  pagy: Pagy;
  flash: Flash;
  currentUser: SharedCurrentUser;
}) => {
  return (
    <Application flash={flash} currentUser={currentUser}>
      <Header currentUser={currentUser} />
      <Background>
        <Container mt={{ base: 6, md: 12 }} maxW="container.lg">
          <Stack spacing={4}>
            <GoBackLink href={rootPath()}>ホーム画面へ戻る</GoBackLink>
            <ShadowCard>
              <HStack gap={1}>
                <AwarenessIcon boxSize={8} />
                <Heading size="md" pt={1}>
                  お知らせ
                </Heading>
              </HStack>
              <Stack mt={4}>
                {announcements.map((announcement) => (
                  <AnnouncementListItem
                    key={announcement.code}
                    announcement={announcement}
                  />
                ))}
              </Stack>
              <Flex justifyContent="center" mt={8}>
                <Pagination
                  pageCount={pagy.pages}
                  currentPage={pagy.page}
                  onClickPage={(page) => {
                    const params = new URLSearchParams(location.search);
                    params.set("page", page.toString());
                    location.search = params.toString();
                  }}
                />
              </Flex>
            </ShadowCard>
          </Stack>
        </Container>
      </Background>
    </Application>
  );
};

export default AnnouncementsIndex;
