import * as React from "react";
import usePagination from "@mui/material/usePagination";
import { Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import Button from "./Button";
import { IconButton } from "@chakra-ui/react";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";
import ChevronRightIcon from "../icons/ChevronRightIcon";

const Pagination = ({
  pageCount,
  currentPage,
  onClickPage,
}: {
  pageCount: number;
  currentPage: number;
  onClickPage: (page: number) => void;
}) => {
  const siblingCount = useBreakpointValue({ base: 0, sm: 1 });

  const { items } = usePagination({
    count: pageCount,
    page: currentPage,
    siblingCount,
  });

  return (
    <nav>
      <Flex gap={2}>
        {items.map(({ page, type, selected, disabled, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = (
              <Text as="span" h="fit-content" color="primary">
                ...
              </Text>
            );
          } else if (type === "page") {
            children = (
              <Button
                variant={selected ? "solid" : "outline"}
                boxSize={10}
                borderRadius="6px"
                isDisabled={disabled}
                p={0}
                {...item}
                onClick={() => {
                  if (page != null) {
                    onClickPage(page);
                  }
                }}
              >
                {page}
              </Button>
            );
          } else if (type === "next") {
            children = (
              <IconButton
                aria-label="next"
                icon={<ChevronRightIcon color="primary" boxSize={5} />}
                sx={{ backgroundColor: "transparent" }}
                isDisabled={disabled}
                p={0}
                {...item}
                onClick={() => onClickPage(currentPage + 1)}
              />
            );
          } else if (type === "previous") {
            children = (
              <IconButton
                aria-label="previous"
                icon={<ChevronLeftIcon color="primary" boxSize={5} />}
                sx={{ backgroundColor: "transparent" }}
                isDisabled={disabled}
                p={0}
                {...item}
                onClick={() => onClickPage(currentPage - 1)}
              />
            );
          }
          return (
            <Flex
              key={index}
              boxSize={10}
              justifyContent="center"
              alignItems="center"
            >
              {children}
            </Flex>
          );
        })}
      </Flex>
    </nav>
  );
};

export default Pagination;
