import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const MarkEmailUnreadIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 33" boxSize={5} {...props}>
    <path
      d="M25.3307 11.167C24.2196 11.167 23.2752 10.7781 22.4974 10.0003C21.7196 9.22255 21.3307 8.2781 21.3307 7.16699C21.3307 6.05588 21.7196 5.11144 22.4974 4.33366C23.2752 3.55588 24.2196 3.16699 25.3307 3.16699C26.4418 3.16699 27.3863 3.55588 28.1641 4.33366C28.9418 5.11144 29.3307 6.05588 29.3307 7.16699C29.3307 8.2781 28.9418 9.22255 28.1641 10.0003C27.3863 10.7781 26.4418 11.167 25.3307 11.167ZM5.33073 27.167C4.5974 27.167 3.96962 26.9059 3.4474 26.3837C2.92517 25.8614 2.66406 25.2337 2.66406 24.5003V8.50033C2.66406 7.76699 2.92517 7.13921 3.4474 6.61699C3.96962 6.09477 4.5974 5.83366 5.33073 5.83366H18.7974C18.7085 6.2781 18.6641 6.72255 18.6641 7.16699C18.6641 7.61144 18.7085 8.05588 18.7974 8.50033C18.953 9.21144 19.2085 9.87255 19.5641 10.4837C19.9196 11.0948 20.353 11.6337 20.8641 12.1003L15.9974 15.167L5.33073 8.50033V11.167L15.9974 17.8337L23.0307 13.4337C23.4085 13.567 23.7863 13.667 24.1641 13.7337C24.5418 13.8003 24.9307 13.8337 25.3307 13.8337C26.0418 13.8337 26.7418 13.7225 27.4307 13.5003C28.1196 13.2781 28.753 12.9448 29.3307 12.5003V24.5003C29.3307 25.2337 29.0696 25.8614 28.5474 26.3837C28.0252 26.9059 27.3974 27.167 26.6641 27.167H5.33073Z"
      fill="currentColor"
    />
  </Icon>
);

export default MarkEmailUnreadIcon;
