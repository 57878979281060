import React from "react";
import FixedBackgroundBuildingImage from "../shared/components/atoms/FixedBackgroundBuildingImage";
import Background from "../shared/components/atoms/Background";
import {
  Box,
  Container,
  Heading,
  VStack,
  Text,
  OrderedList,
  ListItem,
  UnorderedList,
  Stack,
} from "@chakra-ui/react";
import ShadowCard from "../shared/components/atoms/ShadowCard";
import BracketsList from "../shared/components/atoms/BracketsList";

const TermsOfService = () => {
  return (
    <>
      <FixedBackgroundBuildingImage />
      <Background>
        <Container
          maxW="4xl"
          mt={{ base: 6, md: 20 }}
          zIndex={1}
          lineHeight="170%"
        >
          <ShadowCard>
            <Heading as="h1" size="md" textAlign="center">
              「A-Loop」利用規約
            </Heading>
            <VStack mt={12} gap={10} align="flex-start">
              <Box>
                本規約は、ユニオンシステム株式会社（以下「弊社」と称します）が提供する建築士向けオンラインコミュニティサービス「A-Loop」（以下「本サービス」と称します）について、弊社と本サービスを利用いただくすべてのユーザー（以下、「ユーザー」と称します）との間で適用される共通の条件を定めるものとします。
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第1条（適用範囲及び変更）
                </Heading>
                <OrderedList paddingLeft={2}>
                  <ListItem>
                    本規約は、本サービスを利用するすべてのユーザーに適用され、ユーザーは本利用規約を遵守の上、本サービスを利用するものとします。
                  </ListItem>
                  <ListItem>
                    弊社は、ユーザーに事前の通知をすることなく、本利用規約を変更することができます。本利用規約を変更する場合、弊社はユーザーに対して変更後の規約を公表又は通知するものとし、当該公表又は通知のいずれかがなされた後、ユーザーが本サービスを利用した場合又は会員が1か月以内に退会手続きを行わなかった場合、ユーザーは変更後の規約に同意したものとみなされます。ユーザーは、本規約を誠実に遵守するものとします。
                  </ListItem>
                </OrderedList>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第2条（会員登録）
                </Heading>
                <Text>
                  本利用規約に同意の上で、以下の各号に定める他、弊社所定の方法により会員登録をした方を「会員」とします。
                </Text>
                <OrderedList paddingLeft={2} pt={4}>
                  <ListItem>
                    会員登録申請は必ず本サービスを利用する個人が行わなければならず、代理人による登録申請は認められません。
                  </ListItem>
                  <ListItem>
                    会員登録申請に際しては、真実、正確かつ最新の情報を記載することとし、虚偽の記載が判明した場合、会員登録が抹消されることがあります。
                  </ListItem>
                  <ListItem>
                    弊社は、弊社の基準に従い、登録希望者の登録の可否を判断し、弊社が認める場合に限り登録希望者の登録を認めることとします。
                  </ListItem>
                </OrderedList>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第3条（本サービスの概要）
                </Heading>
                <OrderedList paddingLeft={2}>
                  <ListItem>
                    本サービスは、ユーザーが自身の情報を登録し、本サービスの他の会員に対して登録情報を提供し、また他の会員に関する情報を閲覧できるサービスです。会員登録していない場合、本サービス上の機能を利用することはできません。
                  </ListItem>
                  <ListItem>
                    会員は、自身の登録情報を編集でき、本サービス上で提供する自身に関するすべての情報（以下「会員情報等」と称します）の内容の正確性、真実性、適法性等について、自らが責任を負うものとします。
                  </ListItem>
                  <ListItem>
                    会員は、本サービス上の機能によって、勉強会の視聴やコメントを行うことができます。
                  </ListItem>
                  <ListItem>
                    会員は、本サービス上の機能によって、投稿やコメント、ファイルの共有を行うことができます。
                  </ListItem>
                  <ListItem>
                    会員は、登録されたメールアドレスでお知らせの通知やメールマガジンサービスを受け取ることができます。
                  </ListItem>
                  <ListItem>
                    会員は、本サービスの利用に際し、自己の判断と責任において他の会員に対応するものとし、弊社はユーザーに対して、本サービスの利用に伴う結果に関する一切の責任を負わないものとします。
                  </ListItem>
                  <ListItem>
                    ユーザーは、本サービスの利用に際してダウンロードその他の方法によりファイル等を自身のコンピューター等にダウンロードする場合、自身が保有する情報の消滅、改変、機器の故障、損傷等が生じないよう十分な注意を払うものとし、かかる損害について弊社は一切の責任を負いません。
                  </ListItem>
                  <ListItem>
                    弊社は、本サービスの提供・運営のために、本サービスにおけるユーザーの行為、登録データ等を閲覧できるものとします。ただし、弊社はユーザーの行動を監視する義務を負うものではありません。
                  </ListItem>
                </OrderedList>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第4条（ユーザーに関する情報の開示と利用）
                </Heading>
                <OrderedList paddingLeft={2}>
                  <ListItem>
                    ユーザーが会員登録時及び本サービスの利用に際して弊社に開示した当該ユーザーに関する会員情報等のうち、投稿やコメント等において本サービス上で既に開示済みの情報以外の会員情報等については、原則として、事前の同意なく、弊社は他のユーザーに対して開示しません。ただし、本利用規約又は弊社の個人情報保護方針に定める場合、その他法令に基づく公的機関からの照会、又は弊社が法令によって開示義務を負う場合等はその限りではありません。
                  </ListItem>
                  <ListItem>
                    弊社は、本条に基づく情報の開示に関してユーザーに発生した損害について一切の責任を負いません。
                  </ListItem>
                  <ListItem>
                    前各項の他、会員情報等は、弊社の本利用規約及び個人情報保護方針に従い、弊社が管理します。
                  </ListItem>
                </OrderedList>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第5条（業務の委託）
                </Heading>
                <Text>
                  サービスの提供にあたり、業務の全部及び一部を第三者に委託する場合があります。
                  <br />
                  第三者に委託する場合、当該第三者に対して、弊社の個人情報保護方針と同等の義務を課し、当該第三者は委託された業務の範囲において、本利用規約に則って業務を行うこととします。
                </Text>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第6条（会員の義務）
                </Heading>
                <OrderedList paddingLeft={2}>
                  <ListItem>
                    会員は、自らの意思と責任をもって、本サービスを利用するものとします。
                  </ListItem>
                  <ListItem>
                    会員は、本サービス上で常に最新で正確な情報を提供するものとします。
                  </ListItem>
                  <ListItem>
                    会員は、本サービスを情報の発信を行う場として利用するものとし、責任をもって行動します。
                  </ListItem>
                </OrderedList>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第7条（会員情報等の変更）
                </Heading>
                <Text>
                  会員は、登録した事項に変更が生じた場合には、弊社の定める方法に従い、速やかに変更登録を行うものとします。変更登録がなされなかったことにより生じた損害について、弊社は一切責任を負いません。
                </Text>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第8条（ID及びパスワードの管理）
                </Heading>
                <OrderedList paddingLeft={2}>
                  <ListItem>
                    弊社は、会員に対して、本サービスを利用するために必要となるID及びパスワード（以下「ID等」と称します）を発行します。会員はID等を第三者に貸与し、譲渡し、使用させたり処分したりしてはならないものとします。
                  </ListItem>
                  <ListItem>
                    会員は、ID等について善良な管理者の注意をもって厳重に管理、保管するものとします。弊社は、ID等を使用して本サービスが利用された場合は、会員本人による利用とみなし、ID等の盗用、不正使用、その他会員本人以外の第三者による利用であっても、当該利用に起因してユーザー又は第三者に生じる損害及び結果について、損害賠償責任その他一切の責任を負わないものとします。
                  </ListItem>
                </OrderedList>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第9条（特定ユーザーに対するサービスの停止・会員資格の剥奪）
                </Heading>
                <Text>
                  本サービスの利用について、ユーザーの故意又は過失に関係なく以下の各項に該当する、又は該当するおそれがあると弊社が判断する行為は禁止されます。かかる禁止事項に違反した場合は、当該違反にかかる投稿等された情報の削除、変更の他、本サービスの利用停止、会員資格の剥奪（再登録の禁止を含む）等、弊社が適当と認める措置をとる場合があります。その場合、削除・変更、利用停止、会員資格の剥奪等の措置に関する投稿・苦情は一切受け付けておらず、かかる措置に関し、弊社は一切責任を負わず、その理由についてユーザーに説明する義務を負いません。
                </Text>
                <OrderedList paddingLeft={2} mt={4}>
                  <ListItem>
                    反社会的勢力又は反社会的活動に関する行為。
                  </ListItem>
                  <ListItem>
                    法令又は公序良俗に違反する、又は他人に不利益を与える行為。
                  </ListItem>
                  <ListItem>
                    犯罪行為又はこれを予告、関与、助長する行為。
                  </ListItem>
                  <ListItem>
                    特定の個人又は企業に対する誹謗中傷をする行為。
                  </ListItem>
                  <ListItem>
                    虚偽又は誤解を招くような内容を含む情報を掲載、登録する等の行為。
                  </ListItem>
                  <ListItem>
                    弊社、他のユーザー又は第三者の著作権、商標権、企業秘密等の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為。
                  </ListItem>
                  <ListItem>会員の個人情報を第三者に漏えいする行為。</ListItem>
                  <ListItem>
                    民族、人種、性別、年齢等による差別につながる表現の掲載行為。
                  </ListItem>
                  <ListItem>
                    商材の営業目的、勧誘、採用を目的とする情報提供活動。
                  </ListItem>
                  <ListItem>
                    交際相手を求める行為や出会いを目的とする又は宿泊や居住する相手を探そうとする行為。
                  </ListItem>
                  <ListItem>
                    その他社会的に容認されないと判断される出会い行為。
                  </ListItem>
                  <ListItem>
                    会員又は弊社に対する、営業目的でのメール送信、その他の営業行為。
                  </ListItem>
                  <ListItem>
                    出会い系サイト、アダルト関連のすべての業務に関する内容を含む表現の掲載行為。
                  </ListItem>
                  <ListItem>
                    弊社、他のユーザー又は第三者に損害を生じさせるおそれのある目的又は方法で本サービスを利用する、又は利用しようとする行為。
                  </ListItem>
                  <ListItem>
                    会員の個人情報を収集、蓄積する行為、又はこれらの行為をしようとする行為。
                  </ListItem>
                  <ListItem>
                    自動化された手段（ロボット、ボットネット、スクレーパ等）を使用して本サービスにアクセスする行為。
                  </ListItem>
                  <ListItem>会員に対し継続的に勧誘又は要求する行為。</ListItem>
                  <ListItem>
                    コンピューターウイルス、その他の有害なコンピュータープログラムを含む情報を送信する行為。
                  </ListItem>
                  <ListItem>
                    ねずみ講、チェーンメール、MLM（マルチレベルマーケティング）、リードメール等の第三者を勧誘する内容の情報を掲載又は送信する行為。
                  </ListItem>
                  <ListItem>
                    アフィリエイトや招待することでポイント等の利益が発生するサイトへ誘導する情報（ただし、弊社が事前に許可したものを除きます）を掲載又は送信する行為。
                  </ListItem>
                  <ListItem>
                    通常利用の範囲を超えてサーバーに負担をかける行為、及びそれを助長するような行為、その他本サービスの運営・提供又は他のユーザーによる本サービスの利用を妨害する、又はそれらに支障をきたすおそれのある行為。
                  </ListItem>
                  <ListItem>運営者に成りすます行為。</ListItem>
                  <ListItem>
                    本サービスに関し、利用できる情報を改ざんする行為。
                  </ListItem>
                  <ListItem>
                    本サービス上の画像等を含めた情報を無断使用、編集、複製、転載する行為。
                  </ListItem>
                  <ListItem>
                    弊社又は他者のサーバーに負担をかける行為、又は本サービスの運営やネットワーク・システムに支障を与える行為、又はこれらのおそれのある行為。
                  </ListItem>
                  <ListItem>
                    登録又は投稿をすることで第三者に成りすます行為。
                  </ListItem>
                  <ListItem>
                    ユーザー本人に許可を受けたか否かを問わず、ユーザー本人以外が登録情報（メールアドレスやパスワード等）を利用して本サービスを利用する行為。
                  </ListItem>
                  <ListItem>
                    会員資格を第三者に利用させる又は譲渡する行為。
                  </ListItem>
                  <ListItem>
                    1つの会員資格を複数人で利用する行為又は1人で複数の会員資格を保有する行為。
                  </ListItem>
                  <ListItem>
                    弊社又はユーザーが所属する業界団体の内部規則に違反する行為。
                  </ListItem>
                  <ListItem>弊社の権利を侵害する行為。</ListItem>
                  <ListItem>その他、弊社が不適切と判断する行為。</ListItem>
                </OrderedList>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第10条（免責事項及びユーザーの責任・負担について）
                </Heading>
                <Text>
                  ユーザーは、以下の弊社への免責事項及びユーザーの責任・負担についての内容を了解の上、本サービスを利用することとします。
                </Text>
                <OrderedList paddingLeft={2} mt={4}>
                  <ListItem>
                    弊社が必要と判断した場合には、本利用規約に従い、ユーザーに通知することなくいつでも本サービスを変更、停止又は中止することができるものとします。弊社が本サービスを変更、停止又は中止した場合や、事件・事故等によりやむを得ず本サービスを変更、停止又は中止せざるを得なかった場合にも、弊社はユーザーに対して一切責任を負わないものとします。なお、本サービスの一部のうち、期限を定めてリリースしたものについては、定められた期限の経過をもってその一部は停止され、この停止に関しても、弊社はユーザーに対して一切責任を負わないものとします。
                  </ListItem>
                  <ListItem>
                    本サービスは、ユーザー自身の責任に基づく利用によって成り立っています。会員が本サービス上で行う送信行為（以下「投稿等」と称します）によって提供される投稿やコメント等の一切の情報及び本サービスによりユーザーへ提供した情報は設計実務又はこれに準ずる行為ではなく、これらに基づいてユーザーが行った一切の判断、行為並びにそれらの結果に関する責任は、ユーザー自身が負うものとします。従って、弊社は、これらについて責任を一切負わないものとします。また、弊社は、投稿等の内容及び提供した情報に関して、その正確性・速報性・合法性・完全性・有用性及びその他業界規制等の遵守状況等、ユーザーに対していかなる保証もいたしません。ユーザー自身の判断で利用ください。投稿等の内容、提供した情報及びリンク設定された外部ウェブサイトによって生じた損害（コンピューターウイルス感染被害等による損害も含みます）や、ユーザーと第三者との間又はユーザー同士の紛争、その他のトラブル等に対し、弊社は一切の補償及び関与をいたしません。ただし、ユーザーの行為により弊社がトラブル等に巻き込まれ、損害又は費用を負担した場合、ユーザーは、当該損害及び費用（損害賠償費用、弁護士費用等の一切を含みます）を賠償するものとします。
                  </ListItem>
                  <ListItem>
                    会員が本サービスにおいて投稿・編集した文章、画像、動画等、ユーザーの本サービスへの接続、ユーザーの本利用規約への違反もしくはユーザーによる第三者の権利侵害等、ユーザーによる本サービスの利用又はユーザーの行為に起因又は関連して生じたすべてのクレームや請求については、当該ユーザーの責任と費用負担の下で解決するものとします。
                  </ListItem>
                  <ListItem>
                    本利用規約第9条のいずれかに該当する場合において、本サービスの利用又はユーザーの行為に起因又は関連して生じたすべてのクレームや請求については、当該ユーザーの責任と費用負担の下で解決するものとし、弊社が損害を被った場合、そのユーザーは弊社に当該損害（損害賠償費用、弁護士費用等の一切を含みます）を賠償するものとします。
                  </ListItem>
                  <ListItem>
                    弊社は、会員の投稿等を監視する義務を負わず、また、原因を問わず、それらの情報の消失によってユーザーに生じた損害等について、一切責任を負わないものとします。
                  </ListItem>
                  <ListItem>
                    弊社ウェブサイトから他のウェブサイトへのリンク又は他のウェブサイトから弊社ウェブサイトへのリンクが提供されている場合でも、弊社は、弊社ウェブサイト以外のウェブサイト及びそこから得られる情報に関して一切の責任を負わないものとします。
                  </ListItem>
                  <ListItem>
                    弊社は、当該会員が建築士であることを弊社が定めた一定の基準に従い確認していますが、必ず建築士であることを保証するものではありません。弊社は、弊社の確認基準を超えた、故意の成りすましや建築士証、建築士番号等の盗難、貸与等により、建築士以外の者により建築士として投稿等がなされた場合は、一切の責任を負わないものとします。
                  </ListItem>
                  <ListItem>
                    ユーザーは、（ア）本サービスを利用したこと、又は利用ができなかったこと、（イ）不正アクセスや不正な改変がなされたこと、（ウ）本サービス中の他のユーザーによる投稿等、（エ）その他の行為、第三者の成りすまし行為、（オ）その他本サービスに関連する事項に起因又は関連して生じた損害について、弊社は一切の責任を負わないものとします。
                  </ListItem>
                  <ListItem>
                    本利用規約に別途定める場合を除き、本利用規約のうち、弊社の責任を免責する規定については、弊社の故意もしくは重過失によってユーザーが損害を被ったことが管轄権を有する裁判所により判断された場合は適用されません。
                  </ListItem>
                </OrderedList>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第11条（会員によって登録、投稿、掲載された内容についての閲覧・変更・削除）
                </Heading>
                <OrderedList paddingLeft={2}>
                  <ListItem>
                    弊社は、会員によって投稿等されたすべての内容について、法令又は本利用規約違反の有無の確認その他の目的のために閲覧する権利を有します。この点は、非公開の内容についても同様となります。
                  </ListItem>
                  <ListItem>
                    会員は、以下の事由に該当する又は該当するおそれがある情報を投稿等することができません。以下の事由に該当する又は該当するおそれがある情報が投稿等された場合、弊社は、会員に対して予告なく、投稿等された情報の削除、変更等、弊社が必要と認める措置をとる場合があります。なお、当該措置の対象に該当するか否かは、すべて弊社が判断するものとし、理由の開示はいたしません。また、以下の事由に該当する場合や、本サービスの管理運営の都合上、当該情報の変更、削除等をする場合もあります。
                    <BracketsList
                      contents={[
                        "法令（省令、規則の他、主務官庁による通達、ガイドラインを含むがこれに限られない）又は本利用規約に違反したものである場合",
                        "本利用規約第9条のいずれかに該当する場合",
                        "その他、以下の情報を含む、弊社が変更・削除する必要があると判断した場合",
                      ]}
                    />
                    <Stack pos={"relative"} ml={8} mt={2} gap={0}>
                      <Text
                        _before={{
                          content: `"(3-1)"`,
                          display: "block",
                          position: "absolute",
                          left: "0",
                        }}
                        ml={12}
                      >
                        本サービスの趣旨から逸脱した情報
                      </Text>
                      <Text
                        _before={{
                          content: `"(3-2)"`,
                          display: "block",
                          position: "absolute",
                          left: "0",
                        }}
                        ml={12}
                      >
                        重大な危険行為もしくは第三者の権利侵害に結びつく、又は助長する情報
                      </Text>
                      <Text
                        _before={{
                          content: `"(3-3)"`,
                          display: "block",
                          position: "absolute",
                          left: "0",
                        }}
                        ml={12}
                      >
                        その他、弊社が不適当と判断した情報又は弊社が不適当と判断した行為により投稿等された情報
                      </Text>
                    </Stack>
                  </ListItem>
                  <ListItem>
                    投稿等した情報は、投稿した会員本人からの依頼であっても、本利用規約に定める事由やその他特別の事由がある場合を除き、削除はいたしません。
                  </ListItem>
                  <ListItem>
                    弊社が、第2項各号に定める事由に該当する、又は該当するおそれがあると判断して投稿等された情報を変更・削除した場合、弊社は、当該変更・削除に関する業務に従事した者にかかる人件費、第三者からの当該変更・削除の請求への対応に要した費用、その他当該変更・削除に関して要した費用に相当する金額を含め、弊社が被った損害を、その会員に賠償するよう請求することができるものとします。
                  </ListItem>
                  <ListItem>
                    弊社は、会員が投稿した内容、その他の投稿等された情報を運営上一定期間保存することがありますが、かかる情報を保存する義務を負うものではなく、弊社はいつでもこれらの情報を削除できるものとします。
                  </ListItem>
                </OrderedList>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第12条（情報の無断使用の禁止）
                </Heading>
                <Text>
                  ユーザーは、本サービスが予定している利用態様を超えて、本サービス上の画像等を含めた情報（当該ユーザーが自ら投稿等を行った情報を除く）を無断使用することはできません。従って、本サービス上のあらゆる情報について、ユーザーは、別途弊社が明示的に定めた場合又は弊社の事前の書面による承諾を得た場合において、かかる定めや承諾の範囲内で使用することができます。ただし、複製、譲渡、貸与、翻訳、改変、転載、公衆送信（送信可能化を含みます）、伝送、配布、出版、営業使用等は一切許可されていません。ただし、本サービスで開催する勉強会の告知をする場合はこの限りではありません。
                </Text>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第13条（投稿内容の著作権・利用権）
                </Heading>
                <OrderedList paddingLeft={2}>
                  <ListItem>
                    会員は、自身が商標権・著作権・意匠権等の知的財産権の権利者である、又は権利者から許諾を得ている文章、画像、動画等のみ、本サービスを利用して投稿等することができます。
                  </ListItem>
                  <ListItem>
                    会員が本サービスを利用して投稿した文章、画像、動画等の商標権・著作権・意匠権等の知的財産権については、当該会員その他の既存の権利者に帰属します。ただし、本サービスを通じて投稿された文章、画像、動画等については、弊社は必要と判断する措置を講じた上で、期間の制限を設けずに自由に利用できるものとします（ただし、弊社の指定した非公開方法に基づく投稿やコメント等には適用されません）。これらの利用においても、弊社は会員に対して何らの支払いも要しないものとし、会員は、本項に基づく弊社による著作物の利用に関して著作者人格権を行使しないものとします。
                  </ListItem>
                  <ListItem>
                    前項に定める会員が本サービスを利用して投稿した文章、画像、動画等についての著作権を除き、本サービス及び本サービスに関連するすべての情報についての著作権及びその他の知的財産権はすべて弊社又は弊社に利用を許諾した権利者に帰属し、会員は、弊社の事前の書面による承諾を得た場合を除き、複製、譲渡、貸与、翻訳、改変、転載、公衆送信（送信可能化を含む）、伝送、配布、出版、営業使用等を行ってはなりません。
                  </ListItem>
                  <ListItem>
                    第2項に定める内容に加え、弊社は、本サービスを利用して投稿された文章、画像、動画等を、個人を特定できないよう情報を除去した上で、参考資料やマーケティングデータ等として活用する、又は第三者に提供する場合があります（ただし、弊社の指定した非公開の方法に基づく投稿やコメント等には適用されません）。
                  </ListItem>
                  <ListItem>
                    会員は、文章、画像、動画等を投稿する際に、当該投稿に関連するサービス・機能の仕様をよく確認し、弊社の指定する利用方法・利用条件を守り、投稿画面に表示される注意事項を確認し遵守するものとします。また、当該会員は、自身の投稿物が他のユーザーによって閲覧、ダウンロード、その他の方法で利用されることに同意するものとします。
                  </ListItem>
                  <ListItem>
                    ユーザーは、他の会員が本サービスを利用して投稿した文章、画像、動画等について、弊社の指定した方法及び範囲内でのみ閲覧、ダウンロード、その他の利用ができるものとします。また、当該ユーザーは、当該利用を行う際に、関連するサービス・機能の仕様をよく確認し、弊社の指定する利用方法・利用条件を守り、利用の際に表示される注意事項を確認し遵守するものとします。
                  </ListItem>
                  <ListItem>
                    会員が投稿した文章、画像、動画等の公開、公開される場合の範囲、その他のユーザーによる利用の範囲等については、本サービスを構成するサービス、機能、コンテンツごとに弊社が定めるところに従います。
                  </ListItem>
                </OrderedList>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第14条（会員資格の譲渡等の禁止）
                </Heading>
                <Text>
                  会員は、会員資格又は本サービスに関連する権利義務を第三者に譲渡、貸与すること、引き受けさせること、又は第三者と共有することはできません。
                </Text>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第15条（弊社からの通知の効力）
                </Heading>
                <OrderedList paddingLeft={2}>
                  <ListItem>
                    弊社がユーザーに対して行う、弊社が運営するインターネット・サービスに関する一切の通知は、原則として、当該サービスに関連するウェブサイト上に表示された時点（メールその他、弊社が適当と判断する方法で通知を行う場合は、弊社がユーザーから受け取ったメールアドレス又はその他の連絡先に対して通知が発信された時点）で、効力を発生させるものとします。
                  </ListItem>
                  <ListItem>
                    前項に定める通知の効力は、ユーザーが弊社からの通知を実際に受領又は確認したかどうかにかかわらず、発生します。
                  </ListItem>
                </OrderedList>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第16条（退会）
                </Heading>
                <OrderedList paddingLeft={2}>
                  <ListItem>
                    会員が本サービスを退会する場合は、弊社所定の退会方法にて届け出るものとし、弊社での退会処理終了後、退会となります。
                  </ListItem>
                  <ListItem>
                    会員は、退会手続きを行った場合、弊社で利用いただいていた会員の会員資格に関する一切の権利、特典を失うものとします。
                  </ListItem>
                  <ListItem>
                    会員が本サービスの会員になる際に弊社に届け出た登録情報（変更登録にかかる情報を含みます）は、本利用規約に違反した会員に関する情報等、本サービスを適切に運営するために必要と弊社が判断する情報を除き、退会後、自動削除されます。ただし、本サービスを利用して投稿等した文章、画像、動画等、及び投稿等した際に入力した会員属性情報については削除されず、会員は、第13条に基づき、弊社が引き続き利用できることを予め承諾するものとします。
                  </ListItem>
                </OrderedList>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第17条（サイトへの接続等）
                </Heading>
                <Text>
                  本サービスを受けるためのサイトへの接続は、ユーザーが自己の責任と費用負担の下で行うものとします。弊社のサイトへの接続中、回線の都合等で接続が中断した場合にも、弊社では一切責任を負いません。
                </Text>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第18条（サービスの一時的な中断）
                </Heading>
                <Text>
                  弊社は以下の事由により、ユーザーに事前に連絡することなく、一時的にサービスの提供を中断することがあります。このサービスの中断による損害について、弊社は一切の責任を負いません。
                </Text>
                <OrderedList paddingLeft={2} mt={4}>
                  <ListItem>
                    弊社のシステムの保守、点検、修理等を定期的又は緊急に行う場合
                  </ListItem>
                  <ListItem>
                    火災・停電によりサービスの提供ができなくなった場合
                  </ListItem>
                  <ListItem>
                    天変地異等によりサービスの提供ができなくなった場合
                  </ListItem>
                  <ListItem>
                    その他、運用上又は技術上、本サービス提供の一時的な中断を必要と弊社が判断した場合
                  </ListItem>
                </OrderedList>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第19条（提供サービス及び本利用規約の変更・廃止）
                </Heading>
                <Text>
                  弊社は、本サービスの内容を、ユーザーへの事前の通知なく変更することがあります。ユーザーはこれに予め同意するものとし、ユーザーに不利益又は損害が発生したとしても、弊社は一切の責任を負いません。一方、本サービスの停止又は廃止は、ユーザーに通知するものとし、弊社がこの手続きを行った後に本サービスを停止又は廃止した場合には、弊社は一切の責任を負いません。
                </Text>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第20条（ファイル共有サービス）
                </Heading>
                <Text>
                  弊社は、本サービスを介して送受信されるファイルの内容（内容の信頼性、正確性、完成度、有用性、ウイルスの有無）に関して、一切の責任を負いません。ユーザーは本サービスを介して送受信されるファイルについて、送信又は受信の過程で、弊社の関知しない種々のネットワークや機器を経由することがあることを理解し、場合によっては、ファイルの内容に異常をきたす可能性があること、意図しない第三者による盗み見、利用等が行われる可能性があることを理解した上で本サービスを利用するものとします。
                </Text>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第21条（Cookieの使用）
                </Heading>
                <Text>
                  弊社ウェブサイトでは、ユーザーにより良いサービスをご提供するため、Cookieを利用しています。このまま弊社ウェブサイトを利用になる場合、Cookieの使用に同意いただいたものとみなされます。詳細については「個人情報保護方針」をご覧ください。
                </Text>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第22条（秘密保持）
                </Heading>
                <OrderedList paddingLeft={2}>
                  <ListItem>
                    弊社及びユーザーは、本規約又は本サービスに関連して、相手方より書面、口頭もしくは記録媒体等により提供もしくは開示されたか、又は知り得た、相手方に関する技術、営業、業務、財務又は組織に関するすべての情報（ただし、公知の事実を除く）を本サービスのみに利用するとともに、相手方の書面による承諾なしに第三者に相手方のそれらの情報を提供、開示又は漏えいしないものとします。
                  </ListItem>
                  <ListItem>
                    前項の規定は、本規約の残存期間中はもとより、その終了後においても適用するものとします。
                  </ListItem>
                </OrderedList>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第23条（個人情報及びデータの取り扱い）
                </Heading>
                <Text>
                  弊社は、ユーザーより提供を受けた個人情報等について、弊社が掲げる個人情報保護方針に基づき適切に扱います。
                </Text>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第24条（反社会的勢力ではないことの表明保証）
                </Heading>
                <OrderedList paddingLeft={2}>
                  <ListItem>
                    ユーザー及び弊社は、自己が、次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを相互に確約するものとします。
                    <BracketsList
                      contents={[
                        "暴力団、暴力団員、暴力団員でなくなったときから5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者（以下「暴力団員等」という）。",
                        "暴力団員等が経営を支配していると認められる関係を有すること。",
                        "暴力団員等が経営に実質的に関与していると認められる関係を有すること。",
                        "自己、自社もしくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってする等、不当に暴力団員等を利用していると認められる関係を有すること。",
                        "暴力団員等に対して資金等を提供する、又は便宜を供与する等の関与をしていると認められる関係を有すること。",
                        "役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること。",
                      ]}
                    />
                  </ListItem>
                  <ListItem>
                    ユーザー及び弊社は、自ら又は第三者を利用して次の各号いずれの行為も行わないことを相互に表明し、確約するものとします。
                    <UnorderedList>
                      <ListItem>暴力的な要求行為。</ListItem>
                      <ListItem>法的な責任を超えた不当な要求行為。</ListItem>
                      <ListItem>
                        取り引きに関して、脅迫的な言動をし、又は暴力を用いる行為。
                      </ListItem>
                      <ListItem>
                        風説を流布し、偽計又は威力を用いて相手方の信用を毀損する、又は相手方の業務を妨害する行為。
                      </ListItem>
                      <ListItem>その他前各号に準ずる行為。</ListItem>
                    </UnorderedList>
                  </ListItem>
                </OrderedList>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第25条（本利用規約の解釈）
                </Heading>
                <Text>
                  本利用規約のいずれかの条項又はその一部が、管轄権を有する裁判所により、違法又は無効と判断された場合であっても、その趣旨に最も近い有効な条項を当該無効な条項もしくは部分と置き換えて適用、又は当該条項もしくは部分の趣旨に最も近い有効な条項となるよう、合理的な解釈を加えて適用します。
                </Text>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第26条（準拠法・合意管轄）
                </Heading>
                <Text>
                  本利用規約に関する事項の一切は、日本法に準拠して解釈されるものとし、本サービスの利用に関し紛争が生じた際には、大阪地方裁判所を第一審の専属合意管轄裁判所とします。
                </Text>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第27条（協議解決）
                </Heading>
                <Text>
                  弊社及びユーザーは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合、互いに信義誠実の原則に従って協議の上、速やかに解決を図るものとします。
                </Text>
              </Box>
              <Box>
                <Heading as="h2" size="sm" mb={3}>
                  第28条（本利用規約の効力）
                </Heading>
                <OrderedList paddingLeft={2}>
                  <ListItem>
                    本利用規約は2024年4月1日から発効するものとし、過去の規約に優先して適用されるものとします。
                  </ListItem>
                  <ListItem>
                    本利用規約の内容と、本利用規約外における本サービスの説明等が異なる場合には、本利用規約の内容が優先して適用されるものとします。
                  </ListItem>
                </OrderedList>
              </Box>
            </VStack>
          </ShadowCard>
        </Container>
      </Background>
    </>
  );
};

export default TermsOfService;
