import React, { useEffect } from "react";
import { Box, Flex } from "@chakra-ui/react";

const SiteSeal = () => {
  useEffect(() => {
    if (location.host === "aloop.jp") {
      const script = document.createElement("script");

      script.src = "/site_seal.js";
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);
  return (
    <Flex wrap="wrap" justify="center" columnGap={3} rowGap={2}>
      <Box width={20}>
        <div id="DigiCertClickID_gtoFHXgW"></div>
      </Box>
      <Box color="gray" fontSize="xs">
        お送りいただくお客様の個人情報は
        <br />
        SSL暗号化通信により保護されます。
      </Box>
    </Flex>
  );
};

export default SiteSeal;
