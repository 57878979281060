import useFlash from "./useFlash";
import useBaseRequest from "../../../shared/lib/useBaseRequest";
import { useCallback } from "react";

const useRequest = () => {
  const showFlash = useFlash();
  const onUnexpectedError = useCallback(
    () => showFlash({ error: "エラーが発生しました" }),
    [showFlash],
  );

  const onForbiddenError = useCallback(
    async (message: string) => {
      showFlash({ error: message }, "bottom");
    },
    [showFlash],
  );

  return useBaseRequest({ onUnexpectedError, onForbiddenError });
};

export default useRequest;
