import { Avatar, AvatarProps } from "@chakra-ui/react";
import React from "react";

function blackOrWhiteByBackgroundColor(backgroundColor: string) {
  const r = parseInt(backgroundColor.substring(1, 3), 16);
  const g = parseInt(backgroundColor.substring(3, 5), 16);
  const b = parseInt(backgroundColor.substring(5, 7), 16);

  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}

const UserAvatar = ({
  bgColor,
  name,
  src,
  ...props
}: AvatarProps & Required<Pick<AvatarProps, "bgColor" | "name" | "src">>) => {
  const color = blackOrWhiteByBackgroundColor(bgColor as string);
  return (
    <Avatar
      src={src}
      name={src ? undefined : name.replace(/\s/g, "")}
      bgColor={src ? undefined : bgColor}
      color={src ? undefined : color}
      {...props}
    />
  );
};

export default UserAvatar;
