import { Box, Container, Heading } from "@chakra-ui/react";
import React from "react";
import { postPath } from "../../../routes";
import { Flash, SharedTag } from "../../shared/lib/types";
import Background from "../shared/components/atoms/Background";
import Footer from "../shared/components/atoms/Footer";
import GoBackLink from "../shared/components/atoms/GoBackLink";
import Header from "../shared/components/atoms/Header";
import Application from "../shared/components/layouts/Application";
import { UploadFileType } from "../../shared/components/UploadFiles";
import {
  DataType,
  jsonToFormData,
  PostForm,
  usePostForm,
} from "../posts/components/PostForm";
import useFlash from "../shared/lib/useFlash";
import useRequest from "../shared/lib/useRequest";
import {
  Post,
  SharedApprovedCurrentUser,
} from "../shared/lib/types";

const PostsEdit = ({
  post,
  flash,
  currentUser,
  tags,
}: {
  post: Post;
  flash: Flash;
  currentUser: SharedApprovedCurrentUser;
  tags: SharedTag[];
}) => {
  const request = useRequest();
  const showFlash = useFlash();
  const methods = usePostForm({
    isAnonymousField: false,
    currentUser: currentUser,
    defaultValues: {
      title: post.title,
      content: post.content,
      tag_ids: post.tags.map((tag) => tag.id.toString()),
      imageFiles: post.image_files,
      filesWithoutImage: post.files_without_image,
    },
  });

  const onSubmit = async (data: DataType, uploadFiles: UploadFileType) => {
    const formData = await jsonToFormData(data, uploadFiles);
    const res = await request(postPath(post.code), "PUT", formData);

    if (res.ok) {
      location.href = postPath(post.code);
    } else if (res.status === 404) {
      showFlash({
        error: (
          <>
            投稿が見つかりませんでした。
            <br />
            ページを再読み込みしてください。
          </>
        ),
      });
    }
  };

  return (
    <Application flash={flash} currentUser={currentUser}>
      <Background color="brand.50">
        <Header currentUser={currentUser} />
        <Container maxW="container.md" mt={{ base: 8, sm: 14 }}>
          <Heading as="h1" size="md">
            質問を編集
          </Heading>
          <Box mt={7}>
            <GoBackLink href={postPath(post.code)}>戻る</GoBackLink>
            <Box mt={4}>
              <PostForm
                tags={tags}
                onSubmit={onSubmit}
                methods={methods}
                submitLabel="質問を更新"
              />
            </Box>
          </Box>
        </Container>
      </Background>
      <Footer />
    </Application>
  );
};

export default PostsEdit;
