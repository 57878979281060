import { Box, Stack, Text } from "@chakra-ui/react";
import React from "react";
import Comment from "../../../posts/components/Comment";
import {
  PostComment,
  SharedCurrentUser,
  PostsShow,
} from "../../lib/types";

const FetchedComments = ({
  comments,
  currentUser,
  post,
}: {
  comments: PostComment[];
  currentUser: SharedCurrentUser;
  post: PostsShow;
}) => {
  if (comments.length === 0) {
    return (
      <Text
        fontSize="sm"
        color="#636665"
        align="center"
        mt={{ base: 6, sm: 10 }}
      >
        <Box display={"inline-block"}>この質問にはまだ回答がついていません。</Box>
        <Box display={"inline-block"}>ぜひ、あなたの見解や経験を元に回答してみませんか？</Box>
      </Text>
    );
  }
  return (
    <>
      <Text fontSize="xs" textAlign="center" mt={{ base: 4, sm: 5 }}>
        <Box as="strong" fontSize="lg" verticalAlign="middle">
          {comments.length}
        </Box>
        件のコメントがあります
      </Text>
      <Stack gap={5} mt={{ base: 6, sm: 10 }}>
        {[...comments].reverse().map((comment) => (
          <Comment
            comment={comment}
            key={comment.code}
            currentUser={currentUser}
            post={post}
          />
        ))}
      </Stack>
    </>
  );
};

export default FetchedComments;
