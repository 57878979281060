import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { communityPostCommentsPath } from "../../../../routes";
import {
  Stack,
  Flex,
  Avatar,
  Text,
  Link,
  Spacer,
  Box,
  SlideFade,
} from "@chakra-ui/react";
import { Button } from "../../shared/components/atoms";
import dayjs from "dayjs";
import { Textarea } from "../../shared/components/atoms/form";
import useRequest from "../../shared/lib/useRequest";
import CustomLinkLinkify from "../../shared/components/atoms/CustomLinkLinkify";
import {
  CommunityPostCommentsIndex,
  UserCommunitiesPost,
} from "../../shared/lib/types";

const Post = ({
  post,
  is_community_member,
  onClickJoin,
}: {
  post: UserCommunitiesPost;
  is_community_member: boolean;
  onClickJoin: () => void;
}) => {
  const schema = yup.object({
    content: yup.string().trim().required().max(65535),
  });

  type FormData = yup.InferType<typeof schema>;

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    watch,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      content: "",
    },
    resolver: yupResolver(schema),
  });

  const request = useRequest();
  const onSubmit = async (data: FormData) => {
    await request(communityPostCommentsPath(post.code), "POST", {
      community_post_comment: data,
    });

    await fetchComments(post.code);
    reset();
  };

  const [fetchCommentsResult, setFetchCommentsResult] =
    useState<CommunityPostCommentsIndex>({
      comments: post.comments,
      comments_count: post.comments_count,
    });

  const fetchComments = useCallback(
    async (post_code: string) => {
      const res = await request(communityPostCommentsPath(post_code), "GET");
      setFetchCommentsResult(await res.json());
    },
    [request],
  );

  useEffect(() => {
    void fetchComments(post.code);
  }, [fetchComments, post.code]);

  const content = watch("content");
  const commentsCount = fetchCommentsResult.comments_count;

  return (
    <Box
      bgColor="white"
      key={post.code}
      borderRadius="4px"
      border="1px solid rgba(0, 0, 0, 0.10)"
    >
      <Stack
        pt={{ base: 6, sm: 10 }}
        px={{ base: 4, sm: 6 }}
        pb={{ base: 4, sm: 6 }}
        gap={0}
      >
        <Flex gap={3} align="center">
          <Avatar w={12} h={12} src={post.author.profile_image_url} />
          <Stack gap={0}>
            <Text fontWeight="bold">{post.author.full_name}</Text>
            <Text color="#848484" fontSize="xs">
              {dayjs(post.created_at).format("L LT")}
            </Text>
          </Stack>
        </Flex>
        <Box mt={4} fontSize={{ base: "sm", sm: "md" }} whiteSpace="pre-wrap">
          <CustomLinkLinkify>{post.content}</CustomLinkLinkify>
        </Box>
        <Text align="right" mt={4} fontSize="xs">
          コメント
          {is_community_member ? (
            <Text color="textLink" textDecoration="underline" as="span" ml={1}>
              {commentsCount}件
            </Text>
          ) : (
            <>{commentsCount}件</>
          )}
        </Text>
        <Flex
          borderTop="1px solid"
          justify="center"
          pt={6}
          mt={4}
          borderColor="rgba(0, 0, 0, 0.20)"
        >
          {commentsCount > 3 && (
            <Link
              color="textLink"
              textDecoration="underline"
              textAlign="center"
              fontSize="sm"
            >
              {is_community_member
                ? "すべてのコメントを表示"
                : "コミュニティに参加してすべてのコメントを表示"}
            </Link>
          )}
        </Flex>
        <Stack gap={2} mt={{ base: 7, sm: 4 }}>
          {[...fetchCommentsResult.comments].reverse().map((comment) =>
            comment.is_own ? (
              <SlideFade in offsetY="50px" key={comment.code}>
                <Flex gap={3} direction="row-reverse">
                  <Avatar
                    w={{ base: 8, sm: 12 }}
                    h={{ base: 8, sm: 12 }}
                    src={comment.author.profile_image_url}
                  />
                  <Stack gap={1}>
                    <Text
                      px={4}
                      py={3}
                      bgColor="brand.100"
                      fontSize="sm"
                      borderRadius="8px"
                      maxW="md"
                      wordBreak="break-word"
                      whiteSpace="pre-wrap"
                    >
                      {comment.content}
                    </Text>
                    <Text fontSize="xs">
                      {dayjs(comment.created_at).format("L LT")}
                    </Text>
                  </Stack>
                  <Spacer />
                </Flex>
              </SlideFade>
            ) : (
              <SlideFade in offsetY="50px" key={comment.code}>
                <Flex gap={3}>
                  <Avatar
                    w={{ base: 8, sm: 12 }}
                    h={{ base: 8, sm: 12 }}
                    src={comment.author.profile_image_url}
                  />
                  <Stack gap={1}>
                    <Text fontWeight="bold" fontSize="sm">
                      {comment.author.full_name}
                    </Text>
                    <Text
                      px={4}
                      py={3}
                      bgColor="#F5F5F5"
                      fontSize="sm"
                      borderRadius="8px"
                      maxW="md"
                      wordBreak="break-word"
                      whiteSpace="pre-wrap"
                    >
                      {comment.content}
                    </Text>
                    <Text fontSize="xs" align="right">
                      {dayjs(comment.created_at).format("L LT")}
                    </Text>
                  </Stack>
                  <Spacer />
                </Flex>
              </SlideFade>
            ),
          )}
        </Stack>
      </Stack>
      <Box position="relative">
        <Flex
          gap={1}
          pt={{ base: 4, sm: 6 }}
          px={{ base: 4, sm: 6 }}
          pb={{ base: 4, sm: 10 }}
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          data-testid={`${post.code}_comment`}
        >
          <Controller
            name="content"
            control={control}
            render={({ field }) => (
              <Textarea
                placeholder="コメントを入力"
                borderRadius="8px"
                {...field}
                bgColor="brand.100"
                border="none"
                autoExpand
              />
            )}
          />
          <Button
            type="submit"
            size="sm"
            minWidth="fit-content"
            isDisabled={content === ""}
            isLoading={isSubmitting}
          >
            投稿
          </Button>
          {!is_community_member && (
            <Stack
              position="absolute"
              color="black"
              top={0}
              left={0}
              bgColor="black"
              w="full"
              h="full"
              zIndex="100"
              background="rgba(60, 170, 145, 0.70)"
              backdropFilter="blur(2px)"
              alignItems="center"
              justify="center"
              gap={1}
              direction={{ base: "row", sm: "column" }}
              p={4}
            >
              <Text
                color="white"
                fontWeight="bold"
                fontSize={{ base: "xs", sm: "md" }}
              >
                コミュニティに参加すると、発言が可能になります
              </Text>
              <Button size={{ base: "sm", sm: "md" }} onClick={onClickJoin}>
                コミュニティに参加
              </Button>
            </Stack>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export default Post;
