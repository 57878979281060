import { Flex, Text } from "@chakra-ui/react";
import React, { ReactNode } from "react";

const Tag = ({ children }: { children: ReactNode }) => {
  return (
    <Flex
      h={6}
      px={2}
      background="#F0F5F4"
      borderRadius="2px"
      alignItems="center"
      w="fit-content"
    >
      <Text fontSize="xs" color="#636665">
        {children}
      </Text>
    </Flex>
  );
};

export default Tag;
