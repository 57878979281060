import { Box, Text } from "@chakra-ui/react";
import React from "react";
import useFileState from "../../lib/useFileState";

const DropzonePreview = ({
  fileState,
  editable = true,
}: {
  fileState: ReturnType<typeof useFileState>;
  editable?: boolean;
}) => {
  return (
    <>
      <Box className="image-preview">
        <Box as="object" width="100%" minH="320px" data={fileState.preview} />
      </Box>
      {editable && (
        <Text
          as="button"
          color="textLink"
          textDecoration="underline"
          _hover={{ textDecoration: "none" }}
          type="button"
          onClick={() => fileState.setFile(undefined)}
          mt={2}
          w="fit-content"
          ml="auto"
        >
          ファイルを変更
        </Text>
      )}
    </>
  );
};

export default DropzonePreview;
