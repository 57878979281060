import { Box, Flex } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export const ProfileItem = ({ children }: { children: ReactNode }) => {
  return (
    <Flex direction={{ base: "column", lg: "row" }} rowGap={2} columnGap={4}>
      {children}
    </Flex>
  );
};

export const ProfileItemLeft = ({ children }: { children: ReactNode }) => {
  return <Box flex="3">{children}</Box>;
};

export const ProfileItemRight = ({ children }: { children: ReactNode }) => {
  return <Box flex="5">{children}</Box>;
};
