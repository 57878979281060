import { Box, Flex, HStack, Stack, Text } from "@chakra-ui/react";
import React from "react";
import SectionTitle from "../../shared/components/atoms/SectionTitle";
import { Button } from "../../shared/components/atoms";
import { FormLabel } from "../../shared/components/atoms/form";
import {
  BusinessType,
  SharedIndustries,
  SharedPrefectures,
} from "../../shared/lib/types";

const Confirmation = ({
  values,
  logoImagePreview,
  businessTypes,
  prefectures,
  industries,
  isSubmitting,
  onSubmit,
  onClickFix,
}: {
  values: Record<string, string | number>;
  logoImagePreview: string;
  businessTypes: BusinessType[];
  prefectures: SharedPrefectures;
  industries: SharedIndustries;
  isSubmitting: boolean;
  onSubmit: () => void;
  onClickFix: () => void;
}) => {
  const businessTypeLabel = businessTypes.find(
    (businessType) => businessType.value === values.business_type,
  )!.label;

  return (
    <>
      <SectionTitle mt={14}>事業区分</SectionTitle>
      <Box mt={7}>
        <Text>{businessTypeLabel}</Text>
      </Box>
      <SectionTitle mt={14}>{businessTypeLabel}情報</SectionTitle>
      <Stack gap={7} mt={7}>
        <Stack>
          <FormLabel as="div">事業者名</FormLabel>
          <Text>{values.name}</Text>
        </Stack>
        <Stack>
          <FormLabel as="div">事業者名かな</FormLabel>
          <Text minH={6}>{values.name_kana}</Text>
        </Stack>
        <Stack>
          <FormLabel as="div">郵便番号</FormLabel>
          <Text>{values.postal_code}</Text>
        </Stack>
        <Stack>
          <FormLabel as="div">都道府県</FormLabel>
          <Text>
            {
              prefectures.find(
                (prefecture) =>
                  prefecture.id.toString() === values.prefecture_id.toString(),
              )!.name
            }
          </Text>
        </Stack>
        <Stack>
          <FormLabel as="div">市区町村</FormLabel>
          <Text>{values.address1}</Text>
        </Stack>
        <Stack>
          <FormLabel as="div">番地</FormLabel>
          <Text>{values.address2}</Text>
        </Stack>
        <Stack>
          <FormLabel as="div">建物名</FormLabel>
          <Text minH={6}>{values.address3}</Text>
        </Stack>
        <Stack>
          <FormLabel as="div">電話番号</FormLabel>
          <Text>{values.phone_number}</Text>
        </Stack>
        <Stack>
          <FormLabel as="div">電話番号</FormLabel>
          <Text>{values.phone_number}</Text>
        </Stack>
        <Stack>
          <FormLabel as="div">受発注で利用するメールアドレス</FormLabel>
          <Text>{values.email}</Text>
        </Stack>
        <Stack>
          <FormLabel as="div">主業種</FormLabel>
          <Text>
            {
              industries.find(
                (industry) =>
                  industry.id.toString() === values.industry_id.toString(),
              )!.name
            }
          </Text>
        </Stack>
        <Stack>
          <FormLabel as="div">従業員数</FormLabel>
          <Text>{values.number_of_employees}</Text>
        </Stack>
        <Stack>
          <FormLabel as="div">設立月</FormLabel>
          <HStack>
            <Text>{values.establishment_year}</Text>
            <Text>年</Text>
            <Text>{values.establishment_month}</Text>
            <Text>月</Text>
          </HStack>
        </Stack>
        <Stack>
          <FormLabel as="div">資本金</FormLabel>
          <Text>{values.capital_ten_thousand_yen}</Text>
        </Stack>
        <Stack>
          <FormLabel as="div">企業ロゴ</FormLabel>
          <Box as="object" w="full" minH="400px" data={logoImagePreview} />
        </Stack>
        <Stack>
          <FormLabel as="div">代表者指名</FormLabel>
          <Text>{values.president_name}</Text>
        </Stack>
        <Stack>
          <FormLabel as="div">Webサイト</FormLabel>
          <Text minH={6}>{values.website_url}</Text>
        </Stack>
      </Stack>
      <SectionTitle mt={14}>SNS</SectionTitle>
      <Stack gap={7} mt={7}>
        <Stack>
          <FormLabel as="div">Facebook</FormLabel>
          <Text minH={6}>{values.facebook_url}</Text>
        </Stack>
        <Stack>
          <FormLabel as="div">Instagram</FormLabel>
          <Text minH={6}>{values.instagram_url}</Text>
        </Stack>
        <Stack>
          <FormLabel as="div">note</FormLabel>
          <Text minH={6}>{values.note_url}</Text>
        </Stack>
        <Stack>
          <FormLabel as="div">Pinterest</FormLabel>
          <Text minH={6}>{values.pinterest_url}</Text>
        </Stack>
        <Stack>
          <FormLabel as="div">Threads</FormLabel>
          <Text minH={6}>{values.threads_url}</Text>
        </Stack>
        <Stack>
          <FormLabel as="div">TikTok</FormLabel>
          <Text minH={6}>{values.tiktok_url}</Text>
        </Stack>
        <Stack>
          <FormLabel as="div">X（旧Twitter）</FormLabel>
          <Text minH={6}>{values.x_url}</Text>
        </Stack>
        <Stack>
          <FormLabel as="div">YouTube</FormLabel>
          <Text minH={6}>{values.youtube_url}</Text>
        </Stack>
      </Stack>
      <Box
        mt={8}
        paddingBottom={3}
        borderBottomColor="brand.500"
        borderBottomWidth="2px"
      />
      <Flex justify="center" mt={10} gap={3}>
        <Button
          type="button"
          isLoading={false}
          isDisabled={isSubmitting}
          w="full"
          variant="outline"
          onClick={onClickFix}
          wordBreak="keep-all"
          overflowWrap="anywhere"
        >
          入力内容を&#8203;修正
        </Button>
        <Button
          type="button"
          onClick={onSubmit}
          isLoading={isSubmitting}
          w="full"
        >
          上記内容で申請
        </Button>
      </Flex>
    </>
  );
};

export default Confirmation;
