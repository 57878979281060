import { Box, HStack, BoxProps, forwardRef } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import HelpIcon from "../icons/HelpIcon";

const HelpMessage = forwardRef(
  ({ children, ...props }: { children: ReactNode } & BoxProps, ref) => {
    return (
      <HStack color="textLink" gap={0.5} ref={ref} {...props}>
        <HelpIcon boxSize={4} />
        <Box fontSize="xs" textDecor="underline">
          {children}
        </Box>
      </HStack>
    );
  },
);

export default HelpMessage;
