import { Box, Container, Flex, Heading } from "@chakra-ui/react";
import React, { useState } from "react";
import { postPath, postsPath, previewPostsPath } from "../../../routes";
import { Flash, SharedTag } from "../../shared/lib/types";
import { Button } from "../shared/components/atoms";
import Background from "../shared/components/atoms/Background";
import Footer from "../shared/components/atoms/Footer";
import GoBackLink from "../shared/components/atoms/GoBackLink";
import Header from "../shared/components/atoms/Header";
import PostDetail from "../shared/components/atoms/PostDetail";
import Application from "../shared/components/layouts/Application";
import {
  filterFilesWithoutImage,
  filterImageFiles,
  UploadFileType,
} from "../../shared/components/UploadFiles";
import {
  DataType,
  jsonToFormData,
  PostForm,
  usePostForm,
} from "../posts/components/PostForm";
import useRequest from "../shared/lib/useRequest";
import {
  PostPreviewsCreate,
  SharedApprovedCurrentUser,
} from "../shared/lib/types";
import { Helmet } from "react-helmet";

const PostsNew = ({
  flash,
  currentUser,
  tags,
}: {
  flash: Flash;
  currentUser: SharedApprovedCurrentUser;
  tags: SharedTag[];
}) => {
  const request = useRequest();
  const methods = usePostForm({
    isAnonymousField: true,
    currentUser: currentUser,
  });
  const [showPreview, setShowPreview] = useState(false);
  const [formInputs, setFormInputs] = useState<DataType>();
  const [previewPost, setPreviewPost] = useState<PostPreviewsCreate>();
  const [files, setFiles] = useState<UploadFileType>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onPreview = async (data: DataType, uploadFiles: UploadFileType) => {
    const res = await request(previewPostsPath(), "POST", { post: data });

    if (res.ok) {
      window.scrollTo(0, 0);
      setPreviewPost({
        ...(await res.json()),
      });
      setFormInputs(data);
      setFiles(uploadFiles);
      setShowPreview(true);
    }
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    const formData = await jsonToFormData(formInputs!, files!);
    const res = await request(postsPath(), "POST", formData);

    if (res.ok) {
      methods.reset();
      const json = await res.json();
      location.href = postPath(json.code);
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <Application flash={flash} currentUser={currentUser}>
      <Helmet>
        <title>
          質問を新規作成 | 建築士のためのコミュニティA-Loop【エーループ】
        </title>
      </Helmet>
      <Background color="brand.50">
        <Header currentUser={currentUser} />
        <Container maxW="container.md" mt={{ base: 8, sm: 14 }}>
          <Box display={showPreview ? "none" : undefined}>
            <Heading as="h1" size="md">
              質問を新規作成
            </Heading>
            <Box mt={7}>
              <GoBackLink href={postsPath()}>Q&A一覧に戻る</GoBackLink>
              <Box mt={4}>
                <PostForm
                  tags={tags}
                  onSubmit={onPreview}
                  submitLabel={"確認画面へ進む"}
                  withQuestinonaire
                  currentUser={currentUser}
                  methods={methods}
                />
              </Box>
            </Box>
          </Box>
          {showPreview && (
            <Box>
              <Helmet>
                <title>
                  質問内容を確認 |
                  建築士のためのコミュニティA-Loop【エーループ】
                </title>
              </Helmet>
              <Heading as="h1" size="md">
                質問内容を確認
              </Heading>
              <Box pointerEvents="none" mt={4}>
                <PostDetail
                  post={{
                    ...previewPost!,
                    image_files: filterImageFiles(files!).map((file) => ({
                      url: URL.createObjectURL(file as File),
                      signed_id: "",
                    })),
                    files_without_image: filterFilesWithoutImage(files!).map(
                      (file) => ({
                        name: file.name,
                        signed_id: "",
                        url: "",
                      }),
                    ),
                  }}
                  currentUser={currentUser}
                  preview
                />
              </Box>
              <Flex maxW="container.sm" mx="auto" gap={4} mt={7}>
                <Button
                  variant="outline"
                  flex={1}
                  onClick={() => setShowPreview(false)}
                  isLoading={isSubmitting}
                >
                  修正
                </Button>
                <Button
                  aria-label="質問投稿を確定する"
                  flex={1}
                  isLoading={isSubmitting}
                  onClick={onSubmit}
                >
                  質問を投稿
                </Button>
              </Flex>
            </Box>
          )}
        </Container>
      </Background>
      <Footer />
    </Application>
  );
};

export default PostsNew;
