import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  HStack,
  Avatar,
  Text,
} from "@chakra-ui/react";
import {
  TalkRooms,
  SharedOwnedBusinessEntity,
} from "../../../shared/lib/types";
import TalkMessages from "./TalkMessages";

const TalkMessagesModal = ({
  talkRoom,
  currentBusinessEntity,
  isOpen,
  onClose,
}: {
  talkRoom: TalkRooms[number];
  currentBusinessEntity: SharedOwnedBusinessEntity;
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent h="100vh">
        <ModalHeader pb={0} px={4} pt={3}>
          <HStack
            gap={3}
            borderBottomColor={"border.cardOutline"}
            borderBottomWidth={"1px"}
            pb={3}
          >
            <Avatar
              src={talkRoom.partner.image_url}
              boxSize={8}
            />
            <Text fontSize="sm" fontWeight="bold">
              {talkRoom.partner.name}
            </Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton top={3} />
        <ModalBody
          display="flex"
          flexDirection="column"
          overflow="hidden"
          px={0}
          pt={0}
        >
          <TalkMessages
            talkRoom={talkRoom}
            currentBusinessEntity={currentBusinessEntity}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TalkMessagesModal;
