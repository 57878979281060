import { Box, BoxProps, Heading } from "@chakra-ui/react";
import React from "react";

const SectionTitle = (props: BoxProps) => {
  return (
    <Box
      paddingBottom={3}
      borderBottomColor="brand.500"
      borderBottomWidth="2px"
      {...props}
    >
      <Heading size="md" color="brand.500">
        {props.children}
      </Heading>
    </Box>
  );
};

export default SectionTitle;
