import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const CircleErrorIcon = (props: IconProps) => (
  <Icon viewBox="0 0 14 14" boxSize="14px" {...props}>
    <path
      d="M6.99609 10.2001C7.1651 10.2001 7.30794 10.1429 7.42461 10.0286C7.54128 9.91428 7.59961 9.77261 7.59961 9.60361C7.59961 9.4346 7.54245 9.29176 7.42813 9.1751C7.31379 9.05843 7.17213 9.0001 7.00313 9.0001C6.83412 9.0001 6.69128 9.05726 6.57461 9.17158C6.45794 9.28591 6.39961 9.42758 6.39961 9.59658C6.39961 9.76559 6.45677 9.90843 6.57109 10.0251C6.68543 10.1418 6.82709 10.2001 6.99609 10.2001ZM6.39961 7.8001H7.59961V3.8001H6.39961V7.8001ZM7.00421 13.4001C6.12337 13.4001 5.29405 13.2334 4.51628 12.9001C3.7385 12.5668 3.05794 12.1084 2.47461 11.5251C1.89128 10.9418 1.43294 10.2614 1.09961 9.48413C0.766276 8.70681 0.599609 7.87625 0.599609 6.99246C0.599609 6.10866 0.766276 5.28065 1.09961 4.50843C1.43294 3.73621 1.89128 3.05843 2.47461 2.4751C3.05794 1.89176 3.73827 1.43343 4.51558 1.1001C5.2929 0.766764 6.12345 0.600098 7.00724 0.600098C7.89104 0.600098 8.71905 0.766764 9.49128 1.1001C10.2635 1.43343 10.9413 1.89176 11.5246 2.4751C12.1079 3.05843 12.5663 3.73745 12.8996 4.51216C13.2329 5.28689 13.3996 6.11466 13.3996 6.9955C13.3996 7.87634 13.2329 8.70565 12.8996 9.48343C12.5663 10.2612 12.1079 10.9418 11.5246 11.5251C10.9413 12.1084 10.2623 12.5668 9.48754 12.9001C8.71282 13.2334 7.88504 13.4001 7.00421 13.4001Z"
      fill="#CD2929"
    />
  </Icon>
);

export default CircleErrorIcon;
