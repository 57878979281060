import { Box, Heading, Link, Stack, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import "cropperjs/dist/cropper.css";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import YubinBango from "yubinbango.js";
import * as yup from "yup";
import {
  editProfileWorkplacePath,
  profileWorkplacePath,
  userPath,
} from "../../../../routes";
import prefCode from "../../../shared/lib/prefCode";
import { Flash } from "../../../shared/lib/types";
import { Button } from "../../shared/components/atoms";
import InfoMessage from "../../shared/components/atoms/InfoMessage";
import SiteSeal from "../../shared/components/atoms/SiteSeal";
import { FormLabel, Input } from "../../shared/components/atoms/form";
import Select from "../../shared/components/atoms/form/Select";
import Textarea from "../../shared/components/atoms/form/Textarea";
import useRequest from "../../shared/lib/useRequest";
import {
  Prefecture,
  ProfileWorkplace,
  SharedCurrentUser,
} from "../../shared/lib/types";
import {
  ProfileItem,
  ProfileItemLeft,
  ProfileItemRight,
} from "../components/ProfileItem";
import ProfilePageLayout from "../components/ProfilePageLayout";
import useAutokana from "../../shared/lib/useAutokana";

const Page = ({
  workplace,
  prefectures,
  currentUser,
}: {
  workplace: ProfileWorkplace;
  prefectures: Prefecture[];
  currentUser: NonNullable<SharedCurrentUser>;
}) => {
  const schema = yup.object({
    name: yup.string().trim(),
    // https://github.com/jquense/yup#extending-built-in-schema-with-new-methods
    // うまくいかないので苦肉の策
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    name_kana: yup.string().trim().hiragana(),
    postal_code: yup
      .string()
      .trim()
      .matches(/^\d{7}$/, {
        message: "ハイフン無しの7桁の数字を入力してください",
        excludeEmptyString: true,
      }),
    prefecture_id: yup.string().trim(),
    address1: yup.string().trim(),
    address2: yup.string().trim(),
    address3: yup.string().trim(),
    phone_number: yup.string().trim().phoneNumber(),
    website_url: yup.string().trim().url(),
    business_detail: yup.string().trim(),
  });

  const nameAutoKana = useAutokana("#name", "#name_kana");

  type FormData = yup.InferType<typeof schema>;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      ...workplace,
      prefecture_id: (workplace.prefecture_id ?? "").toString(),
    },
    resolver: yupResolver(schema),
  });
  const request = useRequest();

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    const res = await request(profileWorkplacePath(), "PUT", data);

    if (res.ok) {
      location.href = currentUser.is_approved
        ? userPath(currentUser.code, { tab: "profile" })
        : editProfileWorkplacePath();
    }
  };

  const fillAddressByPostalCode = async (postalCode: string) => {
    if (postalCode.length !== 7) return;

    const address = await YubinBango.getAddress(postalCode);
    setValue("prefecture_id", prefCode[address.prefecture].toString());
    setValue("address1", `${address.locality}${address.street}`);
    setValue("address2", address.extended);
  };

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)}>
      <Box
        background="#FFF"
        px={{ base: 5, md: 8 }}
        py={{ base: 6, md: 10 }}
        borderRadius="2px"
      >
        <Heading as="h1" size="lg">
          勤務地情報
        </Heading>
        <InfoMessage mt={{ base: 3, md: 4 }}>
          ここで入力した情報はプロフィールで公開されます。
        </InfoMessage>
        <Stack mt={{ base: 6, md: 10 }} gap={6}>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel htmlFor="name">会社名</FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    id="name"
                    onInput={() => {
                      setValue("name_kana", nameAutoKana?.getFurigana());
                    }}
                  />
                )}
              />
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel htmlFor="name_kana">会社名ふりがな</FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="name_kana"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input {...field} error={error?.message} id="name_kana" />
                )}
              />
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel htmlFor="postal_code">郵便番号</FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="postal_code"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    onChange={async (...e) => {
                      void fillAddressByPostalCode(e[0].target.value);
                      field.onChange(...e);
                    }}
                    maxW={{ md: "50%" }}
                    id="postal_code"
                    error={error?.message}
                  />
                )}
              />
              <Text fontSize="xs" color="#6D787D" mt={1}>
                例）1600022
                <br />
                ※ハイフンはいりません。
                <br />
                ※海外在住の方は「0000000」と入力してください。
                <br />
                ※郵便番号を入力すると住所が入力されます。
              </Text>
              <Box fontSize="sm" as="span">
                郵便番号がわからない方は
                <Link
                  color="textLink"
                  textDecoration="underline"
                  _hover={{ textDecoration: "none" }}
                  href="https://www.post.japanpost.jp/zipcode/"
                  target="_blank"
                  rel="noreferrer"
                >
                  こちら
                </Link>
              </Box>
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel htmlFor="prefecture_id">都道府県</FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="prefecture_id"
                control={control}
                render={({ field, fieldState }) => (
                  <Select
                    required
                    placeholder="都道府県を選択"
                    error={fieldState.error?.message ?? ""}
                    {...field}
                    maxW={{ md: "50%" }}
                    id="prefecture_id"
                  >
                    {prefectures.map(({ id, name }) => (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel htmlFor="address1">市区町村</FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="address1"
                control={control}
                render={({ field }) => (
                  <Input {...field} placeholder="新宿区新宿" id="address1" />
                )}
              />
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel htmlFor="address2">番地</FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="address2"
                control={control}
                render={({ field }) => <Input {...field} id="address2" />}
              />
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel htmlFor="address3">ビル・マンション名</FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="address3"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="新宿マルネビル8階"
                    id="address3"
                  />
                )}
              />
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel htmlFor="phone_number">電話番号</FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="phone_number"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    maxW={{ md: "50%" }}
                    placeholder="03-3352-6121"
                    id="phone_number"
                    error={error?.message}
                  />
                )}
              />
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel htmlFor="website_url">Webサイト</FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="website_url"
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    error={fieldState.error?.message ?? ""}
                    id="website_url"
                  />
                )}
              />
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel htmlFor="business_detail">事業内容</FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="business_detail"
                control={control}
                render={({ field }) => (
                  <Textarea
                    id="business_detail"
                    {...field}
                    h={40}
                    placeholder={`・建築の設計監理、建物の企画・調査・構想・計画
・意匠・構造
・マネジメント・コンサルタント業務
・都市計画
                    `}
                  />
                )}
              />
            </ProfileItemRight>
          </ProfileItem>
        </Stack>
      </Box>
      <Box mt={{ base: 8, md: 10 }} textAlign="center">
        <Button
          maxW="fit-content"
          type="submit"
          isDisabled={!isDirty}
          isLoading={isSubmitting}
        >
          変更を保存
        </Button>
      </Box>
    </Stack>
  );
};

const ProfileWorkplacesEdit = ({
  workplace,
  prefectures,
  flash,
  currentUser,
}: {
  workplace: ProfileWorkplace;
  prefectures: Prefecture[];
  flash: Flash;
  currentUser: NonNullable<SharedCurrentUser>;
}) => {
  return (
    <ProfilePageLayout current_user={currentUser} flash={flash}>
      <Page
        prefectures={prefectures}
        workplace={workplace}
        currentUser={currentUser}
      />
      <Box mt={6}>
        <SiteSeal />
      </Box>
    </ProfilePageLayout>
  );
};

export default ProfileWorkplacesEdit;
