import { Box, Container, Flex } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { Header } from "../../../shared/components/atoms";
import Background from "../../../shared/components/atoms/Background";
import SideMenu from "../../../shared/components/atoms/SideMenu";
import Application from "../../../shared/components/layouts/Application";
import { SharedApprovedCurrentUser } from "../../../shared/lib/types";
import {
  editBusinessMatchingOwnedBusinessEntityActivationPath,
  editBusinessMatchingOwnedBusinessEntityPath,
} from "../../../../../routes";
import { Flash } from "../../../../shared/lib/types";

const OwnedBusinessEntityLayout = ({
  flash,
  currentUser,
  currentBusinessEntity,
  children,
}: {
  flash: Flash;
  currentUser: SharedApprovedCurrentUser;
  currentBusinessEntity: { code: string; is_approved: boolean };
  children: ReactNode;
}) => {
  const items = [
    {
      label: "事業者確認情報",
      href: editBusinessMatchingOwnedBusinessEntityPath(
        currentBusinessEntity.code,
      ),
    },
  ];

  if (currentBusinessEntity.is_approved) {
    items.push({
      label: "サービス利用設定",
      href: editBusinessMatchingOwnedBusinessEntityActivationPath(
        currentBusinessEntity.code,
      ),
    });
  }

  return (
    <Application flash={flash} currentUser={currentUser}>
      <Header currentUser={currentUser} showBusinessMatchingHeader />
      <Background color="#EDF5F3">
        <Container maxW="container.xl" mt={{ base: 6, md: 10 }}>
          <Flex
            columnGap={12}
            rowGap={4}
            direction={{ base: "column", md: "row" }}
          >
            <Box flex="1">
              <SideMenu items={items} />
            </Box>
            <Box flex="4">{children}</Box>
          </Flex>
        </Container>
      </Background>
    </Application>
  );
};

export default OwnedBusinessEntityLayout;
