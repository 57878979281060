import * as yup from "yup";

export const anonymousSchema = (showAnonymousField: boolean) =>
  yup
    .string()
    .when([], {
      is: () => showAnonymousField,
      then: (schema) => schema.required(),
    })
    .label("質問者の表示名");
