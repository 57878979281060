import {
  Box,
  CloseButton,
  Flex,
  Grid,
  HStack,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  StackProps,
  useDisclosure,
} from "@chakra-ui/react";
import React, { ReactNode, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as TSwiper } from "swiper/types";
import "swiper/css";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";
import ChevronRightIcon from "../icons/ChevronRightIcon";

const SliderButton = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children: ReactNode;
}) => {
  return (
    <Box
      as="button"
      onClick={onClick}
      backgroundColor="#EDF5F3"
      color="#1A443A"
      borderRadius="full"
      w="full"
      h="full"
    >
      {children}
    </Box>
  );
};

const ImagesAttachedWithPreview = ({
  urls,
  onRemove = undefined,
  ...props
}: {
  urls: string[];
  onRemove?: (idx: number) => void;
} & StackProps) => {
  const { isOpen: isOpenSlider, onOpen, onClose } = useDisclosure();
  const [initialIndex, setInitialIndex] = useState<number>();

  const onOpenSlider = (index: number) => {
    setInitialIndex(index);
    onOpen();
  };

  const onCloseSlider = () => {
    onClose();
    setInitialIndex(undefined);
  };

  const [swiper, setSwiper] = useState<TSwiper>();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (swiper != null && initialIndex != null) {
      swiper.slideTo(initialIndex, 0);
    }
  }, [initialIndex, swiper]);

  const PrevButton = () => {
    return (
      <Box w={16} h={16}>
        {activeIndex > 0 && (
          <SliderButton onClick={() => swiper?.slidePrev()}>
            <ChevronLeftIcon boxSize={8} />
          </SliderButton>
        )}
      </Box>
    );
  };

  const NextButton = () => {
    return (
      <Box w={16} h={16}>
        {activeIndex < urls.length - 1 && (
          <SliderButton onClick={() => swiper?.slideNext()}>
            <ChevronRightIcon boxSize={8} />
          </SliderButton>
        )}
      </Box>
    );
  };

  if (urls.length == 0) return <></>;

  return (
    <>
      <HStack gap={2} {...props}>
        {urls.map((url, idx) => (
          <Box key={url} position="relative">
            {onRemove && (
              <CloseButton
                w={10}
                h={10}
                background="#EDF5F3"
                borderRadius="full"
                position="absolute"
                right={1}
                top={1}
                onClick={() => onRemove(idx)}
              />
            )}
            <Image
              borderRadius={8}
              src={url}
              onClick={() => onOpenSlider(idx)}
              objectFit="cover"
              loading="lazy"
              aspectRatio={1 / 1}
              w={28}
            />
          </Box>
        ))}
      </HStack>
      <Modal isOpen={isOpenSlider} onClose={onCloseSlider} size="full">
        <ModalOverlay />
        <ModalContent backgroundColor="transparent">
          <Box mt={16}>
            <Flex align="center" mx={4} direction="column">
              <Grid
                gap={6}
                templateColumns={{ base: undefined, md: "auto 1fr auto" }}
                alignItems="center"
                maxW="container.lg"
                w="full"
              >
                <Box display={{ base: "none", md: "block" }}>
                  <PrevButton />
                </Box>
                <Box minW="0">
                  <Flex justify="flex-end" color="white">
                    <Flex as="button" align="center" onClick={onCloseSlider}>
                      <CloseButton as="div" />
                      <Box>閉じる</Box>
                    </Flex>
                  </Flex>
                  <Box
                    backgroundColor="rgba(0, 0, 0, 0.4)"
                    backdropFilter="blur(8px)"
                  >
                    <Swiper
                      onSwiper={(s) => setSwiper(s)}
                      onSlideChange={(s) => setActiveIndex(s.activeIndex)}
                    >
                      {urls.map((url) => (
                        <SwiperSlide key={url}>
                          <Box w="full" h="full">
                            <Image
                              src={url}
                              objectFit="contain"
                              w="full"
                              h={{ base: "70svh", md: "80dvh" }}
                            />
                          </Box>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Box>
                </Box>
                <Box display={{ base: "none", md: "block" }}>
                  <NextButton />
                </Box>
              </Grid>
              <Flex justify="space-between" w="full" mt={4}>
                <Box display={{ base: "block", md: "none" }}>
                  <PrevButton />
                </Box>
                <Box display={{ base: "block", md: "none" }}>
                  <NextButton />
                </Box>
              </Flex>
            </Flex>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImagesAttachedWithPreview;
