import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const DehazeIcon = (props: IconProps) => (
  <Icon viewBox="0 0 22 18" h="18px" w="22px" {...props}>
    <path
      d="M0.79248 3.54528V0.453613H21.2383V3.54528H0.79248ZM0.79248 17.5453V14.4536H21.2383V17.5453H0.79248ZM0.79248 10.5453V7.45361H21.2383V10.5453H0.79248Z"
      fill="currentColor"
    />
  </Icon>
);

export default DehazeIcon;
