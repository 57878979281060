import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormLabel,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { Input } from "../../shared/components/atoms/form";
import { Button } from "../../shared/components/atoms";
import SearchIcon from "../../shared/components/icons/SearchIcon";
import {
  SharedOwnedBusinessEntity,
  SharedIndustries,
  SharedPrefectures,
} from "../../shared/lib/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { businessMatchingRootPath } from "../../../../routes";
import ArrowDropUpIcon from "../../shared/components/icons/ArrowDropUpIcon";
import ArrowDropDownIcon from "../../shared/components/icons/ArrowDropDownIcon";

const DetailSearchForm = ({
  industries,
  prefectures,
}: {
  industries: SharedIndustries;
  prefectures: SharedPrefectures;
}) => {
  const form = useFormContext();

  return (
    <Stack gap={6}>
      <Box>
        <FormLabel>主業種</FormLabel>
        <Flex columnGap={2} rowGap={2} wrap="wrap" mt={1}>
          <Controller
            name="q.industry_id_eq_any"
            control={form.control}
            render={({ field: { ref, ...field } }) => (
              <CheckboxGroup {...field}>
                {industries.map(({ id, name }) => (
                  <Checkbox key={id} value={id.toString()} ref={ref}>
                    {name}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            )}
          />
        </Flex>
      </Box>
      <Box>
        <FormLabel>所在地</FormLabel>
        <Flex columnGap={2} rowGap={2} wrap="wrap" mt={1}>
          <Controller
            name="q.prefecture_id_eq_any"
            control={form.control}
            render={({ field: { ref, ...field } }) => (
              <CheckboxGroup {...field}>
                {prefectures.map(({ id, name }) => (
                  <Checkbox key={id} value={id.toString()} ref={ref}>
                    {name}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            )}
          />
        </Flex>
      </Box>
      <Flex justify="flex-end" mt={4}>
        <Button type="submit" size="sm">
          <Text pt={0.5}>入力内容で検索</Text>
        </Button>
      </Flex>
    </Stack>
  );
};

const SearchForm = ({
  industries,
  prefectures,
  currentBusinessEntity,
}: {
  industries: SharedIndustries;
  prefectures: SharedPrefectures;
  currentBusinessEntity: SharedOwnedBusinessEntity | undefined;
}) => {
  const schema = yup.object().shape({
    q: yup.object().shape({
      name_or_industry_name_or_prefecture_name_cont: yup.string().ensure(),
      industry_id_eq_any: yup.array(yup.string().ensure()).ensure(),
      prefecture_id_eq_any: yup.array(yup.string().ensure()).ensure(),
    }),
  });
  type FormData = yup.InferType<typeof schema>;
  const form = useForm<FormData>({
    defaultValues: {
      q: {
        name_or_industry_name_or_prefecture_name_cont: "",
        industry_id_eq_any: [],
        prefecture_id_eq_any: [],
      },
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, control, setValue } = form;
  const onSubmit = (data: FormData) => {
    location.href = businessMatchingRootPath(data);
  };

  const [isDetailOpen, setIsDetailOpen] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setValue(
      "q.name_or_industry_name_or_prefecture_name_cont",
      query.get("q[name_or_industry_name_or_prefecture_name_cont]") ?? "",
    );
    setValue(
      "q.industry_id_eq_any",
      query.getAll("q[industry_id_eq_any][]") ?? [],
    );
    setValue(
      "q.prefecture_id_eq_any",
      query.getAll("q[prefecture_id_eq_any][]") ?? [],
    );
  }, [setValue]);

  return (
    <FormProvider {...form}>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        {currentBusinessEntity?.is_active ? (
          <>
            <Controller
              name="q.name_or_industry_name_or_prefecture_name_cont"
              control={control}
              render={({ field }) => (
                <Input
                  placeholder="気になるキーワードを入力"
                  borderRadius={4}
                  h={12}
                  rightElement={() => (
                    <InputRightElement h="full">
                      <Box w="full" h="full" py={2} mr={2}>
                        <Button
                          px={2}
                          py={1.5}
                          w="full"
                          h="full"
                          type="submit"
                          aria-label="検索する"
                        >
                          <SearchIcon />
                        </Button>
                      </Box>
                    </InputRightElement>
                  )}
                  {...field}
                />
              )}
            />
            <Button
              mt={3}
              variant="outline"
              fontSize="xs"
              leftIcon={
                isDetailOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
              }
              iconSpacing={0.5}
              borderRadius={2}
              py={1}
              pl={1}
              pr={2}
              onClick={() => setIsDetailOpen((prev) => !prev)}
            >
              <Box as="span" pt={0.5}>
                詳細検索
              </Box>
            </Button>
            {isDetailOpen && (
              <Box
                backgroundColor="white"
                px={5}
                pt={4}
                pb={5}
                border="1px solid #99A9B0"
                borderRadius={4}
                mt={3}
              >
                <Stack gap={4}>
                  <Flex justify="space-between" alignItems="center">
                    <Text fontWeight={"bold"}>詳細検索</Text>
                    <Box
                      as="button"
                      fontSize="sm"
                      color="primary"
                      type="button"
                      onClick={() => setIsDetailOpen(false)}
                    >
                      閉じる
                    </Box>
                  </Flex>
                  <DetailSearchForm
                    industries={industries}
                    prefectures={prefectures}
                  />
                </Stack>
              </Box>
            )}
          </>
        ) : (
          <Box
            backgroundColor="white"
            px={5}
            pt={4}
            pb={5}
            border="1px solid #99A9B0"
            borderRadius={4}
            mt={3}
          >
            <Stack gap={4}>
              <Text fontWeight={"bold"}>検索</Text>
              <DetailSearchForm
                industries={industries}
                prefectures={prefectures}
              />
            </Stack>
          </Box>
        )}
      </Box>
    </FormProvider>
  );
};

export default SearchForm;
