import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const ChatBubbleIcon = (props: IconProps) => (
  <Icon viewBox="0 0 16 17" boxSize={5} {...props}>
    <path
      d="M1.5 16.4995V2.99951C1.5 2.58701 1.64687 2.23389 1.94062 1.94014C2.23437 1.64639 2.5875 1.49951 3 1.49951H15C15.4125 1.49951 15.7656 1.64639 16.0594 1.94014C16.3531 2.23389 16.5 2.58701 16.5 2.99951V11.9995C16.5 12.412 16.3531 12.7651 16.0594 13.0589C15.7656 13.3526 15.4125 13.4995 15 13.4995H4.5L1.5 16.4995ZM3.8625 11.9995H15V2.99951H3V12.8433L3.8625 11.9995Z"
      fill="currentColor"
    />
  </Icon>
);

export default ChatBubbleIcon;
