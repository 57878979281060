import React from "react";
import {
  Checkbox as ChakuraCheckbox,
  CheckboxProps,
  forwardRef,
} from "@chakra-ui/react";

const Checkbox = forwardRef<CheckboxProps, "input">(
  ({ children, ...props }, ref) => {
    return (
      <ChakuraCheckbox
        colorScheme="teal"
        ref={ref}
        borderColor="teal"
        {...props}
      >
        {children}
      </ChakuraCheckbox>
    );
  },
);

export default Checkbox;
