import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const ThumbUpIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" boxSize={6} {...props}>
    <path
      d="M15 17.5002H5.83335V6.66683L11.6667 0.833496L12.7084 1.87516C12.8056 1.97238 12.8854 2.10433 12.9479 2.271C13.0104 2.43766 13.0417 2.59738 13.0417 2.75016V3.04183L12.125 6.66683H17.5C17.9445 6.66683 18.3334 6.8335 18.6667 7.16683C19 7.50016 19.1667 7.88905 19.1667 8.33349V10.0002C19.1667 10.0974 19.1528 10.2016 19.125 10.3127C19.0972 10.4238 19.0695 10.5279 19.0417 10.6252L16.5417 16.5002C16.4167 16.7779 16.2084 17.014 15.9167 17.2085C15.625 17.4029 15.3195 17.5002 15 17.5002ZM7.50002 15.8335H15L17.5 10.0002V8.33349H10L11.125 3.75016L7.50002 7.37516V15.8335ZM5.83335 6.66683V8.33349H3.33335V15.8335H5.83335V17.5002H1.66669V6.66683H5.83335Z"
      fill="currentColor"
    />
  </Icon>
);

export default ThumbUpIcon;
