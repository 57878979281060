import React from "react";
import FixedBackgroundBuildingImage from "../shared/components/atoms/FixedBackgroundBuildingImage";
import Background from "../shared/components/atoms/Background";
import {
  Box,
  Container,
  Heading,
  VStack,
  Text,
  OrderedList,
  ListItem,
  UnorderedList,
  Link,
} from "@chakra-ui/react";
import ShadowCard from "../shared/components/atoms/ShadowCard";
import BracketsList from "../shared/components/atoms/BracketsList";

const PrivacyPolicy = () => {
  return (
    <>
      <FixedBackgroundBuildingImage />
      <Background>
        <Container maxW="4xl" mt={{ base: 6, md: 20 }} zIndex={1}>
          <ShadowCard>
            <Heading as="h1" size="md" textAlign="center">
              個人情報保護方針
            </Heading>
            <VStack mt={12} gap={10}>
              <Text>
                ユニオンシステム株式会社（以下「弊社」といいます）は、弊社が提供するサービスを通じて収集した個人を特定できる情報の保護について、社会的責務としての認識をもち、個人情報保護に関する法律及び関連するその他の法令・規範を厳守します。以下の方針に基づき、個人情報の取り扱いに努めます。
              </Text>
              <OrderedList spacing={4} paddingLeft={4}>
                <ListItem>
                  個人情報の取得
                  <Text mt={2}>
                    弊社は、適法かつ公正な手段によって、個人を特定する情報（氏名、性別、メールアドレス、建築士番号、所属団体、所属企業その他の記述により当該本人を識別できるもの）（以下「個人情報」といいます）を取得します。また、弊社は個人情報に該当するか否かにかかわらず、以下の情報を取得します。
                    <BracketsList
                      contents={[
                        "利用者がご利用になった弊社サービスの内容、ご利用日時及び回数、利用者による弊社サービスの利用・閲覧に関連する情報（これには、Cookie、アクセスログ等の利用状況に関する情報、ご利用の端末情報、OS情報、位置情報、IPアドレス、ブラウザ情報、ブラウザ言語等を含む通信に関する情報を含みます）",
                        "利用者が本サービスに掲載・発信した投稿、写真、動画、コメントその他の情報",
                      ]}
                    />
                  </Text>
                </ListItem>
                <ListItem>
                  個人情報の利用目的
                  <Text>
                    弊社は、個人情報を次の目的で利用することがあります。
                    <br />
                    ただし、それ以外の目的で利用する必要がある場合には、予め本人の承諾を得ることを前提といたします。また、収集した個人情報の取り扱いを外部に委託する場合には、委託先について厳正な調査を行った上、個人情報の漏えい等の事故が発生しないよう適正な監督を行います。
                    <UnorderedList mt={4}>
                      <ListItem>
                        弊社サービスの提供、運営、維持、保護、改善のため
                      </ListItem>
                      <ListItem>
                        利用者への連絡、お問い合わせに対する返信のため
                      </ListItem>
                      <ListItem>
                        弊社サービスに関するお知らせやプロモーション情報の提供のため
                      </ListItem>
                      <ListItem>
                        利用者に対し、弊社又は弊社のグループ会社その他の第三者の商品又はサービスに関する情報の提供、広告又は宣伝等を行うため（弊社は、利用者の弊社サービスの利用状況等を分析し、利用者の行動傾向、属性等を推定した上で、当該分析・推定結果に基づいた利用者に対する広告・マーケティング等に利用することがあります）
                      </ListItem>
                      <ListItem>
                        第三者のメーリングサービスを利用して利用者にメールを配信するため
                      </ListItem>
                      <ListItem>法令に基づく義務の遂行のため</ListItem>
                    </UnorderedList>
                  </Text>
                </ListItem>
                <ListItem>
                  仮名加工情報
                  <Text mt={2}>
                    弊社は、利用者の個人情報を元に、他の情報と照合しない限り特定の個人を識別できないように加工して得られる個人に関する情報である仮名情報を作成しています。
                    作成した仮名加工情報は、以下の利用目的を達するために必要な範囲で利用します。
                  </Text>
                  <UnorderedList mt={4}>
                    <ListItem>
                      弊社サービスの提供、運営、維持、保護、改善のため
                    </ListItem>
                    <ListItem>
                      利用者への連絡、お問い合わせに対する返信のため
                    </ListItem>
                    <ListItem>
                      弊社サービスに関するお知らせやプロモーション情報の提供のため
                    </ListItem>
                    <ListItem>法令に基づく義務の遂行のため</ListItem>
                  </UnorderedList>
                </ListItem>
                <ListItem>
                  匿名加工情報
                  <Text>
                    弊社は、利用者の個人情報を元に、特定の個人を識別できないように加工して得られる情報であり、元の個人情報を復元できないようにしたものである匿名加工情報を継続的に作成し、第三者に提供します。
                  </Text>
                  <Text mt={4}>
                    【作成】
                    <UnorderedList>
                      <ListItem>
                        作成する匿名加工情報に含まれる個人に関する情報の項目
                        <br />
                        利用者の個人属性情報（性別、年齢（年代）、職種、居住地の都道府県、サービス利用履歴）
                      </ListItem>
                    </UnorderedList>
                  </Text>
                  <Text>
                    【提供】
                    <UnorderedList>
                      <ListItem>
                        作成する匿名加工情報に含まれる個人に関する情報の項目
                        <br />
                        利用者の個人属性情報（性別、年齢（年代）、職種、居住地の都道府県、サービス利用履歴）
                      </ListItem>
                      <ListItem>
                        匿名加工情報の提供の方法
                        <br />
                        データファイルの暗号化やパスワード保護する等、セキュリティを確保した上で、データの送付もしくは記録媒体での送付又は書類の提供
                      </ListItem>
                    </UnorderedList>
                  </Text>
                </ListItem>
                <ListItem>
                  個人関連情報
                  <Text>
                    弊社は、第三者から、識別子や行動履歴等、利用者に関連する情報（個人関連情報）の提供を受け、当該情報を弊社が管理する利用者の個人情報と紐付けることで個人情報として取得することがあります。具体的には、Cookie、モバイル広告識別子、メールアドレスや電話番号（不可逆的な変換処理がされたものを含みます）、広告の閲覧回数等の広告配信関連ログ、位置情報その他の行動履歴等を取得し、弊社が管理する個人情報と紐付けて利用する場合があります。この場合は、本個人情報保護指針第2項に規定する利用目的の達成に必要な範囲で、個人情報として適切に取り扱います。
                  </Text>
                </ListItem>
                <ListItem>
                  Cookieの使用
                  <Text>
                    弊社は、利用者の利便性を高める目的で、Cookieを利用しています。
                    Cookieは、利用者のブラウザを識別するために使用され、個人を特定する情報は含まれません。利用者はブラウザの設定でCookieの使用を拒否できますが、その場合、一部の機能が制限される可能性があります。
                  </Text>
                </ListItem>
                <ListItem>
                  外部送信情報
                  <Text>
                    弊社のウェブサイトでは、利用者の利便性向上等のために、外部機関のサービスを利用しています。従って、弊社のウェブサイトを利用することにより、利用者の情報が外部機関に送信されることがあります。情報送信先の外部機関の事業者名、送信される利用者の情報、情報の利用目的は以下のとおりです。
                  </Text>
                  <Text mt={4}>
                    外部送信先の事業者名: Google LLC
                    <br />
                    利用目的: Googleアナリティクス
                    <br />
                    利用者の情報:
                    端末又はアプリの情報、ネットワーク情報、アクセス情報
                    <br />
                    <Link
                      color="textLink"
                      textDecoration="underline"
                      _hover={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                      href="https://marketingplatform.google.com/about/analytics/terms/jp/"
                    >
                      Terms of Service | Google Analytics – Google
                    </Link>
                    <br />
                    <Link
                      color="textLink"
                      textDecoration="underline"
                      _hover={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                      href="https://policies.google.com/privacy"
                    >
                      プライバシー ポリシー – ポリシーと規約 – Google
                    </Link>
                    <br />
                    <Link
                      color="textLink"
                      textDecoration="underline"
                      _hover={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                      href="https://policies.google.com/technologies/partner-sites"
                    >
                      Google
                      のサービスを使用するサイトやアプリから収集した情報の
                      Google による使用 – ポリシーと規約 – Google
                    </Link>
                    <br />
                    <br />
                    外部送信先の事業者名: Amazon Web Services, Inc.
                    <br />
                    利用目的: AWS
                    <br />
                    利用者の情報:
                    端末又はアプリの情報、アクセス情報、ユーザーの行動情報
                    <br />
                    <Link
                      color="textLink"
                      textDecoration="underline"
                      _hover={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                      href="https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice_Japanese_2023-09-22.pdf"
                    >
                      プライバシー通知（Amazon Web Services）
                    </Link>
                    <br />
                    <Link
                      color="textLink"
                      textDecoration="underline"
                      _hover={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                      href="https://aws.amazon.com/jp/compliance/data-privacy-faq/"
                    >
                      データプライバシー - アマゾン ウェブ サービス（AWS）
                    </Link>
                    <br />
                    <Link
                      color="textLink"
                      textDecoration="underline"
                      _hover={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                      href="https://aws.amazon.com/jp/aup/"
                    >
                      AWS利用規約
                    </Link>
                  </Text>
                </ListItem>
                <ListItem>
                  個人情報の管理と安全性
                  <Text>
                    弊社は個人情報の正確性を保ち、適切な運用・管理を行います。個人情報の紛失、破壊、改ざん、漏えいを防ぐため、適切なセキュリティ対策を講じます。
                  </Text>
                </ListItem>
                <ListItem>
                  個人情報の第三者提供
                  <Text>
                    本人の承諾を得ることなく、個人情報を第三者に提供することはありません。ただし、法令に基づく場合や上記2.のいずれかに該当する場合は除きます。
                  </Text>
                </ListItem>
                <ListItem>
                  情報の開示・訂正・利用停止・消去等
                  <Text>
                    本人は自身の個人情報について、開示・訂正・利用停止・消去等を求める権利を有しています。これらの要求がある場合には、具体的な方法については、以下お問い合わせ先にお問い合わせください。迅速かつ誠実に対応します。
                  </Text>
                </ListItem>
                <ListItem>
                  安全管理組織・体制
                  <Text>
                    弊社は個人情報保護のための組織と体制を整備し、適切な管理を実施します。個人情報保護管理責任者を置き、体制を継続的に見直し、改善に努めます。
                    <br />
                    また、弊社は、ジオトラスト社の「トゥルービジネスID」を取得しています。
                    <br />
                    ご入力いただくお客様の個人情報は、SSLとよばれる暗号化技術によって保護されていますので、安心してご利用ください。（SSL対応のブラウザでないと正しく動作しない場合がありますので、ご注意ください）
                  </Text>
                </ListItem>
                <ListItem>
                  個人情報保護方針の変更
                  <Text>
                    弊社は、必要に応じて個人情報保護方針を変更することがあります。変更がある場合は、ウェブサイト上でお知らせします。
                  </Text>
                </ListItem>
              </OrderedList>
              <Box mr="auto">
                個人情報方針に関するお問い合わせ先
                <br />
                〒542-0012 大阪市中央区谷町6-1-16 ナルカワビル
                <br />
                ユニオンシステム株式会社
                <br />
                info@unions.co.jp
                <br />
                <br />
                制定 2024年4月1日
                <br />
                <br />
                ユニオンシステム株式会社
                <br />
                代表取締役社長 吉田 健一郎
              </Box>
            </VStack>
          </ShadowCard>
        </Container>
      </Background>
    </>
  );
};

export default PrivacyPolicy;
