import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const TikTokIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" boxSize={8} {...props}>
    <path
      d="M22.5881 14.6671C21.2864 14.6702 20.0167 14.2628 18.9583 13.5024V18.8056C18.958 19.7878 18.6589 20.7464 18.101 21.5534C17.5431 22.3604 16.753 22.9772 15.8364 23.3213C14.9198 23.6655 13.9204 23.7206 12.9718 23.4793C12.0231 23.238 11.1705 22.7117 10.5279 21.9709C9.88529 21.2301 9.48334 20.3101 9.37579 19.3339C9.26824 18.3576 9.46022 17.3717 9.92605 16.5079C10.3919 15.6441 11.1094 14.9436 11.9826 14.5002C12.8558 14.0567 13.843 13.8913 14.8124 14.0262V16.6935C14.3688 16.5534 13.8925 16.5576 13.4514 16.7055C13.0104 16.8534 12.6272 17.1373 12.3565 17.5168C12.0858 17.8963 11.9416 18.352 11.9443 18.8187C11.9471 19.2854 12.0967 19.7392 12.3718 20.1155C12.6469 20.4918 13.0334 20.7712 13.4761 20.9138C13.9189 21.0565 14.3952 21.0551 14.8372 20.9098C15.2791 20.7645 15.6639 20.4828 15.9368 20.1049C16.2097 19.727 16.3566 19.2723 16.3566 18.8056V8.43848H18.9583C18.9565 8.65899 18.9749 8.8792 19.0133 9.09632C19.1037 9.58102 19.2917 10.0421 19.5658 10.4514C19.8398 10.8607 20.1942 11.2096 20.6072 11.4767C21.1948 11.8666 21.8837 12.0744 22.5881 12.0743V14.6671Z"
      fill="#212529"
    />
  </Icon>
);

export default TikTokIcon;
