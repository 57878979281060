import React from "react";
import { Flash } from "../../../shared/lib/types";
import Application from "../../shared/components/layouts/Application";
import FixedBackgroundBuildingImage from "../../shared/components/atoms/FixedBackgroundBuildingImage";
import Background from "../../shared/components/atoms/Background";
import { Box, Container, Flex, Link, Stack, Text } from "@chakra-ui/react";
import ShadowCard from "../../shared/components/atoms/ShadowCard";
import { businessMatchingRootPath, newContactPath } from "../../../../routes";
import { Button, Header } from "../../shared/components/atoms";
import { SharedApprovedCurrentUser } from "../../shared/lib/types";

const ApplicationDonesShow = ({
  flash,
  currentUser,
}: {
  flash: Flash;
  currentUser: SharedApprovedCurrentUser;
}) => {
  return (
    <Application flash={flash} currentUser={currentUser}>
      <FixedBackgroundBuildingImage />
      <Header currentUser={currentUser} showBusinessMatchingHeader />
      <Background>
        <Container maxW="4xl" mt={20} zIndex={1}>
          <ShadowCard>
            <Flex direction="column" align="center">
              <Text
                lineHeight="150%"
                fontSize={{ base: "md", lg: "2rem" }}
                fontWeight="600"
              >
                受発注サービスの申し込みを承りました
              </Text>
              <Box
                textAlign="center"
                mt={10}
                display={{ base: "none", lg: "block" }}
              >
                <Text>受発注サービスのお申し込みを承りました。</Text>
                <Text>審査結果が出るまで、今しばらくお待ちください。</Text>
                <Text>
                  審査に通過した事業主様には、利用開始のご案内をメールでお知らせいたします。
                </Text>
              </Box>
              <Box
                textAlign="center"
                mt={10}
                fontSize="sm"
                display={{ base: "block", lg: "none" }}
              >
                <Box>
                  <Text>受発注サービスの申し込みを承りました。</Text>
                </Box>
                <Box mt={4}>
                  <Text>審査結果が出るまで、</Text>
                  <Text>今しばらくお待ちください。</Text>
                </Box>
                <Box mt={4}>
                  <Text>審査に通過した事業主様には</Text>
                  <Text>利用開始のご案内を</Text>
                  <Text>メールでお知らせいたします。</Text>
                </Box>
              </Box>
              <Link
                href={businessMatchingRootPath()}
                color="textLink"
                fontSize={{ base: "sm", lg: "md" }}
                textDecoration="underline"
                _hover={{ textDecoration: "none" }}
                mt={10}
              >
                ビジネスパートナー一覧画面へ
              </Link>
              <Box
                backgroundColor="#EDF5F3"
                w="full"
                px={4}
                pt={6}
                pb={7}
                mt={12}
                textAlign="center"
              >
                <Stack gap={1}>
                  <Text fontWeight="bold">
                    受発注サービスに関するお問い合わせ
                  </Text>
                  <Text fontSize="sm" display={{ base: "none", lg: "block" }}>
                    以下のお問い合わせフォームからお気軽にご連絡ください。
                  </Text>
                  <Box fontSize="sm" display={{ base: "block", lg: "none" }}>
                    <Text>以下のお問い合わせフォームから</Text>
                    <Text>お気軽にご連絡ください。</Text>
                  </Box>
                </Stack>
                <Button
                  mt={4}
                  as="a"
                  href={newContactPath()}
                  target="_blank"
                  rel="noreferrer"
                >
                  お問い合わせフォームへ
                </Button>
              </Box>
            </Flex>
          </ShadowCard>
        </Container>
      </Background>
    </Application>
  );
};

export default ApplicationDonesShow;
