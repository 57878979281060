import React, { ReactNode, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Flex, Stack, Text, Box, CheckboxGroup } from "@chakra-ui/react";
import { FormLabel, Input } from "../../shared/components/atoms/form";
import Textarea from "../../shared/components/atoms/form/Textarea";
import {
  useImageFileDropzoneState,
  ImageFileDropzone,
} from "../../shared/components/atoms/ImageFileDropzone";
import Checkbox from "../../shared/components/atoms/form/Checkbox";
import { SharedTag } from "../../../shared/lib/types";

const CommunityForm = ({
  defaultValues = { name: "", description: "", tag_ids: [] },
  thumbnailFirstPreview = "",
  onSubmit,
  tags,
  renderButton,
}: {
  defaultValues?: { name: string; description: string; tag_ids: string[] };
  thumbnailFirstPreview?: string;
  onSubmit: (formData: FormData) => void;
  tags: SharedTag[];
  renderButton: ({ isSubmitting }: { isSubmitting: boolean }) => ReactNode;
}) => {
  const schema = yup.object({
    name: yup.string().trim().max(30).required().label("コミュニティ名"),
    description: yup.string().ensure().trim().max(500).label("コミュニティ説明"),
    tag_ids: yup.array(yup.string().required()).required(),
  });

  type FormData = yup.InferType<typeof schema>;

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm<FormData>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [deleteThumbnail, setDeleteThumbnail] = useState(false);
  const thumbnailFieldState = useImageFileDropzoneState({
    firstPreview: thumbnailFirstPreview,
  });

  const _onSubmit = async (data: FormData) => {
    if (!thumbnailFieldState.validate()) {
      return;
    }

    const formData = new FormData();
    formData.append("community[name]", data.name);
    formData.append("community[description]", data.description);
    if (data.tag_ids.length > 0) {
      data.tag_ids.forEach((tag_id) => {
        formData.append("community[tag_ids][]", tag_id);
      });
    } else {
      formData.append("community[tag_ids][]", "");
    }
    formData.append("delete_thumbnail", deleteThumbnail ? "1" : "0");
    if (thumbnailFieldState.file) {
      formData.append("community[thumbnail]", thumbnailFieldState.file);
    }
    await onSubmit(formData);
  };

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(_onSubmit, thumbnailFieldState.validate)}
    >
      <Stack gap={6} mt={{ base: 4, lg: 8 }}>
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              label="コミュニティ名"
              required
              error={fieldState.error?.message ?? ""}
              {...field}
              message="30文字以内"
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field, fieldState }) => (
            <Textarea
              label="コミュニティ説明"
              error={fieldState.error?.message ?? ""}
              {...field}
              message="500文字以内"
            />
          )}
        />
        <Box>
          <FormLabel>タグ</FormLabel>
          <Flex wrap="wrap" gap={3}>
            <Controller
              name="tag_ids"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <>
                  <Text color="#6D787D" fontSize="xs">
                    コミュニティに関連するタグを選ぶことで、あなたのコミュニティを見つけやすくなります
                  </Text>
                  <CheckboxGroup {...field}>
                    {tags.map(({ id, name }) => (
                      <Checkbox key={id} value={id.toString()} ref={ref}>
                        {name}
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                </>
              )}
            />
          </Flex>
        </Box>
        <Stack>
          <FormLabel>コミュニティ画像</FormLabel>
          <ImageFileDropzone
            label="コミュニティ画像"
            onChange={(thumbnail: string) => {
              setDeleteThumbnail(thumbnail == null);
            }}
            imageFileDropzoneState={thumbnailFieldState}
            croppable
            aspectRatio={2 / 1}
          />
          <Text fontSize="sm" color="#6D787D">
            コミュニティに画像を登録することができます。 <br />
            この画像はコミュニティ一覧やコミュニティ内で表示されます。
          </Text>
        </Stack>
      </Stack>
      {renderButton({ isSubmitting })}
    </Box>
  );
};

export default CommunityForm;
