import { Text } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { TextProps } from "@chakra-ui/react";

const InputError = ({
  children,
  ...props
}: {
  children: ReactNode;
} & TextProps) => {
  return (
    <Text color="caution" fontSize="sm" {...props}>
      {children}
    </Text>
  );
};

export default InputError;
