import { extendTheme } from "@chakra-ui/react";
import { ButtonStyle } from "../components/atoms/Button";

const theme = extendTheme({
  fonts: {
    heading: `"IBM Plex Sans JP", sans-serif`,
    body: `"IBM Plex Sans JP", sans-serif`,
  },
  semanticTokens: {
    colors: {
      brand: {
        50: "#EDF5F3",
        100: "#D9EEE9",
        200: "#B3DDD3",
        500: "#41AA92",
      },
      caution: "#CD4429",
      primary: "brand.500",
      textBase: "#212625",
      textLink: "#1A827B",
      textGray: "#6B767B",
      borderDivider: "#C4CCCA",
      gray: {
        base: "#6B6B6B",
        light: "#F2F2F2",
      },
      border: {
        cardOutline: "#DCE5E3",
      },
      text: {
        caution: {
          dark: "#CD2929",
        },
      },
      background: {
        caution: "#FFF3F3",
      },
      tealBlue: {
        base: "#0088A6",
        light: "#C8EAF2",
      },
    },
  },
  styles: {
    global: {
      body: {
        fontSize: "16px",
        color: "textBase",
      },
    },
  },
  components: {
    Link: {
      baseStyle: {
        color: "textLink",
        textDecoration: "underline",
        _hover: {
          textDecoration: "none",
        },
      },
    },
    Button: ButtonStyle,
  },
});

export default theme;
