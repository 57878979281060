import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const FacebookIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" boxSize={8} {...props}>
    <path
      d="M19.9156 17.0874L20.3868 14.0332H17.4404V12.0512C17.4404 11.2156 17.852 10.4012 19.1716 10.4012H20.511V7.80082C20.511 7.80082 19.2955 7.59448 18.1334 7.59448C15.707 7.59448 14.121 9.05731 14.121 11.7054V14.0332H11.4238V17.0874H14.121V24.4708H17.4404V17.0874H19.9156Z"
      fill="#212529"
    />
  </Icon>
);

export default FacebookIcon;
