import { Box, Link } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";

const GoBackLink = ({
  children,
  href,
}: {
  children: ReactNode;
  href: string;
}) => {
  return (
    <Link display="flex" alignItems="center" href={href} w="fit-content">
      <ChevronLeftIcon color="textLink" boxSize={6} />
      <Box
        as="span"
        color="textLink"
        textDecoration="underline"
        _hover={{ textDecoration: "none" }}
      >
        {children}
      </Box>
    </Link>
  );
};

export default GoBackLink;
