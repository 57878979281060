import React, { useEffect } from "react";
import Application from "../shared/components/layouts/Application";
import Header from "../shared/components/atoms/Header";
import Background from "../shared/components/atoms/Background";
import {
  Avatar,
  Box,
  CheckboxGroup,
  Container,
  Flex,
  Grid,
  Heading,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FormLabel, Input } from "../shared/components/atoms/form";
import Checkbox from "../shared/components/atoms/form/Checkbox";
import { Button } from "../shared/components/atoms";
import { Controller, useForm } from "react-hook-form";
import {
  communitiesPath,
  communityPath,
  newCommunityPath,
} from "../../../routes";
import Tag from "../shared/components/atoms/Tag";
import Pagination from "../shared/components/atoms/Pagination";
import { Communities, SharedCurrentUser } from "../shared/lib/types";
import { Flash, Pagy, SharedTag } from "../../shared/lib/types";

const CommunitiesIndex = ({
  communities,
  tags,
  pagy,
  flash,
  currentUser,
}: {
  communities: Communities;
  tags: SharedTag[];
  pagy: Pagy;
  flash: Flash;
  currentUser: SharedCurrentUser;
}) => {
  type FormData = { keyword: string; tags: string[] };
  const { control, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: {
      keyword: "",
      tags: [],
    },
  });

  const onSubmit = async (data: FormData) => {
    const query = new URLSearchParams({
      "q[name_or_description_cont]": data.keyword,
    });
    data.tags.forEach((tag) => {
      query.append("q[tags_id_eq_any][]", tag);
    });

    location.href = `${communitiesPath()}?${query}`;
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setValue("keyword", params.get("q[name_or_description_cont]") ?? "");
    setValue("tags", params.getAll("q[tags_id_eq_any][]") ?? []);
  }, [setValue]);

  return (
    <Application flash={flash} currentUser={currentUser}>
      <Header currentUser={currentUser} />
      <Background color="brand.50">
        <Container maxW="container.lg" mt={14}>
          <Flex alignItems="center" justifyContent="space-between">
            <Heading size="lg">コミュニティ一覧</Heading>
            <Button variant="outline" as="a" href={newCommunityPath()}>
              コミュニティ作成
            </Button>
          </Flex>
          <Text fontSize="sm" mt={3}>
            建築士同士が交流し、情報交換を行える場所です。
            <br />
            共通の目的や課題をもっている建築士が集まり、設計で困っていることや設計事例、経営についてなど、質問やコメントを行いながら情報交換が行えます。
          </Text>
          <Box
            backgroundColor="#FFF"
            w="full"
            px={10}
            py={8}
            mt={4}
            as="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack gap={6}>
              <Box maxW="sm">
                <Controller
                  name="keyword"
                  control={control}
                  render={({ field }) => (
                    <Input
                      label="キーワード"
                      placeholder="コミュニティ名、コミュニティ説明で検索"
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box>
                <FormLabel>タグ</FormLabel>
                <Flex columnGap={4} rowGap={3} wrap="wrap" mt={3}>
                  <Controller
                    name="tags"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <CheckboxGroup {...field}>
                        {tags.map(({ id, name }) => (
                          <Checkbox key={id} value={id.toString()} ref={ref}>
                            {name}
                          </Checkbox>
                        ))}
                      </CheckboxGroup>
                    )}
                  />
                </Flex>
              </Box>
            </Stack>
            <Flex justifyContent="center">
              <Button type="submit" minW={{ base: "full", sm: "xs" }} mt={8}>
                検索
              </Button>
            </Flex>
          </Box>
          <Grid
            mt={14}
            rowGap={4}
            columnGap={2}
            justifyContent="center"
            templateColumns="repeat(auto-fill, minmax(288px, 1fr))"
          >
            {communities.map((community) => (
              <Box key={community.code}>
                <Box
                  backgroundSize="cover"
                  backgroundImage={community.thumbnail_url}
                  w="full"
                  h={120}
                  backgroundPosition="center center"
                />
                <Box backgroundColor="#FFF" px={5} py={4}>
                  <Link href={communityPath(community.code)}>
                    <Heading size="md" as="h3">
                      {community.name}
                    </Heading>
                  </Link>
                  <Flex gap={1} flexWrap="wrap" mt={1}>
                    {community.tags.map((tag) => (
                      <Tag key={tag.name}>{tag.name}</Tag>
                    ))}
                  </Flex>
                  <Text
                    mt={3}
                    color="black"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    display="-webkit-box"
                    css={{ WebkitLineClamp: 5, WebkitBoxOrient: "vertical" }}
                  >
                    {community.description}
                  </Text>
                  <Box mt={3}>
                    <Text as="span">{community.members_count}人 が参加中</Text>
                  </Box>
                  <Flex
                    mt={3}
                    pt={3}
                    borderTop="1px solid"
                    borderColor="rgba(0, 0, 0, 0.10)"
                    justifyContent="space-between"
                  >
                    <Text>コミュニティ管理者</Text>
                    <Flex gap={1}>
                      <Avatar
                        w={6}
                        h={6}
                        src={community.owner.profile_image_url}
                      />
                      <Text as="span" size="sm">
                        {community.owner.full_name}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              </Box>
            ))}
          </Grid>
          <Flex justifyContent="center" mt={14}>
            <Pagination
              pageCount={pagy.pages}
              currentPage={pagy.page}
              onClickPage={(page) => {
                const params = new URLSearchParams(location.search);
                params.set("page", page.toString());
                location.search = params.toString();
              }}
            />
          </Flex>
        </Container>
      </Background>
    </Application>
  );
};

export default CommunitiesIndex;
