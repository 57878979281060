import React from "react";
import {
  Box,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Textarea } from "../../../shared/components/atoms/form";
import { Button } from "../../../shared/components/atoms";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import useRequest from "../../../shared/lib/useRequest";
import { businessMatchingTalkRoomMessagesPath } from "../../../../../routes";
import UploadFiles, {
  useUploadFiles,
} from "../../../../shared/components/UploadFiles";
import FileUploadButton from "../../../../shared/components/FileUploadButton";
import AddIcon from "../../../shared/components/icons/AddIcon";
import { uploadFiles as directUploadFiles } from "../../../../shared/lib/uploadFile";
import SendIcon from "../../../shared/components/icons/SendIcon";
import HelpMessage from "../../../shared/components/atoms/HelpMessage";

const schema = yup.object({
  content: yup.string().trim().required().label("メッセージ"),
});
type FormData = yup.InferType<typeof schema>;

const TalkMessageForm = ({ talkRoomCode }: { talkRoomCode: string }) => {
  const request = useRequest();
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      content: "",
    },
    resolver: yupResolver(schema),
  });
  const uploadFilesState = useUploadFiles();
  const [uploadFiles, addUploadFiles, removeUploadFile, clearUploadFiles] =
    uploadFilesState;

  const onSubmit = async (data: FormData) => {
    const results = await directUploadFiles({ files: uploadFiles as File[] });
    const blobIds = results.map((result) => result.blob.signed_id);
    await request(businessMatchingTalkRoomMessagesPath(talkRoomCode), "POST", {
      talk_message: { ...data, files: blobIds },
    });
    clearUploadFiles();
    reset();
  };

  const leftIcon = useBreakpointValue({ base: undefined, sm: <SendIcon /> });
  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)} gap={0}>
      <Flex gap={{ base: 2, sm: 3 }} alignItems="flex-end">
        <Controller
          name="content"
          control={control}
          render={({ field }) => (
            <Textarea
              placeholder="メッセージを入力"
              {...field}
              bgColor="brand.100"
              border="none"
              minH={{ base: 9, sm: 12 }}
              fontSize={{ base: "sm", sm: "md" }}
              py={{ base: 1.5, sm: 3 }}
              rows={1}
              autoExpand
              setMaxHeight
              _placeholder={{ lineHeight: "1.5rem" }}
              maxH="7rem"
            />
          )}
        />
        <Button
          leftIcon={leftIcon}
          iconSpacing={1}
          whiteSpace="nowrap"
          h={{ base: 9, sm: 12 }}
          type="submit"
          size="sm"
          w={{ base: 9, sm: "fit-content" }}
          isLoading={isSubmitting}
        >
          <Text display={{ base: "none", sm: "block" }}>送信</Text>
          <SendIcon display={{ base: "block", sm: "none" }} />
        </Button>
      </Flex>
      {uploadFiles.length > 0 && (
        <Box mt={4} maxH={{ base: "55vh", sm: "none" }} overflowY="auto">
          <UploadFiles
            uploadFiles={uploadFiles}
            removeFile={removeUploadFile}
          />
        </Box>
      )}
      <HStack>
        <FileUploadButton multiple onChange={addUploadFiles} name="files">
          <HStack
            gap={0}
            mt={2}
            textDecoration="underline"
            color="textLink"
            _hover={{ textDecoration: "none" }}
          >
            <AddIcon />
            <Box fontSize="xs">ファイル添付</Box>
          </HStack>
        </FileUploadButton>
        <Popover>
          <PopoverTrigger>
            <HelpMessage cursor="pointer">ファイル添付について</HelpMessage>
          </PopoverTrigger>
          <PopoverContent width={{ base: "xs", md: "auto" }}>
            <PopoverArrow />
            <PopoverBody>
              <Text fontSize="xs">
                ・添付できるファイル容量の上限は5MB未満です。
                <br />
                ・jpeg,jpg,png,gifの形式のファイルのみプレビュー表示され、それ以外はダウンロード形式になります。
                <br />
                ・最大で5つまでアップロード可能です。
              </Text>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </HStack>
    </Stack>
  );
};

export default TalkMessageForm;
