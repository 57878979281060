import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  newUserSessionPath,
  userDatabaseAuthenticationPasswordPath,
} from "../../../../routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Heading, Link, Stack, Text, Box } from "@chakra-ui/react";
import ShadowCard from "../../shared/components/atoms/ShadowCard";
import Input from "../../shared/components/atoms/form/Input";
import { Button } from "../../shared/components/atoms";
import ErrorMessage from "../../shared/components/atoms/ErrorMessage";
import Application from "../../shared/components/layouts/Application";
import useRequest from "../../shared/lib/useRequest";
import FixedBackgroundBuildingImage from "../../shared/components/atoms/FixedBackgroundBuildingImage";
import Background from "../../shared/components/atoms/Background";
import Header from "../../shared/components/atoms/Header";
import { Flash } from "../../../shared/lib/types";

const AuthPasswordsNew = ({ flash }: { flash: Flash }) => {
  const schema = yup.object({
    email: yup.string().trim().required().label("メールアドレス"),
  });

  type FormData = yup.InferType<typeof schema>;

  const [errorMessage, setErrorMessage] = useState<string>("");

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm<FormData>({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });

  const request = useRequest();
  const onSubmit = async (data: FormData) => {
    const res = await request(
      userDatabaseAuthenticationPasswordPath(),
      "POST",
      {
        user_database_authentication: data,
      },
    );
    if (res.ok) {
      location.href = res.headers.get("Location")!;
      return;
    } else {
      const json = await res.json();
      setErrorMessage(json.errors.email[0]);
      return;
    }
  };

  return (
    <Application flash={flash} disableFloatingRegisterButton>
      <FixedBackgroundBuildingImage />
      <Background>
        <Header onlyLogo />
        <Container
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          maxW="lg"
          mt={{ base: 6, lg: 12 }}
          zIndex={1}
        >
          <ShadowCard>
            <Heading size="md" textAlign="center">
              パスワードを再設定
            </Heading>
            {errorMessage !== "" && (
              <Box mt={4}>
                <ErrorMessage>{errorMessage}</ErrorMessage>
              </Box>
            )}
            <Stack gap={4} mt={{ base: 2, lg: 4 }}>
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    label="メールアドレス"
                    required
                    placeholder="sample@aloop.com"
                    error={fieldState.error?.message ?? ""}
                    {...field}
                  />
                )}
              />
            </Stack>
            <Stack mt={4} align="stretch">
              <Button type="submit" isLoading={isSubmitting}>
                パスワード再設定リンクを送信
              </Button>
            </Stack>
            <Text mt={3} fontSize={{ base: "xs", lg: "sm" }} textAlign="center">
              <Link
                color="textLink"
                textDecoration="underline"
                _hover={{ textDecoration: "none" }}
                href={newUserSessionPath()}
              >
                ログインページに戻る
              </Link>
            </Text>
          </ShadowCard>
        </Container>
      </Background>
    </Application>
  );
};

export default AuthPasswordsNew;
