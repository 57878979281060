import { Box, Flex, FlexProps, Link } from "@chakra-ui/react";
import React, { useContext } from "react";
import {
  newUserDatabaseAuthenticationRegistrationPath,
  newUserDatabaseAuthenticationSessionPath,
  newUserPersonalInfoApplicationPath,
} from "../../../../../routes";
import { FlipperContext } from "../../../../shared/lib/FlipperContext";
import { SharedCurrentUser } from "../../lib/types";
import { Button, ButtonProps } from "./Button";

const RegisterOrLoginButtons = ({
  currentUser,
  loginButtonProps,
  ...props
}: {
  currentUser: SharedCurrentUser;
  loginButtonProps: ButtonProps;
} & FlexProps) => {
  const href = currentUser?.is_all_public_feature_accessible
    ? ""
    : currentUser?.is_temporary
      ? newUserPersonalInfoApplicationPath()
      : newUserDatabaseAuthenticationRegistrationPath();

  const flipper = useContext(FlipperContext);

  const label = currentUser?.is_all_public_feature_accessible ? (
    ""
  ) : currentUser?.is_temporary && !currentUser?.applicationing ? (
    "本人確認を申請する"
  ) : flipper.free_registration ? (
    <Box>
      会員登録
      <wbr />
      （無料）
    </Box>
  ) : (
    "会員登録する"
  );

  return (
    <Flex gap={2} {...props}>
      <Button
        as={Link}
        textAlign={"center"}
        wordBreak={"keep-all"}
        textDecoration="none"
        href={href}
        flex={1}
      >
        {label}
      </Button>
      {currentUser == null && (
        <Button
          as="a"
          cursor="pointer"
          href={newUserDatabaseAuthenticationSessionPath()}
          flex={{ base: 1, md: "initial" }}
          {...loginButtonProps}
        >
          ログイン
        </Button>
      )}
    </Flex>
  );
};

export default RegisterOrLoginButtons;
