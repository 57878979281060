import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const ExpandMoreIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" boxSize={5} {...props}>
    <path
      d="M10 12.8127L5 7.81266L6.16667 6.646L10 10.4793L13.8333 6.646L15 7.81266L10 12.8127Z"
      fill="currentColor"
    />
  </Icon>
);

export default ExpandMoreIcon;
