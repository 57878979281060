import {
  Radio as ChakraRadio,
  RadioProps,
  Text,
  forwardRef,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

const Radio = forwardRef(
  (
    {
      children,
      value,
      ...radioProps
    }: { children: ReactNode; value: string } & RadioProps,
    ref,
  ) => {
    return (
      <ChakraRadio
        colorScheme="teal"
        borderColor="teal"
        value={value}
        size="lg"
        ref={ref}
        {...radioProps}
      >
        <Text fontSize="md" pt={0.5}>
          {children}
        </Text>
      </ChakraRadio>
    );
  },
);

export default Radio;
