import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { businessMatchingOwnedBusinessEntityActivationPath } from "../../../../routes";
import useRequest from "../../../admin/shared/lib/useRequest";
import { Button } from "../../shared/components/atoms";
import InfoMessage from "../../shared/components/atoms/InfoMessage";
import SiteSeal from "../../shared/components/atoms/SiteSeal";
import WarningMessage from "../../shared/components/atoms/WarningMessage";
import {
  BusinessMatchingActiveBusinessEntitiesBusinessEntity,
  SharedApprovedCurrentUser,
} from "../../shared/lib/types";
import useFlash from "../../shared/lib/useFlash";
import { Flash } from "../../../shared/lib/types";
import OwnedBusinessEntityLayout from "../shared/components/OwnedBusinessEntityLayout";

const ActiveBusinessEntitiesEdit = ({
  businessEntity,
  flash,
  currentUser,
}: {
  businessEntity: BusinessMatchingActiveBusinessEntitiesBusinessEntity;
  flash: Flash;
  currentUser: SharedApprovedCurrentUser;
}) => {
  return (
    <OwnedBusinessEntityLayout
      flash={flash}
      currentUser={currentUser}
      currentBusinessEntity={businessEntity}
    >
      <Box background="#FFF" p={8} borderRadius="2px">
        <Heading as="h1" size="lg">
          サービス利用設定
        </Heading>
        <Box>
          <Text fontWeight={"bold"} mt={6} id="current-active">
            現在の利用設定
          </Text>
          <Box mt={2} aria-labelledby="current-active">
            {currentActiveStatus(businessEntity)}
          </Box>
        </Box>
        <Box mt={4}>
          <MessageForCurrentActiveStatus businessEntity={businessEntity} />
        </Box>
        <Box mt={8}>
          <ToggleActiveButton businessEntity={businessEntity} />
        </Box>
      </Box>
      <Box mt={6}>
        <SiteSeal />
      </Box>
    </OwnedBusinessEntityLayout>
  );
};

const currentActiveStatus = (
  businessEntity: BusinessMatchingActiveBusinessEntitiesBusinessEntity,
) => (businessEntity.is_active ? "掲載中" : "掲載一時停止中");

const MessageForCurrentActiveStatus = ({
  businessEntity,
}: {
  businessEntity: BusinessMatchingActiveBusinessEntitiesBusinessEntity;
}) =>
  businessEntity.is_active ? (
    <InfoMessage>受発注のすべての機能をご利用いただける状態です。</InfoMessage>
  ) : (
    <WarningMessage>
      現在、ビジネスパートナー一覧に事業者情報は表示されていない状態です。
      <br />
      そのため新規パートナーとのやりとりを開始できません。
      <br />
      新たなパートナーとやりとりしたい場合は、下部のボタンから掲載を再開してください。
    </WarningMessage>
  );

const ToggleActiveButton = ({
  businessEntity,
}: {
  businessEntity: BusinessMatchingActiveBusinessEntitiesBusinessEntity;
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const request = useRequest();
  const showFlash = useFlash();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const toggleActive = async (active: boolean) => {
    const res = await request(
      businessMatchingOwnedBusinessEntityActivationPath(businessEntity.code),
      "PUT",
      { active },
    );

    if (res.ok) {
      location.reload();
    } else {
      showFlash({ error: "エラーが発生しました" });
    }
  };

  return businessEntity.is_active ? (
    <>
      <Button onClick={onOpen} variant="outline">
        <Box wordBreak={"keep-all"} overflowWrap={"anywhere"}>
          ビジネスパートナー一覧への
          <wbr />
          掲載を一時停止する
        </Box>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent mx={2}>
          <ModalHeader fontWeight={"bold"} wordBreak={"keep-all"}>
            ビジネスパートナー一覧への
            <wbr />
            掲載を一時停止する
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <WarningMessage>
              事業者情報ページを非表示にします。 <br />
              掲載一時停止中は、新規パートナーとのメッセージのやりとりが開始できなくなりますのでご注意ください。{" "}
              <br />
              ※既存のパートナーとは、引き続きやりとりが可能です。
            </WarningMessage>
          </ModalBody>
          <ModalFooter gap={3}>
            <Button onClick={onClose} color="gray">
              <Box wordBreak={"keep-all"} overflowWrap={"anywhere"}>
                掲載一時停止を
                <wbr />
                やめる
              </Box>
            </Button>
            <Button
              onClick={handleSubmit(() => toggleActive(false))}
              color="red"
              isLoading={isSubmitting}
            >
              <Box wordBreak={"keep-all"} overflowWrap={"anywhere"}>
                掲載一時
                <wbr />
                停止
              </Box>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  ) : (
    <Button
      onClick={handleSubmit(() => toggleActive(true))}
      isLoading={isSubmitting}
    >
      <Box wordBreak="keep-all" overflowWrap={"anywhere"}>
        ビジネスパートナー一覧への
        <wbr />
        掲載を再開する
      </Box>
    </Button>
  );
};

export default ActiveBusinessEntitiesEdit;
