import { Box } from "@chakra-ui/react";
import React, { ReactNode } from "react";

const ShadowCard = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      px={{ base: 5, md: 16 }}
      py={{ base: 10, md: 14 }}
      borderRadius="2px"
      background="#FFF"
      boxShadow="8px 8px 0px 0px rgba(26, 130, 123, 0.08)"
    >
      {children}
    </Box>
  );
};

export default ShadowCard;
