import { Box, Container, Flex, Show, Stack } from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { newPostPath } from "../../../routes";
import { FlipperContext } from "../../shared/lib/FlipperContext";
import { Flash, Pagy, SharedTag } from "../../shared/lib/types";
import Background from "../shared/components/atoms/Background";
import Footer from "../shared/components/atoms/Footer";
import Header from "../shared/components/atoms/Header";
import HelpMessage from "../shared/components/atoms/HelpMessage";
import PagyPagination from "../shared/components/atoms/PagyPagination";
import PostSummaryCard from "../shared/components/atoms/PostSummaryCard";
import ServiceCaution from "../shared/components/atoms/ServiceCaution";
import AddIcon from "../shared/components/icons/AddIcon";
import Application from "../shared/components/layouts/Application";
import { Posts, SharedCurrentUser } from "../shared/lib/types";
import PostOrderLinks from "./components/PostsOrderLinks";
import SearchForm from "./components/SearchForm";
import ChangeThemeButtons from "./components/ChangeThemeButtons";

const PostsIndex = ({
  posts,
  tags,
  flash,
  pagy,
  currentUser,
}: {
  posts: Posts;
  tags: SharedTag[];
  flash: Flash;
  pagy: Pagy;
  currentUser: SharedCurrentUser;
}) => {
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setKeyword(params.get("q[title_or_content]") ?? "");
  }, []);

  const ref = useRef<HTMLDivElement>(null);
  const flipper = useContext(FlipperContext);

  useEffect(() => {
    const url = new URL(location.href);
    let newDesignValue = null;
    if (currentUser != null) {
      newDesignValue =
        currentUser.current_post_theme_name === "compact" ? "1" : "2";
    } else {
      newDesignValue =
        localStorage.getItem("current_post_theme_name") === "compact"
          ? "1"
          : "2";
    }
    url.searchParams.set("new_design", newDesignValue);

    window.history.replaceState(null, "", url.toString());
  }, [currentUser, flipper]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("search") == "1" && ref.current) {
      ref.current.scrollIntoView({ block: "start" });
      window.scrollTo(0, window.scrollY - 84);
    }
  }, [flipper]);

  return (
    <Application flash={flash} currentUser={currentUser}>
      <Header currentUser={currentUser} />
      <Background color="brand.50">
        <Container maxW="container.md" mt={{ base: 8, sm: 14 }}>
          <Flex justify={"flex-end"} alignItems="flex-end" mb={2}>
            <HelpMessage
              as="a"
              href="https://aloop.jp/announcements/20241209_143904"
              target="_blank"
              rel="noreferrer"
            >
              Q&A投稿方法
            </HelpMessage>
          </Flex>
          <SearchForm tags={tags} />
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            flexDir={{ base: "column-reverse", sm: "row" }}
            mt={6}
            rowGap={4}
          >
            <Box alignSelf={{ base: undefined, sm: "flex-end" }}>
              <ChangeThemeButtons currentUser={currentUser} />
            </Box>
            <Box>
              <PostOrderLinks />
            </Box>
          </Flex>
          <Stack gap={4} ref={ref} mt={4}>
            {posts.map((post) => (
              <PostSummaryCard
                post={post}
                key={post.code}
                // eslint-disable-next-line no-irregular-whitespace
                query={keyword.split(/ |　/)}
                currentUser={currentUser}
              />
            ))}
          </Stack>

          <Flex justifyContent="center" mt={14}>
            <PagyPagination pagy={pagy} />
          </Flex>
          <Box mt={12}>
            <ServiceCaution />
          </Box>
        </Container>
        <Show below="md">
          <Box
            position="sticky"
            w="fit-content"
            pr={8}
            ml="auto"
            mt={2}
            bottom={8}
            mr={{ base: 0, md: 56 }}
          >
            <Stack align="center" w="fit-content">
              <Box
                as="a"
                href={newPostPath()}
                borderRadius="full"
                bgColor="#348875"
                w="fit-content"
                p={3}
                onClick={() => 1}
                float="right"
              >
                <AddIcon boxSize={8} color="white" />
              </Box>
            </Stack>
          </Box>
        </Show>
      </Background>
      <Footer />
    </Application>
  );
};

export default PostsIndex;
