import { Box, Flex, HStack, Link, Stack, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import { postPath, postsPath } from "../../../../../routes";
import { SharedPost, SharedCurrentUser } from "../../lib/types";
import BarChartIcon from "../icons/BarChatIcon";
import ChatBubbleIcon from "../icons/ChatBubbleIcon";
import SellIcon from "../icons/SellIcon";
import BookmarkButton from "./BookmarkButton";
import Highlight from "./Highlight";
import PostResourceAvatar from "./PostResourceAvatar";
import PostResourceDisplayName from "./PostResourceDisplayName";

const PostSummaryCardCompact = ({
  post,
  query = [],
  currentUser,
}: {
  post: SharedPost;
  query?: string[];
  currentUser: SharedCurrentUser;
}) => {
  return (
    <Box
      backgroundColor="#FFF"
      px={5}
      pt={5}
      pb={4}
      borderRadius="8px"
      as="a"
      display="block"
      textDecoration="none"
      color="inherit"
      href={postPath(post.code)}
      _hover={{
        outline: "4px solid",
        outlineColor: "primary",
      }}
    >
      <HStack>
        {post.questionnaire_accepting && (
          <HStack
            borderRadius={2}
            w="fit-content"
            pl={2.5}
            pr={3}
            py={0.5}
            gap={0.5}
            mb={3}
            color="#0088A6"
            bgColor="#C8EAF2"
          >
            <BarChartIcon boxSize="18px" />
            <Box mt={0.5} fontSize="xs" fontWeight="bold">
              アンケート 実施中
            </Box>
          </HStack>
        )}
        {post.is_resolved && (
          <Box
            pt={1}
            pb={0.5}
            px={3}
            mb={3}
            fontWeight="bold"
            fontSize="xs"
            color="#6B6B6B"
            bgColor="#F2F2F2"
            borderRadius={2}
            verticalAlign="middle"
            mr={{ base: 1, md: 2 }}
          >
            解決済み
          </Box>
        )}
      </HStack>
      <Box mb={1}>
        <Text
          fontWeight="700"
          verticalAlign="middle"
          noOfLines={2}
          fontSize="md"
          display="inline"
        >
          <Highlight query={query}>{post.title}</Highlight>
        </Text>
      </Box>
      {post.tags.length > 0 && (
        <Flex gap={1} pb={2} wrap="wrap" alignItems="center">
          <SellIcon />
          {post.tags.map((tag, index) => (
            <React.Fragment key={tag.name}>
              <Link
                as="button"
                key={tag.name}
                fontSize="xs"
                // TODO: リンク内にリンクを置くため
                // アクセシビリティ的によくない
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  location.href = postsPath({
                    q: {
                      tags_id_eq_any: [tag.id],
                    },
                  });
                }}
              >
                {tag.name}
              </Link>
              {index !== post.tags.length - 1 && (
                <Box as="span" fontSize="xs" color="gray">
                  ,
                </Box>
              )}
            </React.Fragment>
          ))}
        </Flex>
      )}
      <Flex gap={3} justify="space-between" align="center">
        <HStack gap={2}>
          <PostResourceAvatar
            boxSize={7}
            author={post.author}
            loading="lazy"
            ignoreFallback
            sx={{ "--avatar-font-size": "0.75rem" }}
          />
          <Stack
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: undefined, md: "center" }}
            gap={{ base: 0, md: 2 }}
          >
            <PostResourceDisplayName
              author={post.author}
              displayNameProps={{ fontSize: "xs" }}
            />
            <Text fontSize="xs" color="#848484">
              {dayjs(post.created_at).format("L")}
            </Text>
          </Stack>
        </HStack>
        <HStack justify={{ base: "space-between" }}>
          <HStack gap={1} color="gray">
            <ChatBubbleIcon boxSize="1.125rem" />
            <Text fontSize={{ base: "xs", md: "sm" }} pt="3px">
              {post.comments_count}
            </Text>
          </HStack>
          <BookmarkButton
            post_code={post.code}
            bookmarked={post.is_bookmarked}
            currentUser={currentUser}
          />
        </HStack>
      </Flex>
    </Box>
  );
};

export default PostSummaryCardCompact;
