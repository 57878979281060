import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const WarningAmberIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" boxSize={5} {...props}>
    <path
      id="Vector"
      d="M9.99998 5.40837L16.275 16.25H3.72498L9.99998 5.40837ZM9.99998 2.08337L0.833313 17.9167H19.1666L9.99998 2.08337ZM10.8333 13.75H9.16665V15.4167H10.8333V13.75ZM10.8333 8.75004H9.16665V12.0834H10.8333V8.75004Z"
      fill="currentColor"
    />
  </Icon>
);

export default WarningAmberIcon;
