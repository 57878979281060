import { useState } from "react";

const useFiles = (
  initial: File[],
  {
    maxCount,
    maxTotalBytesize,
  }: {
    maxCount: number;
    maxTotalBytesize: number;
  },
) => {
  const [files, setFiles] = useState<File[]>(initial);

  const append = (
    newFiles: File[],
    onError: (reason: "maxCount" | "maxTotalBytesize") => void,
  ) => {
    if (files.length + newFiles.length > maxCount) {
      onError("maxCount");
      return;
    }
    if (
      [...files, ...newFiles].reduce((acc, file) => acc + file.size, 0) >
      maxTotalBytesize
    ) {
      onError("maxTotalBytesize");
      return;
    }
    setFiles((prev) => [...prev, ...newFiles]);
  };

  const remove = (index: number) => {
    setFiles((prev) => prev.filter((_, idx) => idx !== index));
  };

  return {
    files,
    append,
    remove,
  };
};

export default useFiles;
