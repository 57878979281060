import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const InstagramIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" boxSize={8} {...props}>
    <path
      d="M16.0015 12.0229C13.8004 12.0229 12.025 13.7987 12.025 16.0002C12.025 18.2018 13.8004 19.9776 16.0015 19.9776C18.2026 19.9776 19.978 18.2018 19.978 16.0002C19.978 13.7987 18.2026 12.0229 16.0015 12.0229ZM16.0015 18.5861C14.5791 18.5861 13.4162 17.4264 13.4162 16.0002C13.4162 14.5741 14.5756 13.4144 16.0015 13.4144C17.4274 13.4144 18.5867 14.5741 18.5867 16.0002C18.5867 17.4264 17.4239 18.5861 16.0015 18.5861ZM21.0681 11.8602C21.0681 12.3759 20.6528 12.7879 20.1406 12.7879C19.625 12.7879 19.2131 12.3725 19.2131 11.8602C19.2131 11.3478 19.6284 10.9325 20.1406 10.9325C20.6528 10.9325 21.0681 11.3478 21.0681 11.8602ZM23.7018 12.8017C23.643 11.559 23.3592 10.4582 22.449 9.55127C21.5423 8.64433 20.4417 8.36048 19.1993 8.29817C17.9188 8.22548 14.0807 8.22548 12.8002 8.29817C11.5612 8.35702 10.4607 8.64087 9.55049 9.54781C8.64029 10.4548 8.35997 11.5555 8.29767 12.7983C8.22499 14.0791 8.22499 17.918 8.29767 19.1988C8.35651 20.4415 8.64029 21.5423 9.55049 22.4492C10.4607 23.3562 11.5578 23.64 12.8002 23.7023C14.0807 23.775 17.9188 23.775 19.1993 23.7023C20.4417 23.6435 21.5423 23.3596 22.449 22.4492C23.3558 21.5423 23.6395 20.4415 23.7018 19.1988C23.7745 17.918 23.7745 14.0825 23.7018 12.8017ZM22.0476 20.573C21.7776 21.2515 21.255 21.7742 20.5732 22.0477C19.5523 22.4527 17.1297 22.3592 16.0015 22.3592C14.8733 22.3592 12.4472 22.4492 11.4297 22.0477C10.7514 21.7777 10.2288 21.255 9.95541 20.573C9.55049 19.5519 9.64394 17.1287 9.64394 16.0002C9.64394 14.8718 9.55395 12.4452 9.95541 11.4275C10.2254 10.749 10.7479 10.2263 11.4297 9.95282C12.4507 9.54781 14.8733 9.64128 16.0015 9.64128C17.1297 9.64128 19.5558 9.55127 20.5732 9.95282C21.2516 10.2228 21.7742 10.7455 22.0476 11.4275C22.4525 12.4486 22.359 14.8718 22.359 16.0002C22.359 17.1287 22.4525 19.5553 22.0476 20.573Z"
      fill="#212529"
    />
  </Icon>
);

export default InstagramIcon;
