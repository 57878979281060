import React, { useCallback, useState } from "react";
import useFlash from "../../public/shared/lib/useFlash";
import FilesWithoutImage from "../../public/shared/components/atoms/FilesWithoutImage";
import ImagesWithSlider from "../../public/shared/components/atoms/ImagesWithSlider";
import { Box } from "@chakra-ui/react";
import { HelperImageFile, HelperNotImageFile } from "../lib/types";

export type FileType = File | HelperImageFile | HelperNotImageFile;
export type UploadFileType = Array<FileType & { is_image?: boolean }>;

export const useUploadFiles = (
  defaultImageFiles: Array<{
    url: string;
    signed_id: string;
  }> = [],
  defaultFilesWithoutImage: Array<{
    name: string;
    url: string;
    signed_id: string;
  }> = [],
) => {
  const [isChange, setIsChange] = useState(false);
  const [files, setFiles] = useState<Array<FileType & { is_image?: boolean }>>([
    ...defaultImageFiles.map((file) => {
      return { ...file, is_image: true };
    }),
    ...defaultFilesWithoutImage.map((file) => {
      return { ...file, is_image: false };
    }),
  ]);
  const flash = useFlash();

  const addFiles = useCallback(
    (addedFiles: File[]) =>
      setFiles((prev) => {
        if ([...prev, ...addedFiles].length > 5) {
          flash({
            error: "ファイルは5つ以下になるように選択してください",
          });
          return prev;
        }
        if (
          addedFiles.some(
            (uploadFile) =>
              uploadFile.size != null && uploadFile.size >= 5 * 1000 * 1000,
          )
        ) {
          flash({
            error: "ファイルサイズの上限は5MBです",
          });
          return prev;
        }
        setIsChange(true);
        return [...prev, ...addedFiles];
      }),
    [flash],
  );

  const removeFile = (removedFile: FileType) => {
    setIsChange(true);
    setFiles((prev) => {
      return prev.filter((file) => file !== removedFile);
    });
  };

  const clear = () => {
    setIsChange(false);
    setFiles([]);
  };

  return [files, addFiles, removeFile, clear, isChange] as [
    typeof files,
    typeof addFiles,
    typeof removeFile,
    typeof clear,
    typeof isChange,
  ];
};

const isImage = (file: FileType & { is_image?: boolean }) =>
  file.is_image ||
  ["image/jpeg", "image/png", "image/gif"].includes((file as File).type);

export const filterImageFiles = (uploadFiles: FileType[]) => {
  return uploadFiles.filter((file) => isImage(file)) as Array<
    File | HelperImageFile
  >;
};

export const filterFilesWithoutImage = (uploadFiles: FileType[]) => {
  return uploadFiles.filter((file) => !isImage(file)) as Array<
    File | HelperNotImageFile
  >;
};

const UploadFiles = ({
  uploadFiles,
  removeFile,
}: {
  uploadFiles: FileType[];
  removeFile: (file: FileType) => void;
}) => {
  const imageFiles = filterImageFiles(uploadFiles);
  const filesWithoutImage = filterFilesWithoutImage(uploadFiles);

  return (
    <>
      {filesWithoutImage.length !== 0 && (
        <FilesWithoutImage
          files={filesWithoutImage}
          downloadable={false}
          onRemove={(idx) => {
            removeFile(filesWithoutImage[idx]);
          }}
        />
      )}
      {imageFiles.length !== 0 && (
        <Box mt={filesWithoutImage.length !== 0 ? 3 : undefined}>
          <ImagesWithSlider
            urls={imageFiles.map(
              (file) =>
                (file as HelperImageFile).url ??
                URL.createObjectURL(file as File),
            )}
            onRemove={(idx) => {
              removeFile(imageFiles[idx]);
            }}
          />
        </Box>
      )}
    </>
  );
};

export default UploadFiles;
