import { Flex, Text } from "@chakra-ui/react";
import "cropperjs/dist/cropper.css";
import React, { useState } from "react";
import Select from "../../../shared/components/atoms/form/Select";
import dayjs from "dayjs";
import { InputError } from "../../../shared/components/atoms/form";
import { calcAge } from "../../../shared/lib/dateUtils";

const BirthdaySelect = ({
  value,
  onChange: dispatchOnChange,
  error,
}: {
  value: string;
  onChange: (date: string | undefined) => void;
  error?: string;
}) => {
  const valueDay = dayjs(value);

  // valueDayが空文字の時は全てNaNになる
  const [year, setYear] = useState<number>(valueDay.year());
  const [month, setMonth] = useState<number>(valueDay.month() + 1);
  const [day, setDay] = useState<number>(valueDay.date());

  const currentYear = dayjs().year();
  const yearOptions = [];
  for (let i = currentYear - 99; i <= currentYear - 18; i++) {
    yearOptions.push(i);
  }
  const monthOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const dayOptions = (year: number, month: number) => {
    if (isNaN(year) || isNaN(month))
      return [...Array(28).keys()].map((i) => i + 1);

    const days = dayjs(`${year}-${month}`).daysInMonth();
    const result = [];
    for (let i = 1; i <= days; i++) {
      result.push(i);
    }
    return result;
  };

  const onChange = (year: number, month: number, day: number) => {
    setYear(year);
    setMonth(month);
    setDay(day);

    if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
      dispatchOnChange(`${year}-${month}-${day}`);
    } else {
      dispatchOnChange(undefined);
    }
  };

  return (
    <>
      <Flex
        gap={4}
        align={{ base: "stretch", sm: "center" }}
        direction={{ base: "column", sm: "row" }}
      >
        <Flex flex={1} gap={2} align="center">
          <Select
            value={isNaN(year) ? undefined : year}
            onChange={(e) => onChange(parseInt(e.target.value), month, day)}
            invalid={error != null}
            id="year"
          >
            <option></option>
            {yearOptions.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
          <Text as="label" htmlFor="year">
            年
          </Text>
        </Flex>
        <Flex flex={1} gap={2} align="center">
          <Select
            value={isNaN(month) ? undefined : month}
            onChange={(e) => onChange(year, parseInt(e.target.value), day)}
            invalid={error != null}
            id="month"
          >
            <option></option>
            {monthOptions.map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </Select>
          <Text as="label" htmlFor="month">
            月
          </Text>
        </Flex>
        <Flex flex={1} gap={2} align="center">
          <Select
            value={isNaN(day) ? undefined : day}
            onChange={(e) => onChange(year, month, parseInt(e.target.value))}
            invalid={error != null}
            id="day"
          >
            <option></option>
            {dayOptions(year, month).map((day) => (
              <option value={day} key={day}>
                {day}
              </option>
            ))}
          </Select>
          <Text as="label" htmlFor="day">
            日
          </Text>
        </Flex>
        <Text as="span" flexShrink={0}>
          {`( ${calcAge(year, month, day)}歳 )`}
        </Text>
      </Flex>
      {error != null && <InputError>{error}</InputError>}
    </>
  );
};

export default BirthdaySelect;
