import { SharedCurrentUser } from "./types";

export const isShowFloatingRegisterButton = ({
  flipper,
  currentUser,
}: {
  flipper: Record<string, boolean>;
  currentUser: SharedCurrentUser;
}) => {
  return flipper.floating_register_button_feature && currentUser == null;
};
