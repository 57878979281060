import { SlideFade, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { questionVotesPath } from "../../../../../../routes";
import useRequest from "../../../lib/useRequest";
import Button from "../Button";
import useFlash from "../../../lib/useFlash";
import { PostsShow, SharedCurrentUser } from "../../../lib/types";
import RegistrationRequiredAction from "../RegistrationRequiredAction";

const Votes = ({
  questionnaire,
  currentUser,
  beforeVote,
  onVote,
}: {
  questionnaire: NonNullable<PostsShow["questionnaire"]>;
  currentUser: SharedCurrentUser;
  beforeVote: () => void;
  onVote: () => void;
}) => {
  const request = useRequest();
  const showFlash = useFlash();

  const vote = async (questionCode: string) => {
    beforeVote();
    const res = await request(questionVotesPath(questionCode), "POST");
    if (res.ok) {
      onVote();
    } else if (res.status === 404) {
      showFlash({
        error: (
          <>
            アンケートが見つかりませんでした。
            <br />
            ページを再読み込みしてください。
          </>
        ),
      });
    }
  };

  return (
    <>
      <SlideFade in>
        <Stack gap={2}>
          {questionnaire.questions.map((question) => (
            <RegistrationRequiredAction
              currentUser={currentUser}
              key={question.code}
              onClick={() => vote(question.code)}
              render={(props) => (
                <Button variant="outline" w="full" {...props}>
                  {question.content}
                </Button>
              )}
            />
          ))}
        </Stack>
        <Text fontSize="xs" color="#6D787D" mt={2}>
          ※ 投票者の情報は一切公開されません
        </Text>
      </SlideFade>
    </>
  );
};

export default Votes;
