import React, { ReactNode } from "react";
import { Flex } from "@chakra-ui/react";

const InfoItem = ({ children }: { children: ReactNode }) => {
  return (
    <Flex direction={{ base: "column", lg: "row" }} columnGap={8}>
      {children}
    </Flex>
  );
};

export default InfoItem;
