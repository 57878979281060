import React, { useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import ThumbUpIcon from "../../../shared/components/icons/ThumbUpIcon";
import ThumbUpFilledIcon from "../../../shared/components/icons/ThumbUpFilledIcon";
import { motion, useAnimation } from "framer-motion";

type ThumbUpButtonWithCountProps = {
  count: number;
  isActive: boolean;
  onActive: () => Promise<void>;
  onInactive: () => Promise<void>;
  isDisabled?: boolean;
};

const ThumbUpButtonWithCount = ({
  count: propCount,
  isActive: propIsActive,
  onActive,
  onInactive,
  isDisabled = false,
}: ThumbUpButtonWithCountProps) => {
  const [count, setCount] = useState(propCount);
  const [isActive, setIsActive] = useState(propIsActive);
  const [inProgress, setInProgress] = useState(false);
  const toggleActive = async () => {
    setInProgress(true);
    if (!isActive) {
      await onActive();
      setCount(count + 1);
      setIsActive(true);
      await controls.start({
        scale: [1, 1.3, 1.6, 1.3, 1],
        rotate: ["-5deg", "0deg", "10deg", "-10deg", "10deg", "-10deg", "0deg"],
      });
    } else {
      setCount(count - 1);
      setIsActive(false);
      await onInactive();
    }
    setInProgress(false);
  };
  const controls = useAnimation();

  return (
    <Flex
      align="center"
      gap={1}
      onClick={isDisabled || inProgress ? undefined : toggleActive}
      color="textGray"
      as="button"
      cursor={isDisabled ? "default" : undefined}
      role="group"
    >
      <Box
        as={motion.div}
        animate={controls}
        p={1}
        _groupHover={{ bgColor: isDisabled ? undefined : "#EDF5F3" }}
        borderRadius="full"
      >
        {isActive ? (
          <ThumbUpFilledIcon color="textLink" />
        ) : (
          <ThumbUpIcon color="textLink" />
        )}
      </Box>
      {count > 0 && (
        <Text fontSize="sm" pt={0.5}>
          {count}
        </Text>
      )}
      <Text fontSize="sm" pt={0.5}>
        参考になった
      </Text>
    </Flex>
  );
};

export default ThumbUpButtonWithCount;
