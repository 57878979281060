import React, { useCallback, useEffect, useState } from "react";
import { Flash } from "../../../shared/lib/types";
import Application from "../../shared/components/layouts/Application";
import {
  TalkRooms,
  SharedApprovedCurrentUser,
  SharedOwnedBusinessEntity,
} from "../../shared/lib/types";
import { Footer, Header } from "../../shared/components/atoms";
import TalkMessages from "./components/TalkMessages";
import {
  HStack,
  Stack,
  Text,
  Avatar,
  Box,
  useDisclosure,
  useBreakpointValue,
  Show,
} from "@chakra-ui/react";
import TalkMessageListItem from "./components/TalkMessageListItem";
import { businessMatchingBusinessEntityPath } from "../../../../routes";
import TalkMessagesModal from "./components/TalkMessagesModal";

const TalkRoomsIndex = ({
  flash,
  talkRooms,
  currentBusinessEntity,
  currentUser,
}: {
  flash: Flash;
  talkRooms: TalkRooms;
  currentBusinessEntity: SharedOwnedBusinessEntity;
  currentUser: SharedApprovedCurrentUser;
}) => {
  const [selectedTalkRoom, setSelectedTalkRoom] = useState<TalkRooms[number]>();

  const talkRoomByQueryCode = useCallback(() => {
    const code = new URLSearchParams(location.search).get("code");
    return code
      ? talkRooms.find((talkRoom) => talkRoom.code === code)
      : undefined;
  }, [talkRooms]);

  useEffect(() => {
    const talkRoom = talkRoomByQueryCode() ?? talkRooms[0];
    setSelectedTalkRoom(talkRoom);
  }, [talkRooms, setSelectedTalkRoom, talkRoomByQueryCode]);

  const { onOpen, onClose, isOpen } = useDisclosure();

  const onClickTalkMessageListItem = useBreakpointValue({
    base: (talkRoom: TalkRooms[number]) => {
      setSelectedTalkRoom(talkRoom);
      onOpen();
    },
    lg: (talkRoom: TalkRooms[number]) => {
      setSelectedTalkRoom(talkRoom);
    },
  });

  return (
    <Application flash={flash} currentUser={currentUser}>
      <Stack
        h={{ base: "auto", sm: "100vh" }}
        minH="100dvh"
        gap={0}
        bgColor="brand.50"
      >
        <Header currentUser={currentUser} showBusinessMatchingHeader/>
        <Stack p={6} gap={4} direction="row" flexGrow={1} overflow="hidden">
          <Stack
            bgColor={"white"}
            p={4}
            w={{ base: "100%", lg: "35%" }}
            minW={{ base: "100%", lg: "20rem" }}
            maxW="30rem"
            borderRadius="8px"
            borderColor="border.cardOutline"
            borderWidth="1px"
            overflowY="auto"
          >
            {talkRooms.map((talkRoom) => (
              <TalkMessageListItem
                onClick={() =>
                  onClickTalkMessageListItem &&
                  onClickTalkMessageListItem(talkRoom)
                }
                key={talkRoom.code}
                partner={talkRoom.partner}
                message={talkRoom.last_message.content}
                last_sent_at={talkRoom.last_message.created_at}
                isActive={selectedTalkRoom?.code === talkRoom.code}
              />
            ))}
          </Stack>
          <Show above="lg">
            <Stack
              bgColor={"white"}
              px={6}
              pb={6}
              flexGrow={1}
              w="65%"
              gap={0}
              borderRadius="8px"
              borderColor="border.cardOutline"
              borderWidth="1px"
            >
              {selectedTalkRoom && (
                <>
                  <Stack pt={6}>
                    <Box
                      w="full"
                      borderBottomColor={"border.cardOutline"}
                      borderBottomWidth={"1px"}
                      pb={5}
                      px={4}
                    >
                      <HStack
                        gap={4}
                        w="fit-content"
                        {...(selectedTalkRoom.partner.status === "active"
                          ? {
                              as: "a",
                              href: businessMatchingBusinessEntityPath(
                                selectedTalkRoom.partner.code,
                              ),
                            }
                          : {})}
                      >
                        <Avatar
                          src={selectedTalkRoom.partner.image_url}
                        />
                        <Text
                          fontSize="xl"
                          fontWeight="bold"
                          data-testid="talk-room-title"
                        >
                          {selectedTalkRoom.partner.name}
                        </Text>
                      </HStack>
                    </Box>
                  </Stack>

                  <TalkMessages
                    talkRoom={selectedTalkRoom}
                    currentBusinessEntity={currentBusinessEntity}
                  />
                </>
              )}
            </Stack>
          </Show>
          {selectedTalkRoom && (
            <TalkMessagesModal
              talkRoom={selectedTalkRoom}
              currentBusinessEntity={currentBusinessEntity}
              isOpen={isOpen}
              onClose={onClose}
            />
          )}
        </Stack>
        <Footer />
      </Stack>
    </Application>
  );
};

export default TalkRoomsIndex;
