import { Box, BoxProps, HStack } from "@chakra-ui/react";
import React from "react";
import { HelperPostResourceAuthor } from "../../lib/types";

const PostResourceDisplayName = ({
  author,
  displayNameProps
}: {
  author: Partial<HelperPostResourceAuthor>;
  displayNameProps?: BoxProps;
}) => {
  return (
    <HStack fontWeight="bold" gap={1} flexWrap="wrap">
      <Box {...displayNameProps}>{author.display_name}</Box>
      <Box>
        {(author.anonymous || author.is_guest) && (
          <Box
            as="span"
            fontWeight="normal"
            fontSize="xs"
            color="#848484"
            verticalAlign="middle"
          >
            {author.anonymous
              ? `(${author.architect_type}・${author.floor_age}代)`
              : "(ゲスト)"}
          </Box>
        )}
      </Box>
    </HStack>
  );
};

export default PostResourceDisplayName;
