import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const YoutubeIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" boxSize={8} {...props}>
    <path
      d="M24.7372 11.6176C24.5267 10.826 23.9064 10.2027 23.1189 9.99111C21.6915 9.60669 15.9676 9.60669 15.9676 9.60669C15.9676 9.60669 10.2437 9.60669 8.8162 9.99111C8.02868 10.2027 7.40843 10.826 7.19791 11.6176C6.81543 13.0523 6.81543 16.0456 6.81543 16.0456C6.81543 16.0456 6.81543 19.039 7.19791 20.4737C7.40843 21.2652 8.02868 21.8626 8.8162 22.0742C10.2437 22.4586 15.9676 22.4586 15.9676 22.4586C15.9676 22.4586 21.6914 22.4586 23.1189 22.0742C23.9064 21.8626 24.5267 21.2652 24.7372 20.4737C25.1197 19.039 25.1197 16.0456 25.1197 16.0456C25.1197 16.0456 25.1197 13.0523 24.7372 11.6176ZM14.0955 18.7634V13.3279L18.8796 16.0457L14.0955 18.7634Z"
      fill="#212529"
    />
  </Icon>
);

export default YoutubeIcon;
