import {useEffect, useState} from "react";
import * as AutoKana from "vanilla-autokana";

const useAutokana = (source: string, target: string) => {
  const [autokana, setAutokana] = useState<AutoKana.AutoKana>()

  useEffect(() => {
    const autokana = AutoKana.bind(source, target)
    setAutokana(autokana);
  }, [source, target]);

  return autokana;
};

export default useAutokana;
