import {
  Avatar,
  Box,
  BoxProps,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import {
  SharedOwnedBusinessEntities,
  SharedOwnedBusinessEntity,
} from "../../lib/types";
import { Button } from ".";
import AddIcon from "../icons/AddIcon";
import MoreHorizIcon from "../icons/MoreHorizIcon";
import {
  businessMatchingCurrentBusinessEntityPath,
  editBusinessMatchingOwnedBusinessEntityPath,
  newBusinessMatchingOwnedBusinessEntityPath,
} from "../../../../../routes";
import useRequest from "../../lib/useRequest";

const BusinessEntityStatusTag = ({
  businessEntity,
  props,
}: {
  businessEntity: SharedOwnedBusinessEntity;
  props?: BoxProps;
}) => {
  const style = (businessEntity: SharedOwnedBusinessEntity) => {
    const caution = {
      textColor: "text.caution.dark",
      backgroundColor: "background.caution",
    };
    const blue = {
      textColor: "tealBlue.base",
      backgroundColor: "tealBlue.light",
    };
    const gray = {
      textColor: "gray.base",
      backgroundColor: "gray.light",
    };

    if (businessEntity.is_rejected) {
      return {
        color: caution,
        text: "申請却下",
      };
    } else if (businessEntity.is_email_verified === false) {
      return {
        color: caution,
        text: "メール認証が未完了です",
      };
    } else if (businessEntity.is_active === false) {
      return {
        color: gray,
        text: "掲載一時停止中",
      };
    } else if (businessEntity.is_active === true) {
      if (businessEntity.applicationing) {
        return {
          color: blue,
          text: "掲載中 (再審査中)",
        };
      } else {
        return {
          color: blue,
          text: "掲載中",
        };
      }
    } else {
      if (businessEntity.applicationing) {
        return {
          color: gray,
          text: "ご利用審査中",
        };
      } else {
        return {
          color: gray,
          text: "審査前",
        };
      }
    }
  };

  return (
    <Box
      px="6px"
      width="fit-content"
      {...style(businessEntity).color}
      {...props}
    >
      <Text>{style(businessEntity).text}</Text>
    </Box>
  );
};

const BusinessMatchingHeader = ({
  ownedBusinessEntities,
}: {
  ownedBusinessEntities: SharedOwnedBusinessEntities;
}) => {
  const request = useRequest();

  if (!ownedBusinessEntities) return <></>;

  const setCurrentBusinessEntityCode = async (code: string) => {
    const res = await request(
      businessMatchingCurrentBusinessEntityPath(),
      "PUT",
      {
        code: code,
      },
    );

    if (res.ok) {
      await res.json().then((json) => {
        if (json.redirect_url != null) {
          location.href = json.redirect_url;
        } else {
          location.reload();
        }
      });
    }
  };

  const fontSize = { base: "xs", md: "sm" };
  const menuWidth = { base: "272px", md: "360px" };

  return (
    <Flex
      backgroundColor="white"
      pl={6}
      pr={4}
      py={4}
      justify="space-between"
      h={{ base: 14, md: 16 }}
      fontSize={fontSize}
      zIndex="1"
    >
      <HStack gap={2}>
        <Avatar
          src={ownedBusinessEntities.current.logo_image_url}
          boxSize={{ base: 7, md: 8 }}
        />
        <Flex
          gap={{ base: 0, md: 3 }}
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "start", md: "center" }}
        >
          <Text fontSize={{ base: "md", md: "lg" }} fontWeight="bold">
            {ownedBusinessEntities.current.name}
          </Text>
          <BusinessEntityStatusTag
            businessEntity={ownedBusinessEntities.current}
          />
        </Flex>
      </HStack>
      <Menu autoSelect={false}>
        <MenuButton
          fontSize={fontSize}
          textDecor="underline"
          textColor="textLink"
        >
          事業者設定
        </MenuButton>
        <MenuList
          p={5}
          minW={menuWidth}
          maxW={menuWidth}
          maxH="50vh"
          overflow="scroll"
        >
          <MenuGroup
            title="利用中の事業者一覧"
            fontSize={fontSize}
            p={0}
            mx={0}
            mt={0}
            mb={4}
          >
            {ownedBusinessEntities.all.map((businessEntity, index) => {
              const isLast = index === ownedBusinessEntities.all.length - 1;
              return (
                <Box key={businessEntity.code}>
                  <MenuItem
                    key={businessEntity.code}
                    as="div"
                    px={0}
                    py={2}
                    _hover={{ backgroundColor: "brand.50", cursor: "default" }}
                    backgroundColor={
                      businessEntity.code === ownedBusinessEntities.current.code
                        ? "brand.50"
                        : ""
                    }
                  >
                    <Flex
                      w="full"
                      justifyContent="space-between"
                      alignItems="center"
                      gap={4}
                    >
                      <Stack
                        w="full"
                        gap={1}
                        as="button"
                        textAlign="start"
                        onClick={async () => {
                          await setCurrentBusinessEntityCode(
                            businessEntity.code,
                          );
                        }}
                      >
                        <Text> {businessEntity.name}</Text>
                        <BusinessEntityStatusTag
                          businessEntity={businessEntity}
                        />
                      </Stack>
                      <Box
                        as="a"
                        color="textGray"
                        minW={10}
                        minH={10}
                        _hover={{ backgroundColor: "brand.100" }}
                        borderRadius="23px"
                        textAlign="center"
                        alignContent="center"
                        href={editBusinessMatchingOwnedBusinessEntityPath(
                          businessEntity.code,
                        )}
                        aria-label={`${businessEntity.name} 編集`}
                      >
                        <MoreHorizIcon boxSize={6} />
                      </Box>
                    </Flex>
                  </MenuItem>
                  {!isLast && <MenuDivider />}
                </Box>
              );
            })}
          </MenuGroup>
          <Button
            variant="outline"
            as="a"
            w="fill-available"
            p={0}
            mt={4}
            href={newBusinessMatchingOwnedBusinessEntityPath()}
          >
            <AddIcon boxSize={4} mr="1.5px" />
            <Text fontSize={fontSize}>新しい事業者を追加</Text>
          </Button>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default BusinessMatchingHeader;
