import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const FileUploadIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" boxSize={5} {...props}>
    <path
      id="Vector"
      d="M14.9999 12.4999V14.9999H4.99992V12.4999H3.33325V14.9999C3.33325 15.9166 4.08325 16.6666 4.99992 16.6666H14.9999C15.9166 16.6666 16.6666 15.9166 16.6666 14.9999V12.4999H14.9999ZM5.83325 7.49992L7.00825 8.67492L9.16658 6.52492V13.3333H10.8333V6.52492L12.9916 8.67492L14.1666 7.49992L9.99992 3.33325L5.83325 7.49992Z"
      fill="currentColor"
    />
  </Icon>
);

export default FileUploadIcon;
