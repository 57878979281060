import React from "react";
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  defineStyleConfig,
  forwardRef,
} from "@chakra-ui/react";

export const ButtonStyle = defineStyleConfig({
  defaultProps: {
    size: "md",
  },
  sizes: {
    sm: {
      fontSize: "sm",
      py: 2.5,
      px: 4,
      h: "auto",
      minW: 0,
    },
    md: {
      fontSize: "md",
      py: 3,
      px: 6,
      h: "auto",
      minW: 0,
    },
    lg: {
      fontSize: "2xl",
      py: 4,
      px: 10,
      h: "auto",
      minW: 0,
    },
  },
});

export type ButtonProps = {
  variant?: "outline" | "solid";
  color?: "primary" | "gray" | "red" | "white";
} & Omit<ChakraButtonProps, "variant" | "color">;

export const Button = forwardRef<ButtonProps, "button">(
  ({ children, variant = "solid", color = "primary", ...buttonProps }, ref) => {
    const styleProps = {
      solid: {
        primary: {
          bgColor: "primary",
          color: "#fff",
          _hover: {
            bgColor: "rgba(60, 170, 145, 0.80)",
          },
        },
        gray: {
          bgColor: "#EDF2F7",
          color: "textBase",
          _hover: {
            bgColor: "rgba(237, 242, 247, 0.80)",
          },
        },
        red: {
          bgColor: "#E11F1F",
          color: "#fff",
          _hover: {
            bgColor: "rgba(225, 31, 31, 0.80)",
          },
        },
        white: {
          bgColor: "white",
          color: "primary",
        }
      },
      outline: {
        primary: {
          bgColor: "transparent",
          color: "primary",
          border: "1px solid;",
          borderColor: "primary",
          _hover: {
            bgColor: "primary",
            color: "#ffffff",
          },
        },
      },
    }[variant][color];

    return (
      <ChakraButton
        borderRadius={2}
        whiteSpace="pre-wrap"
        lineHeight="150%"
        fontWeight="bold"
        ref={ref}
        {...styleProps}
        {...buttonProps}
        _disabled={{ background: "#6A7573", color: "white", borderColor: "inherit" }}
      >
        {children}
      </ChakraButton>
    );
  },
);

export default Button;
