import React from "react";
import { Box, Container, Flex, Image, Text } from "@chakra-ui/react";
import ShadowCard from "../shared/components/atoms/ShadowCard";
import { Button } from "../shared/components/atoms";
import Application from "../shared/components/layouts/Application";
import { rootPath } from "../../../routes";
import FixedBackgroundBuildingImage from "../shared/components/atoms/FixedBackgroundBuildingImage";
import Background from "../shared/components/atoms/Background";
import { Flash } from "../../shared/lib/types";
import { LogoSVG } from "../svg";

const UserPersonalInfoApplicationsDone = ({ flash }: { flash: Flash }) => {
  return (
    <Application flash={flash} disableFloatingRegisterButton>
      <FixedBackgroundBuildingImage />
      <Background>
        <Container maxW="4xl" mt={{ base: 6, lg: 20 }} zIndex={1}>
          <ShadowCard>
            <Flex direction="column" align="center">
              <Image src={LogoSVG} width={{ base: 32, lg: 48 }} />
              <Text
                fontSize={{ base: "md", md: "2rem" }}
                fontWeight="600"
                mt={{ base: 6, md: 10 }}
              >
                本人確認の申請が完了しました
              </Text>
              <Text
                mt={{ base: 7, lg: 10 }}
                fontSize={{ base: "sm", lg: "md" }}
                textAlign={{ base: "left", md: "center" }}
                lineHeight="tall"
              >
                A-Loopでは、会員登録でいただいた情報を元に実在する建築士かを審査します。
                <br />
                <Box as="span" fontWeight="bold">
                  審査結果は2営業日以内にメールでお送りいたします。
                </Box>
                <br />
                <br />
                本人認証が完了した会員様にはメールで本人認証完了のご案内とともに
                <br />
                A-Loopのすべてのサービスをご利用いただけます。
              </Text>
              <Box mt={{ base: 10, md: 10 }}>
                <Button as="a" href={rootPath()}>
                  A-Loop トップページへ
                </Button>
              </Box>
            </Flex>
          </ShadowCard>
        </Container>
      </Background>
    </Application>
  );
};

export default UserPersonalInfoApplicationsDone;
