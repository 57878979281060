import {
  Box,
  CheckboxGroup,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import "cropperjs/dist/cropper.css";
import React, { useContext, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  newUserSessionPath,
  privacyPolicyPath,
  termsOfServicePath,
  userDatabaseAuthenticationRegistrationPath,
  validateUserDatabaseAuthenticationRegistrationPath,
} from "../../../../routes";
import { Flash } from "../../../shared/lib/types";
import { uploadFile } from "../../../shared/lib/uploadFile";
import { Button } from "../../shared/components/atoms";
import Background from "../../shared/components/atoms/Background";
import ErrorMessage from "../../shared/components/atoms/ErrorMessage";
import FixedBackgroundBuildingImage from "../../shared/components/atoms/FixedBackgroundBuildingImage";
import {
  ImageFileDropzone,
  useImageFileDropzoneState,
} from "../../shared/components/atoms/ImageFileDropzone";
import SectionTitle from "../../shared/components/atoms/SectionTitle";
import ShadowCard from "../../shared/components/atoms/ShadowCard";
import SiteSeal from "../../shared/components/atoms/SiteSeal";
import {
  FormLabel,
  Input,
  InputError,
  Radio,
} from "../../shared/components/atoms/form";
import Checkbox from "../../shared/components/atoms/form/Checkbox";
import Select from "../../shared/components/atoms/form/Select";
import Application from "../../shared/components/layouts/Application";
import {
  AuthRegistrationsServiceReferralSources,
  Prefecture,
  SharedArchitectLicenses,
  SharedArchitectTypes,
  SharedIndustries,
  SharedUserPositions,
} from "../../shared/lib/types";
import useRequest from "../../shared/lib/useRequest";
import { LogoSVG } from "../../svg";
import { useDebouncedCallback } from "use-debounce";
import { FlipperContext } from "../../../shared/lib/FlipperContext";
import BirthdaySelect from "../../user/shared/components/BirthdaySelect";
import { schema } from "./lib/schema";

type FormType = yup.InferType<typeof schema>;

const inputMaxW = { base: "none", sm: 80 };

const AuthRegistrationsNew = ({
  architectLicenses,
  architectTypes,
  prefectures,
  industries,
  userPositions,
  serviceReferralSources,
  flash,
}: {
  architectLicenses: SharedArchitectLicenses;
  architectTypes: SharedArchitectTypes;
  prefectures: Prefecture[];
  industries: SharedIndustries;
  userPositions: SharedUserPositions;
  serviceReferralSources: AuthRegistrationsServiceReferralSources;
  flash: Flash;
}) => {
  const otherReasonId = serviceReferralSources
    .find((it) => it.name === "その他")
    ?.id?.toString();
  const fromSalesId = serviceReferralSources
    .find((it) => it.name === "営業からの紹介")
    ?.id?.toString();

  const flipper = useContext(FlipperContext);

  const methods = useForm<FormType>({
    defaultValues: {
      temporary_user_input: {
        nickname: "",
        architect_license_id: "",
        architect_type_id: "",
        birthday: "",
        prefecture_id: "",
        industry_id: "",
        user_position_id: "",
      },
      user_service_referral_source_attributes: {
        service_referral_source_ids: [],
        other_reason: "",
        referral_code: "",
      },
      email: "",
      password: "",
      password_confirmation: "",
      agreement: false,
    },
    resolver: yupResolver(schema),
    context: { otherReasonId, fromSalesId, flipper },
  });
  const { control, handleSubmit, setError, clearErrors } = methods;
  const anonymousImageFieldState = useImageFileDropzoneState();

  const request = useRequest();
  const validateNickname = async (data: FormType) => {
    const res = await request(
      validateUserDatabaseAuthenticationRegistrationPath(),
      "POST",
      data,
    );
    const json = await res.json();
    if (json["nickname"] != null) {
      json["nickname"].forEach((message: string) => {
        setError("temporary_user_input.nickname", {
          type: "custom",
          message,
        });
      });
    } else {
      clearErrors("temporary_user_input.nickname");
    }

    return json["nickname"] == null;
  };

  const debouncedValidateNickname = useDebouncedCallback(validateNickname, 500);

  const validate = async (data: FormType) => {
    let isSuccess = true;
    isSuccess = anonymousImageFieldState.validate() && isSuccess;
    isSuccess =
      (await validateNickname({ user_database_authentication: data })) &&
      isSuccess;
    return isSuccess;
  };

  const onSubmit = async (data: FormType) => {
    if (!(await validate(data))) return;

    if (anonymousImageFieldState.file != null) {
      const { blob } = await uploadFile({
        file: anonymousImageFieldState.file,
      });
      data.temporary_user_input.anonymous_image = blob.signed_id;
    }
    const res = await request(
      userDatabaseAuthenticationRegistrationPath(),
      "POST",
      {
        user_database_authentication: data,
      },
    );

    if (res.ok) {
      location.href = res.headers.get("Location")!;
      return;
    } else {
      const json = await res.json();
      setError("email", { type: "custom", message: json.errors.email[0] });
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const referral_code = searchParams.get("referral_code")
    if (referral_code) {
      methods.setValue("user_service_referral_source_attributes.service_referral_source_ids", [fromSalesId]);
      methods.setValue("user_service_referral_source_attributes.referral_code", referral_code);
    }
  }, [fromSalesId, methods])

  return (
    <Application flash={flash} disableFloatingRegisterButton>
      <FixedBackgroundBuildingImage />
      <Background>
        <Container maxW="2xl" mt={{ base: 6, lg: 20 }} zIndex={1}>
          <ShadowCard>
            <FormProvider {...methods}>
              <Box
                as="form"
                onSubmit={handleSubmit(onSubmit, () =>
                  anonymousImageFieldState.validate(),
                )}
              >
                <Flex direction="column">
                  <Flex justify="center">
                    <Image src={LogoSVG} width={32} />
                  </Flex>
                  <Text textAlign={"center"} fontSize="xs" mt={6}>
                    {flipper.free_registration ? (
                      <>
                        A-Loopは、建築士の方が無料でご利用いただける <br />
                        コミュニティサービスです。
                      </>
                    ) : (
                      <>
                        A-Loopは、建築士の方が利用いただける <br />
                        コミュニティサービスです。
                      </>
                    )}
                  </Text>
                  <Heading
                    size="lg"
                    mt={{ base: 6, md: 10 }}
                    textAlign="center"
                  >
                    {flipper.free_registration
                      ? "会員登録（無料）"
                      : "会員登録"}
                  </Heading>
                  <SectionTitle mt={8}>公開情報</SectionTitle>
                  <Stack gap={7} mt={8}>
                    <Stack gap={3}>
                      <FormLabel required>取得している建築士資格</FormLabel>
                      <Controller
                        name="temporary_user_input.architect_license_id"
                        control={control}
                        render={({ field, fieldState }) => (
                          <>
                            <RadioGroup {...field}>
                              <Stack>
                                {architectLicenses.map((architectLicense) => (
                                  <Radio
                                    value={String(architectLicense.id)}
                                    key={architectLicense.id}
                                  >
                                    {architectLicense.name}
                                  </Radio>
                                ))}
                              </Stack>
                            </RadioGroup>
                            {fieldState.error?.message !== undefined && (
                              <InputError>
                                {fieldState.error.message}
                              </InputError>
                            )}
                          </>
                        )}
                      />
                      <ErrorMessage>
                        A-Loopは建築士向けのサービスです。
                        <br />
                        建築士資格をお持ちでない方は登録できません。
                      </ErrorMessage>
                    </Stack>
                    <Controller
                      name="temporary_user_input.architect_type_id"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Select
                          {...field}
                          error={error?.message}
                          placeholder="職種を選択"
                          maxW={inputMaxW}
                          label="職種"
                          required
                        >
                          {architectTypes.map((architectType) => (
                            <option
                              key={architectType.id}
                              value={architectType.id}
                            >
                              {architectType.name}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                    <Controller
                      name="temporary_user_input.nickname"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          label="ニックネーム"
                          required
                          error={error?.message}
                          maxW={inputMaxW}
                          onChange={async (e) => {
                            field.onChange(e);
                            await debouncedValidateNickname({
                              user_database_authentication: {
                                temporary_user_input: {
                                  nickname: e.target.value,
                                },
                              },
                            });
                          }}
                          message={
                            <>
                              使用できる文字は、ひらがな、カタカナ、漢字、数字、アルファベットです。{" "}
                              <br />
                              15文字以内で登録してください。 <br />
                              Q&A投稿時に匿名での投稿を選択した場合に表示されます。
                            </>
                          }
                        />
                      )}
                    />
                    <Box>
                      <FormLabel>匿名投稿用プロフィール写真</FormLabel>
                      <Box>
                        <ImageFileDropzone
                          croppable
                          imageFileDropzoneState={anonymousImageFieldState}
                          previewBoxProps={{
                            maxW: { base: "full", sm: "50%" },
                          }}
                          label="プロフィール写真"
                          rounded
                          additionalMessage={
                            <>
                              Q&Aに匿名で質問やコメントした際に表示されるプロフィール写真です。{" "}
                              <br />
                              個人を特定されない建物写真や風景写真を推奨します。
                            </>
                          }
                        />
                      </Box>
                    </Box>
                  </Stack>
                  <SectionTitle mt={14}>非公開情報</SectionTitle>
                  <Stack gap={7} mt={8}>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          label="メールアドレス"
                          required
                          error={error?.message}
                          maxW={inputMaxW}
                          placeholder="sample@aloop.com"
                        />
                      )}
                    />
                    <Controller
                      name="password"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          label="パスワード"
                          type="password"
                          required
                          error={error?.message}
                          maxW={inputMaxW}
                          message="8文字以上で半角英数字を最低1文字ずつ含んでください。"
                        />
                      )}
                    />
                    <Controller
                      name="password_confirmation"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          {...field}
                          label="パスワード（確認用）"
                          type="password"
                          required
                          error={error?.message}
                          maxW={inputMaxW}
                        />
                      )}
                    />
                    <Box>
                      <FormLabel required>生年月日</FormLabel>
                      <Controller
                        name="temporary_user_input.birthday"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <BirthdaySelect
                            value={value}
                            onChange={(date) => {
                              onChange(date);
                            }}
                            error={error?.message}
                          />
                        )}
                      />
                      <Text fontSize="xs" color="#6D787D" mt={1}>
                        プロフィールには年齢のみ公開されます。
                      </Text>
                    </Box>
                    <Controller
                      name="temporary_user_input.prefecture_id"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Select
                          {...field}
                          label="居住地"
                          required
                          placeholder="居住地を選択してください"
                          error={error?.message}
                          maxW={inputMaxW}
                        >
                          {prefectures.map(({ id, name }) => (
                            <option value={id} key={id}>
                              {name}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                    <Controller
                      name="temporary_user_input.industry_id"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Select
                          {...field}
                          label="業種"
                          required
                          placeholder="業種を選択してください"
                          error={error?.message}
                          maxW={inputMaxW}
                        >
                          {industries.map(({ id, name }) => (
                            <option value={id} key={id}>
                              {name}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                    <Controller
                      name="temporary_user_input.user_position_id"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Select
                          {...field}
                          label="ポジション"
                          required
                          placeholder="ポジションを選択してください"
                          error={error?.message}
                          maxW={inputMaxW}
                        >
                          {userPositions.map(({ id, name }) => (
                            <option value={id} key={id}>
                              {name}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                    <Box>
                      <FormLabel required>A-Loopを知ったきっかけ</FormLabel>
                      <Box mt={2}>
                        <Controller
                          name="user_service_referral_source_attributes.service_referral_source_ids"
                          control={control}
                          render={({
                            field: { ref, ...field },
                            fieldState: { error },
                          }) => (
                            <Box>
                              <Stack>
                                <CheckboxGroup {...field}>
                                  {serviceReferralSources.map(
                                    ({ id, name }) => (
                                      <React.Fragment key={id}>
                                        <Checkbox
                                          key={id}
                                          value={id.toString()}
                                          ref={ref}
                                        >
                                          {name}
                                        </Checkbox>
                                        {id.toString() === otherReasonId &&
                                          field.value.includes(
                                            otherReasonId,
                                          ) && (
                                            <Controller
                                              name="user_service_referral_source_attributes.other_reason"
                                              control={control}
                                              render={({
                                                field,
                                                fieldState: { error },
                                              }) => (
                                                <Input
                                                  {...field}
                                                  error={error?.message}
                                                  aria-label="その他内容"
                                                />
                                              )}
                                            />
                                          )}
                                        {id.toString() === fromSalesId &&
                                          field.value.includes(fromSalesId) && (
                                            <Controller
                                              name="user_service_referral_source_attributes.referral_code"
                                              control={control}
                                              render={({
                                                field,
                                                fieldState: { error },
                                              }) => (
                                                <Input
                                                  {...field}
                                                  placeholder="紹介コード"
                                                  message={
                                                    <>紹介コードをお持ちの場合は入力してください。<br />
                                                    紹介コードが分からない場合は営業担当の名前を入力してください。</>
                                                  }
                                                  error={error?.message}
                                                />
                                              )}
                                            />
                                          )}
                                      </React.Fragment>
                                    ),
                                  )}
                                </CheckboxGroup>
                              </Stack>
                              {error && (
                                <InputError>{error.message}</InputError>
                              )}
                            </Box>
                          )}
                        />
                      </Box>
                    </Box>
                  </Stack>
                </Flex>
                <Box h={0.5} bgColor="#3CAA91" mt={12} />
                <Box mt={12}>
                  <Controller
                    name="agreement"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <Checkbox {...field}>
                          <Text fontSize="sm">
                            「利用規約」および「個人情報保護方針」に同意する
                          </Text>
                        </Checkbox>
                        {fieldState.error !== undefined && (
                          <InputError>{fieldState.error.message}</InputError>
                        )}
                      </>
                    )}
                  />
                  <Text fontSize="xs" ml={6}>
                    「
                    <Link
                      color="textLink"
                      textDecoration="underline"
                      _hover={{ textDecoration: "none" }}
                      href={termsOfServicePath()}
                      target="_blank"
                      rel="noreferrer"
                    >
                      利用規約
                    </Link>
                    」「
                    <Link
                      color="textLink"
                      textDecoration="underline"
                      _hover={{ textDecoration: "none" }}
                      href={privacyPolicyPath()}
                      target="_blank"
                      rel="noreferrer"
                    >
                      個人情報保護方針
                    </Link>
                    」をご確認いただき、チェックをお願いします。
                  </Text>
                </Box>
                <Flex justify="center" mt={12}>
                  <Button
                    type="submit"
                    isLoading={methods.formState.isSubmitting}
                  >
                    会員登録
                  </Button>
                </Flex>
                <Text textAlign="center" fontSize="sm" mt={6}>
                  すでに会員の方は
                  <Link
                    color="textLink"
                    textDecoration="underline"
                    _hover={{ textDecoration: "none" }}
                    href={newUserSessionPath()}
                  >
                    こちらからログイン
                  </Link>
                </Text>
                <Box mt={14}>
                  <SiteSeal />
                </Box>
              </Box>
            </FormProvider>
          </ShadowCard>
        </Container>
      </Background>
    </Application>
  );
};

export default AuthRegistrationsNew;
