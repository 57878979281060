import { Box, Container, Heading, Link, Stack, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  newUserDatabaseAuthenticationPasswordPath,
  newUserDatabaseAuthenticationRegistrationPath,
  newUserSessionPath,
  privacyPolicyPath,
  termsOfServicePath,
} from "../../../../routes";
import useRequest from "../../shared/lib/useRequest";
import { Button } from "../../shared/components/atoms";
import Background from "../../shared/components/atoms/Background";
import ErrorMessage from "../../shared/components/atoms/ErrorMessage";
import FixedBackgroundBuildingImage from "../../shared/components/atoms/FixedBackgroundBuildingImage";
import Header from "../../shared/components/atoms/Header";
import ShadowCard from "../../shared/components/atoms/ShadowCard";
import Checkbox from "../../shared/components/atoms/form/Checkbox";
import Input from "../../shared/components/atoms/form/Input";
import OpenInNewIcon from "../../shared/components/icons/OpenInNewIcon";
import Application from "../../shared/components/layouts/Application";
import { Flash } from "../../../shared/lib/types";

const AuthSessionsNew = ({ flash }: { flash: Flash }) => {
  const schema = yup.object({
    email: yup.string().trim().required().label("メールアドレス"),
    password: yup.string().required().label("パスワード"),
    remember_me: yup.bool().required(),
  });

  type FormData = yup.InferType<typeof schema>;

  const [errorMessage, setErrorMessage] = useState<string>("");

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<FormData>({
    defaultValues: {
      email: "",
      password: "",
      remember_me: false,
    },
    resolver: yupResolver(schema),
  });

  const request = useRequest();
  const onSubmit = async (data: FormData) => {
    const res = await request(newUserSessionPath(), "POST", {
      user_database_authentication: data,
    });
    if (res.ok) {
      location.href = res.headers.get("Location")!;
      return;
    } else {
      const json = await res.json();
      setErrorMessage(json["error"]);
      return;
    }
  };

  return (
    <Application flash={flash} disableFloatingRegisterButton>
      <FixedBackgroundBuildingImage />
      <Background>
        <Header onlyLogo />
        <Container
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          maxW="lg"
          mt={{ base: 6, lg: 12 }}
          zIndex={1}
        >
          <ShadowCard>
            <Heading size="md" textAlign="center">
              ログイン
            </Heading>
            {errorMessage !== "" && (
              <Box mt={4}>
                <ErrorMessage>{errorMessage}</ErrorMessage>
              </Box>
            )}
            <Stack gap={4} mt={{ base: 2, lg: 4 }}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    label="メールアドレス"
                    required
                    placeholder="sample@aloop.com"
                    error={errors.email?.message ?? ""}
                    {...field}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Input
                    type="password"
                    label="パスワード"
                    error={errors.password?.message ?? ""}
                    {...field}
                    required
                  />
                )}
              />
              <Controller
                name="remember_me"
                control={control}
                render={({ field: { value, ...field } }) => (
                  <Checkbox
                    size={{ base: "sm", lg: "md" }}
                    {...field}
                    isChecked={value}
                  >
                    ログイン状態を維持する
                  </Checkbox>
                )}
              />
            </Stack>
            <Text
              mt={{ base: 7, lg: 10 }}
              lineHeight="130%"
              fontSize={{ base: "xs", lg: "sm" }}
              textAlign={{ base: "left", lg: "center" }}
            >
              ログインすることにより、
              <Link
                color="#1A827B"
                display="inline-flex"
                alignItems="center"
                gap="2px"
                textDecoration="underline"
                _hover={{ textDecoration: "none" }}
                href={termsOfServicePath()}
                target="_blank"
                rel="noreferrer"
              >
                利用規約
                <OpenInNewIcon color="textLink" boxSize={4} />
              </Link>
              および
              <Link
                color="#1A827B"
                display="inline-flex"
                alignItems="center"
                gap="2px"
                textDecoration="underline"
                _hover={{ textDecoration: "none" }}
                href={privacyPolicyPath()}
                target="_blank"
                rel="noreferrer"
              >
                個人情報保護方針
                <OpenInNewIcon color="textLink" boxSize={4} />
              </Link>
              に同意したものとします。
            </Text>
            <Stack mt={4} align="stretch">
              <Button type="submit" isLoading={isSubmitting}>
                ログイン
              </Button>
            </Stack>
            <Text
              mt={10}
              fontSize={{ base: "xs", lg: "sm" }}
              textAlign="center"
            >
              アカウントをお持ちでない方は
              <Link
                color="textLink"
                textDecoration="underline"
                _hover={{ textDecoration: "none" }}
                href={newUserDatabaseAuthenticationRegistrationPath()}
              >
                新規登録
              </Link>
            </Text>
            <Text mt={3} fontSize={{ base: "xs", lg: "sm" }} textAlign="center">
              パスワードを忘れた方は
              <Link
                color="textLink"
                textDecoration="underline"
                _hover={{ textDecoration: "none" }}
                href={newUserDatabaseAuthenticationPasswordPath()}
              >
                こちら
              </Link>
            </Text>
          </ShadowCard>
        </Container>
      </Background>
    </Application>
  );
};

export default AuthSessionsNew;
