import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const SellIcon = (props: IconProps) => (
  <Icon viewBox="0 0 18 18" boxSize="18px" {...props}>
    <path
      d="M10.6875 16.05C10.4 16.3375 10.0437 16.4813 9.61875 16.4813C9.19375 16.4813 8.8375 16.3375 8.55 16.05L1.95 9.45C1.8125 9.3125 1.70313 9.15 1.62188 8.9625C1.54062 8.775 1.5 8.575 1.5 8.3625V3C1.5 2.5875 1.64688 2.23438 1.94063 1.94063C2.23438 1.64688 2.5875 1.5 3 1.5H8.3625C8.575 1.5 8.775 1.54062 8.9625 1.62188C9.15 1.70313 9.3125 1.8125 9.45 1.95L16.05 8.56875C16.3375 8.85625 16.4813 9.20938 16.4813 9.62813C16.4813 10.0469 16.3375 10.4 16.05 10.6875L10.6875 16.05ZM9.61875 15L14.9813 9.6375L8.3625 3H3V8.3625L9.61875 15ZM4.875 6C5.1875 6 5.45312 5.89062 5.67188 5.67188C5.89062 5.45312 6 5.1875 6 4.875C6 4.5625 5.89062 4.29688 5.67188 4.07812C5.45312 3.85938 5.1875 3.75 4.875 3.75C4.5625 3.75 4.29688 3.85938 4.07812 4.07812C3.85938 4.29688 3.75 4.5625 3.75 4.875C3.75 5.1875 3.85938 5.45312 4.07812 5.67188C4.29688 5.89062 4.5625 6 4.875 6Z"
      fill="#6B767B"
    />
  </Icon>
);

export default SellIcon;
