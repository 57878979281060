import {
  As,
  CloseButton,
  HStack,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import React from "react";
import DescriptionIcon from "../icons/DescriptionIcon";
import DownloadIcon from "../icons/DownloadIcon";
import { HelperNotImageFile } from "../../../../shared/lib/types";

const FilesWithoutImage = ({
  files,
  downloadable = true,
  onRemove = undefined,
}: {
  files: Array<HelperNotImageFile | File>;
  downloadable?: boolean;
  onRemove?: (index: number) => void;
}) => {
  if (files.length === 0) return;

  const listItemProps = (file: HelperNotImageFile | File) =>
    downloadable
      ? {
          as: "a" as As,
          href: (file as HelperNotImageFile).url,
          download: file.name,
        }
      : {};

  return (
    <List>
      {files.map((file, idx) => (
        <ListItem
          key={idx}
          display="block"
          w="100%"
          {...listItemProps(file)}
        >
          <HStack gap={0} minH={{ base: 8, md: 10 }}>
            <DescriptionIcon boxSize={{ base: 5, md: 6 }} />
            <Text
              pt={0.5}
              fontWeight="bold"
              color={downloadable ? "textLink" : undefined}
              fontSize={{ base: "sm", md: "lg" }}
              ml={0.5}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
            >
              {file.name}
            </Text>
            {downloadable && <DownloadIcon color="textLink" ml={2} />}
            {onRemove && <CloseButton ml={2} onClick={() => onRemove(idx)} />}
          </HStack>
        </ListItem>
      ))}
    </List>
  );
};

export default FilesWithoutImage;
