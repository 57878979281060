import React, { ReactNode } from "react";
import { Flash } from "../../../shared/lib/types";
import Application from "../../shared/components/layouts/Application";
import GoBackLink from "../../shared/components/atoms/GoBackLink";
import {
  businessMatchingOwnedBusinessEntityTalkRoomsPath,
  businessMatchingRootPath,
} from "../../../../routes";
import {
  BusinessMatchingBusinessEntity,
  SharedCurrentUser,
} from "../../shared/lib/types";
import {
  Box,
  Card,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import FacebookIcon from "../../shared/components/icons/FacebookIcon";
import InstagramIcon from "../../shared/components/icons/InstagramIcon";
import NoteIcon from "../../shared/components/icons/NoteIcon";
import PinterestIcon from "../../shared/components/icons/PinterestIcon";
import ThreadsIcon from "../../shared/components/icons/ThreadsIcon";
import TikTokIcon from "../../shared/components/icons/TikTokIcon";
import XIcon from "../../shared/components/icons/XIcon";
import YoutubeIcon from "../../shared/components/icons/YoutubeIcon";
import { Button, Footer, Header } from "../../shared/components/atoms";
import Background from "../../shared/components/atoms/Background";
import SmsIcon from "../../shared/components/icons/SmsIcon";
import useRequest from "../../shared/lib/useRequest";
import useFlash from "../../shared/lib/useFlash";
import { useForm } from "react-hook-form";
import InfoItem from "../shared/components/InfoItem";
import InfoItemLeft from "../shared/components/InfoItemLeft";
import InfoItemRight from "../shared/components/InfoItemRight";

const BusinessEntitiesShow = ({
  flash,
  businessEntity,
  currentUser,
}: {
  flash: Flash;
  businessEntity: BusinessMatchingBusinessEntity;
  currentUser: SharedCurrentUser | null;
}) => {
  const request = useRequest();
  const showFlash = useFlash();
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const snsLinkBoxSizing = { base: "44px", md: 8 };
  const hiddenTextForInactiveUser = "受発注ご利用中のお客様のみ閲覧できます";
  const TalkRoomRestrictionModal = useDisclosure();
  const ownedBusinessEntities = currentUser?.owned_business_entities;

  const ContentsForCurrentUser = ({ children }: { children: ReactNode }) => {
    return (
      <>
        {businessEntity.show_all_info ? (
          children
        ) : (
          <Text color="gray">{hiddenTextForInactiveUser}</Text>
        )}
      </>
    );
  };

  const redirectToTalkRoom = async () => {
    const currentBusinessEntityCode = ownedBusinessEntities?.current.code;
    if (!currentBusinessEntityCode) {
      return;
    }

    const res = await request(
      businessMatchingOwnedBusinessEntityTalkRoomsPath(
        currentBusinessEntityCode,
      ),
      "POST",
      {
        partner_code: businessEntity.code,
      },
    );
    if (res.ok) {
      const json = await res.json();
      location.href = businessMatchingOwnedBusinessEntityTalkRoomsPath(
        currentBusinessEntityCode,
        { code: json.talk_room_code },
      );
    } else if (res.status === 404) {
      showFlash({
        error: (
          <>
            事業者が見つかりませんでした。
            <br />
            ページをリロードしてください。
          </>
        ),
      });
    }
  };

  return (
    <Application flash={flash} currentUser={currentUser}>
      <Header currentUser={currentUser} showBusinessMatchingHeader />
      <Background color="brand.50">
        <Container maxW="container.md" mt={{ base: 8, sm: 10 }}>
          <Stack gap={6}>
            <GoBackLink href={businessMatchingRootPath()}>
              事業者一覧に戻る
            </GoBackLink>
            <Flex
              gap={{ base: 6, lg: 10 }}
              direction={{ base: "column", lg: "row" }}
              alignItems={{ base: "start", lg: "center" }}
            >
              <Image
                src={businessEntity.logo_image_url}
                minW="160px"
                maxW="160px"
                minH="160px"
              />
              <Stack whiteSpace={{ base: "normal", lg: "nowrap" }}>
                <Text>
                  {businessEntity.show_all_info
                    ? businessEntity.name_kana
                    : hiddenTextForInactiveUser}
                </Text>
                <Heading as="h1" size="lg">
                  {businessEntity.show_all_info
                    ? businessEntity.name
                    : hiddenTextForInactiveUser}
                </Heading>
              </Stack>
            </Flex>
            {!businessEntity.is_owned && (
              <Box as="form">
                <Button
                  type="submit"
                  w="full"
                  onClick={handleSubmit(async () => {
                    if (!ownedBusinessEntities?.current.is_active) {
                      TalkRoomRestrictionModal.onOpen();
                      return;
                    }

                    await redirectToTalkRoom();
                  })}
                  isLoading={isSubmitting}
                >
                  <HStack gap={2}>
                    <SmsIcon boxSize={5} />
                    <Text pt={0.5}>メッセージを送る</Text>
                  </HStack>
                </Button>
              </Box>
            )}
            <Card px={6} py={8}>
              <Stack gap={6}>
                <InfoItem>
                  <InfoItemLeft>
                    <Text fontWeight="bold">所在地</Text>
                  </InfoItemLeft>
                  <InfoItemRight>
                    <ContentsForCurrentUser>
                      <Text>
                        〒{businessEntity.postal_code}
                        <br />
                        {businessEntity.prefecture_name}
                        {businessEntity.address1}
                        {businessEntity.address2}
                        {businessEntity.address3}
                      </Text>
                    </ContentsForCurrentUser>
                  </InfoItemRight>
                </InfoItem>
                <Divider borderColor="borderDivider" />
                <InfoItem>
                  <InfoItemLeft>
                    <Text fontWeight="bold">主業種</Text>
                  </InfoItemLeft>
                  <InfoItemRight>
                    <Text>{businessEntity.industry_name}</Text>
                  </InfoItemRight>
                </InfoItem>
                <Divider borderColor="borderDivider" />
                <InfoItem>
                  <InfoItemLeft>
                    <Text fontWeight="bold">従業員数</Text>
                  </InfoItemLeft>
                  <InfoItemRight>
                    <Text>{businessEntity.number_of_employees}名</Text>
                  </InfoItemRight>
                </InfoItem>
                <Divider borderColor="borderDivider" />
                <InfoItem>
                  <InfoItemLeft>
                    <Text fontWeight="bold">設立</Text>
                  </InfoItemLeft>
                  <InfoItemRight>
                    <Text>
                      {businessEntity.establishment_year_month.split("/")[0]}年
                      {businessEntity.establishment_year_month.split("/")[1]}月
                    </Text>
                  </InfoItemRight>
                </InfoItem>
                <Divider borderColor="borderDivider" />
                <InfoItem>
                  <InfoItemLeft>
                    <Text fontWeight="bold">資本金</Text>
                  </InfoItemLeft>
                  <InfoItemRight>
                    <Text>
                      {businessEntity.capital_ten_thousand_yen === 0
                        ? "-"
                        : `${businessEntity.capital_ten_thousand_yen}円`}
                    </Text>
                  </InfoItemRight>
                </InfoItem>
                <Divider borderColor="borderDivider" />
                <InfoItem>
                  <InfoItemLeft>
                    <Text fontWeight="bold">代表者指名</Text>
                  </InfoItemLeft>
                  <InfoItemRight>
                    <ContentsForCurrentUser>
                      <Text>{businessEntity.president_name}</Text>
                    </ContentsForCurrentUser>
                  </InfoItemRight>
                </InfoItem>
                <Divider borderColor="borderDivider" />
                <InfoItem>
                  <InfoItemLeft>
                    <Text fontWeight="bold">Webサイト</Text>
                  </InfoItemLeft>
                  <InfoItemRight>
                    <ContentsForCurrentUser>
                      <Link
                        href={businessEntity.website_url}
                        color="textLink"
                        textDecoration="underline"
                        _hover={{ textDecoration: "none" }}
                      >
                        {businessEntity.website_url}
                      </Link>
                    </ContentsForCurrentUser>
                  </InfoItemRight>
                </InfoItem>
                <Divider borderColor="borderDivider" />
                <InfoItem>
                  <InfoItemLeft>
                    <Text fontWeight="bold">SNS</Text>
                  </InfoItemLeft>
                  <InfoItemRight>
                    <ContentsForCurrentUser>
                      <HStack flexWrap="wrap">
                        <FacebookIcon boxSize={snsLinkBoxSizing} />
                        <InstagramIcon boxSize={snsLinkBoxSizing} />
                        <NoteIcon boxSize={snsLinkBoxSizing} />
                        <PinterestIcon boxSize={snsLinkBoxSizing} />
                        <ThreadsIcon boxSize={snsLinkBoxSizing} />
                        <TikTokIcon boxSize={snsLinkBoxSizing} />
                        <XIcon boxSize={snsLinkBoxSizing} />
                        <YoutubeIcon boxSize={snsLinkBoxSizing} />
                      </HStack>
                    </ContentsForCurrentUser>
                  </InfoItemRight>
                </InfoItem>
              </Stack>
            </Card>
          </Stack>
        </Container>
      </Background>
      <Footer />
      <Modal
        isOpen={TalkRoomRestrictionModal.isOpen}
        onClose={TalkRoomRestrictionModal.onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent mx={2}>
          <ModalCloseButton />
          <ModalBody
            textAlign="center"
            pt={{ base: 16, md: 28 }}
            pb={{ base: 12, md: 20 }}
          >
            <VStack>
              <Text fontWeight="bold" fontSize={{ md: "2xl" }}>
                {currentUser == null
                  ? "ご利用になるには会員登録が必要です"
                  : !ownedBusinessEntities?.current.is_approved
                    ? "受発注未利用の方は利用できません。"
                    : !ownedBusinessEntities?.current.is_active
                      ? "受発注一時停止中の方は利用できません。"
                      : ""}
              </Text>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Application>
  );
};

export default BusinessEntitiesShow;
