import { Box } from "@chakra-ui/react";
import React from "react";
import { BgCityDubble, BgCitySingle, BgCityTriple } from "../../../svg";

const FixedBackgroundBuildingImage = () => {
  return (
    <Box
      position="fixed"
      bottom="0"
      left="0"
      width="120%"
      transform="translateX(-16%)"
      bgSize="contain"
      bgImage={{
        base: `url(${BgCitySingle})`,
        lg: `url(${BgCityDubble})`,
        "2xl": `url(${BgCityTriple})`,
      }}
      minH="100svh"
      bgRepeat="no-repeat"
      bgPosition="center bottom"
    />
  );
};

export default FixedBackgroundBuildingImage;
