import React from "react";
import { communitiesPath, communityPath } from "../../../routes";
import Application from "../shared/components/layouts/Application";
import Header from "../shared/components/atoms/Header";
import { Container, Heading, Box, Flex } from "@chakra-ui/react";
import ShadowCard from "../shared/components/atoms/ShadowCard";
import Background from "../shared/components/atoms/Background";
import { Helmet } from "react-helmet";

import CommunityForm from "./components/CommunityForm";
import { Button } from "../shared/components/atoms";
import GoBackLink from "../shared/components/atoms/GoBackLink";
import { Flash, SharedTag } from "../../shared/lib/types";
import { SharedCurrentUser } from "../shared/lib/types";

const CommunitiesNew = ({
  tags,
  flash,
  currentUser,
}: {
  tags: SharedTag[];
  flash: Flash;
  currentUser: SharedCurrentUser;
}) => {
  const onSubmit = async (formData: FormData) => {
    const req = new Request(communitiesPath(), {
      method: "POST",
      body: formData,
    });

    const res = await fetch(req);
    if (res.ok) {
      const json = await res.json();
      location.href = communityPath(json.code);
    }
  };

  return (
    <Application flash={flash} currentUser={currentUser}>
      <Helmet>
        <title>A-Loop | コミュニティ作成</title>
      </Helmet>
      <Header currentUser={currentUser} />
      <Background color="brand.50">
        <Container maxW="3xl" mt={{ base: 6, lg: 12 }}>
          <GoBackLink href={communitiesPath()}>
            コミュニティ一覧に戻る
          </GoBackLink>
          <Box mt={2}>
            <ShadowCard>
              <Container maxW="xl">
                <Heading size="lg" textAlign="center" as="h1">
                  コミュニティ作成
                </Heading>
                <CommunityForm
                  onSubmit={onSubmit}
                  renderButton={({ isSubmitting }) => (
                    <Flex justify="center" mt={12}>
                      <Button type="submit" w="xs" isLoading={isSubmitting}>
                        コミュニティを作成
                      </Button>
                    </Flex>
                  )}
                  tags={tags}
                />
              </Container>
            </ShadowCard>
          </Box>
        </Container>
      </Background>
    </Application>
  );
};

export default CommunitiesNew;
