import { Mark } from "@chakra-ui/react";
import { findAll } from "highlight-words-core";
import React from "react";

const normalize = (str: string) => {
  const fullwidth =
    "！＂＃＄％＆＇（）＊＋，－．／０１２３４５６７８９：；＜＝＞？＠ＡＢＣＤＥＦＧＨＩＪＫＬＭＮＯＰＱＲＳＴＵＶＷＸＹＺ［＼］＾＿｀ａｂｃｄｅｆｇｈｉｊｋｌｍｎｏｐｑｒｓｔｕｖｗｘｙｚ｛｜｝～";
  const halfwidth =
    "!\"#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~";

  const result = str.split("").reduce((acc, char) => {
    const index = fullwidth.indexOf(char);
    if (index !== -1) {
      return acc + halfwidth[index];
    } else {
      return acc + char;
    }
  }, "");

  return result.toLowerCase();
};

const Highlight = ({
  children,
  query,
}: {
  children: string;
  query: string[];
}) => {
  const normalizedChunks = findAll({
    searchWords: query.map((str) => normalize(str)),
    textToHighlight: normalize(children),
    autoEscape: true,
  });

  const chunks = normalizedChunks.map(({ end, highlight, start }) => ({
    highlight,
    text: children.slice(start, end),
  }));

  return (
    <>
      {chunks.map(({ highlight, text }, index) => {
        if (!highlight) return text;
        return (
          <Mark bg="#A9EEB4" key={index}>
            {text}
          </Mark>
        );
      })}
    </>
  );
};

export default Highlight;
