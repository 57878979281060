import React from "react";
import { SharedPost, SharedCurrentUser } from "../../lib/types";
import PostSummaryCardCompact from "./PostSummaryCardCompact";
import PostSummaryCardDetail from "./PostSummaryCardDetail";

const PostSummaryCard = ({
  ...props
}: {
  post: SharedPost;
  query?: string[];
  currentUser: SharedCurrentUser;
}) => {
  const theme =
    props.currentUser?.current_post_theme_name ??
    localStorage.getItem("current_post_theme_name") ??
    "detail";

  if (theme === "detail") {
    return <PostSummaryCardDetail {...props} />;
  } else {
    return <PostSummaryCardCompact {...props} />;
  }
};

export default PostSummaryCard;
