import { Box, Tooltip } from "@chakra-ui/react";
import React, { useState } from "react";
import BookmarkIcon from "../icons/BookmarkIcon";
import useRequest from "../../lib/useRequest";
import { postBookmarkPath } from "../../../../../routes";
import useFlash from "../../lib/useFlash";
import BookmarkedIcon from "../icons/BookmarkedIcon";
import RegistrationRequiredAction from "./RegistrationRequiredAction";
import { SharedCurrentUser } from "../../lib/types";

const BookmarkButton = ({
  post_code,
  bookmarked: propBookmarked,
  currentUser,
}: {
  post_code: string;
  bookmarked: boolean;
  currentUser: SharedCurrentUser;
}) => {
  const [bookmarked, setBookmarked] = useState(propBookmarked);
  const request = useRequest();
  const flash = useFlash();
  const onBookmark = async () => {
    const res = await request(postBookmarkPath(post_code), "PUT");

    if (res.ok) {
      setBookmarked(true);
      flash({ success: "ブックマークしました" });
    } else if (res.status === 404) {
      flash({
        error: (
          <>
            投稿が見つかりませんでした。
            <br />
            ページを再読み込みしてください。
          </>
        ),
      });
    }
  };
  const onUnbookmark = async () => {
    const res = await request(postBookmarkPath(post_code), "DELETE");

    if (res.ok) {
      setBookmarked(false);
      flash({ success: "ブックマークを解除しました" });
    } else if (res.status === 404) {
      flash({
        error: (
          <>
            投稿が見つかりませんでした。
            <br />
            ページを再読み込みしてください。
          </>
        ),
      });
    }
  };
  const iconProps = { boxSize: 6, m: 2 };
  return (
    <RegistrationRequiredAction
      currentUser={currentUser}
      onClick={async (e) => {
        if (bookmarked) {
          void onUnbookmark();
        } else {
          void onBookmark();
        }
        e.preventDefault();
      }}
      render={(props) => (
        <Tooltip
          label="Q&Aをブックマークに追加すると、あとからQ&Aを読み返すことができます。"
          hasArrow
          placement="top"
        >
          <Box
            as="button"
            {...props}
            aria-label="bookmark"
            borderRadius="full"
            _hover={{
              bgColor: "#EDF5F3"
            }}
          >
            {bookmarked ? (
              <BookmarkedIcon {...iconProps} />
            ) : (
              <BookmarkIcon {...iconProps} />
            )}
          </Box>
        </Tooltip>
      )}
    />
  );
};

export default BookmarkButton;
