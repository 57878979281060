import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const ExpandLessIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" boxSize={5} {...props}>
    <path
      d="M6.16667 12.8122L5 11.6455L10 6.64551L15 11.6455L13.8333 12.8122L10 8.97884L6.16667 12.8122Z"
      fill="currentColor"
    />
  </Icon>
);

export default ExpandLessIcon;
