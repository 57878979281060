import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

const AwarenessIcon = (props: IconProps) => {
  return (
    <Icon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_7541_74115"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7541_74115)">
        <path
          d="M21.3333 17.333V14.6663H26.6667V17.333H21.3333ZM22.9333 26.6663L18.6667 23.4663L20.2667 21.333L24.5333 24.533L22.9333 26.6663ZM20.2667 10.6663L18.6667 8.53301L22.9333 5.33301L24.5333 7.46634L20.2667 10.6663ZM4 19.9997V11.9997H9.33333L16 5.33301V26.6663L9.33333 19.9997H4Z"
          fill="#3CAA91"
        />
      </g>
    </Icon>
  );
};

export default AwarenessIcon;
