import { Box, Flex, HStack, Image, Link } from "@chakra-ui/react";
import React from "react";
import { rootPath } from "../../../../../routes";
import { LogoSVG } from "../../../svg";
import { SharedCurrentUser } from "../../lib/types";
import GlobalHeader from "./GlobalHeader";
import BusinessMatchingHeader from "./BusinessMatchingHeader";

const Header = ({
  currentUser,
  onlyLogo = false,
  shadow = true,
  showBusinessMatchingHeader = false,
}: {
  currentUser?: SharedCurrentUser;
  onlyLogo?: boolean;
  shadow?: boolean;
  showBusinessMatchingHeader?: boolean;
}) => {
  if (onlyLogo) {
    return (
      <Flex
        bg="white"
        boxShadow="0px 8px 16px -8px rgba(10, 100, 79, 0.12)"
        zIndex={2}
        px={6}
        py={5}
      >
        <HStack as={Link} pb={1} h="full" href={rootPath()}>
          <Image src={LogoSVG} h={8} />
        </HStack>
      </Flex>
    );
  }

  return (
    <Box position="sticky" top="0px" zIndex="100">
      <GlobalHeader currentUser={currentUser} shadow={shadow} />
      {showBusinessMatchingHeader && currentUser?.is_approved && (
        <BusinessMatchingHeader
          ownedBusinessEntities={currentUser.owned_business_entities}
        />
      )}
    </Box>
  );
};

export default Header;
