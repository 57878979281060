import {
  Box,
  Container,
  Flex,
  FlexProps,
  Grid,
  GridItem,
  HStack,
  Heading,
  HeadingProps,
  Link,
  Show,
  Spinner,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useInfiniteQuery } from "@tanstack/react-query";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import {
  editProfileBasicPath,
  userCommentedRepliedPostsPath,
  userPostsPath,
} from "../../../routes";
import { Flash } from "../../shared/lib/types";
import { Button } from "../shared/components/atoms";
import Background from "../shared/components/atoms/Background";
import CustomLinkLinkify from "../shared/components/atoms/CustomLinkLinkify";
import Footer from "../shared/components/atoms/Footer";
import Header from "../shared/components/atoms/Header";
import PostSummaryCard from "../shared/components/atoms/PostSummaryCard";
import UserAvatar from "../shared/components/atoms/UserAvatar";
import BookmarkedIcon from "../shared/components/icons/BookmarkedIcon";
import ContactSupportIcon from "../shared/components/icons/ContactSupportIcon";
import FilledChatBubbleIcon from "../shared/components/icons/FilledChatBubbleIcon";
import ModeEditIcon from "../shared/components/icons/ModeEditIcon";
import ThumbUpFilledIcon from "../shared/components/icons/ThumbUpFilledIcon";
import Application from "../shared/components/layouts/Application";
import {
  SharedApprovedCurrentUser,
  User,
  UserCommentedRepliedPostsIndex,
  UserPostsIndex,
} from "../shared/lib/types";
import useRequest from "../shared/lib/useRequest";
import BusinessCenterIcon from "../shared/components/icons/BusinessCenterIcon";
import { FlipperContext } from "../../shared/lib/FlipperContext";

const ProfileItem = ({ children }: { children: ReactNode }) => {
  return (
    <Flex
      pb={6}
      borderBottom="1px solid #D5E0E4"
      direction={{ base: "column", md: "row" }}
      gap={{ base: 2, md: 0 }}
      _last={{ borderBottom: "0px" }}
    >
      {children}
    </Flex>
  );
};

const ProfileItemLeft = ({ children }: { children: ReactNode }) => {
  return (
    <Text flex={1} fontWeight="bold">
      {children}
    </Text>
  );
};

const ProfileItemRight = ({ children }: { children: ReactNode }) => {
  return (
    <Box flex={2} whiteSpace="pre-wrap" fontSize={{ base: "sm", md: "md" }}>
      {children}
    </Box>
  );
};

const SectionTitle = ({
  children,
  ...props
}: { children: ReactNode } & HeadingProps) => {
  return (
    <Heading
      as="h2"
      fontSize={{ base: "2xl", md: "3xl" }}
      color="primary"
      lineHeight={{ base: "133%", md: "120%" }}
      pb={{ base: 0, md: 3 }}
      borderBottom={{ base: "", md: "2px solid var(--chakra-colors-primary)" }}
      {...props}
    >
      {children}
    </Heading>
  );
};

const EditProfileButton = (props: FlexProps) => {
  return (
    <Flex justify="right" {...props}>
      <Button
        as="a"
        href={editProfileBasicPath()}
        minW={{ base: "full", md: "fit-content" }}
        size="sm"
      >
        <HStack gap={1}>
          <ModeEditIcon boxSize="18px" />
          <Text pt={0.5}>プロフィール編集</Text>
        </HStack>
      </Button>
    </Flex>
  );
};

const UsersShow = ({
  user,
  isOwn,
  flash,
  currentUser,
  activity,
}: {
  user: User;
  isOwn: boolean;
  flash: Flash;
  currentUser: SharedApprovedCurrentUser;
  activity: Record<string, number>;
}) => {
  const workplaceAddress = (workplace: User["workplace"]) => {
    return `${workplace.prefecture?.name ?? ""}${workplace.address1}${workplace.address2}${workplace.address3}`;
  };
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    switch (tab) {
      case "qa":
        setTabIndex(0);
        break;
      case "profile":
        setTabIndex(1);
        break;
    }
  }, []);
  const flipper = useContext(FlipperContext);

  return (
    <Application flash={flash} currentUser={currentUser}>
      <Header currentUser={currentUser} shadow={false} />
      <Container maxW="container.lg" mt={{ base: 6, md: 12 }}>
        <Stack
          align="center"
          gap={{ base: 5, md: 10 }}
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "center", md: "flex-start" }}
        >
          {isOwn && (
            <Show below="md">
              <EditProfileButton w="full" />
            </Show>
          )}
          <UserAvatar
            src={user.profile_image_url}
            bgColor={user.avatar_bgcolor}
            name={user.full_name}
            boxSize={{ base: 28, md: 40 }}
            mt={{ base: 2, md: 0 }}
            sx={{ "--avatar-font-size": "3rem" }}
          />
          <Box flex="1">
            <Flex align="flex-start" justify="space-between">
              <Box>
                <Text fontSize="sm" color="#888888">
                  {user.full_name_kana}
                </Text>
                <Heading size="lg" as="p">
                  {user.full_name}
                </Heading>
              </Box>
              {isOwn && (
                <Show above="md">
                  <EditProfileButton />
                </Show>
              )}
            </Flex>
            <HStack mt={{ base: 4, md: 2 }} gap={{ base: 1, md: 5 }}>
              <Text fontSize={{ base: "lg", md: "lg" }}>
                {user.architect_license.name}
              </Text>
              <HStack
                gap={2}
                fontSize={{ base: "xs", md: "sm" }}
                color="#888888"
              >
                <Text>{user.prefecture.name}在住</Text>
                <Box as="span">/</Box>
                <Text>{user.age}歳</Text>
              </HStack>
            </HStack>
            {flipper.restore_workplace_feature &&
              user.workplace.name !== "" && (
                <Flex mt={2} gap={2} align="center">
                  <BusinessCenterIcon />
                  <Text pt={{ base: 1, md: 0.5 }} fontSize="sm">
                    {user.workplace.name}
                    {user.workplace.prefecture && (
                      <>（{user.workplace.prefecture.name}）</>
                    )}
                  </Text>
                </Flex>
              )}
            <Box mt={6} fontSize="sm" whiteSpace="pre-wrap">
              <CustomLinkLinkify>{user.bio}</CustomLinkLinkify>
            </Box>
          </Box>
        </Stack>
      </Container>
      <Tabs
        variant="unstyled"
        mt={{ base: 14, md: 10 }}
        index={tabIndex}
        onChange={setTabIndex}
      >
        <TabList as={Container} maxW="container.lg" px={{ base: 0, md: 6 }}>
          {isOwn && (
            <Tab
              fontSize={{ base: "sm", md: "md" }}
              py={3}
              px={{ base: 4, md: 8 }}
              w={{ base: "120px", md: "168px" }}
              _selected={{
                bg: "#EDF5F3",
                fontWeight: "bold",
                borderRadius: "4px 4px 0 0",
              }}
            >
              Q&A情報
            </Tab>
          )}
          <Tab
            fontSize={{ base: "sm", md: "md" }}
            py={3}
            px={{ base: 4, md: 8 }}
            _selected={{
              bg: "#EDF5F3",
              fontWeight: "bold",
              borderRadius: "4px 4px 0 0",
            }}
          >
            プロフィール詳細
          </Tab>
        </TabList>
        <TabPanels>
          {isOwn && (
            <TabPanel p={0}>
              <Background color="brand.50">
                <Container maxW="container.lg" px={6} mt={7}>
                  <Heading size="md" as="h3">
                    アクティビティ
                  </Heading>
                  <Grid
                    mt={4}
                    gap={2}
                    gridTemplateColumns={{
                      base: undefined,
                      md: "repeat(auto-fit, minmax(160px, 1fr))",
                    }}
                  >
                    <ActivityItem
                      icon={
                        <ContactSupportIcon boxSize={{ base: 5, md: 10 }} />
                      }
                      text="質問の投稿数"
                      count={activity.posts_count}
                    />
                    <ActivityItem
                      icon={
                        <FilledChatBubbleIcon
                          boxSize={{ base: "18px", md: 8 }}
                        />
                      }
                      text="回答の投稿数"
                      count={activity.comments_replies_count}
                    />
                    <ActivityItem
                      icon={<BookmarkedIcon boxSize={{ base: 5, md: 9 }} />}
                      text="ブックマーク数"
                      count={activity.bookmarks_count}
                    />
                    <ActivityItem
                      icon={
                        <ThumbUpFilledIcon
                          boxSize={{ base: 5, md: 9 }}
                          color="primary"
                        />
                      }
                      text="[参考になった]獲得数"
                      count={activity.received_usefuls_count}
                    />
                  </Grid>
                  <Tabs variant="unstyled" mt={10}>
                    <TabList gap={4}>
                      <Tab _selected={{ fontWeight: "bold" }} px={0}>
                        質問した投稿
                      </Tab>
                      <Tab _selected={{ fontWeight: "bold" }} px={0}>
                        回答した投稿
                      </Tab>
                    </TabList>
                    <TabIndicator mt="-4px" height="4px" bg="primary" />
                    <TabPanels>
                      <TabPanel px={0}>
                        <Posts
                          currentUser={currentUser}
                          path={userPostsPath(user.code)}
                        />
                      </TabPanel>
                      <TabPanel px={0}>
                        <Posts
                          currentUser={currentUser}
                          path={userCommentedRepliedPostsPath(user.code)}
                        />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Container>
              </Background>
            </TabPanel>
          )}
          <TabPanel p={0}>
            <Background color="brand.50">
              <Container maxW="container.lg" px={6}>
                <Box
                  backgroundColor="#FFF"
                  mt={7}
                  py={{ base: 8, md: 10 }}
                  px={{ base: 9, md: 12 }}
                  borderRadius="2px"
                >
                  <section>
                    <SectionTitle>基本情報</SectionTitle>
                    <Stack mt={{ base: 8, md: 7 }} gap={6}>
                      <ProfileItem>
                        <ProfileItemLeft>職種</ProfileItemLeft>
                        <ProfileItemRight>
                          {user.architect_type.name}
                        </ProfileItemRight>
                      </ProfileItem>
                      <ProfileItem>
                        <ProfileItemLeft>取得している資格</ProfileItemLeft>
                        <ProfileItemRight>
                          <Stack gap={{ base: 1, md: 2 }}>
                            <Box as="span">{user.architect_license.name}</Box>
                            {user.additional_licenses.map(({ name }) => (
                              <Box key={name} as="span">
                                {name}
                              </Box>
                            ))}
                          </Stack>
                          {user.other_architect_license !== "" && (
                            <HStack
                              gap="15px"
                              mt={user.additional_licenses.length !== 0 ? 3 : 0}
                              align="start"
                            >
                              <Box
                                as="span"
                                fontWeight="bold"
                                color="#99A9B0"
                                minW="fit-content"
                                fontSize="md"
                              >
                                その他
                              </Box>
                              <Box as="span">
                                {user.other_architect_license}
                              </Box>
                            </HStack>
                          )}
                        </ProfileItemRight>
                      </ProfileItem>
                      {user.industry != null && (
                        <ProfileItem>
                          <ProfileItemLeft>業種</ProfileItemLeft>
                          <ProfileItemRight>{user.industry}</ProfileItemRight>
                        </ProfileItem>
                      )}
                      {user.user_position != null && (
                        <ProfileItem>
                          <ProfileItemLeft>ポジション</ProfileItemLeft>
                          <ProfileItemRight>
                            {user.user_position}
                          </ProfileItemRight>
                        </ProfileItem>
                      )}
                    </Stack>
                  </section>
                  {flipper.restore_workplace_feature &&
                    Object.values(user.workplace).some(
                      (value) => value !== "",
                    ) && (
                      <section>
                        <SectionTitle mt="72px">勤務地情報</SectionTitle>
                        <Stack mt={{ base: 8, md: 7 }} gap={6}>
                          {user.workplace.name !== "" && (
                            <ProfileItem>
                              <ProfileItemLeft>会社名</ProfileItemLeft>
                              <ProfileItemRight>
                                {user.workplace.name}
                              </ProfileItemRight>
                            </ProfileItem>
                          )}
                          {user.workplace.name_kana !== "" && (
                            <ProfileItem>
                              <ProfileItemLeft>会社名ふりがな</ProfileItemLeft>
                              <ProfileItemRight>
                                {user.workplace.name_kana}
                              </ProfileItemRight>
                            </ProfileItem>
                          )}
                          {(workplaceAddress(user.workplace) !== "" ||
                            user.workplace.postal_code !== "") && (
                            <ProfileItem>
                              <ProfileItemLeft>所在地</ProfileItemLeft>
                              <ProfileItemRight>
                                {user.workplace.postal_code !== "" && (
                                  <Box>〒{user.workplace.postal_code}</Box>
                                )}
                                <Box>{workplaceAddress(user.workplace)}</Box>
                              </ProfileItemRight>
                            </ProfileItem>
                          )}
                          {user.workplace.phone_number !== "" && (
                            <ProfileItem>
                              <ProfileItemLeft>電話番号</ProfileItemLeft>
                              <ProfileItemRight>
                                {user.workplace.phone_number}
                              </ProfileItemRight>
                            </ProfileItem>
                          )}
                          {user.workplace.website_url !== "" && (
                            <ProfileItem>
                              <ProfileItemLeft>Webサイト</ProfileItemLeft>
                              <ProfileItemRight>
                                <Link
                                  href={user.workplace.website_url}
                                  color="textLink"
                                  textDecoration="underline"
                                  _hover={{ textDecoration: "none" }}
                                >
                                  {user.workplace.website_url}
                                </Link>
                              </ProfileItemRight>
                            </ProfileItem>
                          )}
                          {user.workplace.business_detail !== "" && (
                            <ProfileItem>
                              <ProfileItemLeft>事業内容</ProfileItemLeft>
                              <ProfileItemRight>
                                <CustomLinkLinkify>
                                  {user.workplace.business_detail}
                                </CustomLinkLinkify>
                              </ProfileItemRight>
                            </ProfileItem>
                          )}
                        </Stack>
                      </section>
                    )}
                </Box>
                {isOwn && <EditProfileButton mt={{ base: 4, md: 6 }} />}
              </Container>
            </Background>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Footer />
    </Application>
  );
};

const ActivityItem = ({
  icon,
  text,
  count,
}: {
  icon: ReactNode;
  text: string;
  count: number;
}) => {
  return (
    <Grid
      bg="white"
      gridRow={{ base: "auto", md: "span 3" }}
      gridTemplateRows="subgrid"
      gridTemplateColumns={{ base: "auto 1fr auto", md: "none" }}
      justifyItems="center"
      borderRadius={4}
      pt={{ base: 2.5, md: 5 }}
      pb={2.5}
      px={{ base: 4, md: 0 }}
    >
      <GridItem h="fit-content" justifySelf={{ base: "start", md: "auto" }}>
        {icon}
      </GridItem>
      <GridItem w={{ base: "80%", md: "auto" }}>{text}</GridItem>
      <GridItem justifySelf={{ base: "end", md: "auto" }}>
        <Box as="span" fontWeight="bold" fontSize={{ base: "md", md: "4xl" }}>
          {count}
        </Box>
      </GridItem>
    </Grid>
  );
};

const Posts = ({
  currentUser,
  path,
}: {
  currentUser: SharedApprovedCurrentUser;
  path: string;
}) => {
  const request = useRequest();

  const fetchUserPosts = async ({
    pageParam,
  }: {
    pageParam: number;
  }): Promise<UserPostsIndex | UserCommentedRepliedPostsIndex> => {
    const res = await request(path, "GET", {
      page: pageParam,
    });
    return res.json();
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [path],
      queryFn: fetchUserPosts,
      initialPageParam: 1,
      getNextPageParam: (lastPage) => lastPage.page_metadata.next,
    });

  return (
    <Stack gap={4}>
      {(data?.pages ?? []).map((page) =>
        page.posts.map((post) => (
          <PostSummaryCard
            key={post.code}
            post={post}
            currentUser={currentUser}
          />
        )),
      )}
      <InView
        onChange={async (inView) => {
          if (hasNextPage && !isFetchingNextPage && inView) {
            await fetchNextPage();
          }
        }}
      ></InView>
      {isFetchingNextPage && (
        <Box textAlign="center">
          <Spinner />
        </Box>
      )}
    </Stack>
  );
};

export default UsersShow;
