import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const XIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" boxSize={8} {...props}>
    <path
      d="M20.0724 9.60669H22.2631L17.4783 15.0502L23.1071 22.4586H18.7008L15.2472 17.9666L11.3002 22.4586H9.10642L14.2232 16.6351L8.82715 9.60669H13.3451L16.4636 13.7125L20.0724 9.60669ZM19.3028 21.1549H20.5161L12.6842 10.8425H11.3809L19.3028 21.1549Z"
      fill="#212529"
    />
  </Icon>
);

export default XIcon;
