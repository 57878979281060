import { Box, Fade, Stack } from "@chakra-ui/react";
import React, { ReactNode, useContext } from "react";
import { useInView } from "react-intersection-observer";
import ExpandLessIcon from "../../shared/components/icons/ExpandLessIcon";
import { isShowFloatingRegisterButton } from "../../shared/lib/isShowFloatingRegisterButton";
import { FlipperContext } from "../../../shared/lib/FlipperContext";
import { SharedCurrentUser } from "../../shared/lib/types";

const BackToTop = ({
  children,
  currentUser,
}: {
  children: ReactNode;
  currentUser: SharedCurrentUser;
}) => {
  const { ref, inView } = useInView({ initialInView: true });
  const onClickBackToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const flipper = useContext(FlipperContext);
  return (
    <>
      <Box ref={ref} />
      {children}
      <Box
        position="sticky"
        w="fit-content"
        pr={8}
        bottom={isShowFloatingRegisterButton({ flipper, currentUser }) ? 32 : 8}
        float="right"
        mr={{ base: 0, md: 56 }}
      >
        <Fade in={!inView}>
          <Stack align="center" w="fit-content">
            <Box
              as="button"
              borderRadius="full"
              bgColor="primary"
              w="fit-content"
              p={2}
              onClick={onClickBackToTop}
            >
              <ExpandLessIcon boxSize={8} color="white" />
            </Box>
            <Box as="span">TOPに戻る</Box>
          </Stack>
        </Fade>
      </Box>
    </>
  );
};

export default BackToTop;
