import {
  Box,
  Flex,
  Grid,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Show,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { ReactNode } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { Button } from "../../shared/components/atoms";
import { Textarea } from "../../shared/components/atoms/form";
import { SharedCurrentUser } from "../../shared/lib/types";
import FileUploadButton from "../../../shared/components/FileUploadButton";
import PostResourceAvatar from "../../shared/components/atoms/PostResourceAvatar";
import PostResourceDisplayName from "../../shared/components/atoms/PostResourceDisplayName";
import AddIcon from "../../shared/components/icons/AddIcon";
import UploadFiles, { useUploadFiles } from "../../../shared/components/UploadFiles";
import { anonymousSchema } from "../lib/schema";
import { HelperImageFile, HelperNotImageFile } from "../../../shared/lib/types";
import HelpMessage from "../../shared/components/atoms/HelpMessage";
import { uploadFile } from "../../../shared/lib/uploadFile";

export const useCommentForm = (
  props: Omit<useCommentOrReplyFormProps, "name">,
) => {
  return useCommentOrReplyForm({ ...props, name: "post_comment" });
};

export const CommentFormFields = (
  props: Omit<CommentOrReplyFormFieldsProps, "name">,
) => {
  return (
    <CommentOrReplyFormFields
      {...props}
      name="post_comment"
      placeholder="コメントを入力"
    />
  );
};

export const useReplyForm = (
  props: Omit<useCommentOrReplyFormProps, "name">,
) => {
  return useCommentOrReplyForm({ ...props, name: "post_comment_reply" });
};

export const ReplyFormFields = (
  props: Omit<CommentOrReplyFormFieldsProps, "name">,
) => {
  return (
    <CommentOrReplyFormFields
      {...props}
      name="post_comment_reply"
      placeholder="返信を入力"
      autoFocus
    />
  );
};

export const EditModal = ({
  title,
  isOpen,
  onClose,
  formState,
  children,
}: {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  formState: ReturnType<typeof useCommentOrReplyForm>;
  children: ReactNode;
}) => {
  const ModalCancelButton = () => {
    return (
      <Box flex="1">
        <Button
          color="gray"
          w="full"
          type="button"
          isLoading={formState.isSubmitting}
          onClick={onClose}
        >
          キャンセル
        </Button>
      </Box>
    );
  };

  const ModalSubmitButton = () => {
    return (
      <Button
        w="full"
        type="submit"
        isDisabled={!formState.isSubmittable}
        isLoading={formState.isSubmitting}
      >
        送信
      </Button>
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: "full", sm: "xl" }}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={formState.onSubmit}
        data-testid="edit-modal"
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          {children}
          <Show below="sm">
            <Flex gap={2} mt={8}>
              <Box flex="1">
                <ModalCancelButton />
              </Box>
              <Box flex="1">
                <ModalSubmitButton />
              </Box>
            </Flex>
          </Show>
        </ModalBody>

        <ModalFooter as={HStack} gap={2}>
          <Show above="sm">
            <Box w="152px">
              <ModalCancelButton />
            </Box>
            <Box w="152px">
              <ModalSubmitButton />
            </Box>
          </Show>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

type useCommentOrReplyFormProps = {
  onSubmit: (data: FormData) => void;
  defaultValues?: { content?: string; anonymous?: string };
  defaultImageFiles?: HelperImageFile[];
  defaultFilesWithoutImage?: HelperNotImageFile[];
  hasAnonymousField?: boolean;
  currentUser?: SharedCurrentUser;
  name: string;
};

const useCommentOrReplyForm = ({
  onSubmit: propOnSubmit,
  defaultValues: propDefaultValues = { content: "", anonymous: "" },
  defaultImageFiles = [],
  defaultFilesWithoutImage = [],
  hasAnonymousField = false,
  name,
}: useCommentOrReplyFormProps) => {
  const defaultValues = {
    content: propDefaultValues.content ?? "",
    anonymous: propDefaultValues.anonymous ?? "",
  };
  const uploadFilesState = useUploadFiles(
    defaultImageFiles,
    defaultFilesWithoutImage,
  );
  const [uploadFiles, , , clear, isUploadFilesChange] = uploadFilesState;

  const schema = yup.object({
    content: yup.string().trim().required().max(65535),
    anonymous: anonymousSchema(hasAnonymousField),
  });

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty, isValid, isSubmitting },
  } = methods;

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    const formData = new FormData();
    formData.append(`${name}[content]`, data.content);
    if (hasAnonymousField && data.anonymous) {
      formData.append(`${name}[anonymous]`, data.anonymous);
    }
    if (uploadFiles.length !== 0) {
      for (const file of uploadFiles) {
        if ("signed_id" in file) {
          formData.append(`${name}[files][]`, file.signed_id);
        } else {
          const { blob } = await uploadFile({ file });
          formData.append(`${name}[files][]`, blob.signed_id);
        }
      }
    } else {
      formData.append(`${name}[files][]`, "");
    }

    propOnSubmit(formData);

    clear();
    reset({ content: "", anonymous: data.anonymous });
  };
  const isSubmittable = isValid && (isDirty || isUploadFilesChange);

  return {
    methods,
    uploadFilesState,
    onSubmit: handleSubmit(onSubmit),
    isSubmittable,
    isSubmitting,
    hasAnonymousField,
  };
};

type CommentOrReplyFormFieldsProps = {
  formState: ReturnType<typeof useCommentOrReplyForm>;
  name: string;
  currentUser: SharedCurrentUser;
  anonymous: boolean;
  placeholder?: string;
  autoFocus?: boolean;
};

const CommentOrReplyFormFields = ({
  formState,
  name,
  currentUser,
  anonymous,
  placeholder = "",
  autoFocus = false,
}: CommentOrReplyFormFieldsProps) => {
  const [uploadFiles, addUploadFiles, removeUploadFile] =
    formState.uploadFilesState;

  const { control } = formState.methods;

  return (
    <>
      <Stack gap={4}>
        <Grid
          columnGap={{ base: 2, sm: 3 }}
          rowGap={1}
          templateColumns="auto 1fr"
          templateRows="auto auto auto"
          templateAreas={{
            base: `
            "avatar name"
            "input input"
            "upload-files upload-files"
            `,
            sm: `
            "avatar name"
            "avatar input"
            "avatar upload-files"
            `,
          }}
          alignItems={{ base: "center", sm: "flex-start" }}
        >
          {currentUser?.is_all_public_feature_accessible && (
            <PostResourceAvatar
              author={{
                ...currentUser,
                display_name: anonymous
                  ? currentUser.nickname
                  : currentUser.full_name,
                display_image_url: anonymous
                  ? currentUser.anonymous_image_url
                  : currentUser.profile_image_url,
                anonymous,
              }}
              gridArea="avatar"
              boxSize={{ base: 7, sm: 12 }}
            />
          )}
          <Box fontWeight="bold" fontSize="sm" gridArea="name">
            {currentUser?.is_all_public_feature_accessible &&
              (anonymous ? (
                <PostResourceDisplayName
                  author={{
                    display_name: currentUser.nickname,
                    anonymous,
                    is_guest: false,
                    floor_age: currentUser.floor_age,
                    architect_type: currentUser.architect_type,
                  }}
                />
              ) : (
                <PostResourceDisplayName
                  author={{
                    display_name: currentUser.full_name,
                    anonymous,
                    is_guest: currentUser.is_guest,
                  }}
                />
              ))}
          </Box>
          <Flex gap={3} alignItems="flex-end" mt={1} gridArea="input">
            <Controller
              name="content"
              control={control}
              render={({ field }) => (
                <Textarea
                  placeholder={placeholder}
                  borderRadius="8px"
                  {...field}
                  bgColor="brand.100"
                  border="none"
                  h="full"
                  rows={9}
                  autoExpand
                  minH={12}
                  autoFocus={
                    autoFocus && currentUser?.is_all_public_feature_accessible
                  }
                  message="※出典（引用元）を記載するとコメントの信憑性が高まります。"
                />
              )}
            />
          </Flex>
          <Box mt={2} gridArea="upload-files">
            <Box>
              <FileUploadButton
                multiple
                onChange={addUploadFiles}
                name={`${name}-files`}
              >
                <HStack
                  gap={0}
                  textDecoration="underline"
                  color="textLink"
                  _hover={{ textDecoration: "none" }}
                >
                  <AddIcon />
                  <Box fontSize="xs">ファイル添付</Box>
                </HStack>
              </FileUploadButton>
            </Box>
            <UploadFiles
              uploadFiles={uploadFiles}
              removeFile={removeUploadFile}
            />
            {uploadFiles.length > 0 && (
              <Popover>
                <PopoverTrigger>
                  <HelpMessage as="button" type="button" mt={3}>
                    プレビュー表示にならないファイル
                  </HelpMessage>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody>
                    <Text fontSize="sm">
                      jpeg,jpg,png,gifの形式のファイルのみプレビュー表示され、それ以外はダウンロード形式になります。
                    </Text>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            )}
          </Box>
        </Grid>
      </Stack>
    </>
  );
};
