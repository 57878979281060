import {
  Box,
  CircularProgress,
  SlideFade,
  Stack,
  Text,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useState } from "react";
import { postPath } from "../../../../../../routes";
import useRequest from "../../../lib/useRequest";
import QuestionResult from "./QuestionResult";
import Votes from "./Votes";
import { PostsShow, SharedCurrentUser } from "../../../lib/types";

const Questionnaire = ({
  currentUser,
  questionnaire: propQuestionnaire,
}: {
  currentUser: SharedCurrentUser;
  questionnaire: NonNullable<PostsShow["questionnaire"]>;
}) => {
  const request = useRequest();

  const [questionnaire, setQuestionnaire] = useState(propQuestionnaire);
  const [questionnaireLoading, setQuestionnaireLoading] = useState(false);

  const fetchQuestionnaire = async () => {
    setQuestionnaireLoading(true);
    const res = await request(postPath(questionnaire.post_code), "GET");
    setQuestionnaire((await res.json()).questionnaire);
    setQuestionnaireLoading(false);
  };

  if (questionnaireLoading) {
    return (
      <CircularProgress
        isIndeterminate
        color="primary"
        mx="auto"
        display="block"
        w="fit-content"
      />
    );
  }

  return (
    <>
      <Text fontSize="xs" color="#6D787D" mt={4}>
        {questionnaire.total_votes_count}票・
        {questionnaire.voting_ended
          ? "最終結果"
          : `残り${dayjs(questionnaire.voting_ended_at).toNow(true)}`}
      </Text>
      <Box mt={2}>
        {questionnaire.aggregate == null ? (
          <Votes
            questionnaire={questionnaire}
            currentUser={currentUser}
            beforeVote={() => setQuestionnaireLoading(true)}
            onVote={async () => {
              await fetchQuestionnaire();
              setQuestionnaireLoading(false);
            }}
          />
        ) : (
          <SlideFade in>
            <Box>
              <Stack gap={2}>
                {questionnaire.questions.map((question) => (
                  <QuestionResult
                    key={question.code}
                    ratio={
                      questionnaire.total_votes_count === 0
                        ? 0
                        : Math.floor(
                          (questionnaire.aggregate![question.code] /
                            questionnaire.total_votes_count) *
                          100,
                        )
                    }
                    selected={question.code == questionnaire.voted_to_code}
                  >
                    {question.content}
                  </QuestionResult>
                ))}
              </Stack>
            </Box>
          </SlideFade>
        )}
      </Box>
    </>
  );
};

export default Questionnaire;
