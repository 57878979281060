import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const CheckCircleIcon = (props: IconProps) => (
  <Icon
    width="20px"
    height="21px"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.83268 14.3332L14.7077 8.45817L13.541 7.2915L8.83268 11.9998L6.45768 9.62484L5.29102 10.7915L8.83268 14.3332ZM9.99935 18.8332C8.84657 18.8332 7.76324 18.6144 6.74935 18.1769C5.73546 17.7394 4.85352 17.1457 4.10352 16.3957C3.35352 15.6457 2.75977 14.7637 2.32227 13.7498C1.88477 12.7359 1.66602 11.6526 1.66602 10.4998C1.66602 9.34706 1.88477 8.26373 2.32227 7.24984C2.75977 6.23595 3.35352 5.354 4.10352 4.604C4.85352 3.854 5.73546 3.26025 6.74935 2.82275C7.76324 2.38525 8.84657 2.1665 9.99935 2.1665C11.1521 2.1665 12.2355 2.38525 13.2493 2.82275C14.2632 3.26025 15.1452 3.854 15.8952 4.604C16.6452 5.354 17.2389 6.23595 17.6764 7.24984C18.1139 8.26373 18.3327 9.34706 18.3327 10.4998C18.3327 11.6526 18.1139 12.7359 17.6764 13.7498C17.2389 14.7637 16.6452 15.6457 15.8952 16.3957C15.1452 17.1457 14.2632 17.7394 13.2493 18.1769C12.2355 18.6144 11.1521 18.8332 9.99935 18.8332Z"
      fill="#41AA92"
    />
  </Icon>
);

export default CheckCircleIcon;
