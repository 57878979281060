import React from "react";
import { Pagy } from "../../../../shared/lib/types";
import Pagination from "./Pagination";

const PagyPagination = ({ pagy }: { pagy: Pagy }) => {
  return (
    <Pagination
      pageCount={pagy.pages}
      currentPage={pagy.page}
      onClickPage={(page) => {
        const params = new URLSearchParams(location.search);
        params.set("page", page.toString());
        location.search = params.toString();
      }}
    />
  );
};

export default PagyPagination;
