import { AvatarProps } from "@chakra-ui/react";
import React from "react";
import {
  HelperAnonymousPostResourceAuthor,
  HelperPostResourceAuthor,
} from "../../lib/types";
import UserAvatar from "./UserAvatar";

export type PostResourceAvatarProps = {
  author:
    | HelperPostResourceAuthor
    | Omit<
        HelperAnonymousPostResourceAuthor,
        | "age"
        | "architect_license"
        | "prefecture"
        | "is_profile_visitable"
        | "is_guest"
        | "architect_type"
      >;
} & AvatarProps;

const PostResourceAvatar = ({ author, ...props }: PostResourceAvatarProps) => {
  return (
    <UserAvatar
      name={author.display_name}
      bgColor={author.avatar_bgcolor}
      src={author.display_image_url}
      {...props}
    />
  );
};

export default PostResourceAvatar;
