import {
  Box,
  Container,
  Flex,
  HStack,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import "cropperjs/dist/cropper.css";
import React, { useCallback, useState } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import * as yup from "yup";
import {
  doneUserPersonalInfoApplicationPath,
  userPersonalInfoApplicationPath,
  validateUserPersonalInfoApplicationPath,
} from "../../../routes";
import { Flash } from "../../shared/lib/types";
import { uploadFiles } from "../../shared/lib/uploadFile";
import { Button } from "../shared/components/atoms";
import ArchitectLicenseImageField from "../shared/components/atoms/ArchitectLicenseImageField";
import Background from "../shared/components/atoms/Background";
import FixedBackgroundBuildingImage from "../shared/components/atoms/FixedBackgroundBuildingImage";
import InfoMessage from "../shared/components/atoms/InfoMessage";
import SectionTitle from "../shared/components/atoms/SectionTitle";
import ShadowCard from "../shared/components/atoms/ShadowCard";
import SiteSeal from "../shared/components/atoms/SiteSeal";
import {
  FormLabel,
  Input,
  InputError,
  Radio,
} from "../shared/components/atoms/form";
import Select from "../shared/components/atoms/form/Select";
import Textarea from "../shared/components/atoms/form/Textarea";
import AddIcon from "../shared/components/icons/AddIcon";
import Application from "../shared/components/layouts/Application";
import {
  Prefecture,
  SharedArchitectLicenses,
  SharedIndustries,
  SharedUserPositions,
  UserUserPersonalInfoApplicationsTemporaryUserInput,
} from "../shared/lib/types";
import useAutokana from "../shared/lib/useAutokana";
import useFileState from "../shared/lib/useFileState";
import useRequest from "../shared/lib/useRequest";
import { LogoSVG } from "../svg";
import BirthdaySelect from "../user/shared/components/BirthdaySelect";
import Confirmation from "./components/Confirmation";
import SampleModalButton from "../user/shared/components/SampleModalButton";

const schema = yup.object({
  family_name: yup.string().trim().required().label("姓"),
  given_name: yup.string().trim().required().label("名"),
  family_name_kana: yup
    .string()
    .required()
    .hiragana()
    .trim()
    .label("ふりがな姓"),
  given_name_kana: yup
    .string()
    .required()
    .hiragana()
    .trim()
    .label("ふりがな名"),
  architect_license_id: yup
    .string()
    .trim()
    .required()
    .label("取得している建築士資格"),
  architect_number: yup
    .string()
    .trim()
    .required()
    .matches(/^\d+$/, "半角数字で入力してください")
    .label("建築士番号"),
  is_same_name: yup.string().trim().required().label("ご登録氏名について"),
  different_name_reason: yup
    .string()
    .trim()
    .when("is_same_name", {
      is: "no",
      then: (schema) => schema.required(),
    })
    .label("ご登録の氏名と建築士証のお名前が異なる理由"),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  nickname: yup.string().trim().required().nickname().label("ニックネーム"),
  birthday: yup.string().trim().required().label("生年月日"),
  prefecture_id: yup.string().trim().required().label("居住地"),
  industry_id: yup.string().trim().required().label("業種"),
  user_position_id: yup.string().trim().required().label("ポジション"),
  architect_type_id: yup.string().trim().required().label("職種"),
});

type FormData = yup.InferType<typeof schema>;

const UserPersonalInfoApplicationsNew = ({
  architectLicenses,
  temporaryUserInput,
  flash,
  prefectures,
  industries,
  userPositions,
}: {
  architectLicenses: SharedArchitectLicenses;
  temporaryUserInput: UserUserPersonalInfoApplicationsTemporaryUserInput;
  flash: Flash;
  prefectures: Prefecture[];
  industries: SharedIndustries;
  userPositions: SharedUserPositions;
}) => {
  const methods = useUserPersonalInfoApplicationForm({ temporaryUserInput });

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
  } = methods;

  const architectLicenseImageFile = useFileState({ required: true });
  const architectLicenseModalDisclosure = useDisclosure();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const onConfirm = async () => {
    if ((await validate({ shouldFocus: true })) === false) {
      return;
    }

    setShowConfirmation(true);
    window.scrollTo(0, 0);
  };

  const request = useRequest();

  const onSubmit = async (data: FormData) => {
    const results = await uploadFiles({
      files: [architectLicenseImageFile.file!],
    });
    const res = await request(userPersonalInfoApplicationPath(), "POST", {
      user_personal_info_application: {
        ...data,
        architect_license_image: results[0].blob.signed_id,
      },
    });

    if (res.ok) {
      location.href = doneUserPersonalInfoApplicationPath();
    }
  };

  const requestValidate = useCallback(async () => {
    const res = await request(
      validateUserPersonalInfoApplicationPath(),
      "POST",
      getValues(),
    );
    return res.json();
  }, [getValues, request]);

  const validator = {
    validateArchitectNumber: useCallback(
      async ({ shouldFocus = false } = {}) => {
        let isSuccess = true;
        await requestValidate().then((json) => {
          if (json["architect_number"] != null) {
            isSuccess = false;
            json["architect_number"]?.forEach((message: string) => {
              setError(
                "architect_number",
                {
                  type: "custom",
                  message,
                },
                { shouldFocus },
              );
            });
          } else {
            clearErrors("architect_number");
          }
        });
        return isSuccess;
      },
      [clearErrors, requestValidate, setError],
    ),
    validateFiles: useCallback(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ shouldFocus = false } = {}) => {
        let result = true;
        result = architectLicenseImageFile.validate() && result;
        return result;
      },
      [architectLicenseImageFile],
    ),
  };

  const validate = async ({ shouldFocus = false } = {}) => {
    let isSuccess = true;
    isSuccess = validator.validateFiles({ shouldFocus }) && isSuccess;
    isSuccess =
      (await validator.validateArchitectNumber({ shouldFocus })) && isSuccess;
    return isSuccess;
  };

  const debounceValidateArchitectNumber = useDebouncedCallback(
    validator.validateArchitectNumber,
    500,
  );
  const familyNameAutokana = useAutokana("#family_name", "#family_name_kana");
  const givenNameAutokana = useAutokana("#given_name", "#given_name_kana");

  const inputMaxW = "326px";

  return (
    <Application flash={flash} disableFloatingRegisterButton>
      <FixedBackgroundBuildingImage />
      <Background>
        <Container maxW="2xl" mt={{ base: 6, lg: 20 }} zIndex={1}>
          <ShadowCard>
            {showConfirmation && (
              <Confirmation
                values={getValues()}
                onClickFix={() => setShowConfirmation(false)}
                architectLicenses={architectLicenses}
                architectLicenseImagePreview={
                  architectLicenseImageFile.preview!
                }
                temporaryUserInput={temporaryUserInput}
                prefectures={prefectures}
                industries={industries}
                userPositions={userPositions}
                isSubmitting={methods.formState.isSubmitting}
                onSubmit={methods.handleSubmit(onSubmit)}
              />
            )}
            <FormProvider {...methods}>
              <Box
                display={showConfirmation ? "none" : "block"}
                as="form"
                onSubmit={handleSubmit(onConfirm, () =>
                  validate({ shouldFocus: true }),
                )}
              >
                <Flex direction="column">
                  <Flex justify="center">
                    <Image src={LogoSVG} width={32} />
                  </Flex>
                  <Heading size="lg" mt={{ base: 6, md: 8 }} textAlign="center">
                    本人確認登録
                  </Heading>
                  <Flex
                    mt={8}
                    bg="brand.50"
                    borderRadius={2}
                    px={3}
                    py={2}
                    align="center"
                    gap={2}
                  >
                    <Text pt={1} fontSize="xs">
                      本人確認でいただいた情報を元に実在する建築士かを審査します。
                      <br />
                      審査完了後、A-Loopのすべてのサービスがご利用いただけます。
                    </Text>
                  </Flex>
                  {temporaryUserInput.nickname == null && (
                    <>
                      <SectionTitle mt={8}>公開情報</SectionTitle>
                      <Stack gap={7} mt={7}>
                        <Controller
                          name="nickname"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <Input
                              {...field}
                              label="ニックネーム"
                              maxW={inputMaxW}
                              required
                              error={error?.message}
                              message={
                                <>
                                  使用できる文字は、ひらがな、カタカナ、漢字、数字、アルファベットです。{" "}
                                  <br />
                                  15文字以内で登録してください。 <br />
                                  Q&A投稿時に匿名での投稿を選択した場合に表示されます。
                                </>
                              }
                            />
                          )}
                        />
                      </Stack>
                    </>
                  )}

                  {[
                    temporaryUserInput.birthday,
                    temporaryUserInput.industry_id,
                    temporaryUserInput.prefecture_id,
                    temporaryUserInput.user_position_id,
                  ].some((value) => value == null) && (
                    <>
                      <SectionTitle mt={8}>非公開情報</SectionTitle>
                      <Stack gap={7} mt={7}>
                        {temporaryUserInput.birthday == null && (
                          <Box>
                            <FormLabel required>生年月日</FormLabel>
                            <Controller
                              name="birthday"
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <BirthdaySelect
                                  value={value}
                                  onChange={(date) => {
                                    onChange(date);
                                  }}
                                  error={error?.message}
                                />
                              )}
                            />
                            <Text fontSize="xs" color="#6D787D" mt={1}>
                              プロフィールには年齢のみ公開されます
                            </Text>
                          </Box>
                        )}
                        {temporaryUserInput.prefecture_id == null && (
                          <Controller
                            name="prefecture_id"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <Select
                                {...field}
                                label="居住地"
                                required
                                placeholder="居住地を選択してください"
                                error={error?.message}
                                maxW={inputMaxW}
                              >
                                {prefectures.map(({ id, name }) => (
                                  <option value={id} key={id}>
                                    {name}
                                  </option>
                                ))}
                              </Select>
                            )}
                          />
                        )}

                        {temporaryUserInput.industry_id == null && (
                          <Controller
                            name="industry_id"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <Select
                                {...field}
                                label="業種"
                                required
                                placeholder="業種を選択してください"
                                error={error?.message}
                                maxW={inputMaxW}
                              >
                                {industries.map(({ id, name }) => (
                                  <option value={id} key={id}>
                                    {name}
                                  </option>
                                ))}
                              </Select>
                            )}
                          />
                        )}

                        {temporaryUserInput.user_position_id == null && (
                          <Controller
                            name="user_position_id"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <Select
                                {...field}
                                label="ポジション"
                                required
                                placeholder="ポジションを選択してください"
                                error={error?.message}
                                maxW={inputMaxW}
                              >
                                {userPositions.map(({ id, name }) => (
                                  <option value={id} key={id}>
                                    {name}
                                  </option>
                                ))}
                              </Select>
                            )}
                          />
                        )}
                      </Stack>
                    </>
                  )}

                  <SectionTitle mt={8}>ご本人情報</SectionTitle>
                  <Stack gap={7} mt={7}>
                    <Stack gap={1}>
                      <FormLabel required>氏名</FormLabel>
                      <Stack
                        gap={{ base: 2, sm: 6 }}
                        direction={{ base: "column", sm: "row" }}
                      >
                        <HStack gap={4}>
                          <FormLabel htmlFor="family_name">姓</FormLabel>
                          <Controller
                            name="family_name"
                            control={control}
                            render={({ field, fieldState }) => (
                              <Input
                                required
                                error={fieldState.error?.message ?? ""}
                                {...field}
                                onInput={() => {
                                  setValue(
                                    "family_name_kana",
                                    familyNameAutokana?.getFurigana(),
                                  );
                                }}
                                id="family_name"
                              />
                            )}
                          />
                        </HStack>
                        <HStack gap={4}>
                          <FormLabel htmlFor="given_name">名</FormLabel>
                          <Controller
                            name="given_name"
                            control={control}
                            render={({ field, fieldState }) => (
                              <Input
                                required
                                error={fieldState.error?.message ?? ""}
                                {...field}
                                onInput={() => {
                                  setValue(
                                    "given_name_kana",
                                    givenNameAutokana?.getFurigana(),
                                  );
                                }}
                                id="given_name"
                              />
                            )}
                          />
                        </HStack>
                      </Stack>
                    </Stack>
                    <Stack gap={1}>
                      <FormLabel required>氏名ふりがな</FormLabel>
                      <Stack
                        gap={{ base: 2, sm: 6 }}
                        direction={{ base: "column", sm: "row" }}
                      >
                        <HStack gap={4}>
                          <FormLabel htmlFor="family_name_kana">姓</FormLabel>
                          <Controller
                            name="family_name_kana"
                            control={control}
                            render={({ field, fieldState }) => (
                              <Input
                                required
                                error={fieldState.error?.message ?? ""}
                                {...field}
                                id="family_name_kana"
                              />
                            )}
                          />
                        </HStack>
                        <HStack gap={4}>
                          <FormLabel htmlFor="given_name_kana">名</FormLabel>
                          <Controller
                            name="given_name_kana"
                            control={control}
                            render={({ field, fieldState }) => (
                              <Input
                                required
                                error={fieldState.error?.message ?? ""}
                                {...field}
                                id="given_name_kana"
                              />
                            )}
                          />
                        </HStack>
                      </Stack>
                    </Stack>
                  </Stack>
                  <SectionTitle mt={14}>建築士免許証明書 情報</SectionTitle>
                  <Stack gap={7} mt={7}>
                    <Stack gap={2}>
                      <FormLabel required>取得している建築士資格</FormLabel>
                      <Flex gap={4} align="center">
                        <Text fontSize="xl">
                          {
                            architectLicenses.find(
                              (architectLicense) =>
                                architectLicense.id.toString() ===
                                watch("architect_license_id"),
                            )?.name
                          }
                        </Text>
                        <Text
                          as="a"
                          fontSize="sm"
                          textDecoration="underline"
                          _hover={{ textDecoration: "none" }}
                          color="textLink"
                          cursor="pointer"
                          onClick={architectLicenseModalDisclosure.onOpen}
                        >
                          変更する
                        </Text>
                        {errors.architect_license_id?.message !== undefined && (
                          <InputError>
                            {errors.architect_license_id?.message?.toString()}
                          </InputError>
                        )}
                      </Flex>
                    </Stack>
                    <Box fontSize="sm">
                      <Box
                        fontWeight="bold"
                        textAlign="center"
                        py={2}
                        bgColor="primary"
                        color="white"
                      >
                        本人確認書類について
                      </Box>
                      <Box border="1px solid #3CAA91" py={3} px={3}>
                        以下の項目は、本人確認のために提出いただいております。{" "}
                        <br />
                        本人確認にのみ使用し、建築士免許証明書の情報は公開されません。
                      </Box>
                    </Box>
                    <Box>
                      <Controller
                        name="architect_number"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Input
                            required
                            label="建築士番号"
                            error={fieldState.error?.message ?? ""}
                            maxW={inputMaxW}
                            {...field}
                            onChange={async (e) => {
                              field.onChange(e);
                              await debounceValidateArchitectNumber();
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Stack gap={0}>
                      <FormLabel required>建築士免許証明書 画像</FormLabel>
                      <SampleModalButton />
                      <Box mt={2}>
                        <ArchitectLicenseImageField
                          fileState={architectLicenseImageFile}
                        />
                      </Box>
                      <InfoMessage mt={2}>
                        アップロードする建築士免許証明書（表面）の写真は、内容がはっきりと読み取れる写真をアップロードしてください。裏面の写真は不要です。
                      </InfoMessage>
                    </Stack>
                    <Box>
                      <IsSameNameField />
                    </Box>
                  </Stack>
                </Flex>
                <Flex justify="center" mt={8}>
                  <Button type="submit" isLoading={false} isDisabled={false}>
                    入力内容確認へ進む
                  </Button>
                </Flex>
                <Box mt={14}>
                  <SiteSeal />
                </Box>
              </Box>
            </FormProvider>
          </ShadowCard>
        </Container>
      </Background>

      <Modal
        isOpen={architectLicenseModalDisclosure.isOpen}
        onClose={architectLicenseModalDisclosure.onClose}
      >
        <ModalOverlay />
        <ModalContent py={10} px={12} pb={12}>
          <ModalHeader p={0} fontWeight="700">
            取得している建築士資格
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody py={8} pb={0}>
            <Box>
              <Controller
                name="architect_license_id"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    mt={2}
                    gap={4}
                    display="flex"
                    flexDirection="column"
                    {...field}
                  >
                    {architectLicenses.map(({ id, name }) => (
                      <Radio value={id.toString()} key={id}>
                        {name}
                      </Radio>
                    ))}
                  </RadioGroup>
                )}
              />
            </Box>
          </ModalBody>
          <ModalFooter p={0} mt={6}>
            <Button
              type="button"
              onClick={architectLicenseModalDisclosure.onClose}
              mx="auto"
              leftIcon={<AddIcon />}
            >
              変更
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Application>
  );
};

const IsSameNameField = () => {
  const { control, watch, setValue } = useFormContext();

  const isSameName = watch("is_same_name");

  return (
    <>
      <FormLabel required>ご登録氏名について</FormLabel>
      <Text fontSize={{ base: "xs", sm: "sm" }} mt={1}>
        ご登録の氏名と建築士免許証明書に記載されている名前は同一ですか？
      </Text>
      <Controller
        name="is_same_name"
        control={control}
        render={({ field, fieldState }) => (
          <>
            <RadioGroup
              mt={2}
              gap={4}
              display="flex"
              {...field}
              onChange={(value) => {
                field.onChange(value);
                if (value === "yes") {
                  setValue("different_name_reason", "");
                }
              }}
            >
              {[
                { value: "yes", label: "はい" },
                { value: "no", label: "いいえ" },
              ].map(({ value, label }) => (
                <Radio value={value} key={value}>
                  {label}
                </Radio>
              ))}
            </RadioGroup>
            {fieldState.error?.message !== undefined && (
              <InputError>{fieldState.error?.message}</InputError>
            )}
          </>
        )}
      />
      {isSameName === "no" && (
        <Box
          mt={4}
          p={4}
          bgColor="brand.50"
          borderRadius="2px"
          border="1px solid rgba(99, 99, 99, 0.30);"
        >
          <Controller
            name="different_name_reason"
            control={control}
            render={({ field, fieldState }) => (
              <Textarea
                label="ご登録の氏名と建築士証のお名前が異なる理由"
                required
                placeholder="例：婚姻により氏名が変わったため"
                error={fieldState.error?.message ?? ""}
                {...field}
              />
            )}
          />
        </Box>
      )}
    </>
  );
};

const useUserPersonalInfoApplicationForm = ({
  temporaryUserInput,
}: {
  temporaryUserInput: UserUserPersonalInfoApplicationsTemporaryUserInput;
}) => {
  return useForm<FormData>({
    defaultValues: {
      family_name: "",
      given_name: "",
      family_name_kana: "",
      given_name_kana: "",
      architect_license_id: temporaryUserInput.architect_license_id.toString(),
      architect_number: "",
      is_same_name: "",
      different_name_reason: "",
      nickname: temporaryUserInput.nickname,
      birthday: temporaryUserInput.birthday,
      prefecture_id: temporaryUserInput.prefecture_id,
      industry_id: temporaryUserInput.industry_id,
      user_position_id: temporaryUserInput.user_position_id,
      architect_type_id: temporaryUserInput.architect_type_id,
    },
    resolver: yupResolver(schema),
  });
};

export default UserPersonalInfoApplicationsNew;
