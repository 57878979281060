import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const ChevronLeftIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" boxSize={5} {...props}>
    <path
      id="Vector"
      d="M13.0875 6.175L11.9125 5L6.91248 10L11.9125 15L13.0875 13.825L9.27081 10L13.0875 6.175Z"
      fill="currentColor"
    />
  </Icon>
);

export default ChevronLeftIcon;
