import { Stack } from "@chakra-ui/react";
import React from "react";
import Comment from "../../../posts/components/Comment";

const TemporaryComments = () => {
  return (
    <Stack gap={5} mt={{ base: 6, sm: 10 }}>
      {[
        {
          code: "1",
          author: {
            code: "1",
            display_image_url: "",
            display_name: "高橋憲太郎",
            avatar_bgcolor: "",
            anonymous: false,
            is_profile_visitable: false,
            is_guest: false,
          } as {
            code: string;
            display_image_url: string;
            display_name: string;
            avatar_bgcolor: "",
            anonymous: false;
            is_profile_visitable: false,
            is_guest: false,
          },
          content:
            "本人確認が完了すると、コメントを閲覧したり発言をすることが可能になります",
          files_without_image: [],
          image_files: [],
          created_at: "",
          updated_at: "",
          is_own: false,
          replies: [],
          replies_count: 0,
          useful_count: 0,
          is_marked_useful: false,
        },
        {
          code: "2",
          author: {
            code: "2",
            display_image_url: "",
            display_name: "田中美和",
            avatar_bgcolor: "",
            anonymous: false,
            is_profile_visitable: false,
            is_guest: false,
          } as {
            code: string;
            display_image_url: string;
            display_name: string;
            avatar_bgcolor: "",
            anonymous: false;
            is_profile_visitable: false,
            is_guest: false,
          },
          content: "本人確認の申請をする",
          files_without_image: [],
          image_files: [],
          created_at: "",
          updated_at: "",
          is_own: false,
          replies: [],
          replies_count: 0,
          useful_count: 0,
          is_marked_useful: false,
        },
        {
          code: "3",
          author: {
            code: "3",
            display_image_url: "",
            display_name: "山本健太",
            avatar_bgcolor: "",
            anonymous: false,
            is_profile_visitable: false,
            is_guest: false,
          } as {
            code: string;
            display_image_url: string;
            display_name: string;
            avatar_bgcolor: "",
            anonymous: false;
            is_profile_visitable: false,
            is_guest: false,
          },
          content:
            "A-Loopは、建築士の方が利用いただけるコミュニティサービスです。",
          files_without_image: [],
          image_files: [],
          created_at: "",
          updated_at: "",
          is_own: false,
          replies: [],
          replies_count: 0,
          useful_count: 0,
          is_marked_useful: false,
        },
      ].map((comment) => (
        <Comment
          key={comment.code}
          comment={comment}
          currentUser={null}
          post={{
            code: "1",
            title: "",
            content: "",
            comments_count: 0,
            created_at: "",
            updated_at: "",
            questionnaire_accepting: false,
            tags: [],
            author: {
              code: "1",
              display_image_url: "",
              display_name: "",
              avatar_bgcolor: "",
              anonymous: false,
              is_profile_visitable: false,
              is_guest: false,
            },
            is_own: false,
            is_bookmarked: false,
            is_resolved: false,
            previous_post_anonymous: "",
            image_files: [],
            files_without_image: [],
            questionnaire: {
              post_code: "1",
              voted_to_code: "",
              questions: [],
              aggregate: null,
              total_votes_count: 0,
              voting_ended_at: "",
              voting_ended: false,
              most_voted_code: "",
            },
          }}
        />
      ))}
    </Stack>
  );
};

export default TemporaryComments;
