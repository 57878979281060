import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { ReactNode, useCallback, useState } from "react";
import Dropzone from "./Dropzone";
import { Cropper } from "react-cropper";
import { InputError } from "./form";

export const useImageFileDropzoneState = ({
  firstPreview = "",
  required = false,
} = {}) => {
  const [isRequiredError, setIsRequiredError] = useState(false);
  const [isFilesizeError, setIsFilesizeError] = useState(false);
  const [file, setFile] = useState<File>();
  const [preview, setPreview] = useState<string>(firstPreview);
  const [isChange, setIsChange] = useState(false);

  const validateArg = useCallback(
    (arg: File | undefined) => {
      let isValid = true;
      if (required && arg === undefined && preview === "") {
        setIsRequiredError(true);
        isValid = false;
      } else {
        setIsRequiredError(false);
      }

      // 1024でないのは、MacのFinderの表示が1000だったため
      if (arg && arg.size / 1000 > 5000) {
        setIsFilesizeError(true);
        isValid = false;
      } else {
        setIsFilesizeError(false);
      }
      return isValid;
    },
    [required, setIsRequiredError, setIsFilesizeError, preview],
  );

  const validate = useCallback(() => validateArg(file), [file, validateArg]);

  const isError = isRequiredError || isFilesizeError;

  const setFileAndPreview = useCallback(
    (file: File, preview: string) => {
      validateArg(file);
      setFile(file);
      setPreview(preview);
      setIsChange(true);
    },
    [validateArg, setFile, setPreview],
  );

  const unsetFileAndPreview = useCallback(() => {
    validateArg(undefined);
    setFile(undefined);
    setPreview("");
    setIsChange(true);
  }, [validateArg, setFile, setPreview]);

  return {
    isError,
    file,
    validate,
    preview,
    isChange,
    _private: {
      isRequiredError,
      isFilesizeError,
      setIsFilesizeError,
      setFileAndPreview,
      unsetFileAndPreview,
    },
  };
};

export const ImageFileDropzone = ({ croppable = false, ...props }) => {
  if (croppable) {
    return (
      <CroppableImageFileDropzone
        imageFileDropzoneState={props.imageFileDropzoneState}
        {...props}
      />
    );
  } else {
    return (
      <NormalImageFileDropzone
        imageFileDropzoneState={props.imageFileDropzoneState}
        {...props}
      />
    );
  }
};

const NormalImageFileDropzone = ({
  onChange = undefined,
  label = "",
  name = "",
  imageFileDropzoneState,
  showDeleteButton = true,
  previewBoxProps = {},
  additionalMessage = "",
}: {
    onChange?: (file: File | undefined, result: string) => void;
    label?: string;
    name?: string;
    imageFileDropzoneState: ReturnType<typeof useImageFileDropzoneState>;
    showDeleteButton?: boolean;
    previewBoxProps?: Record<string, unknown>;
    additionalMessage?: ReactNode;
  }) => {
  const {
    preview,
    _private: {
      isRequiredError,
      isFilesizeError,
      setIsFilesizeError,
      setFileAndPreview,
      unsetFileAndPreview,
    },
  } = imageFileDropzoneState;

  const onDrop = useCallback(
    (file: File, result: string) => {
      setFileAndPreview(file, result);
      if (onChange !== undefined) {
        onChange(file, result);
      }
    },
    [onChange, setFileAndPreview],
  );

  return (
    <PreviewOrDropzone
      {...{
        preview,
        isFilesizeError,
        showDeleteButton,
        isRequiredError,
        setIsFilesizeError,
        unsetFileAndPreview,
        onChange,
        onDrop,
        name,
        label,
        previewBoxProps,
        additionalMessage
      }}
    />
  );
};

export const CroppableImageFileDropzone = ({
  onChange = undefined,
  label = "",
  name = "",
  imageFileDropzoneState,
  showDeleteButton = true,
  aspectRatio = 1 / 1,
  rounded = false,
  previewBoxProps = {},
  additionalMessage = "",
}: {
    onChange?: (file: File | undefined, result: string) => void;
    label?: string;
    name?: string;
    imageFileDropzoneState: ReturnType<typeof useImageFileDropzoneState>;
    showDeleteButton?: boolean;
    aspectRatio?: number;
    rounded?: boolean;
    previewBoxProps?: Record<string, unknown>;
    additionalMessage?: ReactNode;
  }) => {
  const {
    preview,
    _private: {
      isRequiredError,
      isFilesizeError,
      setIsFilesizeError,
      setFileAndPreview,
      unsetFileAndPreview,
    },
  } = imageFileDropzoneState;

  const [open, setOpen] = useState<boolean>(false);
  const [cropper, setCropper] = useState<Cropper>();
  const [inputImage, setInputImage] = useState<string>("");
  const [fileType, setFileType] = useState("");

  const onDrop = useCallback((file: File, result: string) => {
    setInputImage(result);
    setOpen(true);
    setFileType(file.type);
  }, []);

  // https://github.com/fengyuanchen/cropperjs/blob/main/docs/examples/crop-a-round-image.html
  const getRoundedCanvas = (sourceCanvas: HTMLCanvasElement) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d")!;
    const width = sourceCanvas.width;
    const height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    context.fillStyle = "white";
    context.fillRect(0, 0, width, height);
    context.imageSmoothingEnabled = true;
    context.beginPath();
    context.arc(
      width / 2,
      height / 2,
      Math.min(width, height) / 2,
      0,
      2 * Math.PI,
      true,
    );
    context.clip();
    context.drawImage(sourceCanvas, 0, 0, width, height);
    return canvas;
  };

  const getCropData = () => {
    if (cropper == null) {
      return;
    }

    const croppedCanvas = rounded
      ? getRoundedCanvas(cropper.getCroppedCanvas())
      : cropper.getCroppedCanvas();

    croppedCanvas.toBlob((blob) => {
      const file = new File([blob!], "croppedImage");
      setFileAndPreview(file, croppedCanvas.toDataURL(fileType));
      if (onChange != null) {
        onChange(file, croppedCanvas.toDataURL(fileType));
      }
    }, fileType);
    setOpen(false);
  };

  const onClickDeleteButton = () => {
    unsetFileAndPreview();
    if (onChange != null) {
      onChange(undefined, "");
    }
  };

  return (
    <>
      <PreviewOrDropzone
        {...{
          preview,
          isFilesizeError,
          showDeleteButton,
          onClickDeleteButton,
          isRequiredError,
          setIsFilesizeError,
          unsetFileAndPreview,
          onChange,
          onDrop,
          name,
          label,
          additionalMessage
        }}
        previewBoxProps={{
          ...previewBoxProps,
          borderRadius: rounded ? "full" : "none",
          overflow: "hidden",
        }}
      />
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`${label}を設定`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="image-cropper-modal">
              <Cropper
                id="image-cropper"
                style={{ height: 400, width: "100%" }}
                src={inputImage}
                initialAspectRatio={aspectRatio}
                aspectRatio={aspectRatio}
                className={rounded ? "rounded" : ""}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <button type="button" onClick={getCropData}>
              選択
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const PreviewOrDropzone = ({
  preview,
  isFilesizeError,
  showDeleteButton,
  isRequiredError,
  setIsFilesizeError,
  unsetFileAndPreview,
  onChange,
  onDrop,
  name,
  label,
  previewBoxProps,
  additionalMessage,
}: {
  preview: string;
  isFilesizeError: boolean;
  showDeleteButton: boolean;
  isRequiredError: boolean;
  setIsFilesizeError: (arg: boolean) => void;
  unsetFileAndPreview: () => void;
  onChange?: (file: File | undefined, result: string) => void;
  onDrop: (file: File, result: string) => void;
  name: string;
  label: string;
  previewBoxProps: Record<string, unknown>;
  additionalMessage: ReactNode;
}) => {
  const _onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0] as File;
      const result = URL.createObjectURL(file);
      onDrop(file, result);
    },
    [onDrop],
  );

  const onClickDeleteButton = () => {
    unsetFileAndPreview();
    if (onChange != null) {
      onChange(undefined, "");
    }
  };

  if (preview) {
    return (
      <>
        <Box className="image-preview" {...previewBoxProps}>
          <img width="100%" src={preview} alt="image-preview" />
          {isFilesizeError && (
            <Text fontSize="sm" color="caution">
              アップロードした画像が5MBを超えています
            </Text>
          )}
        </Box>
        {showDeleteButton && (
          <Text
            as="button"
            color="textLink"
            textDecoration="underline"
            _hover={{ textDecoration: "none" }}
            type="button"
            onClick={onClickDeleteButton}
            mt={2}
          >
            画像を変更
          </Text>
        )}
      </>
    );
  } else {
    return (
      <Box onClick={() => setIsFilesizeError(false)}>
        <Dropzone
          onDrop={_onDrop}
          accept={{
            "image/png": [],
            "image/jpeg": [],
          }}
          name={name}
        />
        <Text fontSize="xs" mt={1} color="#6D787D">
          {additionalMessage !== "" && <>{additionalMessage} <br /></>}
          PNGもしくはJPGファイルで、5MB未満のサイズでアップロードしてください。
        </Text>
        {isRequiredError && <InputError>{`${label}は必須です`}</InputError>}
      </Box>
    );
  }
};
