import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const ArrowRightIcon = (props: IconProps) => {
  return (
    <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
      <g id="Icon/arrow_right">
        <path
          id="Vector"
          d="M7.91669 14.1667L12.0834 10L7.91669 5.83337V14.1667Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
};

export default ArrowRightIcon;
