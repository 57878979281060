import { useDisclosure } from "@chakra-ui/react";
import React, { MouseEventHandler, ReactNode } from "react";
import { SharedCurrentUser } from "../../lib/types";
import RegistrationRequiredModal from "./RegistrationRequiredModal";

const RegistrationRequiredAction = ({
  currentUser,
  onClick,
  href,
  render,
}: {
  currentUser: SharedCurrentUser;
  onClick?: MouseEventHandler;
  href?: string;
  render: (props: { onClick: MouseEventHandler; href?: string }) => ReactNode;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const renderProps: { onClick: React.MouseEventHandler; href?: string } = {
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (currentUser?.is_all_public_feature_accessible) {
        if (onClick) {
          onClick(e);
        }
      } else {
        onOpen();
        e.preventDefault();
      }
    },
  };

  if (href && currentUser?.is_all_public_feature_accessible) {
    renderProps.href = href;
  }

  return (
    <>
      {render(renderProps)}
      <RegistrationRequiredModal
        isOpen={isOpen}
        onClose={onClose}
        currentUser={currentUser}
      />
    </>
  );
};

export default RegistrationRequiredAction;
