import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const BookmarkIcon = (props: IconProps) => (
  <Icon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 21V5C5 4.45 5.19583 3.97917 5.5875 3.5875C5.97917 3.19583 6.45 3 7 3H13V5H7V17.95L12 15.8L17 17.95V11H19V21L12 18L5 21ZM17 9V7H15V5H17V3H19V5H21V7H19V9H17Z"
      fill="#3CAA91"
    />
  </Icon>
);

export default BookmarkIcon;
