import {
  Stack,
  Select as ChakraSelect,
  SelectProps,
  forwardRef,
} from "@chakra-ui/react";
import React, { ReactNode, useId } from "react";
import FormLabel from "./FormLabel";
import InputError from "./InputError";

const Select = forwardRef<
  SelectProps & {
    label?: string;
    required?: boolean;
    error?: string;
    invalid?: boolean;
    id?: string;
    withLockIcon?: boolean;
    placeholder?: string;
    children: ReactNode;
  },
  "select"
>(
  (
    {
      label = "",
      required = false,
      error = "",
      invalid = undefined,
      id: propId = "",
      withLockIcon = false,
      placeholder = "",
      children,
      ...props
    },
    ref,
  ) => {
    const randomId = useId();
    const id = propId !== "" ? propId : randomId;

    return (
      <Stack width="100%" gap={0.5}>
        {label !== "" && (
          <FormLabel
            required={required}
            htmlFor={id}
            withLockIcon={withLockIcon}
          >
            {label}
          </FormLabel>
        )}
        <ChakraSelect
          placeholder={placeholder}
          borderRadius="2px"
          border="1px solid #99A9B0"
          errorBorderColor="#CD4429"
          fontSize={{ base: "sm", sm: "md" }}
          id={id}
          isInvalid={invalid === true ? true : error !== ""}
          {...props}
          ref={ref}
          bgColor="white"
        >
          {children}
        </ChakraSelect>
        {error !== "" && <InputError>{error}</InputError>}
      </Stack>
    );
  },
);

export default Select;
