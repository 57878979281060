import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const ScheduleIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" boxSize={5} {...props}>
    <path
      d="M11.4748 13.0257L12.5248 11.9757L9.74976 9.20073V5.75073H8.24976V9.80073L11.4748 13.0257ZM8.99976 17.0007C7.96226 17.0007 6.98726 16.8039 6.07476 16.4101C5.16226 16.0164 4.36851 15.482 3.69351 14.807C3.01851 14.132 2.48413 13.3382 2.09038 12.4257C1.69663 11.5132 1.49976 10.5382 1.49976 9.50073C1.49976 8.46323 1.69663 7.48823 2.09038 6.57573C2.48413 5.66323 3.01851 4.86948 3.69351 4.19448C4.36851 3.51948 5.16226 2.98511 6.07476 2.59136C6.98726 2.19761 7.96226 2.00073 8.99976 2.00073C10.0373 2.00073 11.0123 2.19761 11.9248 2.59136C12.8373 2.98511 13.631 3.51948 14.306 4.19448C14.981 4.86948 15.5154 5.66323 15.9091 6.57573C16.3029 7.48823 16.4998 8.46323 16.4998 9.50073C16.4998 10.5382 16.3029 11.5132 15.9091 12.4257C15.5154 13.3382 14.981 14.132 14.306 14.807C13.631 15.482 12.8373 16.0164 11.9248 16.4101C11.0123 16.8039 10.0373 17.0007 8.99976 17.0007ZM8.99976 15.5007C10.6623 15.5007 12.0779 14.9164 13.2466 13.7476C14.4154 12.5789 14.9998 11.1632 14.9998 9.50073C14.9998 7.83823 14.4154 6.42261 13.2466 5.25386C12.0779 4.08511 10.6623 3.50073 8.99976 3.50073C7.33726 3.50073 5.92163 4.08511 4.75288 5.25386C3.58413 6.42261 2.99976 7.83823 2.99976 9.50073C2.99976 11.1632 3.58413 12.5789 4.75288 13.7476C5.92163 14.9164 7.33726 15.5007 8.99976 15.5007Z"
      fill="currentColor"
    />
  </Icon>
);

export default ScheduleIcon;
