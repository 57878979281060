import { Box, BoxProps, List, ListItem } from "@chakra-ui/react";
import React from "react";

const EmailNotReceivedMessage = (props: BoxProps) => {
  return (
    <Box backgroundColor="#EDF5F3" py={6} px={{ base: 5, lg: 10 }} {...props}>
      <Box as="span" fontWeight="bold">
        メールが届かない場合は…
      </Box>
      <List mt={2} fontSize="sm">
        <ListItem>
          ・迷惑メールのフォルダに振り分けられていないかご確認ください。
        </ListItem>
        <ListItem>・URL付きのメールを受信許可に設定してください。</ListItem>
        <ListItem>
          ・ドメイン「aloop.jp」を受信許可に設定してください。
        </ListItem>
        <ListItem>・メールボックスの保存容量をご確認ください。</ListItem>
        <ListItem>
          ・セキュリティソフト、ウイルス対策ソフトの設定をご確認ください。
        </ListItem>
        <ListItem>
          ・正しいメールアドレスでない可能性があります。お手数ですが再度最初から登録をお願いいたします。
        </ListItem>
      </List>
    </Box>
  );
};

export default EmailNotReceivedMessage;
