import React, { useEffect, useState } from "react";
import {
  Box,
  CheckboxGroup,
  Flex,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FormLabel, Input } from "../../shared/components/atoms/form";
import Checkbox from "../../shared/components/atoms/form/Checkbox";
import Button from "../../shared/components/atoms/Button";
import { Controller, useForm } from "react-hook-form";
import SearchIcon from "../../shared/components/icons/SearchIcon";
import ArrowDropUpIcon from "../../shared/components/icons/ArrowDropUpIcon";
import ArrowDropDownIcon from "../../shared/components/icons/ArrowDropDownIcon";
import CurrentSearchCondition from "./CurrentSearchCondition";

type SearchFormProps = {
  tags: Array<{ id: number; name: string }>;
};

const SearchForm = ({ tags }: SearchFormProps) => {
  const { control, handleSubmit, setValue, watch } = useForm<{
    keyword: string;
    tags: string[];
    only_bookmarked: string;
  }>({
    defaultValues: {
      keyword: "",
      tags: [],
      only_bookmarked: "0",
    },
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setValue("keyword", params.get("q[title_or_content]") ?? "");
    setValue("tags", params.getAll("q[tags_id_eq_any][]") ?? []);
    setValue("only_bookmarked", params.get("only_bookmarked") ?? "0");
  }, [setValue]);

  const onSubmit = (data: {
    keyword: string;
    tags: string[];
    only_bookmarked: string;
  }) => {
    const url = new URL(location.href)
    url.searchParams.delete("q[title_or_content]")
    url.searchParams.delete("q[tags_id_eq_any][]")
    url.searchParams.delete("only_bookmarked")
    url.searchParams.delete("search")
    url.searchParams.delete("page")

    url.searchParams.append("q[title_or_content]", data.keyword)
    url.searchParams.append("only_bookmarked", data.only_bookmarked)

    data.tags.forEach((tag) => {
      url.searchParams.append("q[tags_id_eq_any][]", tag)
    });
    url.searchParams.append("search", "1")

    location.href = url.toString();
  };

  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const [watchTags, watchOnlyBookmarked] = watch(["tags", "only_bookmarked"]);

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="keyword"
        control={control}
        render={({ field }) => (
          <Input
            placeholder="気になるキーワードを入力"
            borderRadius={4}
            h={12}
            rightElement={() => (
              <InputRightElement h="full">
                <Box w="full" h="full" py={2} mr={2}>
                  <Button px={2} py={1.5} w="full" h="full" type="submit" aria-label="検索する">
                    <SearchIcon />
                  </Button>
                </Box>
              </InputRightElement>
            )}
            {...field}
          />
        )}
      />
      <Button
        mt={3}
        variant="outline"
        fontSize="xs"
        leftIcon={isDetailOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        iconSpacing={0.5}
        borderRadius={2}
        py={1}
        pl={1}
        pr={2}
        onClick={() => setIsDetailOpen((prev) => !prev)}
      >
        <Box as="span" pt={0.5}>
          詳細検索
        </Box>
      </Button>
      {isDetailOpen && (
        <Box
          backgroundColor="#FFF"
          px={5}
          pt={4}
          pb={5}
          border="1px solid #99A9B0"
          borderRadius={4}
          mt={3}
        >
          <Stack gap={6}>
            <Flex justifyContent={"space-between"}>
              <Text fontWeight={"bold"}>詳細検索</Text>
              <Box
                as="button"
                fontSize="sm"
                color="primary"
                type="button"
                onClick={() => setIsDetailOpen(false)}
              >
                閉じる
              </Box>
            </Flex>
            <Box>
              <FormLabel>タグ</FormLabel>
              <Flex columnGap={4} rowGap={3} wrap="wrap" mt={1}>
                <Controller
                  name="tags"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <CheckboxGroup {...field}>
                      {tags.map(({ id, name }) => (
                        <Checkbox key={id} value={id.toString()} ref={ref}>
                          {name}
                        </Checkbox>
                      ))}
                    </CheckboxGroup>
                  )}
                />
              </Flex>
            </Box>
            <Controller
              name="only_bookmarked"
              control={control}
              render={({ field }) => (
                <Box>
                  <FormLabel>ブックマーク</FormLabel>
                  <Box mt={1}>
                    <Checkbox
                      isChecked={field.value === "1"}
                      onChange={(e) => {
                        field.onChange(e.target.checked ? "1" : "0");
                      }}
                    >
                      ブックマーク済みの投稿のみ表示
                    </Checkbox>
                  </Box>
                </Box>
              )}
            />
            )
          </Stack>
          <Flex justify="flex-end" mt={4}>
            <Button type="submit" size="sm">
              <Text pt={0.5}>入力内容で検索</Text>
            </Button>
          </Flex>
        </Box>
      )}
      <CurrentSearchCondition
        watchTags={watchTags}
        watchOnlyBookmarked={watchOnlyBookmarked}
        tags={tags}
      />
    </Box>
  );
};

export default SearchForm;
