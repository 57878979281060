import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const TreeCurveIcon = (props: IconProps) => (
  <Icon viewBox="0 0 48 48" boxSize={12} {...props}>
    <path
      d="M24 0V16C24 20.4183 27.5817 24 32 24H48"
      stroke="#D9EEE9"
      strokeWidth="3"
      fill="none"
    />
  </Icon>
);

export default TreeCurveIcon;
