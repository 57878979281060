import {
  Avatar,
  Box,
  Container,
  Flex,
  Heading,
  Link,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, {useCallback} from "react";
import { Controller, useForm } from "react-hook-form";
import { InView } from "react-intersection-observer";
import * as yup from "yup";
import { userPath } from "../../../../routes";
import { studyGroupCommentsPath } from "../../../../routes";
import { Button } from "../../shared/components/atoms";
import CustomLinkLinkify from "../../shared/components/atoms/CustomLinkLinkify";
import { Textarea } from "../../shared/components/atoms/form";
import { isVisitableUserProfile } from "../../shared/lib/userProfileUtils";
import {
  StudyGroup,
  SharedApprovedCurrentUser,
  StudyGroupCommentsIndex,
} from "../../shared/lib/types";
import useFlash from "../../shared/lib/useFlash";
import useRequest from "../../shared/lib/useRequest";

const CommentsWithForm = ({
  studyGroup,
  currentUser,
}: {
  studyGroup: StudyGroup;
  currentUser: SharedApprovedCurrentUser;
}) => {
  const schema = yup.object().shape({
    content: yup.string().trim().required(),
  });

  type FormData = yup.InferType<typeof schema>;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
  } = useForm<FormData>({
    defaultValues: { content: "" },
    resolver: yupResolver(schema),
  });
  const request = useRequest();
  const showFlash = useFlash();
  const queryClient = useQueryClient();
  const fetchComments = async ({
    pageParam,
  }: {
    pageParam: number;
  }): Promise<StudyGroupCommentsIndex> => {
    const res = await request(studyGroupCommentsPath(studyGroup.code), "GET", {
      page: pageParam,
    });
    return res.json();
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } =
    useInfiniteQuery({
      queryKey: ["study_groups", studyGroup.code, "comments"],
      queryFn: fetchComments,
      initialPageParam: 1,
      getNextPageParam: (lastPage) => lastPage.page_metadata.next,
    });

  const onChangeInView = useCallback(
    async (inView: boolean) => {
      if (inView) {
        await fetchNextPage();
      }
    },
    [fetchNextPage],
  );

  const onSubmit = async (data: FormData) => {
    const res = await request(studyGroupCommentsPath(studyGroup.code), "POST", {
      study_group_comment: data,
    });
    if (res.ok) {
      reset();
      showFlash({ success: "コメントを投稿しました" });

      queryClient.setQueryData(
        ["study_groups", studyGroup.code, "comments"],
        (data: { pages: []; pageParams: [] }) => ({
          pages: data.pages.slice(0, 1),
          pageParams: data.pageParams.slice(0, 1),
        }),
      );
      await queryClient.refetchQueries({
        queryKey: ["study_groups", studyGroup.code, "comments"],
      });
    } else if (res.status === 404) {
      showFlash({
        error: (
          <>
            閲覧期限切れか、すでに削除された勉強会です。
            <br />
            ページをリロードしてください
          </>
        ),
      });
    }
  };
  return (
    <Box
      backgroundColor="white"
      pt={{ base: 5, sm: 8 }}
      pb={{ base: 8, md: 7 }}
      borderRadius={8}
      mt={{ base: 4, md: 6 }}
      border="1px solid #DCE5E3"
    >
      <Container maxW="container.md" px={5}>
        <Heading as="h2" fontSize={{ sm: "xl" }}>
          コメント
        </Heading>
        <Flex
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          gap={3}
          mt={{ base: 3, sm: 4 }}
          direction={{ base: "column", sm: "row" }}
          align={{ base: "normal", sm: "flex-end" }}
        >
          <Controller
            name="content"
            control={control}
            render={({ field }) => (
              <Textarea
                flex="1"
                borderRadius={8}
                backgroundColor="#D9EEE9"
                border=""
                placeholder="コメントを入力"
                minH={12}
                {...field}
                autoExpand
              />
            )}
          />
          <Button
            type="submit"
            minW="fit-content"
            isLoading={isSubmitting}
            isDisabled={!isDirty}
            minH={12}
          >
            送信
          </Button>
        </Flex>
        <Stack mt={{ base: "22", sm: "30" }} gap={{ base: 4, sm: 5 }}>
          {isPending || data == null ? (
            <Box textAlign="center">
              <Spinner />
            </Box>
          ) : (
            <>
              {data.pages[0].study_group_comments.length !== 0 ? (
                <>
                  {data.pages.map((group, i) => (
                    <React.Fragment key={i}>
                      {group.study_group_comments.map((study_group_comment) => (
                        <Flex key={study_group_comment.code} gap={3}>
                          <Avatar
                            src={study_group_comment.author.profile_image_url}
                            boxSize={{ base: 8, sm: 12 }}
                            as={Link}
                            color="inherit"
                            textDecoration="none"
                            href={
                              isVisitableUserProfile(
                                study_group_comment.author,
                                currentUser,
                              )
                                ? userPath(study_group_comment.author.code)
                                : undefined
                            }
                            cursor={
                              isVisitableUserProfile(
                                study_group_comment.author,
                                currentUser,
                              )
                                ? "pointer"
                                : "default"
                            }
                            target="_blank"
                            rel="noreferrer"
                          />
                          <Stack
                            gap={0.5}
                            pb={2}
                            borderBottom="1px solid #E6E6E6"
                            flex="1"
                          >
                            <Box
                              fontWeight="bold"
                              fontSize="sm"
                              as={Link}
                              color="inherit"
                              textDecoration="none"
                              href={
                                isVisitableUserProfile(
                                  study_group_comment.author,
                                  currentUser,
                                )
                                  ? userPath(study_group_comment.author.code)
                                  : undefined
                              }
                              cursor={
                                isVisitableUserProfile(
                                  study_group_comment.author,
                                  currentUser,
                                )
                                  ? "pointer"
                                  : "default"
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              {study_group_comment.author.full_name}
                            </Box>
                            <Box whiteSpace="pre-wrap" fontSize="sm">
                              <CustomLinkLinkify>
                                {study_group_comment.content}
                              </CustomLinkLinkify>
                            </Box>
                            <Box
                              textAlign="right"
                              fontSize="xs"
                              color="#99A9B0"
                            >
                              {dayjs(study_group_comment.created_at).fromNow()}
                            </Box>
                          </Stack>
                        </Flex>
                      ))}
                    </React.Fragment>
                  ))}
                  <InView
                    as="div"
                    onChange={(inView) => onChangeInView(inView)}
                    skip={!hasNextPage}
                  >
                    {isFetchingNextPage && (
                      <Box textAlign="center">
                        <Spinner />
                      </Box>
                    )}
                  </InView>
                </>
              ) : (
                <Box textAlign="center">ここにコメントが表示されます</Box>
              )}
            </>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default CommentsWithForm;
