import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const HelpIcon = (props: IconProps) => (
  <Icon viewBox="0 0 16 16" boxSize={4} {...props}>
    <mask
      id="mask0_3411_15213"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_3411_15213)">
      <path
        d="M7.96658 12.0002C8.19992 12.0002 8.39714 11.9196 8.55825 11.7585C8.71936 11.5974 8.79992 11.4002 8.79992 11.1668C8.79992 10.9335 8.71936 10.7363 8.55825 10.5752C8.39714 10.4141 8.19992 10.3335 7.96658 10.3335C7.73325 10.3335 7.53603 10.4141 7.37492 10.5752C7.21381 10.7363 7.13325 10.9335 7.13325 11.1668C7.13325 11.4002 7.21381 11.5974 7.37492 11.7585C7.53603 11.9196 7.73325 12.0002 7.96658 12.0002ZM7.36659 9.4335H8.59992C8.59992 9.06683 8.64159 8.77794 8.72492 8.56683C8.80825 8.35572 9.04436 8.06683 9.43325 7.70016C9.72214 7.41127 9.94992 7.13627 10.1166 6.87516C10.2833 6.61405 10.3666 6.30016 10.3666 5.9335C10.3666 5.31127 10.1388 4.8335 9.68325 4.50016C9.2277 4.16683 8.68881 4.00016 8.06659 4.00016C7.43325 4.00016 6.91936 4.16683 6.52492 4.50016C6.13047 4.8335 5.85547 5.2335 5.69992 5.70016L6.79992 6.1335C6.85547 5.9335 6.98047 5.71683 7.17492 5.4835C7.36936 5.25016 7.66658 5.1335 8.06659 5.1335C8.42214 5.1335 8.68881 5.23072 8.86658 5.42516C9.04436 5.61961 9.13325 5.8335 9.13325 6.06683C9.13325 6.28905 9.06659 6.49739 8.93325 6.69183C8.79992 6.88627 8.63325 7.06683 8.43325 7.2335C7.94436 7.66683 7.64436 7.99461 7.53325 8.21683C7.42214 8.43905 7.36659 8.84461 7.36659 9.4335ZM7.99992 14.6668C7.0777 14.6668 6.21103 14.4918 5.39992 14.1418C4.58881 13.7918 3.88325 13.3168 3.28325 12.7168C2.68325 12.1168 2.20825 11.4113 1.85825 10.6002C1.50825 9.78905 1.33325 8.92238 1.33325 8.00016C1.33325 7.07794 1.50825 6.21127 1.85825 5.40016C2.20825 4.58905 2.68325 3.8835 3.28325 3.2835C3.88325 2.6835 4.58881 2.2085 5.39992 1.8585C6.21103 1.5085 7.0777 1.3335 7.99992 1.3335C8.92214 1.3335 9.78881 1.5085 10.5999 1.8585C11.411 2.2085 12.1166 2.6835 12.7166 3.2835C13.3166 3.8835 13.7916 4.58905 14.1416 5.40016C14.4916 6.21127 14.6666 7.07794 14.6666 8.00016C14.6666 8.92238 14.4916 9.78905 14.1416 10.6002C13.7916 11.4113 13.3166 12.1168 12.7166 12.7168C12.1166 13.3168 11.411 13.7918 10.5999 14.1418C9.78881 14.4918 8.92214 14.6668 7.99992 14.6668Z"
        fill="currentColor"
      />
    </g>
  </Icon>
);

export default HelpIcon;
