import { HStack, Link, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import { announcementPath } from "../../../../../routes";
import { SharedAnnouncements } from "../../lib/types";
import AnnouncementCategory from "./AnnouncementCategory";

const AnnouncementListItem = ({
  announcement,
}: {
  announcement: SharedAnnouncements[number];
}) => {
  return (
    <HStack
      spacing={{ base: 2, md: 8 }}
      flexDirection={{ base: "column", md: "row" }}
      align="flex-start"
      borderBottomWidth={1}
      borderBottomColor="brand.200"
      pt={{ base: 3, md: 5 }}
      pb={{ base: 4, md: 5 }}
    >
      <HStack
        spacing={8}
        justify={{ base: "space-between", md: "flex-start" }}
        flexDirection={{ base: "row-reverse", md: "row" }}
        w={{ base: "full", md: "auto" }}
      >
        <Text fontSize={{ base: "xs", md: "sm" }} color="textGray">
          {dayjs(
            announcement.publish_started_at ?? announcement.created_at,
          ).format("L")}
        </Text>
        <AnnouncementCategory category={announcement.category} />
      </HStack>
      <Link
        color="textLink"
        textDecoration="underline"
        _hover={{ textDecoration: "none" }}
        href={announcementPath(announcement.code)}
      >
        <Text fontSize={{ base: "sm", md: "md" }} display="inline">
          {announcement.title}
        </Text>
      </Link>
    </HStack>
  );
};

export default AnnouncementListItem;
