import {
  Box,
  Divider,
  Flex,
  HStack,
  Heading,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { ReactNode } from "react";
import { editPostPath, userPath } from "../../../../../routes";
import { HelperImageFile, SharedTag } from "../../../../shared/lib/types";
import CustomLinkLinkify from "../../components/atoms/CustomLinkLinkify";
import { isVisitableUserProfile } from "../../lib/userProfileUtils";
import {
  HelperRealNamePostResourceAuthor,
  PostsShow,
  SharedCurrentUser,
} from "../../lib/types";
import BookmarkButton from "../atoms/BookmarkButton";
import FilesWithoutImage from "../atoms/FilesWithoutImage";
import ImagesWithSlider from "../atoms/ImagesWithSlider";
import PostResourceAvatar from "../atoms/PostResourceAvatar";
import PostResourceDisplayName from "../atoms/PostResourceDisplayName";
import Tag from "../atoms/Tag";
import MoreMenu from "./MoreMenu";
import Questionnaire from "./Questionnaire";

const PostDetail = ({
  post,
  currentUser,
  preview = false,
  children,
}: {
  post: PostsShow;
  currentUser: SharedCurrentUser;
  preview?: boolean;
  children?: ReactNode;
}) => {
  const props = isVisitableUserProfile(post.author, currentUser)
    ? {
      as: Link,
      href: userPath((post.author as HelperRealNamePostResourceAuthor).code),
      cursor: "pointer",
      target: "_blank",
      rel: "noreferrer",
    }
    : {};

  return (
    <Box
      bgColor="white"
      key={post.code}
      borderRadius={8}
      border="1px solid rgba(0, 0, 0, 0.10)"
      position="relative"
    >
      <Box
        pt={{ base: 4, sm: 7 }}
        pb={preview ? { base: 4, sm: 7 } : undefined}
        px={{ base: 5, sm: 9 }}
      >
        <Flex
          justifyContent="space-between"
          direction={{ base: "column", md: "row" }}
        >
          <HStack
            gap={3}
            textDecoration="none"
            color="inherit"
            {...props}
            w="fit-content"
            // 52px = 三点リーダーの大きさ + rightの値
            // 20px = カードの中のpadding
            maxW="calc(100% - 52px + 20px)"
            wordBreak="break-word"
          >
            <PostResourceAvatar w={12} h={12} author={post.author} />
            <Stack gap={0}>
              <PostResourceDisplayName author={post.author} />
              <HStack
                color="#848484"
                fontSize="xs"
                wrap="wrap"
                rowGap={0}
                columnGap={2.5}
              >
                <Text color="#848484" fontSize="xs">
                  投稿日：{dayjs(post.created_at).format("L LT")}
                </Text>
                {post.updated_at !== "" &&
                  post.created_at !== post.updated_at && (
                    <Text color="#848484" fontSize="xs">
                      更新日：{dayjs(post.updated_at).format("L LT")}
                    </Text>
                  )}
              </HStack>
            </Stack>
          </HStack>
          {!preview && (
            <Flex
              justifyContent="flex-end"
              gap={{ base: 1, md: 2 }}
              alignItems="center"
            >
              <BookmarkButton
                post_code={post.code}
                bookmarked={post.is_bookmarked}
                currentUser={currentUser}
              />
              {!post.is_resolved && post.is_own && (
                <MoreMenu
                  buttonProps={{
                    "data-testid": "post-detail-button",
                    cursor: "pointer",
                  }}
                  listItemProps={{
                    as: "a",
                    href: editPostPath(post.code),
                  }}
                  listItemText="投稿を編集"
                />
              )}
            </Flex>
          )}
        </Flex>
        <Heading
          as="h1"
          fontSize={{ base: "md", sm: "xl" }}
          mt={{ base: 2, sm: 4 }}
          lineHeight="1.5"
        >
          {post.title}
        </Heading>
        <Flex gap={1.5} wrap="wrap" mt={1}>
          {post.tags.map((tag: SharedTag) => (
            <Tag key={tag.id}>{tag.name}</Tag>
          ))}
        </Flex>
        <Divider borderColor="#8DCCBE" mt={6} />
        <Box mt={7} fontSize={{ base: "sm", sm: "md" }} whiteSpace="pre-wrap">
          <CustomLinkLinkify>{post.content}</CustomLinkLinkify>
        </Box>
        {post.files_without_image.length !== 0 && (
          <Box mt={4}>
            <FilesWithoutImage files={post.files_without_image} />
          </Box>
        )}
        {post.image_files.length !== 0 && (
          <Box mt={6} mb={4}>
            <ImagesWithSlider
              urls={post.image_files.map((file: HelperImageFile) => file.url)}
            />
          </Box>
        )}
        {post.questionnaire && (
          <Box mt={5}>
            <Questionnaire
              questionnaire={post.questionnaire}
              currentUser={currentUser}
            />
          </Box>
        )}
      </Box>
      {children}
    </Box>
  );
};

export default PostDetail;
