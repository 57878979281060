import {
  Avatar,
  Box,
  Container,
  Flex,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  communitiesPath,
  communityMembersPath,
  communityPath,
  communityPostsPath,
  editCommunityPath,
} from "../../../routes";
import { Flash, Pagy } from "../../shared/lib/types";
import { Button } from "../shared/components/atoms";
import Background from "../shared/components/atoms/Background";
import CustomLinkLinkify from "../shared/components/atoms/CustomLinkLinkify";
import GoBackLink from "../shared/components/atoms/GoBackLink";
import Header from "../shared/components/atoms/Header";
import Pagination from "../shared/components/atoms/Pagination";
import Tag from "../shared/components/atoms/Tag";
import Textarea from "../shared/components/atoms/form/Textarea";
import Application from "../shared/components/layouts/Application";
import {
  UserCommunity,
  SharedApprovedCurrentUser,
} from "../shared/lib/types";
import useRequest from "../shared/lib/useRequest";
import Post from "./components/Post";

const Page = ({
  community,
  is_community_member = false,
  is_community_owner = false,
  pagy,
}: {
  community: UserCommunity;
  is_community_member: boolean;
  is_community_owner: boolean;
  pagy: Pagy;
}) => {
  const request = useRequest();
  const onClickJoin = async () => {
    const res = await request(communityMembersPath(community.code), "POST", {});
    if (res.ok) {
      location.href = communityPath(community.code);
    }
  };
  const {
    isOpen: isNewPostOpen,
    onOpen: onNewPostOpen,
    onClose: onNewPostClose,
  } = useDisclosure();
  const {
    isOpen: isMembersOpen,
    onOpen: onMembersOpen,
    onClose: onMembersClose,
  } = useDisclosure();

  const schema = yup.object({
    content: yup.string().trim().required(),
  });
  type FormData = yup.InferType<typeof schema>;

  const {
    control,
    watch,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<FormData>({
    defaultValues: {
      content: "",
    },
    resolver: yupResolver(schema),
  });

  const content = watch("content");

  const onSubmit = async (data: FormData) => {
    const res = await request(communityPostsPath(community.code), "POST", {
      community_post: data,
    });

    if (res.ok) {
      location.href = communityPath(community.code);
    }
  };

  return (
    <>
      <Box
        backgroundSize="cover"
        backgroundImage={community.thumbnail_url}
        w="full"
        h="xs"
        backgroundPosition="center center"
      />
      <Heading
        as="h1"
        px={{ base: 5, sm: 10, lg: 14 }}
        py={{ base: 4, sm: 8, lg: 10 }}
        size={{ base: "md", sm: "lg" }}
        bgColor="white"
        border="1px solid rgba(0, 0, 0, 0.10)"
      >
        {community.name}
      </Heading>
      <Container mt={{ base: 6, sm: 10 }} maxW="container.lg">
        <GoBackLink href={communitiesPath()}>コミュニティ一覧に戻る</GoBackLink>
        <Flex gap={4} direction={{ base: "column-reverse", md: "row" }} mt={4}>
          <Stack flex={4} gap={4}>
            {is_community_member && (
              <Button w="full" mb={4} onClick={onNewPostOpen}>
                投稿を新規作成
              </Button>
            )}
            {community.posts.map((post) => (
              <Post
                key={post.code}
                post={post}
                is_community_member={is_community_member}
                onClickJoin={onClickJoin}
              />
            ))}
            <Flex justifyContent="center" mt={6}>
              <Pagination
                pageCount={pagy.pages}
                currentPage={pagy.page}
                onClickPage={(page) => {
                  const params = new URLSearchParams(location.search);
                  params.set("page", page.toString());
                  location.search = params.toString();
                }}
              />
            </Flex>
          </Stack>
          <Stack flex={2} gap={4}>
            <Stack
              bgColor="white"
              gap={0}
              pt={{ base: 5, sm: 7 }}
              px={{ base: 4, sm: 6 }}
              pb={{ base: 4, sm: 6 }}
              borderRadius="4px"
              border="1px solid rgba(0, 0, 0, 0.10)"
            >
              <Flex gap={2}>
                {community.tags.map((tag) => (
                  <Tag key={tag.name}>{tag.name}</Tag>
                ))}
              </Flex>
              <Box mt={2} fontSize="sm">
                <CustomLinkLinkify>{community.description}</CustomLinkLinkify>
              </Box>
              <Flex mt={4} gap={2}>
                <Text fontWeight="bold" fontSize={{ base: "sm", sm: "md" }}>
                  コミュニティ参加者
                </Text>
                {is_community_member ? (
                  <Text
                    as="button"
                    fontWeight="bold"
                    color="textLink"
                    textDecoration="underline"
                    onClick={onMembersOpen}
                    fontSize={{ base: "sm", sm: "md" }}
                  >
                    {community.members_count}人
                  </Text>
                ) : (
                  <Text fontSize={{ base: "sm", sm: "md" }}>
                    {community.members_count}人
                  </Text>
                )}
              </Flex>
              <Flex
                mt={2}
                gap={-3}
                as={is_community_member ? "button" : "div"}
                onClick={is_community_member ? onMembersOpen : undefined}
              >
                {community.members.map((member, i) => (
                  <Avatar
                    marginLeft={i !== 0 ? -11 : 0}
                    zIndex={100 - i}
                    key={member.code}
                    borderColor="white"
                    border="2px solid"
                    w={12}
                    h={12}
                    src={member.profile_image_url}
                  />
                ))}
              </Flex>
              <Flex
                justifyContent="space-between"
                align={{ base: "flex-start", sm: "center" }}
                pt={5}
                mt={5}
                borderTop="1px solid"
                borderColor="rgba(0, 0, 0, 0.1)"
                direction={{ base: "column", sm: "row" }}
              >
                <Text fontWeight="bold" fontSize={{ base: "sm", sm: "md" }}>
                  コミュニティ管理者
                </Text>
                <Flex alignItems="center" gap={2}>
                  <Avatar w={8} h={8} src={community.owner.profile_image_url} />
                  <Text
                    fontWeight="bold"
                    color="textLink"
                    textDecoration="underline"
                  >
                    {community.owner.full_name}
                  </Text>
                </Flex>
              </Flex>
              <Text
                align="right"
                fontSize="xs"
                color="rgba(109, 120, 125, 1)"
                mt={2}
              >
                {dayjs(community.created_at).format("L")}に開設
              </Text>
            </Stack>
            <Stack
              bgColor="white"
              borderRadius="4px"
              border="1px solid rgba(0, 0, 0, 0.10)"
              py={{ base: 4, sm: 6 }}
              px={{ base: 4, sm: 6 }}
            >
              {is_community_member ? (
                <Stack align="center" gap={3}>
                  <Button w="fit-content" variant="outline">
                    コミュニティに招待
                  </Button>
                  <Text
                    fontSize="xs"
                    p={2}
                    bgColor="rgba(237, 245, 243, 1)"
                    borderRadius="2px"
                  >
                    コミュニティに招待したい人に招待リンクを伝えることで、簡単にコミュニティに招待することができます。
                  </Text>
                </Stack>
              ) : (
                <Stack align="center" gap={3}>
                  <Button w="fit-content" onClick={onClickJoin}>
                    コミュニティに参加
                  </Button>
                  <Text
                    fontSize="xs"
                    p={2}
                    bgColor="rgba(237, 245, 243, 1)"
                    borderRadius="2px"
                  >
                    コミュニティに参加することで、新たな投稿を作成したりすべてのコメントを閲覧することが可能になります。
                  </Text>
                </Stack>
              )}
            </Stack>
            {is_community_owner && (
              <Flex
                bgColor="white"
                borderRadius="4px"
                border="1px solid rgba(0, 0, 0, 0.10)"
                py={{ base: 4, sm: 6 }}
                px={{ base: 4, sm: 6 }}
                justify="center"
              >
                <Button
                  as="a"
                  href={editCommunityPath(community.code)}
                  w="fit-content"
                  variant="outline"
                >
                  コミュニティ編集
                </Button>
              </Flex>
            )}
          </Stack>
        </Flex>
      </Container>
      <Modal isOpen={isNewPostOpen} onClose={onNewPostClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>新規投稿</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Controller
              name="content"
              control={control}
              render={({ field }) => <Textarea rows={10} {...field} />}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={handleSubmit(onSubmit)}
              w="full"
              isDisabled={content === ""}
              isLoading={isSubmitting}
            >
              投稿する
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isMembersOpen}
        onClose={onMembersClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>コミュニティ参加ユーザー</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {community.members.map((member, i) => (
              <Flex
                alignItems="center"
                gap={3}
                mt={i !== 0 ? 3 : 0}
                key={member.code}
              >
                <Avatar w={12} h={12} src={member.profile_image_url} />
                <Link
                  fontWeight="bold"
                  color="textLink"
                  textDecoration="underline"
                  _hover={{ textDecoration: "none" }}
                >
                  {member.full_name}
                </Link>
              </Flex>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" onClick={onMembersClose}>
              閉じる
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const CommunitiesShow = ({
  community,
  isCommunityMember = false,
  isCommunityOwner = false,
  pagy,
  flash,
  currentUser,
}: {
  community: UserCommunity;
  isCommunityMember: boolean;
  isCommunityOwner: boolean;
  pagy: Pagy;
  flash: Flash;
  currentUser: SharedApprovedCurrentUser;
}) => {
  return (
    <Application flash={flash} currentUser={currentUser}>
      <Background color="#EDF5F3">
        <Header currentUser={currentUser} />
        <Page
          community={community}
          is_community_member={isCommunityMember}
          is_community_owner={isCommunityOwner}
          pagy={pagy}
        />
      </Background>
    </Application>
  );
};

export default CommunitiesShow;
