import { useBusinessEntityForm } from "./useBusinessEntityForm";

export const handleValidateEmailResponse = async ({
  response,
  methods,
}: {
  response: Response;
  methods: Pick<
    ReturnType<typeof useBusinessEntityForm>,
    "setError" | "clearErrors"
  >;
}) => {
  const json = await response.json();
  if (json.email) {
    json.email.forEach((message: string) => {
      methods.setError("email", { message }, { shouldFocus: true });
    });
  } else {
    methods.clearErrors("email");
  }
  return json.email == null;
};
