import {
  Box,
  CheckboxGroup,
  Container,
  Flex,
  Heading,
  Progress,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { Button } from "../../shared/components/atoms";
import Background from "../../shared/components/atoms/Background";
import {
  ImageFileDropzone,
  useImageFileDropzoneState,
} from "../../shared/components/atoms/ImageFileDropzone";
import ShadowCard from "../../shared/components/atoms/ShadowCard";
import {
  VideoFileDropzone,
  useVideoFileDropzoneState,
} from "../../shared/components/atoms/VideoFileDropzone";
import {
  FormLabel,
  Input,
  InputError,
  Textarea,
} from "../../shared/components/atoms/form";
import Checkbox from "../../shared/components/atoms/form/Checkbox";
import { SharedTag } from "../../../shared/lib/types";
import { uploadFile } from "../../../shared/lib/uploadFile";
import useFlash from "../../shared/lib/useFlash";
const schema = () =>
  yup.object({
    title: yup.string().trim().max(30).required().label("タイトル"),
    description: yup.string().trim().max(500).required().label("説明"),
    viewable_datetime: yup
      .object()
      .required()
      .shape({
        started_at: yup.string().ensure().trim(),
        ended_at: yup
          .string()
          .trim()
          .ensure()
          .when("started_at", ([started_at], schema) => {
            return schema.test(
              "is-gt-started-at",
              "終了日時は開始日時よりも後の日時を入力してください",
              (ended_at) => {
                return (
                  started_at === "" ||
                  ended_at === "" ||
                  new Date(started_at) < new Date(ended_at)
                );
              },
            );
          }),
      }),
    tag_ids: yup
      .array(yup.string().required().trim())
      .min(1, "タグは必須です")
      .required(),
    instructor_info: yup.object().shape({
      name: yup.string().trim().max(30).required().label("講師名"),
      introduction: yup.string().trim().max(500).required().label("講師紹介"),
      user_url: yup.string().trim().url(),
    }),
    html_meta_description: yup
      .string()
      .ensure()
      .trim()
      .max(150)
      .label("meta content"),
    published: yup.bool().required(),
    comment_enabled: yup.bool().required(),
  });

type SchemaType = yup.InferType<ReturnType<typeof schema>>;
export type RequestType = {
  study_group: Pick<
    SchemaType,
    | "title"
    | "description"
    | "tag_ids"
    | "html_meta_description"
  > & {
    comment_enabled: string;
    study_group_published_status: string;
    video_file: undefined | string;
    thumbnail: undefined | string;
  };
  viewable_datetime: SchemaType["viewable_datetime"];
  instructor_info: SchemaType["instructor_info"] & {
    instructor_image: undefined | string;
  };
};

type FormType = SchemaType & {
  video_file_url?: string;
  thumbnail_url: string;
  instructor_info: { image_url: string };
};

const StudyGroupForm = ({
  defaultValues,
  onSubmit,
  tags,
  title,
  submitButtonLabel,
}: {
  defaultValues: FormType;
  onSubmit: (data: RequestType) => Promise<void>;
  tags: SharedTag[];
  title: string;
  submitButtonLabel: string;
}) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
    watch,
    trigger,
  } = useForm<SchemaType>({
    defaultValues,
    resolver: yupResolver(schema()),
  });

  const videoFileFieldState = useVideoFileDropzoneState({
    required: true,
    firstPreview: defaultValues.video_file_url ?? "",
  });
  const thumbnailFieldState = useImageFileDropzoneState({
    required: true,
    firstPreview: defaultValues.thumbnail_url,
  });
  const instructorImageFieldState = useImageFileDropzoneState({
    required: true,
    firstPreview: defaultValues.instructor_info.image_url,
  });

  const validateFiles = () => {
    let result = true;
    result = thumbnailFieldState.validate() && result;
    result = videoFileFieldState.validate() && result;
    result = instructorImageFieldState.validate() && result;
    return result;
  };

  const [progress, setProgress] = useState(0);
  const showFlash = useFlash();

  const _onSubmit = async (data: SchemaType) => {
    if (!validateFiles()) {
      return;
    }

    const requestData: RequestType = {
      study_group: {
        title: data.title,
        description: data.description,
        tag_ids: data.tag_ids,
        study_group_published_status: data.published
          ? "published"
          : "unpublished",
        comment_enabled: data.comment_enabled ? "1" : "0",
        html_meta_description: data.html_meta_description,
        thumbnail: undefined,
        video_file: undefined,
      },
      viewable_datetime: data.viewable_datetime,
      instructor_info: {
        ...data.instructor_info,
        instructor_image: undefined,
      },
    };

    if (thumbnailFieldState.file) {
      const { blob } = await uploadFile({
        file: thumbnailFieldState.file,
      });
      requestData.study_group.thumbnail = blob.signed_id;
    }

    if (instructorImageFieldState.file) {
      const { blob } = await uploadFile({
        file: instructorImageFieldState.file,
      });
      requestData.instructor_info.instructor_image = blob.signed_id;
    }

    if (videoFileFieldState.file) {
      const { blob, error } = await uploadFile({
        file: videoFileFieldState.file,
        setProgress,
      });

      if (error) {
        showFlash({ error: "エラーが発生しました" });
      } else {
        requestData.study_group.video_file = blob.signed_id;
      }
    }

    await onSubmit(requestData);
  };

  const [previousStartedAt, setPreviousStartedAt] = useState<string>("");
  const [previousEndedAt, setPreviousEndedAt] = useState<string>("");

  const startedAt = watch("viewable_datetime.started_at");
  const endedAt = watch("viewable_datetime.ended_at");

  if (previousStartedAt !== startedAt || previousEndedAt !== endedAt) {
    void trigger("viewable_datetime.ended_at");
    setPreviousStartedAt(startedAt);
    setPreviousEndedAt(endedAt);
  }

  const isSubmittingOrFileUploading =
    isSubmitting || (progress !== 0 && progress !== 100);

  return (
    <Background color="brand.50">
      <Container maxW="3xl" mt={{ base: 6, lg: 20 }}>
        <Box>
          <ShadowCard>
            <Container maxW="xl">
              <Heading size="lg" textAlign="center" as="h1">
                {title}
              </Heading>
              <Box as="form" onSubmit={handleSubmit(_onSubmit, validateFiles)}>
                <Stack gap={4} mt={{ base: 4, lg: 10 }}>
                  <Text
                    fontSize={{ base: "lg", sm: "xl" }}
                    fontWeight="600"
                    lineHeight="150%"
                  >
                    基本情報
                  </Text>
                  <Controller
                    name="title"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input
                        label="タイトル"
                        required
                        error={fieldState.error?.message ?? ""}
                        {...field}
                        message="30文字以内"
                        isDisabled={isSubmittingOrFileUploading}
                      />
                    )}
                  />
                  <Controller
                    name="description"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Textarea
                        label="詳細"
                        required
                        error={fieldState.error?.message ?? ""}
                        {...field}
                        message="500文字以内"
                        isDisabled={isSubmittingOrFileUploading}
                      />
                    )}
                  />
                  <Box>
                    <FormLabel>視聴可能日時</FormLabel>
                    <Flex gap={3} wrap="wrap">
                      <Flex flex="1" gap={2} align="center">
                        <FormLabel minW="fit-content">開始</FormLabel>
                        <Controller
                          name="viewable_datetime.started_at"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              type="datetime-local"
                              isDisabled={isSubmittingOrFileUploading}
                            />
                          )}
                        />
                      </Flex>
                      <Flex flex="1" gap={2} align="center">
                        <FormLabel minW="fit-content">終了</FormLabel>
                        <Controller
                          name="viewable_datetime.ended_at"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              type="datetime-local"
                              isDisabled={isSubmittingOrFileUploading}
                            />
                          )}
                        />
                      </Flex>
                    </Flex>
                    <Text fontSize="xs">
                      ※開始時が未入力の場合は、公開と同時に視聴可能になります
                    </Text>
                    {errors.viewable_datetime?.ended_at && (
                      <InputError>
                        {errors.viewable_datetime.ended_at.message}
                      </InputError>
                    )}
                  </Box>
                  <Box>
                    <FormLabel required>タグ</FormLabel>
                    <Controller
                      name="tag_ids"
                      control={control}
                      render={({
                        field: { ref, ...field },
                        fieldState: { error },
                      }) => (
                        <>
                          <Flex wrap="wrap" rowGap={3} columnGap={4}>
                            <CheckboxGroup
                              {...field}
                              isDisabled={isSubmittingOrFileUploading}
                            >
                              {tags.map(({ id, name }) => (
                                <Checkbox
                                  key={id}
                                  value={id.toString()}
                                  ref={ref}
                                  isInvalid={!!error}
                                >
                                  {name}
                                </Checkbox>
                              ))}
                            </CheckboxGroup>
                          </Flex>
                          {error?.message != null && (
                            <InputError>{error?.message}</InputError>
                          )}
                        </>
                      )}
                    />
                  </Box>
                  <Box>
                    <FormLabel required>サムネイル</FormLabel>
                    <ImageFileDropzone
                      label="サムネイル"
                      name="thumbnail"
                      imageFileDropzoneState={thumbnailFieldState}
                      croppable
                      aspectRatio={2 / 1}
                      showDeleteButton={!isSubmittingOrFileUploading}
                    />
                  </Box>
                  <Box>
                    <FormLabel required>勉強会動画ファイル</FormLabel>
                    <VideoFileDropzone
                      label="勉強会動画ファイル"
                      name="video_file"
                      videoFileDropzoneState={videoFileFieldState}
                      showDeleteButton={!isSubmittingOrFileUploading}
                    />
                  </Box>
                </Stack>
                <Stack gap={4} mt={{ base: 4, lg: 8 }}>
                  <Text fontSize={{ base: "lg", sm: "xl" }} fontWeight="600">
                    講師情報
                  </Text>
                  <Controller
                    name="instructor_info.name"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input
                        label="講師名"
                        required
                        error={fieldState.error?.message ?? ""}
                        {...field}
                        message="30文字以内"
                        isDisabled={isSubmittingOrFileUploading}
                      />
                    )}
                  />
                  <Controller
                    name="instructor_info.introduction"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Textarea
                        label="講師紹介"
                        required
                        error={fieldState.error?.message ?? ""}
                        {...field}
                        message="500文字以内"
                        isDisabled={isSubmittingOrFileUploading}
                      />
                    )}
                  />
                  <Box>
                    <FormLabel required>講師画像</FormLabel>
                    <ImageFileDropzone
                      label="講師画像"
                      name="instructor_info_instructor_image"
                      imageFileDropzoneState={instructorImageFieldState}
                      croppable
                      aspectRatio={1 / 1}
                      rounded
                      showDeleteButton={!isSubmittingOrFileUploading}
                    />
                  </Box>
                  <Controller
                    name="instructor_info.user_url"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input
                        label="講師A-Loopリンク"
                        error={fieldState.error?.message ?? ""}
                        {...field}
                        isDisabled={isSubmittingOrFileUploading}
                      />
                    )}
                  />
                  <Controller
                    name="html_meta_description"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Textarea
                        label="meta content"
                        error={error?.message}
                        {...field}
                        message="150文字以内"
                        isDisabled={isSubmittingOrFileUploading}
                      />
                    )}
                  />
                  <Controller
                    name="comment_enabled"
                    control={control}
                    render={({ field: { value, ...field } }) => (
                      <Checkbox
                        size={{ base: "sm", lg: "md" }}
                        {...field}
                        isChecked={value}
                        isDisabled={isSubmittingOrFileUploading}
                      >
                        コメントを有効にする
                      </Checkbox>
                    )}
                  />
                  <Controller
                    name="published"
                    control={control}
                    render={({ field: { value, ...field } }) => (
                      <Checkbox
                        size={{ base: "sm", lg: "md" }}
                        {...field}
                        isChecked={value}
                        isDisabled={isSubmittingOrFileUploading}
                      >
                        公開する
                      </Checkbox>
                    )}
                  />
                </Stack>
                <Flex justify="center" mt={10}>
                  <Button
                    type="submit"
                    w="fit-content"
                    isLoading={isSubmittingOrFileUploading}
                  >
                    {submitButtonLabel}
                  </Button>
                </Flex>
                {progress !== 0 && (
                  <Progress
                    value={progress}
                    hasStripe
                    colorScheme="teal"
                    mt={4}
                  />
                )}
              </Box>
            </Container>
          </ShadowCard>
        </Box>
      </Container>
    </Background>
  );
};

export default StudyGroupForm;
