import { Box, CheckboxGroup, Heading, Stack, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import "cropperjs/dist/cropper.css";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import * as yup from "yup";
import {
  editProfileBasicPath,
  profileBasicPath,
  userPath,
  validateProfileBasicPath,
} from "../../../../routes";
import { Flash } from "../../../shared/lib/types";
import { uploadFile } from "../../../shared/lib/uploadFile";
import { Button } from "../../shared/components/atoms";
import {
  ImageFileDropzone,
  useImageFileDropzoneState,
} from "../../shared/components/atoms/ImageFileDropzone";
import SectionTitle from "../../shared/components/atoms/SectionTitle";
import SiteSeal from "../../shared/components/atoms/SiteSeal";
import { FormLabel, Input } from "../../shared/components/atoms/form";
import Checkbox from "../../shared/components/atoms/form/Checkbox";
import Select from "../../shared/components/atoms/form/Select";
import Textarea from "../../shared/components/atoms/form/Textarea";
import {
  AdditionalLicense,
  Prefecture,
  ProfileBasicsProfile,
  SharedArchitectTypes,
  SharedCurrentUser,
  SharedIndustries,
  SharedUserPositions,
} from "../../shared/lib/types";
import useRequest from "../../shared/lib/useRequest";
import BirthdaySelect from "../../user/shared/components/BirthdaySelect";
import {
  ProfileItem,
  ProfileItemLeft,
  ProfileItemRight,
} from "../components/ProfileItem";
import ProfilePageLayout from "../components/ProfilePageLayout";

const schema = yup.object({
  birthday: yup.string().trim().required().label("生年月日"),
  prefecture_id: yup.string().trim().required().label("居住地"),
  nickname: yup
    .string()
    .required()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .nickname()
    .label("ニックネーム"),
  architect_type_id: yup.string().trim().required().label("職種"),
  additional_license_ids: yup.array(yup.string().trim()),
  is_check_other_architect_license: yup.bool(),
  other_architect_license: yup.string().trim().max(500),
  bio: yup.string().trim(),
  industry_id: yup.string().trim().required().label("職種"),
  user_position_id: yup.string().trim().required().label("ポジション"),
});

type FormData = yup.InferType<typeof schema>;

const Page = ({
  profile,
  prefectures,
  architect_types,
  additional_licenses,
  industries,
  userPositions,
  currentUser,
}: {
  profile: ProfileBasicsProfile;
  prefectures: Prefecture[];
  architect_types: SharedArchitectTypes;
  additional_licenses: AdditionalLicense[];
  industries: SharedIndustries;
  userPositions: SharedUserPositions;
  currentUser: NonNullable<SharedCurrentUser>;
}) => {
  const profileImageState = useImageFileDropzoneState({
    firstPreview: profile.profile_image_url,
  });
  const anonymousImageState = useImageFileDropzoneState({
    firstPreview: profile.anonymous_image_url,
  });

  const {
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { isDirty, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      ...profile,
      birthday: profile.birthday,
      architect_type_id: profile.architect_type_id.toString(),
      additional_license_ids: profile.additional_license_ids.map((id) =>
        id.toString(),
      ),
      is_check_other_architect_license: profile.other_architect_license !== "",
    },
    resolver: yupResolver(schema),
  });

  const request = useRequest();
  const validateNickname = async (
    data: yup.InferType<typeof schema>,
    shouldFocus = false,
  ) => {
    const res = await request(validateProfileBasicPath(), "POST", data);
    const json = await res.json();
    if (json.nickname) {
      json.nickname.forEach((message: string) => {
        setError("nickname", { message }, { shouldFocus });
      });
    } else {
      clearErrors("nickname");
    }
    return json.nickname == null;
  };

  const debouncedValidateNickname = useDebouncedCallback(validateNickname, 500);

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    const requestData: { user_profile: FormData } = {
      user_profile: {
        ...data,
        other_architect_license: data.is_check_other_architect_license
          ? data.other_architect_license
          : "",
      },
    };

    if (profileImageState.file) {
      const { blob } = await uploadFile({ file: profileImageState.file! });
      requestData.user_profile.profile_image = blob.signed_id;
    }

    if (anonymousImageState.file) {
      const { blob } = await uploadFile({ file: anonymousImageState.file! });
      requestData.user_profile.anonymous_image = blob.signed_id;
    } else if (anonymousImageState.preview === "") {
      requestData.user_profile.anonymous_image = "";
    }

    if (!(await validateNickname(requestData, true))) {
      return;
    }

    const res = await request(profileBasicPath(), "PUT", requestData);

    if (res.ok) {
      location.href = currentUser.is_approved
        ? userPath(currentUser.code, { tab: "profile" })
        : editProfileBasicPath();
    }
  };

  const isCheckOtherLicense = watch("is_check_other_architect_license");

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)}>
      <Box
        background="#FFF"
        px={{ base: 5, md: 8 }}
        py={{ base: 6, md: 10 }}
        borderRadius="2px"
      >
        <Heading as="h1" size="lg">
          基本情報
        </Heading>
        <SectionTitle mt={5}>公開情報</SectionTitle>
        <Stack mt={{ base: 6, md: 8 }} gap={8}>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel htmlFor="nickname" required>
                ニックネーム
              </FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="nickname"
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    onChange={async (e) => {
                      field.onChange(e);
                      await debouncedValidateNickname({
                        user_profile: { nickname: e.target.value },
                      });
                    }}
                    id="name"
                    error={fieldState.error?.message}
                    message={
                      <>
                        使用できる文字は、ひらがな、カタカナ、漢字、数字、アルファベットです。
                        <br />
                        15文字以内で登録してください。 <br />
                        Q&A投稿時に、匿名での投稿を選択した場合に表示されます。
                      </>
                    }
                  />
                )}
              />
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel>プロフィール写真</FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Box>
                <ImageFileDropzone
                  croppable
                  imageFileDropzoneState={profileImageState}
                  previewBoxProps={{ maxW: { base: "full", sm: "50%" } }}
                  rounded
                  label="プロフィール写真"
                />
              </Box>
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel>匿名投稿用プロフィール写真</FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Box>
                <ImageFileDropzone
                  croppable
                  imageFileDropzoneState={anonymousImageState}
                  previewBoxProps={{ maxW: { base: "full", sm: "50%" } }}
                  rounded
                  label="プロフィール写真"
                />
                <Text fontSize="xs" color="#6D787D">
                  Q&A投稿時に、匿名での投稿を選択した場合に表示されます。
                </Text>
              </Box>
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel required>職種</FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="architect_type_id"
                control={control}
                render={({ field }) => (
                  <Select {...field} maxW={{ sm: "full", md: "50%" }}>
                    {architect_types.map(({ id, name }) => (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel>その他に取得している資格</FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="additional_license_ids"
                control={control}
                render={({ field: { ref, ...other } }) => (
                  <Stack gap={2}>
                    <CheckboxGroup {...other}>
                      {additional_licenses.map(({ id, name }) => (
                        <Checkbox key={id} value={id.toString()} ref={ref}>
                          {name}
                        </Checkbox>
                      ))}
                    </CheckboxGroup>
                    <Controller
                      name="is_check_other_architect_license"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Checkbox onChange={onChange} isChecked={value}>
                          その他
                        </Checkbox>
                      )}
                    />
                    {isCheckOtherLicense && (
                      <Controller
                        name="other_architect_license"
                        control={control}
                        render={({ field }) => (
                          <Textarea
                            placeholder="その他の資格"
                            h={40}
                            {...field}
                          />
                        )}
                      />
                    )}
                  </Stack>
                )}
              />
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel htmlFor="bio">自己紹介</FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="bio"
                control={control}
                render={({ field }) => <Textarea h={40} {...field} id="bio" />}
              />
              <Box
                as="p"
                mt={2}
                p={3}
                fontSize="xs"
                backgroundColor="#F0F2F2"
                borderRadius="2px"
              >
                あなたの経歴や実績、趣味・好きなこと、人物像などを入力していただくことを推奨します。
                <br />
                プロフィールを充実させることで、新たな建築士とのつながりや、仕事のお問い合わせに繋がる可能性があります。
              </Box>
            </ProfileItemRight>
          </ProfileItem>
        </Stack>
        <SectionTitle mt={12}>非公開情報</SectionTitle>
        <Stack mt={8} gap={8}>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel required>生年月日</FormLabel>
              <Text fontSize="sm" color="#6D787D">
                プロフィールには年齢のみ公開されます。
              </Text>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Stack gap={0.5}>
                <Controller
                  name="birthday"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <BirthdaySelect
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                    />
                  )}
                />
              </Stack>
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel required htmlFor="prefecture_id">
                居住地
              </FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="prefecture_id"
                control={control}
                render={({ field, fieldState }) => (
                  <Select
                    required
                    placeholder="都道府県を選択"
                    error={fieldState.error?.message ?? ""}
                    {...field}
                    maxW={{ md: "50%" }}
                  >
                    {prefectures.map(({ id, name }) => (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel required htmlFor="industry_id">
                業種
              </FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="industry_id"
                control={control}
                render={({ field, fieldState }) => (
                  <Select
                    required
                    placeholder="業種を選択"
                    error={fieldState.error?.message ?? ""}
                    {...field}
                    maxW={{ md: "50%" }}
                  >
                    {industries.map(({ id, name }) => (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </ProfileItemRight>
          </ProfileItem>
          <ProfileItem>
            <ProfileItemLeft>
              <FormLabel required htmlFor="user_position_id">
                ポジション
              </FormLabel>
            </ProfileItemLeft>
            <ProfileItemRight>
              <Controller
                name="user_position_id"
                control={control}
                render={({ field, fieldState }) => (
                  <Select
                    required
                    placeholder="ポジションを選択"
                    error={fieldState.error?.message ?? ""}
                    {...field}
                    maxW={{ md: "50%" }}
                  >
                    {userPositions.map(({ id, name }) => (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </ProfileItemRight>
          </ProfileItem>
        </Stack>
      </Box>
      <Box mt={{ base: 8, md: 10 }} textAlign="center">
        <Button
          maxW="fit-content"
          type="submit"
          isDisabled={
            (!isDirty &&
              profileImageState.file === undefined &&
              !anonymousImageState.isChange) ||
            isSubmitting
          }
        >
          変更を保存
        </Button>
      </Box>
    </Stack>
  );
};

const ProfileBasicsEdit = ({
  profile,
  prefectures,
  architectTypes,
  additionalLicenses,
  industries,
  userPositions,
  flash,
  currentUser,
}: {
  profile: ProfileBasicsProfile;
  prefectures: Prefecture[];
  architectTypes: SharedArchitectTypes;
  additionalLicenses: AdditionalLicense[];
  industries: SharedIndustries;
  userPositions: SharedUserPositions;
  flash: Flash;
  currentUser: NonNullable<SharedCurrentUser>;
}) => {
  return (
    <ProfilePageLayout current_user={currentUser} flash={flash}>
      <Page
        profile={profile}
        architect_types={architectTypes}
        additional_licenses={additionalLicenses}
        industries={industries}
        userPositions={userPositions}
        prefectures={prefectures}
        currentUser={currentUser}
      />
      <Box mt={6}>
        <SiteSeal />
      </Box>
    </ProfilePageLayout>
  );
};

export default ProfileBasicsEdit;
