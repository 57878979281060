import { Box, Container, Flex, Heading, Stack } from "@chakra-ui/react";
import React from "react";
import { Helmet } from "react-helmet";
import { communityPath } from "../../../routes";
import { Button } from "../shared/components/atoms";
import Background from "../shared/components/atoms/Background";
import Header from "../shared/components/atoms/Header";
import SideMenu from "../shared/components/atoms/SideMenu";
import Application from "../shared/components/layouts/Application";
import CommunityForm from "./components/CommunityForm";
import { UserCommunity, SharedCurrentUser } from "../shared/lib/types";
import { Flash, SharedTag } from "../../shared/lib/types";

const CommunitiesEdit = ({
  community,
  flash,
  tags,
  currentUser,
}: {
  community: UserCommunity;
  flash: Flash;
  tags: SharedTag[];
  currentUser: SharedCurrentUser;
}) => {
  const onSubmit = async (formData: FormData) => {
    const req = new Request(communityPath(community.code), {
      method: "PUT",
      body: formData,
    });

    const res = await fetch(req);
    if (res.ok) {
      location.href = communityPath(community.code);
    }
  };

  return (
    <Application flash={flash} currentUser={currentUser}>
      <Helmet>
        <title>A-Loop | コミュニティ編集</title>
      </Helmet>
      <Header currentUser={currentUser} />
      <Background color="brand.50">
        <Container
          maxW="container.lg"
          mt={{ base: 6, lg: 12 }}
          pt={14}
          px={12}
          pb={10}
          bgColor="white"
        >
          <Flex gap={12}>
            <Box flex="1">
              <SideMenu
                items={[{ label: "基本情報" }, { label: "メンバー管理" }]}
              />
            </Box>
            <Stack flex={4}>
              <Heading as="h1" size="lg">
                コミュニティ基本情報
              </Heading>
              <Stack mt={12}>
                <CommunityForm
                  defaultValues={{
                    name: community.name,
                    description: community.description,
                    tag_ids: community.tags.map((tag) => tag.id.toString()),
                  }}
                  tags={tags}
                  thumbnailFirstPreview={community.thumbnail_url}
                  onSubmit={onSubmit}
                  renderButton={({ isSubmitting }) => (
                    <Flex justify="end" mt={10}>
                      <Button type="submit" isLoading={isSubmitting}>
                        保存
                      </Button>
                    </Flex>
                  )}
                />
              </Stack>
            </Stack>
          </Flex>
        </Container>
      </Background>
    </Application>
  );
};

export default CommunitiesEdit;
