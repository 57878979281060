import { Box } from "@chakra-ui/react";
import React from "react";

const ServiceCaution = () => {
  return (
    <Box
      fontSize={{ base: "xs", md: "sm" }}
      py={5}
      px={{ base: 5, md: 6 }}
      bgColor="#D9EEE9"
      color="gray"
    >
      本サービスにおいて、個別の投稿内容やコメント内容等はあくまでも投稿者の個人的な見解となり、その内容の正確性を弊社が保証するものではありません。当該意見に依拠する際は、自己責任において利用するようにしてください。
    </Box>
  );
};

export default ServiceCaution;
