import React from "react";
import { Flash } from "../../../shared/lib/types";
import Application from "../../shared/components/layouts/Application";
import FixedBackgroundBuildingImage from "../../shared/components/atoms/FixedBackgroundBuildingImage";
import Background from "../../shared/components/atoms/Background";
import { Box, Container, Flex, Link, Text } from "@chakra-ui/react";
import ShadowCard from "../../shared/components/atoms/ShadowCard";
import { businessMatchingRootPath } from "../../../../routes";
import { SharedApprovedCurrentUser } from "../../shared/lib/types";
import { Header } from "../../shared/components/atoms";
import EmailNotReceivedMessage from "../../../shared/components/EmailNotReceivedMessage";

const VerificationSentsShow = ({
  flash,
  currentUser,
}: {
  flash: Flash;
  currentUser: SharedApprovedCurrentUser;
}) => {
  return (
    <Application flash={flash} currentUser={currentUser}>
      <FixedBackgroundBuildingImage />
      <Header currentUser={currentUser} />
      <Background>
        <Container maxW="4xl" mt="120px" zIndex={1}>
          <ShadowCard>
            <Flex direction="column" align="center">
              <Text
                lineHeight="150%"
                fontSize={{ base: "md", lg: "2rem" }}
                fontWeight="600"
              >
                確認メールを送信しました
              </Text>
              <Box
                textAlign="center"
                mt={10}
                display={{ base: "none", lg: "block" }}
              >
                <Text>
                  現在ご利用いただいているメールアドレスとは異なるメールアドレスで申請を頂いたため、
                </Text>
                <Text>
                  改めてご登録いただいたメールアドレスに確認メールを送信しました。
                </Text>
                <Text>
                  メール内のURLを1時間以内にクリックして仮登録を行ってください。
                </Text>
              </Box>
              <Box
                textAlign="center"
                mt={10}
                fontSize="sm"
                display={{ base: "block", lg: "none" }}
              >
                <Box>
                  <Text>現在ご利用いただいているメールアドレスとは</Text>
                  <Text>異なるメールアドレスで申請を頂いたため、</Text>
                </Box>
                <Box mt={4}>
                  <Text>改めてご登録いただいたメールアドレスに</Text>
                  <Text>確認メールを送信しました。</Text>
                </Box>
                <Box mt={4}>
                  <Text>メール内のURLを1時間以内に</Text>
                  <Text>クリックして仮登録を行ってください。</Text>
                </Box>
              </Box>
              <EmailNotReceivedMessage mt={10} />
              <Link
                href={businessMatchingRootPath()}
                mt={8}
                fontSize="sm"
                color="textLink"
                textDecoration="underline"
                _hover={{ textDecoration: "none" }}
              >
                ビジネスパートナー一覧画面へ
              </Link>
            </Flex>
          </ShadowCard>
        </Container>
      </Background>
    </Application>
  );
};

export default VerificationSentsShow;
