import { Box, Flex, HStack, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { Button } from "../../shared/components/atoms";
import { FormLabel } from "../../shared/components/atoms/form";
import SectionTitle from "../../shared/components/atoms/SectionTitle";
import {
  Prefecture,
  SharedArchitectLicenses,
  SharedIndustries,
  SharedUserPositions,
  UserUserPersonalInfoApplicationsTemporaryUserInput,
} from "../../shared/lib/types";
import SiteSeal from "../../shared/components/atoms/SiteSeal";
import { LogoSVG } from "../../svg";
import dayjs from "dayjs";

const Confirmation = ({
  values,
  onClickFix,
  architectLicenses,
  architectLicenseImagePreview,
  temporaryUserInput,
  prefectures,
  industries,
  userPositions,
  isSubmitting,
  onSubmit,
}: {
  values: Record<string, string | number>;
  onClickFix: () => void;
  architectLicenses: SharedArchitectLicenses;
  architectLicenseImagePreview: string;
  temporaryUserInput: UserUserPersonalInfoApplicationsTemporaryUserInput;
  prefectures: Prefecture[];
  industries: SharedIndustries;
  userPositions: SharedUserPositions;
  isSubmitting: boolean;
  onSubmit: () => void;
}) => {
  return (
    <>
      <Flex direction="column">
        <Flex justify="center">
          <Image src={LogoSVG} width={{ base: 32, lg: 48 }} />
        </Flex>
        <Box mt={{ base: 6, md: 10 }} textAlign="center">
          <Text
            as="span"
            fontWeight="600"
            fontSize={{ base: "1.5rem", md: "2rem" }}
            display="inline-block"
          >
            本人確認登録
          </Text>{" "}
          <Text
            as="span"
            fontWeight="600"
            fontSize={{ base: "1.5rem", md: "2rem" }}
            display="inline-block"
          >
            入力内容確認
          </Text>
        </Box>

        {temporaryUserInput.nickname == null && (
          <>
            <SectionTitle mt={8}>公開情報</SectionTitle>
            <Stack gap={6} mt={7}>
              <Stack>
                <FormLabel as="div">ニックネーム</FormLabel>
                <Text>{values.nickname}</Text>
              </Stack>
            </Stack>
          </>
        )}

        {[
          temporaryUserInput.birthday,
          temporaryUserInput.industry_id,
          temporaryUserInput.prefecture_id,
          temporaryUserInput.user_position_id,
        ].some((value) => value == null) && (
          <>
            <SectionTitle mt={8}>非公開情報</SectionTitle>
            <Stack gap={6} mt={7}>
              {temporaryUserInput.birthday == null && (
                <Stack>
                  <FormLabel as="div">生年月日</FormLabel>
                  <Text>{dayjs(values.birthday).format("LL")}</Text>
                </Stack>
              )}
              {temporaryUserInput.prefecture_id == null && (
                <Stack>
                  <FormLabel as="div">居住地</FormLabel>
                  <Text>
                    {
                      prefectures.find(
                        (prefecture) =>
                          prefecture.id.toString() ===
                          values.prefecture_id.toString(),
                      )!.name
                    }
                  </Text>
                </Stack>
              )}
              {temporaryUserInput.industry_id == null && (
                <Stack>
                  <FormLabel as="div">業種</FormLabel>
                  <Text>
                    {
                      industries.find(
                        (industry) =>
                          industry.id.toString() ===
                          values.industry_id.toString(),
                      )!.name
                    }
                  </Text>
                </Stack>
              )}
              {temporaryUserInput.user_position_id == null && (
                <Stack>
                  <FormLabel as="div">ポジション</FormLabel>
                  <Text>
                    {
                      userPositions.find(
                        (UserPosition) =>
                          UserPosition.id.toString() ===
                          values.user_position_id.toString(),
                      )!.name
                    }
                  </Text>
                </Stack>
              )}
            </Stack>
          </>
        )}

        <SectionTitle mt={8}>ご本人情報</SectionTitle>
        <Stack gap={6} mt={7}>
          <Stack gap={4}>
            <Box>
              <FormLabel as="div">氏名</FormLabel>
              <Flex gap={4} direction={{ base: "column", sm: "row" }} mt={2}>
                <HStack flex={1} gap={4}>
                  <FormLabel as="div">姓</FormLabel>
                  <Text>{values.family_name}</Text>
                </HStack>
                <HStack flex={1} gap={4}>
                  <FormLabel as="div">名</FormLabel>
                  <Text>{values.given_name}</Text>
                </HStack>
              </Flex>
            </Box>
            <Box>
              <FormLabel as="div">氏名ふりがな</FormLabel>
              <Flex gap={4} direction={{ base: "column", sm: "row" }} mt={2}>
                <HStack flex={1} gap={4}>
                  <FormLabel as="div">姓</FormLabel>
                  <Text>{values.family_name_kana}</Text>
                </HStack>
                <HStack flex={1} gap={4}>
                  <FormLabel as="div">名</FormLabel>
                  <Text>{values.given_name_kana}</Text>
                </HStack>
              </Flex>
            </Box>
          </Stack>
        </Stack>
        <SectionTitle mt={14}>建築士免許証明書 情報</SectionTitle>
        <Stack gap={6} mt={7}>
          <Stack>
            <FormLabel as="div">取得している建築士資格</FormLabel>
            <Text>
              {
                architectLicenses.find(
                  (it) => String(it.id) === values.architect_license_id,
                )?.name
              }
            </Text>
          </Stack>
          <Stack>
            <FormLabel as="div">建築士番号</FormLabel>
            <Text>{values.architect_number}</Text>
          </Stack>
          <Stack gap={2}>
            <FormLabel as="div">建築士免許証明書 画像</FormLabel>
            <Box
              as="object"
              w="full"
              minH="400px"
              data={architectLicenseImagePreview}
            />
          </Stack>
          <Stack>
            <Box>
              <FormLabel as="div">ご登録氏名について</FormLabel>
              <Text fontSize="sm">
                ご登録の氏名と建築士免許証明書に記載されている名前は同一ですか？
              </Text>
            </Box>
            <Text>
              {
                [
                  { value: "yes", label: "はい" },
                  { value: "no", label: "いいえ" },
                ].find((it) => it.value === values.is_same_name)?.label
              }
            </Text>
          </Stack>
          {values.is_same_name === "no" && (
            <Stack>
              <FormLabel as="div">
                ご登録の氏名と建築士証のお名前が異なる理由
              </FormLabel>
              <Text whiteSpace="pre-wrap">{values.different_name_reason}</Text>
            </Stack>
          )}
        </Stack>
      </Flex>
      <Flex justify="center" mt={10} gap={3}>
        <Button
          type="button"
          isLoading={false}
          isDisabled={isSubmitting}
          w="full"
          variant="outline"
          onClick={onClickFix}
          wordBreak="keep-all"
          overflowWrap="anywhere"
        >
          入力内容を&#8203;修正
        </Button>
        <Button
          type="button"
          onClick={onSubmit}
          isLoading={isSubmitting}
          w="full"
        >
          登録
        </Button>
      </Flex>
      <Box mt={14}>
        <SiteSeal />
      </Box>
    </>
  );
};

export default Confirmation;
