import { useCallback, useState } from "react";

const useFileState = ({
  required = false,
  fileSize = 5000,
  firstPreview = undefined,
}: {
  required?: boolean;
  fileSize?: number;
  firstPreview?: string | undefined;
} = {}) => {
  const [file, _setFile] = useState<File>();
  const [error, setError] = useState<string>();
  const [preview, setPreview] = useState<string | undefined>(firstPreview);

  const setFile = useCallback(
    (file: File | undefined) => {
      if (file && file.size / 1000 > fileSize) {
        setError(
          `アップロードしたファイルが${fileSize / 1000}MBを超えています`,
        );
        return;
      }

      setError(undefined);
      setPreview(file ? URL.createObjectURL(file) : undefined);
      _setFile(file);
    },
    [fileSize],
  );

  const validate = useCallback(() => {
    if (required && file == null && preview == null) {
      setError("ファイルは必須です");
      return false;
    }

    setError(undefined);
    return true;
  }, [file, required, preview]);

  const isDirty = preview !== firstPreview;

  const reset = useCallback(() => {
    setFile(undefined);
    setPreview(firstPreview);
  }, [setPreview, firstPreview, setFile]);

  return { file, setFile, validate, preview, error, isDirty, reset };
};

export default useFileState;
