import { Box, Flex, Grid, HStack } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import CheckCircleIcon from "../../icons/CheckCircleIcon";

const QuestionResult = ({
  children,
  ratio,
  selected = false,
}: {
  children: ReactNode;
  ratio: number;
  selected?: boolean;
}) => {
  return (
    <Flex align="center" justify="space-between" gap={2}>
      <Grid flex="1">
        <Box
          bgColor="#D9EEE9"
          borderRadius={5}
          w={`${ratio}%`}
          gridArea="1 / 1 / 2 / 2"
        />
        <HStack
          gridArea="1 / 1 / 2 / 2"
          py={2.5}
          px={5}
          fontWeight="bold"
          fontSize="sm"
        >
          <Box pt={0.5}> {children} </Box>
          {selected && <CheckCircleIcon />}
        </HStack>
      </Grid>
      <Box color="#99A9B0" minW={12} textAlign="right">
        {ratio}%
      </Box>
    </Flex>
  );
};

export default QuestionResult;
