import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const NoteIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" boxSize={8} {...props}>
    <path
      d="M9.08691 9.239C11.7605 9.239 15.422 9.10334 18.0508 9.17149C21.5779 9.26172 22.9034 10.7981 22.9482 14.5933C22.993 16.7392 22.9482 22.8841 22.9482 22.8841H19.1322C19.1322 17.5077 19.1549 16.6204 19.1322 14.9263C19.0874 13.4353 18.6616 12.7246 17.516 12.5889C16.3028 12.4533 12.9029 12.5662 12.9029 12.5662V22.8841H9.08691V9.239Z"
      fill="#040000"
    />
  </Icon>
);

export default NoteIcon;
