import {
  Box,
  Flex,
  HStack,
  Heading,
  Link
} from "@chakra-ui/react";
import { default as React } from "react";
import {
  Button
} from "../../shared/components/atoms";
import {
  ChevronRightIcon
} from "../../shared/components/icons";

const TitleWithLinkButton = ({
  title,
  buttonLabel,
  href,
}: {
  title: string;
  buttonLabel: string;
  href: string;
}) => {
  return (
    <Flex justify="space-between" align="center">
      <Heading size="lg">{title}</Heading>
      <Button
        variant="outline"
        display={{ base: "none", md: "block" }}
        as={Link}
        textDecoration="none"
        href={href}
        size="sm"
      >
        <HStack gap={0.5}>
          <Box as="span" pt={0.5}>
            {buttonLabel}
          </Box>
          <ChevronRightIcon boxSize="18px" />
        </HStack>
      </Button>
    </Flex>
  );
};

export default TitleWithLinkButton;
