import React from "react";
import useFlash from "../../shared/lib/useFlash";
import useRequest from "../../shared/lib/useRequest";
import { PostComment, UserPostCommentReply } from "../../shared/lib/types";
import ThumbUpButtonWithCount from "./ThumUpButton/ThumbUpButtonWithCount";

const UsefulButton = ({
  postChild,
  path,
  isDisabled = false,
}: {
  postChild: PostComment | UserPostCommentReply;
  path: string;
  isDisabled?: boolean;
}) => {
  const request = useRequest();
  const showFlash = useFlash();
  const showReplyNotFoundFlash = () => {
    showFlash({
      error: (
        <>
          参考になったをしようとしたコメント、返信が見つかりませんでした。
          <br />
          ページを再読み込みしてください。
        </>
      ),
    });
  };

  return (
    <ThumbUpButtonWithCount
      count={postChild.useful_count}
      isActive={postChild.is_marked_useful}
      onActive={async () => {
        const res = await request(path, "PUT", {});
        if (res.status === 404) {
          showReplyNotFoundFlash();
        }
      }}
      onInactive={async () => {
        const res = await request(path, "DELETE", {});
        if (res.status === 404) {
          showReplyNotFoundFlash();
        }
      }}
      isDisabled={isDisabled}
    />
  );
};

export default UsefulButton;
