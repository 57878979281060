import {
  Box,
  CheckboxGroup,
  CloseButton,
  Flex,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import ja from "date-fns/locale/ja";
import React from "react";
import ReactDatepicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import * as yup from "yup";
import {
  HelperImageFile,
  HelperNotImageFile,
  SharedTag,
} from "../../../shared/lib/types";
import {
  SharedApprovedCurrentUser,
  SharedCurrentUser,
} from "../../shared/lib/types";
import { Button } from "../../shared/components/atoms";
import Dropzone from "../../shared/components/atoms/Dropzone";
import { FormLabel, Input, Textarea } from "../../shared/components/atoms/form";
import Checkbox from "../../shared/components/atoms/form/Checkbox";
import AddIcon from "../../shared/components/icons/AddIcon";
import SendIcon from "../../shared/components/icons/SendIcon";
import AnonymousField from "./AnonymousField";
import UploadFiles, { UploadFileType, useUploadFiles } from "../../../shared/components/UploadFiles";
import { anonymousSchema } from "../lib/schema";
import { uploadFile } from "../../../shared/lib/uploadFile";

// jaの型がなぜが合わない
// 動作としては問題ないので無視
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
registerLocale("ja", ja);

const tomorrow = () => new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
const afterWeek = () =>
  new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);

const QuestionnaireForm = () => {
  const { control } = useFormContext();
  const { fields, remove, append } = useFieldArray({
    control,
    name: "questionnaire.questions",
    rules: { minLength: 2, maxLength: 10 },
  });
  return (
    <>
      <Helmet
        style={[
          {
            cssText: `
        .custom-wrapper {
          display: block;
        }
      `,
          },
        ]}
      />
      <Box
        backgroundColor="#EDF5F3"
        px={6}
        py={5}
        border="1px solid var(--border-input, #99A9B0)"
        borderRadius={2}
      >
        <Stack gap={6}>
          <Box>
            <FormLabel>選択肢</FormLabel>
            <Flex mt={2} align="flex-end" gap={2}>
              <Stack flex={1} gap={2}>
                {fields.map((field, index) => (
                  <Flex align="center" key={field.id} gap={2}>
                    <Controller
                      control={control}
                      name={`questionnaire.questions.${index}.content`}
                      render={({ field, fieldState: { error } }) => (
                        <Input
                          placeholder={`選択肢${index + 1}`}
                          {...field}
                          error={error?.message}
                        />
                      )}
                    />
                    {fields.length > 2 && (
                      <CloseButton onClick={() => remove(index)} />
                    )}
                  </Flex>
                ))}
              </Stack>
              {fields.length < 10 && (
                <Button
                  px={4}
                  onClick={() => append({ content: "" })}
                  data-testid="add-question-button"
                >
                  <AddIcon />
                </Button>
              )}
            </Flex>
          </Box>
          <Box>
            <FormLabel>アンケート締め切り日時</FormLabel>
            <HStack align="center">
              <Box flex={1} maxW={80}>
                <Controller
                  control={control}
                  name="questionnaire.voting_ended_at"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <ReactDatepicker
                      locale="ja"
                      onChange={onChange}
                      placeholderText="日時を選択"
                      customInput={<Input w="full" error={error?.message} />}
                      wrapperClassName="custom-wrapper"
                      dateFormat="yyyy/MM/dd HH:mm"
                      dateFormatCalendar="yyyy年 M月"
                      selected={value}
                      minDate={tomorrow()}
                      maxDate={afterWeek()}
                      filterTime={(time) => {
                        const tomorrowDate = tomorrow();
                        const selectedDate = new Date(time);

                        return (
                          tomorrowDate.getTime() < selectedDate.getTime() &&
                          selectedDate.getTime() <= afterWeek().getTime()
                        );
                      }}
                      timeFormat="HH:mm"
                      showTimeSelect
                      timeCaption="時間"
                    />
                  )}
                />
              </Box>
              <Box>まで</Box>
            </HStack>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export const usePostForm = ({
  isAnonymousField,
  currentUser,
  defaultValues = {
    title: "",
    content: "",
    tag_ids: [],
    imageFiles: [],
    filesWithoutImage: [],
  },
}: {
  isAnonymousField: boolean;
  currentUser: SharedCurrentUser;
  defaultValues?: {
    title: string;
    content: string;
    tag_ids: string[];
    imageFiles: HelperImageFile[];
    filesWithoutImage: HelperNotImageFile[];
  };
}) => {
  const schema = yup.object().shape({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    title: yup.string().postTitle().required().label("タイトル"),
    content: yup.string().trim().required().label("質問内容"),
    tag_ids: yup.array(yup.string()),
    anonymous: anonymousSchema(
      isAnonymousField && !!currentUser?.is_anonymousable,
    ),
    questionnaire: yup.lazy((value) =>
      value == null
        ? yup.mixed()
        : yup
          .object()
          .optional()
          .shape({
            questions: yup
              .array(
                yup.object().shape({
                  content: yup
                    .string()
                    .trim()
                    .required()
                    .max(30)
                    .label("選択肢"),
                }),
              )
              .required()
              .min(2)
              .max(10),
            voting_ended_at: yup
              .string()
              .trim()
              .required()
              .test("voting_ended_at", (value, ctx) => {
                if (new Date(value) <= tomorrow()) {
                  return ctx.createError({
                    message:
                      "アンケート締め切り日時は24時間後以降を選択してください",
                  });
                } else if (new Date(value) >= afterWeek()) {
                  return ctx.createError({
                    message:
                      "アンケート締め切り日時は7日以内を選択してください",
                  });
                } else {
                  return true;
                }
              })
              .label("アンケート締め切り日時"),
          }),
    ),
  });

  return {
    ...useForm({
      defaultValues: {
        title: defaultValues.title,
        content: defaultValues.content,
        anonymous: "",
        tag_ids: defaultValues.tag_ids,
      },
      resolver: yupResolver(schema),
    }),
    defaultValues,
    isAnonymousField,
  };
};

export const jsonToFormData = async (data: DataType, uploadFiles: UploadFileType) => {
  const formData = new FormData();
  formData.append("post[title]", data.title);
  formData.append("post[content]", data.content);
  formData.append("post[anonymous]", data.anonymous);
  if (Object.keys(data.tag_ids).length > 0) {
    data.tag_ids.forEach((tag_id: string) => {
      formData.append("post[tag_ids][]", tag_id);
    });
  } else {
    formData.append("post[tag_ids][]", "");
  }
  if (data.questionnaire) {
    formData.append(
      "post[questionnaire][voting_ended_at]",
      data.questionnaire.voting_ended_at,
    );
    data.questionnaire.questions.forEach((question: { content: string }) => {
      formData.append(
        "post[questionnaire][questions][][content]",
        question.content,
      );
    });
  }
  if (uploadFiles.length > 0) {
    for (const file of uploadFiles) {
      if ("signed_id" in file) {
        formData.append("post[files][]", file.signed_id);
      } else {
        const { blob } = await uploadFile({ file });
        formData.append("post[files][]", blob.signed_id);
      }
    }
  } else {
    formData.append("post[files][]", "");
  }

  return formData;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DataType = Record<string, any>;

export const PostForm = ({
  tags,
  withQuestinonaire = false,
  onSubmit,
  currentUser = undefined,
  methods,
  submitLabel,
}: {
  tags: SharedTag[];
  withQuestinonaire?: boolean;
  onSubmit: (data: DataType, uploadFiles: UploadFileType) => Promise<void>;
  currentUser?: SharedApprovedCurrentUser;
  methods: ReturnType<typeof usePostForm>;
  submitLabel: string;
}) => {
  const questionnaireFormDisclosure = useDisclosure();
  const [uploadFiles, addUploadFiles, removeUploadFile] = useUploadFiles(
    methods.defaultValues.imageFiles,
    methods.defaultValues.filesWithoutImage,
  );

  const _onSubmit = async (data: DataType) => {
    await onSubmit(data, uploadFiles);
  };
  const { watch } = methods;

  const anonymous = watch("anonymous");

  return (
    <FormProvider {...methods}>
      <Box
        backgroundColor="#FFF"
        px={{ base: 6, md: 9 }}
        pt={{ base: 7, md: 10 }}
        pb={{ base: 6, md: 8 }}
        as="form"
        id="post-form"
        onSubmit={methods.handleSubmit(_onSubmit)}
        borderRadius="2px"
        boxShadow="8px 8px 0px 0px rgba(26, 130, 123, 0.08);"
        position="relative"
        w="full"
      >
        <Stack gap={6}>
          <Controller
            name="title"
            control={methods.control}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                error={error?.message}
                label="タイトル"
                required
                message="30文字以内"
              />
            )}
          />
          {methods.isAnonymousField && currentUser?.is_anonymousable && (
            <AnonymousField
              control={methods.control}
              anonymous={anonymous}
              currentUser={currentUser}
              label="質問者の表示名"
            />
          )}
          {withQuestinonaire && !questionnaireFormDisclosure.isOpen && (
            <HStack
              as="button"
              gap={0.5}
              border="1px solid #3CAA91"
              borderRadius={2}
              px={2}
              py={1}
              w="fit-content"
              color="primary"
              onClick={() => {
                methods.setValue("questionnaire", {
                  questions: [{ content: "" }, { content: "" }],
                  voting_ended_at: "",
                });
                questionnaireFormDisclosure.onOpen();
              }}
            >
              <AddIcon boxSize={4} />
              <Box fontSize="xs" mt="1px" fontWeight="bold">
                アンケート形式で質問
              </Box>
            </HStack>
          )}
          {withQuestinonaire && questionnaireFormDisclosure.isOpen && (
            <Box>
              <Flex justify="flex-end">
                <CloseButton
                  onClick={() => {
                    questionnaireFormDisclosure.onClose();
                    methods.setValue("questionnaire", undefined);
                  }}
                />
              </Flex>
              <QuestionnaireForm />
            </Box>
          )}
          <Controller
            name="content"
            control={methods.control}
            render={({ field, fieldState: { error } }) => (
              <Textarea
                {...field}
                error={error?.message}
                label="質問内容"
                required
                minH={48}
                message={
                  <>
                    ・出典（引用元）を記載するとコメントの信憑性が高まります。
                    <br />
                    ・質問内容の一部は、非会員の方も閲覧できます。
                  </>
                }
              />
            )}
          />
          <Box>
            <Dropzone
              onDrop={(files) => {
                addUploadFiles(files);
              }}
              multiple
              name="post-files"
            />
            <Text fontSize="xs" color="#6D787D">
              ・添付できるファイル容量の上限は5MB未満です。
              <br />
              ・jpeg,jpg,png,gifの形式のファイルのみプレビュー表示され、それ以外はダウンロード形式になります。
              <br />
              ・最大で5つまでアップロード可能です。
            </Text>
            <Box mt={uploadFiles.length > 0 ? 3 : 0}>
              <UploadFiles
                uploadFiles={uploadFiles}
                removeFile={removeUploadFile}
              />
            </Box>
          </Box>
          <Box>
            <FormLabel>タグ</FormLabel>
            <Flex columnGap={4} rowGap={3} wrap="wrap" mt={1}>
              <Controller
                name="tag_ids"
                control={methods.control}
                render={({ field: { ref, ...field } }) => (
                  <CheckboxGroup {...field}>
                    {tags.map(({ id, name }) => (
                      <Checkbox key={id} value={id.toString()} ref={ref}>
                        {name}
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                )}
              />
            </Flex>
          </Box>
        </Stack>
        <Flex justify="center" mt={8}>
          <Button
            type="submit"
            leftIcon={<SendIcon />}
            isLoading={methods.formState.isSubmitting}
          >
            <Box pt={0.5} as="span">
              {submitLabel}
            </Box>
          </Button>
        </Flex>
      </Box>
    </FormProvider>
  );
};
