import { Box, Text } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import Dropzone from "./Dropzone";
import { InputError } from "./form";

export const useVideoFileDropzoneState = ({
  required = false,
  firstPreview = "",
}) => {
  const [isError, setIsError] = useState(false);
  const [file, setFile] = useState<File>();
  const [preview, setPreview] = useState<string>(firstPreview);

  const validateArg = useCallback(
    (arg: File | undefined) => {
      let isValid = true;
      if (required && arg === undefined && preview === "") {
        setIsError(true);
        isValid = false;
      } else {
        setIsError(false);
      }
      return isValid;
    },
    [required, setIsError, preview],
  );

  const validate = useCallback(() => validateArg(file), [file, validateArg]);

  return {
    isError,
    file,
    validate,
    _private: {
      preview,
      setPreview,
      setFile,
      validateArg,
    },
  };
};

export const VideoFileDropzone = ({
  label = "",
  name = "",
  videoFileDropzoneState,
  showDeleteButton = true,
}: {
  label?: string;
  name?: string;
  videoFileDropzoneState: ReturnType<typeof useVideoFileDropzoneState>;
  showDeleteButton?: boolean;
}) => {
  const {
    isError,
    _private: { setFile, validateArg, preview, setPreview },
  } = videoFileDropzoneState;

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0] as File;
      const result = URL.createObjectURL(file);
      setFile(file);
      validateArg(file);
      setPreview(result);
    },
    [setFile, setPreview, validateArg],
  );

  const deletePreview = () => {
    setFile(undefined);
    validateArg(undefined);
    setPreview("");
  };

  return (
    <>
      {preview ? (
        <div className="video-preview">
          <video controls width="100%" src={preview} />
          {showDeleteButton && (
            <Text
              as="button"
              color="textLink"
              textDecoration="underline"
              _hover={{ textDecoration: "none" }}
              type="button"
              onClick={deletePreview}
              mt={2}
            >
              動画を変更
            </Text>
          )}
        </div>
      ) : (
        <Box>
          <Dropzone onDrop={onDrop} accept={{ "video/*": [] }} name={name} />
          {isError && <InputError>{`${label}は必須です`}</InputError>}
        </Box>
      )}
    </>
  );
};
