import React from "react";
import { Flash, Pagy } from "../../../shared/lib/types";
import {
  Box,
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import Application from "../../shared/components/layouts/Application";
import {
  BusinessMatchingBusinessEntities,
  SharedCurrentUser,
  SharedIndustries,
  SharedPrefectures,
} from "../../shared/lib/types";
import PagyPagination from "../../shared/components/atoms/PagyPagination";
import {
  businessMatchingBusinessEntityPath,
  newBusinessMatchingOwnedBusinessEntityPath,
} from "../../../../routes";
import { Button, Footer, Header } from "../../shared/components/atoms";
import Background from "../../shared/components/atoms/Background";
import HelpMessage from "../../shared/components/atoms/HelpMessage";
import SearchForm from "./SearchForm";

const BusinessEntitiesIndex = ({
  flash,
  businessEntities,
  industries,
  prefectures,
  currentUser,
  pagy,
}: {
  flash: Flash;
  businessEntities: BusinessMatchingBusinessEntities;
  industries: SharedIndustries;
  prefectures: SharedPrefectures;
  currentUser: SharedCurrentUser | null;
  pagy: Pagy;
}) => {
  const ownedBusinessEntities = currentUser?.owned_business_entities;
  return (
    <Application flash={flash} currentUser={currentUser}>
      <Header currentUser={currentUser} showBusinessMatchingHeader />
      <Background color="brand.50">
        {!currentUser?.is_business_matching_user && (
          <Stack gap={5} alignItems="center" mt="50px">
            <Button
              as="a"
              w="fit-content"
              href={newBusinessMatchingOwnedBusinessEntityPath()}
            >
              <Text pt={0.5}>受発注機能を利用開始する</Text>
            </Button>
            <HelpMessage
              as="a"
              // TODO: リンク先に受発注機能紹介を追加する
              href="https://www.notion.so/usr-aloop/b6a14a7dd65b444bae3bd15283b9b302?pvs=4#bcbba16a34ff4444a26d1764e13fab12"
              target="_blank"
              rel="noreferrer"
            >
              受発注機能とは？
            </HelpMessage>
          </Stack>
        )}
        <Container maxW="container.md" mt={{ base: 8, sm: 14 }}>
          <Flex
            direction={{ base: "column", lg: "row" }}
            justify="space-between"
            alignItems={{ base: "start", lg: "center" }}
            mb={5}
          >
            <Heading size="lg">ビジネスパートナー一覧</Heading>
            {ownedBusinessEntities?.current?.is_active && (
              <HelpMessage
                as="a"
                // TODO: リンク先に受発注機能紹介を追加する
                href="https://www.notion.so/usr-aloop/b6a14a7dd65b444bae3bd15283b9b302?pvs=4#bcbba16a34ff4444a26d1764e13fab12"
                target="_blank"
                rel="noreferrer"
              >
                受発注機能とは？
              </HelpMessage>
            )}
          </Flex>

          <SearchForm
            industries={industries}
            prefectures={prefectures}
            currentBusinessEntity={ownedBusinessEntities?.current}
          />

          <Stack gap={5} mt={{ base: 6, md: 12 }} px={4}>
            {businessEntities.map((businessEntity) => (
              <Box
                backgroundColor="white"
                pl={4}
                py={4}
                key={businessEntity.code}
                as="a"
                display="block"
                href={businessMatchingBusinessEntityPath(businessEntity.code)}
              >
                <HStack gap={10}>
                  <Flex w="146px" h="87px" justify="center">
                    <Image src={businessEntity.logo_image_url} />
                  </Flex>
                  <Stack gap={2}>
                    {ownedBusinessEntities?.current?.is_active && (
                      <Text fontWeight="bold">{businessEntity.name}</Text>
                    )}
                    <Text>{businessEntity.prefecture_name}</Text>
                    <Text>主業種：{businessEntity.industry_name}</Text>
                  </Stack>
                </HStack>
              </Box>
            ))}
          </Stack>
          <Flex justifyContent="center" mt={14}>
            <PagyPagination pagy={pagy} />
          </Flex>
        </Container>
      </Background>
      <Footer />
    </Application>
  );
};

export default BusinessEntitiesIndex;
