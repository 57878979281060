import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const SmsIcon = (props: IconProps) => (
  <Icon viewBox="0 0 18 18" boxSize="18px" {...props}>
    <path
      d="M6 8.25C6.2125 8.25 6.39062 8.17813 6.53438 8.03438C6.67812 7.89063 6.75 7.7125 6.75 7.5C6.75 7.2875 6.67812 7.10938 6.53438 6.96562C6.39062 6.82188 6.2125 6.75 6 6.75C5.7875 6.75 5.60938 6.82188 5.46562 6.96562C5.32187 7.10938 5.25 7.2875 5.25 7.5C5.25 7.7125 5.32187 7.89063 5.46562 8.03438C5.60938 8.17813 5.7875 8.25 6 8.25ZM9 8.25C9.2125 8.25 9.39063 8.17813 9.53438 8.03438C9.67813 7.89063 9.75 7.7125 9.75 7.5C9.75 7.2875 9.67813 7.10938 9.53438 6.96562C9.39063 6.82188 9.2125 6.75 9 6.75C8.7875 6.75 8.60938 6.82188 8.46563 6.96562C8.32188 7.10938 8.25 7.2875 8.25 7.5C8.25 7.7125 8.32188 7.89063 8.46563 8.03438C8.60938 8.17813 8.7875 8.25 9 8.25ZM12 8.25C12.2125 8.25 12.3906 8.17813 12.5344 8.03438C12.6781 7.89063 12.75 7.7125 12.75 7.5C12.75 7.2875 12.6781 7.10938 12.5344 6.96562C12.3906 6.82188 12.2125 6.75 12 6.75C11.7875 6.75 11.6094 6.82188 11.4656 6.96562C11.3219 7.10938 11.25 7.2875 11.25 7.5C11.25 7.7125 11.3219 7.89063 11.4656 8.03438C11.6094 8.17813 11.7875 8.25 12 8.25ZM1.5 16.5V3C1.5 2.5875 1.64688 2.23438 1.94063 1.94063C2.23438 1.64688 2.5875 1.5 3 1.5H15C15.4125 1.5 15.7656 1.64688 16.0594 1.94063C16.3531 2.23438 16.5 2.5875 16.5 3V12C16.5 12.4125 16.3531 12.7656 16.0594 13.0594C15.7656 13.3531 15.4125 13.5 15 13.5H4.5L1.5 16.5ZM3.8625 12H15V3H3V12.8438L3.8625 12Z"
      fill="currentColor"
    />
  </Icon>
);

export default SmsIcon;
