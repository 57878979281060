import React from "react";
import useFileState from "../../lib/useFileState";
import Dropzone from "./Dropzone";
import DropzonePreview from "./DropzonePreview";
import { Box } from "@chakra-ui/react";

const ArchitectLicenseImageField = ({
  fileState,
}: {
  fileState: ReturnType<typeof useFileState>;
}) => {
  return (
    <>
      {fileState.file == null ? (
        <Dropzone
          onDrop={(files) => {
            fileState.setFile(files[0]);
          }}
          name="architect_license_image"
          message={
            <Box wordBreak={"keep-all"}>
              構造一級建築士、または設備一級建築士をお持ちの方は、一級建築士の免許証画像を<wbr />アップロードしてください。
              <br />
              PNG、JPGもしくはPDFファイルで、5MB未満のサイズでアップロードしてください。
            </Box>
          }
          error={fileState.error}
          accept={{
            "image/png": [],
            "image/jpeg": [],
            "application/pdf": [],
          }}
        />
      ) : (
        <DropzonePreview fileState={fileState} />
      )}
    </>
  );
};

export default ArchitectLicenseImageField;
