import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const BrandAwareness = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" boxSize={8} {...props}>
    <mask
      id="mask0_10971_33458"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="32"
      height="32"
    >
      <rect width="32" height="32" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_10971_33458)">
      <path
        d="M21.3333 17.3328V14.6661H26.6667V17.3328H21.3333ZM22.9333 26.6661L18.6667 23.4661L20.2667 21.3328L24.5333 24.5328L22.9333 26.6661ZM20.2667 10.6661L18.6667 8.53276L22.9333 5.33276L24.5333 7.4661L20.2667 10.6661ZM4 19.9994V11.9994H9.33333L16 5.33276V26.6661L9.33333 19.9994H4Z"
        fill="#3CAA91"
      />
    </g>
  </Icon>
);

export default BrandAwareness;
