import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const ThreadsIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" boxSize={8} {...props}>
    <path
      d="M19.5798 15.3558C19.6531 15.3857 19.7197 15.419 19.7896 15.4489C20.7623 15.9179 21.4752 16.6196 21.8482 17.491C22.3712 18.7049 22.4212 20.6772 20.8389 22.2537C19.6331 23.4576 18.1641 23.9997 16.0888 24.0164H16.0788C13.7404 23.9997 11.945 23.2148 10.7358 21.6816C9.65986 20.318 9.1069 18.4189 9.08691 16.0409V16.0309V16.0243C9.10357 13.6463 9.65653 11.7472 10.7325 10.3836C11.9416 8.85036 13.7404 8.06546 16.0788 8.04883H16.0888C18.4306 8.06546 20.2493 8.84704 21.4951 10.3736C22.1081 11.1286 22.5611 12.0366 22.8476 13.0909L21.5018 13.4501C21.2653 12.592 20.9089 11.8603 20.4292 11.2749C19.4565 10.0843 17.9975 9.4723 16.0822 9.459C14.1835 9.47563 12.7478 10.0843 11.8117 11.2683C10.939 12.3758 10.486 13.9789 10.4693 16.0309C10.486 18.083 10.939 19.6861 11.8117 20.7969C12.7444 21.9809 14.1835 22.5896 16.0822 22.6062C17.7943 22.5929 18.9269 22.1871 19.8696 21.2459C20.9455 20.175 20.9255 18.8579 20.5824 18.0564C20.3792 17.5841 20.0128 17.1917 19.5198 16.8957C19.3966 17.7903 19.1268 18.5021 18.6971 19.0508C18.1274 19.7759 17.318 20.1683 16.2754 20.2249C15.4892 20.2681 14.7331 20.0785 14.1468 19.6927C13.4539 19.2338 13.0476 18.5353 13.0043 17.7205C12.921 16.1141 14.1934 14.96 16.1754 14.8469C16.8783 14.807 17.5378 14.8369 18.1474 14.94C18.0675 14.4478 17.9043 14.0554 17.6611 13.7693C17.328 13.3802 16.8083 13.1807 16.1221 13.1773H16.0988C15.5459 13.1773 14.7997 13.3303 14.3234 14.052L13.1775 13.2671C13.817 12.2993 14.853 11.7672 16.1021 11.7672H16.1288C18.214 11.7805 19.4565 13.0809 19.5831 15.3491L19.5765 15.3558H19.5798ZM14.3833 17.644C14.4266 18.4788 15.3293 18.8679 16.2021 18.818C17.0548 18.7715 18.0208 18.4389 18.1841 16.3835C17.7444 16.287 17.258 16.2371 16.7384 16.2371C16.5785 16.2371 16.4186 16.2405 16.2587 16.2504C14.8297 16.3303 14.3533 17.022 14.3866 17.6407L14.3833 17.644Z"
      fill="#212529"
    />
  </Icon>
);

export default ThreadsIcon;
