import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const PinterestIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" boxSize={8} {...props}>
    <path
      d="M16.4018 7.0105C12.6904 7.0105 9.02246 9.48342 9.02246 13.4856C9.02246 16.0309 10.4549 17.477 11.3231 17.477C11.6812 17.477 11.8874 16.4792 11.8874 16.1972C11.8874 15.8609 11.0301 15.1451 11.0301 13.7459C11.0301 10.8392 13.2439 8.77842 16.1088 8.77842C18.5722 8.77842 20.3953 10.1776 20.3953 12.7481C20.3953 14.6679 19.6248 18.2688 17.1288 18.2688C16.2281 18.2688 15.4577 17.618 15.4577 16.6852C15.4577 15.3186 16.4126 13.9954 16.4126 12.5854C16.4126 10.192 13.016 10.6259 13.016 13.5182C13.016 14.1256 13.0919 14.798 13.3632 15.3512C12.864 17.4987 11.844 20.6983 11.844 22.9109C11.844 23.5942 11.9416 24.2667 12.0067 24.95C12.1297 25.0874 12.0682 25.0729 12.2563 25.0042C14.0795 22.5096 14.0143 22.0215 14.8391 18.7569C15.284 19.6029 16.4343 20.0584 17.3459 20.0584C21.1875 20.0584 22.9129 16.3165 22.9129 12.9433C22.9129 9.35326 19.8093 7.0105 16.4018 7.0105Z"
      fill="#212529"
    />
  </Icon>
);

export default PinterestIcon;
