import { Box, Stack, Text } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { Accept, useDropzone } from "react-dropzone";
import FileUploadIcon from "../icons/FileUploadIcon";
import { InputError } from "./form";

const Dropzone = ({
  onDrop,
  accept = {},
  name = "",
  multiple = false,
  message = undefined,
  error = undefined,
}: {
  onDrop: (acceptedFiles: File[]) => void;
  accept?: Accept;
  name?: string;
  multiple?: boolean;
  message?: ReactNode;
  error?: string;
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop,
    accept: accept,
    multiple,
  });

  return (
    <Box>
      <Stack
        {...getRootProps()}
        height={40}
        border="2px dashed #7D8B88"
        align="center"
        justify="center"
        borderRadius="2px"
        cursor="pointer"
        gap={0}
        bgColor="brand.50"
        px={4}
      >
        <input
          {...getInputProps()}
          name={name}
          data-testid={`file-input-${name}`}
        />
        {isDragActive ? (
          <Text color="#82908D" fontWeight="600" mt={2}>
            ファイルをここにドロップ...
          </Text>
        ) : (
          <>
            <FileUploadIcon color="#82908D" boxSize={10} />
            <Text
              color="#82908D"
              fontWeight="600"
              mt={2}
              fontSize={{ base: "sm", md: "md" }}
            >
              ファイルをここにドラッグ＆ドロップ
            </Text>
            <Text color="#82908D" fontSize={{ base: "xs", md: "sm" }}>
              もしくはクリックしてファイルを選択
            </Text>
          </>
        )}
      </Stack>
      {message != null && (
        <Box as="span" fontSize="xs" color="#6D787D">
          {message}
        </Box>
      )}
      {error != null && <InputError>{error}</InputError>}
    </Box>
  );
};

export default Dropzone;
