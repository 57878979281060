import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const AddIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" boxSize={5} {...props}>
    <path
      d="M15.8333 10.8332H10.8333V15.8332H9.16663V10.8332H4.16663V9.1665H9.16663V4.1665H10.8333V9.1665H15.8333V10.8332Z"
      fill="currentColor"
    />
  </Icon>
);

export default AddIcon;
