import { Flex, FlexProps, Text } from "@chakra-ui/react";
import InfoIcon from "../icons/InfoIcon";
import React, { ReactNode } from "react";

const InfoMessage = ({
  children,
  ...props
}: {
  children: ReactNode;
} & FlexProps) => {
  return (
    <Flex
      p={3}
      gap={2}
      color="#276749"
      bgColor="#F0FFF4"
      borderRadius="2px"
      {...props}
    >
      <InfoIcon />
      <Text fontSize="xs" mt={0.5}>{children}</Text>
    </Flex>
  );
};

export default InfoMessage;
