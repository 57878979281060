import { Box, Flex, List, ListItem, Stack } from "@chakra-ui/react";
import React from "react";
import ArrowRightIcon from "../icons/ArrowRightIcon";

const SideMenu = ({
  items,
}: {
  items: {
    label: string;
    href?: string;
  }[];
}) => {
  return (
    <Stack as={List} gap={2}>
      {items.map((item) => {
        const isActive = location.pathname === item.href;
        const style = isActive
          ? {
              color: "white",
              bgColor: "primary",
              _hover: { bgColor: "primary" },
            }
          : { color: "primary" };
        return (
          <ListItem
            key={item.label}
            py={2}
            px={3}
            borderRadius="2px"
            as="a"
            href={item.href}
            _hover={{ backgroundColor: "#D5EDE8" }}
            {...style}
          >
            <Flex align="center">
              <ArrowRightIcon boxSize={6} />
              <Box pt="2px" color="inherit">
                {item.label}
              </Box>
            </Flex>
          </ListItem>
        );
      })}
    </Stack>
  );
};

export default SideMenu;
