import { Box, Link, Stack, StackProps } from "@chakra-ui/react";
import React from "react";
import {
  newContactPath,
  privacyPolicyPath,
  termsOfServicePath,
} from "../../../../../routes";

const Footer = (props: StackProps) => {
  return (
    <>
      <Stack
        gap={5}
        align="center"
        py={5}
        backgroundColor="primary"
        color="white"
        zIndex={1}
        {...props}
      >
        <Stack
          columnGap={8}
          rowGap={4}
          fontSize="sm"
          direction={{ base: "column", sm: "row" }}
          mx={6}
          alignSelf={{ base: "flex-start", sm: "auto" }}
        >
          {[
            { label: "お問い合わせ", href: newContactPath() },
            { label: "個人情報保護方針", href: privacyPolicyPath() },
            { label: "利用規約", href: termsOfServicePath() },
          ].map(({ label, href }) => (
            <Link
              color="inherit"
              textDecor="inherit"
              href={href}
              target="_blank"
              rel="noreferrer"
              key={label}
            >
              {label}
            </Link>
          ))}
        </Stack>
        <Box
          fontSize="xs"
          wordBreak="keep-all"
          overflow="anywhere"
          textAlign="center"
          as="a"
          href="https://www.unions.co.jp/company/"
          target="_blank"
          rel="noreferrer"
        >
          運営会社：ユニオンシステム株式会社{" "}
          <Box whiteSpace="nowrap" as="span">
            ⒸUNION SYSTEM Inc. All rights reserved.
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default Footer;
