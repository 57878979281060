import { Tab, TabIndicator, TabList, Tabs } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const PostOrderLinks = () => {
  const [currentIndex, setCurrentIndex] = useState<number>();
  useEffect(() => {
    const search = new URLSearchParams(location.search);
    switch (search.get("order")) {
      case null:
      case undefined:
      case "":
        setCurrentIndex(0);
        break;
      case "comments":
        setCurrentIndex(1);
        break;
      case "views":
        setCurrentIndex(2);
        break;
    }
  }, []);

  return (
    <Tabs index={currentIndex} onChange={() => {}}>
      <TabList border="none" gap={4}>
        {[
          { label: "新着順", order: "" },
          { label: "コメント数順", order: "comments" },
          { label: "閲覧数順", order: "views" },
        ].map(({ label, order }) => {
          const href = new URL(location.href)
          href.searchParams.delete("order")
          href.searchParams.delete("page")
          href.searchParams.append("order", order)
          return <Tab
            _selected={{ fontWeight: "bold" }}
            px={0}
            key={label}
            as="a"
            href={href.toString()}
            fontSize="sm"
            _active={{
              bg: undefined
            }}
            _focusVisible={{
              boxShadow: undefined
            }}
          >
            {label}
          </Tab>
        })}
      </TabList>
      <TabIndicator mt="-4px" height="4px" bg="primary" />
    </Tabs>
  );
};

export default PostOrderLinks;
