import { Stack, StackProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";

const Overlay = ({
  children,
  ...props
}: { children: ReactNode } & StackProps) => {
  return (
    <Stack
      position="absolute"
      top={0}
      left={0}
      w="full"
      h="full"
      alignItems="center"
      justify="center"
      {...props}
    >
      {children}
    </Stack>
  );
};

export default Overlay;
