import { Box, Flex, HStack, Show, Stack } from "@chakra-ui/react";
import { default as React } from "react";
import AnnouncementListItem from "../../shared/components/atoms/AnnouncementListItem";
import { SharedAnnouncements } from "../../shared/lib/types";
import BrandAwareness from "../../shared/components/icons/BrandAwareness";
import { Button } from "../../shared/components/atoms";
import { ChevronRightIcon } from "../../shared/components/icons";
import { announcementsPath } from "../../../../routes";

const Announcements = ({
  announcements,
  announcementsCount,
}: {
  announcements: SharedAnnouncements;
  announcementsCount: number;
}) => {
  return (
    <Box>
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <HStack gap={1}>
          <BrandAwareness />
          <Box pt={1} fontWeight={"bold"} fontSize={20}>
            お知らせ
          </Box>
        </HStack>
        <Show above="md">
          {announcementsCount > 3 && (
            <Button
              size="sm"
              rightIcon={<ChevronRightIcon />}
              variant="outline"
              iconSpacing={1}
              as="a"
              href={announcementsPath()}
            >
              <Box pt={0.5}>すべてのお知らせを見る</Box>
            </Button>
          )}
        </Show>
      </Flex>
      <Stack mt={4}>
        {announcements.map((announcement) => (
          <AnnouncementListItem
            key={announcement.code}
            announcement={announcement}
          />
        ))}
      </Stack>
      <Show below="md">
        <Flex justifyContent={"center"} mt={7}>
          <Button
            size="sm"
            rightIcon={<ChevronRightIcon />}
            variant="outline"
            iconSpacing={1}
            as="a"
            href={announcementsPath()}
          >
            <Box pt={0.5}>すべてのお知らせを見る</Box>
          </Button>
        </Flex>
      </Show>
    </Box>
  );
};

export default Announcements;
