import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const BusinessCenterIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" boxSize={5} {...props}>
    <mask
      id="mask0_3137_10733"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="21"
    >
      <rect y="0.5" width="20" height="20" fill="currentColor" />
    </mask>
    <g mask="url(#mask0_3137_10733)">
      <path
        d="M3.33366 18.0001C2.87533 18.0001 2.48296 17.8369 2.15658 17.5105C1.83019 17.1841 1.66699 16.7917 1.66699 16.3334V7.16675C1.66699 6.70842 1.83019 6.31605 2.15658 5.98967C2.48296 5.66328 2.87533 5.50008 3.33366 5.50008H6.66699V3.83341C6.66699 3.37508 6.83019 2.98272 7.15658 2.65633C7.48296 2.32994 7.87533 2.16675 8.33366 2.16675H11.667C12.1253 2.16675 12.5177 2.32994 12.8441 2.65633C13.1705 2.98272 13.3337 3.37508 13.3337 3.83341V5.50008H16.667C17.1253 5.50008 17.5177 5.66328 17.8441 5.98967C18.1705 6.31605 18.3337 6.70842 18.3337 7.16675V16.3334C18.3337 16.7917 18.1705 17.1841 17.8441 17.5105C17.5177 17.8369 17.1253 18.0001 16.667 18.0001H3.33366ZM8.33366 5.50008H11.667V3.83341H8.33366V5.50008ZM16.667 13.0001H12.5003V14.6667H7.50033V13.0001H3.33366V16.3334H16.667V13.0001ZM9.16699 13.0001H10.8337V11.3334H9.16699V13.0001ZM3.33366 11.3334H7.50033V9.66675H12.5003V11.3334H16.667V7.16675H3.33366V11.3334Z"
        fill="currentColor"
      />
    </g>
  </Icon>
);

export default BusinessCenterIcon;
