import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const ContactSupportIcon = (props: IconProps) => (
  <Icon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_7771_46220"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="20"
    >
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_7771_46220)">
      <path
        d="M10 18.3337L9.79167 15.8337H9.58333C7.61111 15.8337 5.9375 15.1462 4.5625 13.7712C3.1875 12.3962 2.5 10.7225 2.5 8.75033C2.5 6.7781 3.1875 5.10449 4.5625 3.72949C5.9375 2.35449 7.61111 1.66699 9.58333 1.66699C10.5694 1.66699 11.4896 1.85102 12.3438 2.21908C13.1979 2.58713 13.9479 3.09408 14.5938 3.73991C15.2396 4.38574 15.7465 5.13574 16.1146 5.98991C16.4826 6.84408 16.6667 7.76421 16.6667 8.75033C16.6667 9.79199 16.4965 10.792 16.1563 11.7503C15.816 12.7087 15.3507 13.5975 14.7604 14.417C14.1701 15.2364 13.4688 15.9795 12.6563 16.6462C11.8438 17.3128 10.9583 17.8753 10 18.3337ZM9.5625 13.3128C9.79861 13.3128 10 13.2295 10.1667 13.0628C10.3333 12.8962 10.4167 12.6948 10.4167 12.4587C10.4167 12.2225 10.3333 12.0212 10.1667 11.8545C10 11.6878 9.79861 11.6045 9.5625 11.6045C9.32639 11.6045 9.125 11.6878 8.95833 11.8545C8.79167 12.0212 8.70833 12.2225 8.70833 12.4587C8.70833 12.6948 8.79167 12.8962 8.95833 13.0628C9.125 13.2295 9.32639 13.3128 9.5625 13.3128ZM8.95833 10.667H10.2083C10.2083 10.2503 10.25 9.95866 10.3333 9.79199C10.4167 9.62533 10.6806 9.31977 11.125 8.87533C11.375 8.62533 11.5833 8.35449 11.75 8.06283C11.9167 7.77116 12 7.45866 12 7.12533C12 6.41699 11.7604 5.88574 11.2813 5.53158C10.8021 5.17741 10.2361 5.00033 9.58333 5.00033C8.97222 5.00033 8.45833 5.17046 8.04167 5.51074C7.625 5.85102 7.33333 6.26421 7.16667 6.75033L8.33333 7.20866C8.40278 6.97255 8.53472 6.73991 8.72917 6.51074C8.92361 6.28158 9.20833 6.16699 9.58333 6.16699C9.95833 6.16699 10.2396 6.27116 10.4271 6.47949C10.6146 6.68783 10.7083 6.91699 10.7083 7.16699C10.7083 7.4031 10.6389 7.61491 10.5 7.80241C10.3611 7.98991 10.1944 8.18088 10 8.37533C9.51389 8.79199 9.21875 9.12185 9.11458 9.36491C9.01042 9.60796 8.95833 10.042 8.95833 10.667Z"
        fill="#3CAA91"
      />
    </g>
  </Icon>
);

export default ContactSupportIcon;
