import { Avatar, Box, Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";
import {
  BusinessMatchingSharedTalkMessage,
  TalkRooms,
} from "../../../shared/lib/types";
import dayjs from "dayjs";
import FilesWithoutImage from "../../../shared/components/atoms/FilesWithoutImage";
import ImagesAttachedWithPreview from "../../../shared/components/atoms/ImagesAttachedWithPreview";

function getPrValue(is_own: boolean) {
  if (!is_own) {
    return { base: 12, sm: "7.5rem" };
  } else {
    return { base: 0, sm: 0 };
  }
}

function getPlValue(is_own: boolean) {
  if (is_own) {
    return { base: 12, sm: "7.5rem" };
  } else {
    return { base: 0, sm: 0 };
  }
}

const TalkMessageBubble = ({
  is_own,
  is_consecutive,
  partner,
  message,
}: {
  is_own: boolean;
  is_consecutive: boolean;
  partner: TalkRooms[number]["partner"];
  message: BusinessMatchingSharedTalkMessage;
}) => {
  const prValue = getPrValue(is_own);
  const plValue = getPlValue(is_own);

  return (
    <Box position="relative">
      {!is_own && !is_consecutive && (
        <Avatar
          src={partner.image_url}
          boxSize={{ base: 8, sm: 12 }}
          position="absolute"
        />
      )}
      <Flex
        pl={{ base: "2.75rem", sm: "3.75rem" }}
        gap={3}
        justifyContent={is_own ? "flex-end" : undefined}
      >
        <Stack maxW={{ base: "full", xl: "3xl" }}>
          <Stack
            gap={1}
            alignItems={is_own ? "flex-end" : "flex-start"}
            pr={prValue}
            pl={plValue}
          >
            {!is_own && !is_consecutive && (
              <Text fontSize="sm" fontWeight="bold">
                {partner.name}
              </Text>
            )}
            <Box
              px={4}
              py={3}
              bgColor={is_own ? "brand.100" : "gray.light"}
              fontSize="sm"
              borderRadius="8px"
              wordBreak="break-word"
              whiteSpace="pre-wrap"
              w="full"
            >
              {message.content}
            </Box>
            <Text fontSize="xs" color="textGray">
              {dayjs(message.created_at).format("LLL")}
            </Text>
            <Stack mt={2} w="full">
              <FilesWithoutImage files={message.files_without_image} />
              <ImagesAttachedWithPreview
                urls={message.image_files.map((image_file) => image_file.url)}
                justifyContent={is_own ? "flex-end" : "flex-start"}
              />
            </Stack>
          </Stack>
        </Stack>
      </Flex>
    </Box>
  );
};

export default TalkMessageBubble;
