import React from "react";
import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/react";

const DoneIcon = (props: IconProps) => (
  <Icon
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.16172 14.0002L2.88672 9.7252L3.95547 8.65645L7.16172 11.8627L14.043 4.98145L15.1117 6.0502L7.16172 14.0002Z"
      fill="currentColor"
    />
  </Icon>
);

export default DoneIcon;
