import {
  Box,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  rootPath,
  userDatabaseAuthenticationRegistrationPath,
} from "../../../../routes";
import { Flash } from "../../../shared/lib/types";
import Button from "../../shared/components/atoms/Button";
import { Input } from "../../shared/components/atoms/form";
import SiteSeal from "../../shared/components/atoms/SiteSeal";
import { SharedCurrentUser } from "../../shared/lib/types";
import useRequest from "../../shared/lib/useRequest";
import ProfilePageLayout from "../components/ProfilePageLayout";
import EmailEdit from "./EmailEdit";
import PasswordEdit from "./PasswordEdit";
import { yupResolver } from "@hookform/resolvers/yup";

const ProfileRegistrationsEdit = ({
  flash,
  currentUser,
}: {
  flash: Flash;
  currentUser: NonNullable<SharedCurrentUser>;
}) => {
  const schema = yup.object({
    current_password: yup.string().required().label("パスワード"),
  });

  type FormData = yup.InferType<typeof schema>;

  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    setError,
  } = useForm<FormData>({
    defaultValues: {
      current_password: "",
    },
    resolver: yupResolver(schema),
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const request = useRequest();

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    const res = await request(
      userDatabaseAuthenticationRegistrationPath(),
      "DELETE",
      data,
    );
    if (res.ok) {
      location.href = rootPath();
    } else if (res.status === 422) {
      const json = await res.json();
      setError("current_password", {
        type: "custom",
        message: json.errors.current_password[0],
      });
    }
  };

  return (
    <>
      <ProfilePageLayout current_user={currentUser} flash={flash}>
        <EmailEdit currentEmail={currentUser.email} />
        <PasswordEdit mt={6} />
        <Flex
          mt={8}
          wrap="wrap"
          justifyContent={{ base: "center", md: "space-between" }}
          rowGap={10}
        >
          <Box>
            {currentUser.is_withdrawable && (
              <Link as="button" onClick={onOpen}>
                A-Loopから退会する
              </Link>
            )}
          </Box>
          <Box>
            <SiteSeal />
          </Box>
        </Flex>
      </ProfilePageLayout>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent mx={2}>
          <ModalCloseButton />
          <ModalHeader>A-Loopを退会する</ModalHeader>
          <ModalBody>
            <Text>
              退会手続き後、お客様の会員情報は消去されます。 <br />
              よろしければ以下から退会手続きを進めてください。
            </Text>
            <Box mt={8}>
              <Controller
                name="current_password"
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    label="確認のためにパスワードを入力"
                    type="password"
                    error={fieldState.error?.message ?? ""}
                    {...field}
                  />
                )}
              />
            </Box>
          </ModalBody>
          <ModalFooter gap={4} justifyContent="flex-end">
            <Button color="gray" onClick={onClose}>
              退会をキャンセル
            </Button>
            <Button
              color="red"
              type="submit"
              isDisabled={!isDirty}
              isLoading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              退会する
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProfileRegistrationsEdit;
