import React from "react";
import HelpMessage from "../../../shared/components/atoms/HelpMessage";
import {
  Box,
  BoxProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Image,
  Flex,
} from "@chakra-ui/react";
import InfoMessage from "../../../shared/components/atoms/InfoMessage";
import sample1 from "./sample1.webp";
import sample2 from "./sample2.webp";

const SampleModalButton = ({ ...props }: BoxProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <HelpMessage {...props} onClick={onOpen} cursor={"pointer"} w="fit-content">
        建築士免許証明書の画像例
      </HelpMessage>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={{ base: "full", md: "" }}
      >
        <ModalOverlay />
        <ModalContent maxW="container.md">
          <ModalHeader>建築士免許証明書 画像例</ModalHeader>
          <ModalCloseButton top={2.5} aria-label="閉じる"/>
          <ModalBody pb={7}>
            <InfoMessage>
              以下の画像はイメージです。建築士免許証明書に記載されている内容は、一級、二級、木造建築士によって異なります。
            </InfoMessage>
            <Flex
              bgColor="#F2F2F2"
              gap={{base: 5, md: 16}}
              px={{base: 14, md: 12}}
              py={5}
              alignItems="center"
              mt={5}
              direction={{ base: "column", md: "row" }}
            >
              <Box flex={6}>
                <Image src={sample1} />
              </Box>
              <Box flex={4} px={{ base: 5, md: 0 }}>
                <Image src={sample2} />
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SampleModalButton;
