import {
  HelperPostResourceAuthor,
  SharedCurrentUser,
  SharedUser,
} from "./types";

export const isVisitableUserProfile = (
  user: SharedUser | HelperPostResourceAuthor,
  currentUser: SharedCurrentUser,
) => {
  if (!currentUser?.is_all_public_feature_accessible) return false;

  return user.is_profile_visitable;
};
