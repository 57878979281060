import { Flex, Text } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import WarningAmberIcon from "../icons/WarningAmberIcon";

type Props = {
  children: ReactNode;
};

const ErrorMessage = ({ children }: Props) => {
  return (
    <Flex
      display="flex"
      padding="12px 12px 10px 12px"
      alignItems="center"
      gap="12px"
      border-radius="4px"
      background="#FFF3F3"
      width="100%"
      justifyContent="center"
    >
      <WarningAmberIcon color="caution" boxSize={7} />
      <Text color="#E11F1F" fontSize="11px" fontWeight="400" lineHeight="160%">
        {children}
      </Text>
    </Flex>
  );
};

export default ErrorMessage;
